import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Result, Alert, Button, Divider } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { Context as AuthContext } from '../context/AuthContext';

export default function ApiErrorResult(props) {
  //Template
  const { t } = useTranslation()
  const { authState, signout } = useContext(AuthContext)
  const navigate = useNavigate()
  const style = props?.style
  const template = (props?.template) ? props.template : 'page'
  const errorData = props?.errorData
  let title = props?.title
  let subTitle = props?.subTitle
  let status = props?.status
  let actionTitle = props?.actionTitle
  let actionHandle = props?.actionHandle

  useEffect(() => {
    if (errorData && errorData?.code === 'BAD_TOKEN' && authState.token) {
      signout()
      navigate('/session-expired', { replace: true })
    }
  }, [])

  const getExtra = (title, handle) => {
    if (title && handle) {
      return <Button type="primary" onClick={handle}>{title}</Button>
    } else if (title) {
      return (
        <Alert
          description={<><InfoCircleOutlined /> {actionTitle}</>}
          type="info"
          style={{ textAlign:'center' }}
        />
      )
    } else {
      return null
    }
  }

  if (errorData) {
    if (errorData?.code === 'UNAUTHORISED') {
      status = '403'
      title = '403'
      subTitle = t('Sorry, you are not authorized to access this page.')
      if (!authState.token) {
        actionTitle = t('You must log in to access this page.')
        actionHandle = null
      }
    } else if (errorData?.code === 'BAD_DATA_POST') {
      status = '400'
      title = t('Form validation error')

      if (Array.isArray(errorData.extraData)) {
        subTitle = (
          <>
            <Divider />
            <ul style={{ margin:0, padding:5, listStyleType:'none' }}>
              {errorData.extraData.map(item => {
                return <li>{item.message}</li>
              })}
            </ul>
          </>
        )
      } else {
        subTitle = t('Error while validating the form.')
      }
    }
  }

  if (template === 'page' || template === 'fullPage') {
    return (
      <main>
        {template === 'fullPage' && (
          <div style={{ margin:20, textAlign:'center' }}>
            <img
              src="/asset/system/sodikart-logo-black.png"
              alt="SODIKART"
              className="img-fluid img-center"
            />
          </div>
        )}
        <Result
          status={status ? status : '500'}
          title={title ? title : '500'}
          subTitle={subTitle ? subTitle : t('Sorry, something went wrong...')}
          extra={getExtra(actionTitle, actionHandle)}
          style={style}
        />
      </main>
    )
  
  } else if (template === 'alert') {
    return (
      <Alert
        message={<div style={{ textAlign:'center' }}>{title ? title : t('Error %s').replace('%s', status ? status : '500')}</div>}
        description={
          <div style={{ textAlign:'center' }}>
            <p>{subTitle ? subTitle : t('Sorry, something went wrong...')}</p>
            <p>{actionTitle && actionHandle ? <Button type="primary" onClick={actionHandle}>{actionTitle}</Button> : null}</p>
          </div>
        }
        type="error"
        style={style}
      />
    )
  
  } else {
    return (
      <>ApiErrorResult - Unknow type</>
    )
  }
}
