import { memo, useContext, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Modal, Alert, Space, List, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Context as AuthContext } from '../../context/AuthContext';
import { APIRequest, isBadResponse, delStickers } from '../../models/APIRequest';
import { axiosError } from '../Notifications';
import { isAdmin } from '../../utils/AccessRightUtils';

const StickerDeleteModal = (props) => {
  const TAG = 'StickerDeleteModal'

  //Template
  const { t } = useTranslation()
  const { Text } = Typography
  const stickers = props?.stickers
  const widgetType = props?.widgetType
  const widgetLabel = (props?.widgetLabel) ? props?.widgetLabel : t('Delete')
  const widgetProps = props?.widgetProps
  const onCancel = props?.onCancel
  const onDeletedSticker = props?.onDeletedSticker
  
  //States
  const DEFAULT_STATES = {
    isModalVisible: false,
    isSubmitting: false
  }
  const { authState } = useContext(AuthContext)
  const [ states, setStates ] = useState(DEFAULT_STATES)

  //Check right & availability
  if (!isAdmin(authState)) {
    console.log(TAG, 'Bad rights')
    return null
  } else if (!stickers || !stickers.length) {
    console.log(TAG, 'Bad stickers prop (array require)')
    return null
  }

  //Methods template
  const showModal = () => {
    setStates({...states, isModalVisible: true})
  }

  const hideModal = () => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
    
    setStates({
      ...states,
      ...DEFAULT_STATES
    })
  }

  const handleDeleteStickers = () => {
    console.log(TAG, 'handleDeleteStickers')

    setStates({ ...states, isSubmitting: true })

    const stickerIds = []
    stickers.forEach((sticker) => stickerIds.push(sticker.id))

    delStickers(APIRequest(authState.language, authState.token), stickerIds).then(function (results) {
      if (isBadResponse(results.data)) {
        axiosError(t)

        setStates({ ...states, isSubmitting: false })

        return
      }

      if (typeof onDeletedSticker === 'function') {
        onDeletedSticker(results.data.data.stickers_deleted)
      }
      
      hideModal()
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({ ...states, isSubmitting: false })
    })
  }

  const getWidget = () => {
    if (widgetType === 'menuItem') {
      return (
        <Text type='danger' onClick={showModal} {...widgetProps}>
          <DeleteOutlined /> {widgetLabel}
        </Text>
      )
    } else if (widgetType === 'buttonIcon') {
      return (
        <Button
          shape='circle'
          type='dashed'
          icon={<DeleteOutlined />}
          onClick={showModal}
          {...widgetProps}
        />
      )
    } else if (widgetType === 'button') {
      return (
        <Button
          icon={<DeleteOutlined />}
          onClick={showModal}
          {...widgetProps}
        >
          {widgetLabel}
        </Button>
      )
    } else {
      return 'Unknow widget type'
    }
  }

  //Modal
  return (
    <>
      {getWidget()}

      <Modal
        title={t('Delete sticker selection')}
        open={states.isModalVisible}
        zIndex={110}
        onCancel={hideModal}
        footer={[
          <Button type="link" key="deleteSelectionNo" onClick={hideModal}>
            {t('Cancel')}
          </Button>,
          <Button type="primary" key="deleteSelectionYes" disabled={states.isSubmitting} loading={states.isSubmitting} onClick={handleDeleteStickers}>
            {t('Delete')}
          </Button>,
        ]}
      >
        <Space direction="vertical" style={{ width:'100%' }}>
          <List
            size="small"
            dataSource={stickers}
            renderItem={item => (
              <List.Item style={{ flexDirection:'column', alignItems:'start' }}>
                <Text>{item.title}</Text>
              </List.Item>
            )}
          />

          <Alert
            description={t('Are you sure you want to permanently delete the above stickers?')}
            type="info"
            showIcon
          />
        </Space>
      </Modal>
    </>
  )
}

export default memo(StickerDeleteModal)
