import { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Typography, Space, Table, Button, Dropdown, Menu, Modal, Avatar, Tag, Popover } from 'antd';
import { DownOutlined, DeleteOutlined, EditOutlined, CloseOutlined, CheckOutlined, PlusOutlined, UserOutlined, KeyOutlined } from '@ant-design/icons';
import { APIRequest, isBadResponse, getUsers, activeUser, delUser } from '../../models/APIRequest';
import { Context as AuthContext } from '../../context/AuthContext';
import { ModalContext } from "../../context/ModalContext";
import ApiErrorResult from '../../components/ApiErrorResult';
import { axiosError } from '../../components/Notifications';
import TableHeader from '../../components/table/TableHeader';
import { transformSortOrderToApi } from '../../utils/ApiUtils';
import { getShortTimeFormat } from '../../utils/DateUtils';
import { getTablePagerInformations } from '../../utils/TemplateUtils';
import { GLOBAL_MODAL_TYPES, SYSTEM_RIGHT_LEVEL_COLOR } from "../../Constant";

export default function AdminUserScreen(props) {
  const TAG = 'AdminUserScreen'

  //Template
  const { t } = useTranslation()
  const { Text } = Typography
  const [ updateBreacrumbs ] = useOutletContext()
  const { openModal, closeModal } = useContext(ModalContext)
  const [ modal, contextHolder ] = Modal.useModal()
  const columns = [
    {
      title: t('Avatar'),
      dataIndex: 'avatar',
      sorter: false,
      showSorterTooltip: false,
      align:'center',
      width:40,
      render: (_, { name, avatar }) => {
        if (avatar) {
          return (
            <Avatar size={32} src={avatar} />
          )
        }

        return (
          <Avatar size={32} icon={<UserOutlined />} />
        )
      },
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: 'ascend',
    },
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('Origin'),
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      showSorterTooltip: false,
      filters: [
        {
          text: 'Sodikart',
          value: 'sodikart',
        },
        {
          text: t('System'),
          value: 'system',
        },
        {
          text: t('Sodiconnect'),
          value: 'sodiconnect',
        },
      ],
      filterMultiple: false,
      render: (_, { type }) => {
        return type.toUpperCase()
      }
    },
    {
      title: t('Conn. count'),
      dataIndex: 'connection_count',
      key: 'conncount',
      sorter: true,
      showSorterTooltip: false,
      align:'center',
    },
    {
      title: t('Last conn.'),
      dataIndex: 'date_last_connection',
      key: 'dateconn',
      sorter: true,
      showSorterTooltip: false,
      width:175,
      render: (_, { date_last_connection }) => {
        return (date_last_connection) ? moment(date_last_connection).format(getShortTimeFormat(authState.language)) : t('Never connected')
      }
    },
    {
      title: t('User type'),
      dataIndex: 'system_right',
      sorter: false,
      showSorterTooltip: false,
      align:'center',
      render: (_, { system_right }) => {
        return (
          <Text
            style={{
              backgroundColor: SYSTEM_RIGHT_LEVEL_COLOR[system_right.level-1],
              color:'#FFF',
              padding:'3px 6px',
            }}
          >
            {system_right.name}
          </Text>
        )
      },
    },
    {
      title: t('Access rights'),
      dataIndex: 'access_rights',
      sorter: false,
      showSorterTooltip: false,
      align:'center',
      render: (_, { access_rights }) => {
        if (!access_rights) {
          return t('No access rights')
        }

        const rights = []

        access_rights.forEach((item) => {
          rights.push(<li key={item.id}>{item.name}</li>)
        })

        return <Popover placement='left' content={<ul style={{ margin:0, padding:0, listStyleType:'none' }}>{rights}</ul>}>
          <Tag icon={<KeyOutlined />}>{access_rights.length}</Tag>
        </Popover>
      },
    },
    {
      title: t('Active'),
      dataIndex: 'active',
      key: 'active',
      sorter: true,
      showSorterTooltip: false,
      align:'center',
      width:50,
      filters: [
        {
          text: t('Yes'),
          value: 1,
        },
        {
          text: t('No'),
          value: 0,
        },
      ],
      filterMultiple: false,
      render: (_, { active }) => {
        if (active) {
          return <Text type="success"><CheckOutlined /></Text>
        } else {
          return <Text type="danger"><CloseOutlined /></Text>
        }
      },
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      align:'center',
      render: (_, record) => {
        const superAdmin = (record.id === 1)
        const contextMenu = []

        if (record.editable) {
          contextMenu.push({
            label: t('Edit'),
            icon: <EditOutlined />,
            key: 'edit',
          })
        }

        if (!superAdmin) {
          if (record.active) {
            contextMenu.push({
              label: t('Unactivate'),
              icon: <CloseOutlined />,
              key: 'unactive',
            })
          } else {
            contextMenu.push({
              label: t('Activate'),
              icon: <CheckOutlined />,
              key: 'active',
            })
          }
          
          contextMenu.push({ type: 'divider' })
          
          contextMenu.push({
            label: t('Delete'),
            icon: <DeleteOutlined />,
            key: 'delete',
            danger: true
          })
        }

        return (
          <Dropdown
            icon={<DownOutlined />}
            key={'actions' + record.id}
            loading={false}
            trigger={['click']}
            disabled={!contextMenu.length}
            overlay={
              <Menu
                onClick={({ item, key }) => handleActions(key, record)}
                items={contextMenu}
              />
            }
          >
            <Button block>
              <Space>
                {t('Actions')}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        )
      },
      width: 130,
    }
  ]

  //State
  const { authState } = useContext(AuthContext)
  const [ states, setStates ] = useState({
    firstLoad: true,
    isLoaded: false,
    isSubmitting: false,
    error: false,
    errorData: null,
    users: [],
    page: 1,
    pageMax: 1,
    total: 0,
    limit: 10,
    sortColumn: null,
    sortOrder: null,
    filters: null
  })

  //States methods
  const loadData = () => {
    console.log(TAG, 'loadData')
    console.log(TAG, 'Getting data...')

    getUsers(APIRequest(authState.language, authState.token), states.page, states.limit, states.sortColumn, states.sortOrder, states.filters).then(function (results) {
      if (isBadResponse(results.data)) {
        setStates({
          ...states,
          isLoaded: false,
          firstLoad: false,
          error: true,
          errorData: results.data.data
        })

        return
      }

      setStates({
        ...states,
        isLoaded: true,
        firstLoad: false,
        users:  results.data.data.users,
        page: results.data.data.pager.page,
        pageMax: results.data.data.pager.page_max,
        total: results.data.data.pager.total,
        limit: results.data.data.pager.limit,
        error: false,
        errorData: null,
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: false,
        firstLoad: false,
        error: true,
        errorData: null,
      })
    })
  }

  //Events
  useEffect(() => {
    loadData()

    if (updateBreacrumbs) {
      console.log(TAG, 'updateBreacrumbs')
      updateBreacrumbs([{ title: t('Users'), 'route': null }])
    }
  }, [])

  useEffect(() => {
    if (states.firstLoad || states.error || states.isLoaded) {
      return
    }

    loadData()
  }, [states.isLoaded, states.error])

  //Template method
  const handleTableChange = (newPagination, filters, sorter) => {
    //Add search
    if (states.filters?.search) {
      filters['search'] = states.filters.search
    }

    setStates({
      ...states,
      page: newPagination.current,
      limit: newPagination.pageSize,
      sortColumn: sorter?.columnKey,
      sortOrder: transformSortOrderToApi(sorter?.order),
      filters: filters,
      isLoaded: false,
    })
  }

  const handleActions = (key, record) => {
    if (key === 'edit') {
      openModal(
        GLOBAL_MODAL_TYPES.USER,
        { id: record.id, user: record },
        (userUpdated, close) => {
          console.log(TAG, userUpdated)
          if (userUpdated) {
            const users = Object.assign([], states.users)

            Object.keys(users).forEach(key => {
              if (users[key].id === record.id) {
                users[key] = userUpdated
              }
            })

            setStates({ ...states, users: users })
          }

          if (close) {
            closeModal()
          }
        },
        null
      )

    } else if (key === 'active' || key === 'unactive') {
      setStates({
        ...states,
        isSubmitting: true
      })

      handleActiveUser(record.id, (key === 'active') ? 1 : 0, (result) => {
        if (result) {
          setStates({
            ...states,
            isLoaded: false
          })

          return
        }

        setStates({
          ...states,
          isSubmitting: false
        })
      })

    } else if (key === 'delete') {
      modal.confirm({
        title: record.title,
        content: t('Are you sure you want to permanently delete this user?'),
        okText: t('Delete'),
        okType: 'danger',
        cancelText: t('Cancel'),
        onOk: () => {
          deleteUser(record.id)
        }
      })

    } else {
      console.log(TAG, 'Unknow action (' + key + ')...')
    }
  }

  const handleActiveUser = (userId, active, callback) => {
    activeUser(APIRequest(authState.language, authState.token), userId, active).then(function (results) {
      if (isBadResponse(results.data, true, t)) {
        callback(false)
        
        return
      }

      callback(true)
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      callback(false)
    })
  }

  const handleAddUser = () => {
    openModal(
      GLOBAL_MODAL_TYPES.USER,
      null,
      (folder, close) => {
        if (close) {
          closeModal()
        }

        setStates({ ...states, isLoaded:false })
      },
      null
    )
  }

  const deleteUser = (userId) => {
    console.log(TAG, 'deleteUser')

    setStates({ ...states, isSubmitting: true })

    delUser(APIRequest(authState.language, authState.token), userId).then(function (results) {
      if (isBadResponse(results.data)) {
        axiosError(t)

        setStates({ ...states, isSubmitting:false })

        return
      }

      setStates({ ...states, isLoaded: false, isSubmitting:false })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: false,
        error: true
      })
    })
  }

  if (states.error) {
    return (
      <ApiErrorResult errorData={states.errorData} actionTitle={t('Try to reload')} actionHandle={() => {
        setStates({ ...states, error:false, errorData:null, isLoaded:false })
      }} />
    )
  }

  return (
    <main className='admin-container'>
      <TableHeader
        title={t('Users')}
        buttons={[
          <Button type="primary" icon={<PlusOutlined />} disabled={states.isSubmitting} onClick={handleAddUser}>{t('Add user')}</Button>
        ]}
        showSearch={true}
        onSearchChange={value => {
          if (!value) {
            return
          }

          setStates({
            ...states,
            filters: {
              ...states.filters,
              search: value
            }
          })
        }}
        onSearchSubmit={() => {
          setStates({
            ...states,
            isLoaded: false,
            page: 1,
          })
        }}
        onSearchClear={() => {
          setStates({
            ...states,
            isLoaded: false,
            page: 1,
            filters: {
              ...states.filters,
              search: null
            }
          })
        }}
      />

      <Table
        columns={columns}
        size='small'
        rowKey={(item) => item.id}
        dataSource={states.users}
        pagination={{
          current: states.page,
          pageSize: states.limit,
          total: states.total,
          disabled: (!states.isLoaded),
          showTotal: (total) => getTablePagerInformations(t, states.page, states.limit, states.total),
          showSizeChanger: true,
          size: 'default'
        }}
        loading={!states.isLoaded || states.isSubmitting}
        onChange={handleTableChange}
        scroll={{ x: 680 }}
      />
      {contextHolder}
    </main>
  )
}
