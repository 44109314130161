import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Result, Button } from 'antd';
import { useNavigate } from "react-router-dom";

function SessionExpiredScreen(props) {
  //Route
  const navigate = useNavigate()

  //Template
  const { t } = useTranslation()

  return (
    <main>
      <div style={{ margin:20, textAlign:'center' }}>
        <img
          src="/asset/system/sodikart-logo-black.png"
          alt="SODIKART"
        />
      </div>
      <Result
        status="500"
        title={t('You have been disconnected')}
        subTitle={t('Your session has expired...')}
        extra={<Button type="primary" onClick={() => navigate('/')}>{t('Back to home')}</Button>}
      />
    </main>
  )
}

export default memo(SessionExpiredScreen)