import { useEffect, useState, useContext, createRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Form,
  Grid,
  Button,
  Space,
  Input,
  Radio,
  Checkbox,
  Select
} from 'antd';
import { Context as AuthContext } from '../../../context/AuthContext';
import { formError } from '../../../components/Notifications';
import { FILE_STATUS_DRAFT, FILE_STATUS_ONLINE, FILE_STATUS_ARCHIVE } from '../../../Constant';
import FileTreeSelect from '../../../components/form/FileTreeSelect';
import FormItemLang from '../../../components/form/FormItemLang';

export default function MediaParametersContent(props) {
  const TAG = 'MediaParametersContent'

  if (!props?.formDatas) {
    throw new Error(TAG + ' - PROPS formDatas is undefined')
  } else if (!props?.setParametersStates) {
    throw new Error(TAG + ' - PROPS setParametersStates is undefined')
  } else if (!props?.parametersStates) {
    throw new Error(TAG + ' - PROPS parametersStates is undefined')
  }

  //Template
  const { t } = useTranslation()
  const { Option } = Select
  const { useBreakpoint } = Grid
  const formRef = createRef()
  const screens = useBreakpoint()

  //States
  const { authState } = useContext(AuthContext)
  const formDatas = props.formDatas
  const setParametersStates = props.setParametersStates
  const [ formLayout, setFormLayout ] = useState('horizontal')
  const [ formLang, setFormLang ] = useState(authState.language)
  const [ states, setStates ] = useState(props.parametersStates)
  const [ data, setData ] = useState({
    checkAllChecked: false
  })
  const [ mainFolders, setMainFolders ] = useState([])
  
  //Template methods
  const onCheckAll = (e) => {
    const checked = []

    if (e.target.checked) {
      formDatas.accessRights.forEach(item => {
        checked.push(item.id)
      })
    }

    const newStates = {...states, access_rights: checked}
    
    formRef.current.setFieldsValue(newStates)
    setParametersStates(newStates)
    setStates(newStates)
    setData({...data, checkAllChecked: e.target.checked})
  }

  //Template methods
  const handleCopyLang = (name, currentLang, languages) => {
    const value = {}

    languages.forEach(lang => {
      value[lang.route] = states[name][currentLang]
    })

    const newStates = {...states, [name]: value}

    formRef.current.setFieldsValue(newStates)
    setParametersStates(newStates)
    setStates(newStates)
  }

  //Hooks
  useEffect(() => {
    setFormLayout((!screens.lg) ? 'vertical' : 'horizontal')
  }, [screens])

  const formItemLayout = (formLayout === 'horizontal')
      ? {labelCol:{span: 6}, wrapperCol:{span: 14}}
      : null

  return (
    <Form
      ref={formRef}
      {...formItemLayout}
      layout={formLayout}
      initialValues={states}
      onValuesChange={(changedValues, allValues) => {
        const _states = { ...states, ...allValues }

        //Fix select clear value
        if (_states.id_watermark === undefined) {
          _states.id_watermark = null
        }

        setParametersStates(_states)
        setStates(_states)
      }}
      onFinishFailed={({ values, errorFields, outOfDate }) => formError(errorFields, t)}
      onFinish={() => {
        props.onFinish()
      }}
    >
      <Form.Item
        label={t('Status')}
        name='status'
        required={true}
      >
        <Radio.Group
          options={[
            {label: t('Draft (Only for you and administror)'), value: FILE_STATUS_DRAFT},
            {label: t('Online'), value: FILE_STATUS_ONLINE},
            {label: t('Archive'), value: FILE_STATUS_ARCHIVE},
          ]}
          optionType="button"
          buttonStyle="solid"
        />
      </Form.Item>

      <Form.Item
        label={t('Folders')}
        name='folders'
        extra={t('Select where the media can be found')}
        required={true}
        rules={[
          {required: true, message: t('Folders is required')},
        ]}
      >
        <FileTreeSelect multiple={true} onChange={(ids, folders) => {
          //Automatic selection of default
          if (states.id_folder === null && ids.length === 1) {
            const newStates = { ...states, folders:ids, id_folder:ids[0] }

            formRef.current.setFieldsValue(newStates)
            setParametersStates(newStates)
            setStates(newStates)
          
          //Default folder not exists
          } else if (!ids.includes(states.id_folder)) {
            const newStates = { ...states, folders:ids, id_folder:null }

            formRef.current.setFieldsValue(newStates)
            setParametersStates(newStates)
            setStates(newStates)
          }

          //Set main folders (Bug if in data)
          setMainFolders(folders)
        }} />
      </Form.Item>

      <Form.Item
        label={t('Default folder')}
        name='id_folder'
        extra={t('Select the default folder of the media')}
        required={true}
        rules={[
          {required: true, message: t('Default folder is required')},
        ]}
        disabled={(mainFolders.length === 0)}
      >
        <Select placeholder={t('Select the default folder')} disabled={(mainFolders.length === 0)}>
          {mainFolders.map(item => 
            <Option value={item.id} key={"defaultFolderOption" + item.id}>{item.title}</Option>
          )}
        </Select>
      </Form.Item>
      
      <Form.Item label={t('Access rights')}>
        <Form.Item style={{marginBottom:0}}>
          <Checkbox onChange={onCheckAll}>{data.checkAllChecked ? t('Uncheck all') : t('Check all')}</Checkbox>
        </Form.Item>
        <Form.Item
          name='access_rights'
          required={true}
          rules={[
            {required: true, message: t('Access rights is required')},
          ]}
        >
          <Checkbox.Group style={{ marginTop:6 }}>
            {formDatas.accessRights.map(item => 
              <span key={"accessRightsOption" + item.id}>
                <Checkbox value={item.id}>{item.name}</Checkbox>
                <br />
              </span>
            )}
          </Checkbox.Group>
        </Form.Item>
      </Form.Item>

      {(formDatas.watermarks.length > 0) && (
        <Form.Item
          label={t('Watermark')}
          name='id_watermark'
          extra={t('The watermark is visible on thumbnails and downloaded pictures')}
        >
          <Select placeholder={t('Select a watermark')} allowClear={true}>
            {formDatas.watermarks.map(item => 
              <Option value={item.id} key={"WatermarkOption" + item.id}>{item.name}</Option>
            )}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        label={t('Stickers')}
        name='stickers'
      >
        <Select mode="multiple" allowClear placeholder={t('Add stickers on media')}>
          {formDatas.stickers.map(item => 
            <Option value={item.id} key={"stickersOption" + item.id}>{item.title}</Option>
          )}
        </Select>
      </Form.Item>

      <FormItemLang
        label={t('Terms of use')}
        name='terms_of_use'
        rules={[
          {max: 75, message: t('Terms of use must not exceed %d% characters').replace('%d%', 75)},
        ]}
        currentLang={formLang}
        onLangChange={(value) => {setFormLang(value)}}
        onCopyLang={handleCopyLang}
      >
        <Input />
      </FormItemLang>

      <Form.Item wrapperCol={{ offset: 0, span: 24 }} style={{textAlign:'center'}}>
        <Space>
          <Button onClick={props.onBack}>
            {t('Previous')}
          </Button>
          <Button type="primary" htmlType="submit">
            {t('Next')}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}