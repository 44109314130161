import { memo, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { Space, Typography, Collapse, Checkbox, Empty, Badge, Divider, Spin, Button } from 'antd';
import { FilterOutlined, PushpinOutlined, ApartmentOutlined, LoadingOutlined, PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import FileTree from '../FileTree'
import LoadingBlock from '../../components/LoadingBlock';
import { encodeObjectForUrl, decodeObjectFromUrl } from '../../utils/ApiUtils';
import { FOLDER_ROOT_ID, FILE_SORT_DEFAULT_COLUMN, FILE_SORT_DEFAULT_ORDER } from '../../Constant';
import { setStorageData, getStorageData } from '../../utils/StorageUtils';

const MediaFilterForm = (props) => {
  const TAG = "MediaFilterForm"

  //Route
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  //Template
  const { t } = useTranslation()
  const { Text } = Typography
  const { Panel } = Collapse
  const filters = props?.filters?.filters
  const filtersLoading = (props?.filters?.loading === true)
  const withHeader = (props?.withHeader === false) ? false : true
  const closeDrawer = props?.closeDrawer
  const idFolder = (params?.idFolder) ? params?.idFolder : FOLDER_ROOT_ID
  const search = params?.search ? params.search : null

  //States
  const DEFAULT_FILTER_STATES = {
    isDefault: true,
    time: moment().unix(),
    sort: {
      column: FILE_SORT_DEFAULT_COLUMN,
      order: FILE_SORT_DEFAULT_ORDER,
    },
    filters: null
  }
  const DEFAULT_STATES = {
    isLoaded: false,
    disabled: false,
    idFolder: (idFolder) ? parseInt(idFolder) : null,
    search: search,
    isSearchRoute: (location.pathname.match(/\/media\/search\/([0-9A-Za-z]{1,})/) !== null),
    filterValues: DEFAULT_FILTER_STATES,
    ellipsis: {}
  }
  const [ states, setStates ] = useState(DEFAULT_STATES)

  //Hooks
  useEffect(() => {
    console.log(TAG, '[params]')

    //Check is valid filters if have
    if (params?.filter && !decodeObjectFromUrl(params.filter)) {
      return
    }

    let idFolder = params?.idFolder
    const search = params?.search
    const filters = (params?.filter) ? decodeObjectFromUrl(params.filter) : DEFAULT_FILTER_STATES
    const isSearchRoute = location.pathname.match(/\/media\/search\/([0-9A-Za-z]{1,})/)

    if (!params.search) {
      setStorageData('MediaLastFolderId', params.idFolder)

      idFolder = (params?.idFolder) ? params?.idFolder : FOLDER_ROOT_ID
    }

    const oldStates = getOldStates(idFolder, search, filters)
    
    if (oldStates) {
      console.log(TAG, 'Using oldStates')
    }

    setStates({
      ...DEFAULT_STATES,
      ...oldStates,
      isLoaded: true,
      idFolder: parseInt(idFolder),
      search: params.search,
      isSearchRoute: isSearchRoute,
      filterValues: filters
    })
  }, [params])

  useEffect(() => {
    console.log(TAG, '[states]')

    try {
      setStorageData(TAG, JSON.stringify(states))
    } catch(e) {
      console.log(TAG, e)
    }
  }, [states])

  /************************************************* STATE METHODS ********************************************************/
  const getOldStates = (idFolder, search, filters) => {
    console.log(TAG, 'getOldStates')

    let oldStates = getStorageData(TAG)

    if (oldStates) {
      try {
        oldStates = JSON.parse(oldStates)

        if (!oldStates) {
          console.log(TAG, 'getOldStates - oldStates no found')
          oldStates = null
        
        } else if (oldStates?.idFolder != idFolder) {
          console.log(TAG, 'getOldStates - Folder ID is different')
          oldStates = null
        
        } else if (oldStates?.search != search) {
          console.log(TAG, 'getOldStates - Search is different')
          oldStates = null
        
        } else {
          console.log(TAG, 'getOldStates - oldStates OK!')
        }
      
      } catch(thrown) {
        console.log(TAG, thrown)
        oldStates = null
      }
    } else {
      console.log(TAG, 'getOldStates - oldStates not exists')

      oldStates = null
    }

    return oldStates
  }

  //Template methods
  const handleChangeFilter = (key, value, multipleValues) => {
    //Disabled forms
    setStates({ ...states, disabled: true })

    //Add filter
    const filters = Object.assign({}, states.filterValues.filters)

    if (multipleValues) {
      if (filters[key] && (!value || !value.length)) {
        delete filters[key]
      } else {
        filters[key] = value
      }
    } else {
      if (filters[key] && (!value || value === "")) {
        delete filters[key]
      } else {
        filters[key] = value
      }
    }

    const crypted = encodeObjectForUrl({
      ...states.filterValues,
      time: moment().unix(),
      isDefault: false,
      filters: filters,
    })
    
    if (!crypted) {
      console.log(TAG, 'Error in crypting filters...')
    }

    if (typeof closeDrawer === 'function') {
      closeDrawer()
    }

    if (states.search) {
      navigate('/media/search/' + states.search + '/filter/' + crypted)
    } else {
      navigate('/media/explore/' + states.idFolder + '/filter/' + crypted)
    }
  }

  const haveFilter = () => {
    if (!states.isSearchRoute) {
      return true
    }

    return (
      (states.isSearchRoute && filters?.folders?.options?.length) ||
      filters?.file_types?.options?.length ||
      filters?.keywords?.options?.length ||
      filters?.stickers?.options?.length ||
      filters?.countries?.options?.length ||
      filters?.locations?.options?.length
    )
  }

  const getEllipsisFormKey = (key) => {
    return (states.ellipsis && states.ellipsis.hasOwnProperty(key) && states.ellipsis[key] == true)
  }

  const getActiveFilterKeys = () => {
    if (!filters) {
      return []
    }

    return Object.keys(filters).filter(key => ((key === 'folders' && states.isSearchRoute) || (key !== 'folders' && filters[key].options?.length)))
  }

  const getPanelHeader = (filterKey) => {
    if (!filters || !filters[filterKey]) {
      return null
    }

    return (
      <div style={{ display:'flex', width:'100%', placeContent:'flex-start space-between', flexDirection:'row', alignContent:'flex-start', justifyContent: 'space-between' }}>
        <span style={{ fontSize:14, marginBottom:5, fontWeight:'bold' }}>
          {filters[filterKey].title}
        </span>
        {(states.filterValues?.filters && states.filterValues.filters[filterKey] && states.filterValues.filters[filterKey].length > 0) && 
          <span>
            <Badge count={states.filterValues.filters[filterKey].length} />
          </span>
        }
      </div>
    )
  }

  const getFiltersForm = () => {
    const filtersKey = getActiveFilterKeys()

    if (!haveFilter() || !filtersKey.length) {
      return <Empty description={t('No filters')} image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ marginTop:10, marginBottom:10 }} />
    }

    let i = 0

    return filtersKey.map(key => {
      i += 1

      return (
        <div key={'filtersBlock' + key}>
          <div style={(!getEllipsisFormKey(key)) ? { overflow:'hidden', maxHeight:170 } : null}>
            {getPanelHeader(key)}
            <Checkbox.Group
              style={{ width: '100%' }}
              onChange={(values) => handleChangeFilter(key, values, filters[key].multiple)}
              value={states.filterValues?.filters && states.filterValues.filters[key]}
              disabled={states.disabled}
            >
              <Space direction="vertical">
                {filters[key].options.map(option => {
                  let label = option.label

                  if (key === 'folders') {
                    const labels = label.split(' > ')

                    if (labels.length == 2) {
                      label = <>
                        <Text>{labels[1]}</Text>
                        <Text type='secondary'> <PushpinOutlined /> {labels[0]}</Text>
                      </>
                    } else {
                      label = <Text>{labels[0]}</Text>
                    }
                  }

                  return <Checkbox value={option.value} key={"filter" + key + option.value}>{label}</Checkbox>
                })}
              </Space>
            </Checkbox.Group>
          </div>
          {filters[key].options.length > 5 && (
            <Button
              type='link'
              onClick={() => {
                const ellipsis = Object.assign({}, states.ellipsis)
                ellipsis[key] = (getEllipsisFormKey(key)) ? false : true
                setStates({ ...states, ellipsis:ellipsis })
              }}
              block
            >
              {(!getEllipsisFormKey(key)) ? (
                <>
                  <PlusCircleOutlined /> {t('Show more filters')}
                </>
              ) : (
                <>
                  <MinusCircleOutlined /> {t('Show fewer filters')}
                </>
              )}
            </Button>
          )}
          {(filtersKey.length > 0 && i < filtersKey.length) && <Divider style={{ marginTop:10, marginBottom:10 }} />}
        </div>
      )
    })
  }

  if (!states.isLoaded) {
    return (
      <LoadingBlock />
    )
  }

  return (
    <Collapse defaultActiveKey={['filterFolderTree', 'filterFilters']} expandIconPosition='end'>
      {getActiveFilterKeys().length && (
        <Panel header={<Text strong><FilterOutlined /> {t('Filters')}</Text>} key="filterFilters">
          <Spin spinning={filtersLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
            {getFiltersForm()}
          </Spin>
        </Panel>
      )}

      {(!states.isSearchRoute) && (
        <Panel header={<Text strong><ApartmentOutlined /> {t('Explore')}</Text>} key="filterFolderTree" forceRender={true}>
          <div className="ant-collapse-content-no-padding">
            <FileTree updateBreacrumbs={props?.updateBreacrumbs} onSelect={() => {
              if (typeof closeDrawer === 'function') {
                closeDrawer()
              }
            }} />
          </div>
        </Panel>
      )}
    </Collapse>
  )
}

export default memo(MediaFilterForm)