import { memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Modal, Space, List, Progress, Divider, Select, Typography } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import { Context as AuthContext } from '../../context/AuthContext';
import { APIRequest, isBadResponse, regenerateThumbFile, getWatermarks } from '../../models/APIRequest';
import { axiosError } from '../Notifications';
import { isContributor } from '../../utils/AccessRightUtils';

const FileRegenerateThumbModal = (props) => {
  const TAG = 'FileRegenerateThumbModal'

  //Template
  const { t } = useTranslation()
  const { Text } = Typography
  const { Option } = Select
  const files = props?.files
  const widgetType = props?.widgetType
  const widgetLabel = (props?.widgetLabel) ? props?.widgetLabel : t('Regenerate thumbnails')
  const widgetProps = props?.widgetProps
  const onRegenerateThumbFile = props?.onRegenerateThumbFile
  const onRegenerateThumbFiles = props?.onRegenerateThumbFiles
  
  //States
  const DEFAULT_STATES = {
    isLoaded: false,
    isModalVisible: false,
    isSubmitting: false,
    submitFilesProcessed: 0,
    watermarkId: (props?.idWatermark !== undefined) ? props.idWatermark : null,
    watermarks: []
  }
  const { authState } = useContext(AuthContext)
  const [ states, setStates ] = useState(DEFAULT_STATES)

  //Hooks
  useEffect(() => {
    loadData()
  }, [states.isLoaded])

  //Hooks method
  const loadData = () => {
    console.log(TAG, 'loadData')

    if (states.isLoaded) {
      return
    }

    console.log(TAG, 'Getting data...')

    getWatermarks(APIRequest(authState.language, authState.token)).then(function (results) {
      if (isBadResponse(results.data)) {
        axiosError(t)

        return
      }

      setStates({
        ...states,
        isLoaded: true,
        watermarks: results.data.data.watermarks
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)
    })
  }

  //Methods template
  const showModal = () => {
    setStates({ ...DEFAULT_STATES, isModalVisible:true })
  }

  const hideModal = () => {
    console.log(TAG, 'hideModal')
    setStates(DEFAULT_STATES)
  }

  const handleRegenerateThumbFiles = async () => {
    console.log(TAG, 'handleRegenerateThumbFiles')

    let filesProcessed = 0
    const updatedFiles = []

    setStates({ ...states, isSubmitting:true })

    for(const file of files) {
      console.log(TAG, 'RegenerateThumb file ' + file.id)

      const fileUpdated = await regenerateThumbFile(APIRequest(authState.language, authState.token), file.id, states.watermarkId)
        .then(function (results) {
          if (isBadResponse(results.data)) {
            axiosError(t)
            return null
          }

          return results.data.data.file
        })
        .catch((thrown) => {
          console.log(TAG, thrown)
          axiosError(t)

          return null
        })

      if (fileUpdated) {
        updatedFiles.push(fileUpdated)

        if (typeof onRegenerateThumbFile === 'function') {
          console.log(TAG, fileUpdated)
          onRegenerateThumbFile(fileUpdated)
        }
      }

      filesProcessed = filesProcessed + 1

      setStates({ ...states, isModalVisible:true, isSubmitting:true, submitFilesProcessed:filesProcessed })

      if (filesProcessed >= files.length) {
        setStates({ ...states, isModalVisible:true, isSubmitting:true, submitFilesProcessed:filesProcessed })
        
        if (typeof onRegenerateThumbFiles === 'function') {
          onRegenerateThumbFiles(updatedFiles)
        }

        setTimeout(() => {
          hideModal()
        }, 1500)
      }
    }
  }

  const getWidget = () => {
    if (widgetType === 'menuItem') {
      return (
        <button
          type="button"
          className="menu-item-button"
          onClick={showModal}
        >
          <PictureOutlined /> {widgetLabel}
        </button>
      )
    } else if (widgetType === 'button') {
      return (
        <Button
          icon={<PictureOutlined />}
          onClick={showModal}
          {...widgetProps}
        >
          {widgetLabel}
        </Button>
      )
    } else {
      return 'Unknow widget type'
    }
  }

  //Check right & availability
  if (!isContributor(authState)) {
    console.log(TAG, 'Bad rights')
    return null
  } else if (!files || !files.length) {
    console.log(TAG, 'Bad files prop (array require)')
    return null
  }

  //Modal
  return (
    <>
      {getWidget()}

      <Modal
        title={t('Regenerate thumbnails from original file')}
        open={states.isModalVisible}
        zIndex={110}
        onCancel={hideModal}
        maskClosable={!states.isSubmitting}
        closable={!states.isSubmitting}
        footer={[
          <Button type="link" key="regenerateThumbSelectionNo" disabled={states.isSubmitting} onClick={hideModal}>
            {t('Cancel')}
          </Button>,
          <Button type="primary" key="regenerateThumbSelectionYes" disabled={states.isSubmitting} loading={states.isSubmitting} onClick={handleRegenerateThumbFiles}>
            {t('Regenerate thumbnails')}
          </Button>,
        ]}
      >
        {states.isSubmitting ? (
          <Space direction="vertical" align="center" style={{ width:'100%' }}>
            <Progress
              type="circle"
              percent={(states.submitFilesProcessed / files.length) * 100}
              format={(percent) => states.submitFilesProcessed + ' / ' + files.length}
            />
            <Text>{t('Media processing in progress')}...</Text>
          </Space>
        ) : (
          <>
            <List
              size="small"
              dataSource={files}
              renderItem={item => (
                <List.Item style={{ display:'flex' }}>
                  <div style={{ width:50, textAlign:'center', marginRight:10 }}>
                    <img src={item.thumb} style={{ maxWidth:50, maxHeight:30 }} alt={item.title} />
                  </div>
                  <div style={{ flex:1 }}>
                    {item.title}
                  </div>
                </List.Item>
              )}
            />
            {states.watermarks.length > 0 && (
              <Space direction="vertical" style={{ width:'100%' }}>
                <Divider style={{ marginTop:10, marginBottom:10 }} />
                <Text strong>{t('Select a watermark')}</Text>
                <Select
                  placeholder={t('Select a watermark')}
                  allowClear={true}
                  defaultValue={states.watermarkId}
                  onChange={(value) => {
                    setStates({
                      ...states,
                      watermarkId: value
                    })
                  }}
                  style={{ width:'100%' }}
                  getPopupContainer={trigger => trigger.parentNode}
                >
                  {states.watermarks.map(item => 
                    <Option value={item.id} key={"WatermarkOption" + item.id}>{item.name}</Option>
                  )}
                </Select>
              </Space>
            )}
          </>
        )}
        
      </Modal>
    </>
  )
}

export default memo(FileRegenerateThumbModal)
