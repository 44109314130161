import { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Space, Table, Button, Dropdown, Menu, Modal, Avatar, Alert } from 'antd';
import { DownOutlined, DeleteOutlined, EditOutlined, PlusOutlined, FileImageOutlined } from '@ant-design/icons';
import { APIRequest, isBadResponse, getWatermarks, delWatermark } from '../../models/APIRequest';
import { Context as AuthContext } from '../../context/AuthContext';
import { ModalContext } from "../../context/ModalContext";
import ApiErrorResult from '../../components/ApiErrorResult';
import { axiosError } from '../../components/Notifications';
import TableHeader from '../../components/table/TableHeader';
import { transformSortOrderToApi } from '../../utils/ApiUtils';
import { getTablePagerInformations } from '../../utils/TemplateUtils';
import { GLOBAL_MODAL_TYPES } from "../../Constant";

export default function AdminWatermarkScreen(props) {
  const TAG = 'AdminWatermarkScreen'

  //Template
  const { t } = useTranslation()
  const [ updateBreacrumbs ] = useOutletContext()
  const { openModal, closeModal } = useContext(ModalContext)
  const [ modal, contextHolder ] = Modal.useModal()
  const columns = [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: 'ascend',
    },
    {
      title: t('Watermark'),
      dataIndex: 'watermark',
      sorter: false,
      showSorterTooltip: false,
      align:'center',
      width:40,
      render: (_, { name, image }) => {
        if (image) {
          return (
            <div className="photoshop-bg photoshop-bg-dark" style={{ padding:5 }}>
              <img src={image} style={{ maxHeight:70 }} alt={t('Watermark')} />
            </div>
          )
        }

        return (
          <Avatar size={32} icon={<FileImageOutlined />} />
        )
      },
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      align:'center',
      render: (_, record) => {
        const contextMenu = [
          {
            label: t('Edit'),
            icon: <EditOutlined />,
            key: 'edit',
          },
          { type: 'divider' },
          {
            label: t('Delete'),
            icon: <DeleteOutlined />,
            key: 'delete',
            danger: true
          }
        ]

        return (
          <Dropdown
            icon={<DownOutlined />}
            key={'actions' + record.id}
            loading={false}
            trigger={['click']}
            overlay={
              <Menu
                onClick={({ item, key }) => handleActions(key, record)}
                items={contextMenu}
              />
            }
          >
            <Button block>
              <Space>
                {t('Actions')}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        )
      },
      width: 130,
    }
  ]

  //State
  const { authState } = useContext(AuthContext)
  const [ states, setStates ] = useState({
    firstLoad: true,
    isLoaded: false,
    isSubmitting: false,
    error: false,
    errorData: null,
    watermarks: [],
    page: 1,
    pageMax: 1,
    total: 0,
    limit: 10,
    sortColumn: null,
    sortOrder: null,
    filters: null
  })

  //States methods
  const loadData = () => {
    console.log(TAG, 'loadData')
    console.log(TAG, 'Getting data...')

    getWatermarks(APIRequest(authState.language, authState.token), states.page, states.limit, states.sortColumn, states.sortOrder, states.filters).then(function (results) {
      if (isBadResponse(results.data)) {
        setStates({
          ...states,
          isLoaded: false,
          firstLoad: false,
          error: true,
          errorData: results.data.data
        })

        return
      }

      setStates({
        ...states,
        isLoaded: true,
        firstLoad: false,
        watermarks:  results.data.data.watermarks,
        page: results.data.data.pager.page,
        pageMax: results.data.data.pager.page_max,
        total: results.data.data.pager.total,
        limit: results.data.data.pager.limit,
        error: false,
        errorData: null,
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: false,
        firstLoad: false,
        error: true,
        errorData: null,
      })
    })
  }

  //Events
  useEffect(() => {
    loadData()

    if (updateBreacrumbs) {
      console.log(TAG, 'updateBreacrumbs')
      updateBreacrumbs([{ title: t('Watermarks'), 'route': null }])
    }
  }, [])

  useEffect(() => {
    if (states.firstLoad || states.error || states.isLoaded) {
      return
    }

    loadData()
  }, [states.isLoaded, states.error])

  //Template method
  const handleTableChange = (newPagination, filters, sorter) => {
    //Add search
    if (states.filters?.search) {
      filters['search'] = states.filters.search
    }

    setStates({
      ...states,
      page: newPagination.current,
      limit: newPagination.pageSize,
      sortColumn: sorter?.columnKey,
      sortOrder: transformSortOrderToApi(sorter?.order),
      filters: filters,
      isLoaded: false,
    })
  }

  const handleActions = (key, record) => {
    if (key === 'edit') {
      openModal(
        GLOBAL_MODAL_TYPES.WATERMARK,
        { id: record.id, watermark: record },
        (watermarkUpdated, close) => {
          console.log(TAG, watermarkUpdated)
          if (watermarkUpdated) {
            const watermarks = Object.assign([], states.watermarks)

            Object.keys(watermarks).forEach(key => {
              if (watermarks[key].id === record.id) {
                watermarks[key] = watermarkUpdated
              }
            })

            setStates({ ...states, watermarks: watermarks })
          }

          if (close) {
            closeModal()
          }
        },
        null
      )

    } else if (key === 'delete') {
      modal.confirm({
        title: record.title,
        content: t('Are you sure you want to permanently delete this watermark?'),
        okText: t('Delete'),
        okType: 'danger',
        cancelText: t('Cancel'),
        onOk: () => {
          deleteWatermark(record.id)
        }
      })

    } else {
      console.log(TAG, 'Unknow action (' + key + ')...')
    }
  }

  const handleAddWatermark = () => {
    openModal(
      GLOBAL_MODAL_TYPES.WATERMARK,
      null,
      (folder, close) => {
        if (close) {
          closeModal()
        }

        setStates({ ...states, isLoaded:false })
      },
      null
    )
  }

  const deleteWatermark = (watermarkId) => {
    console.log(TAG, 'deleteWatermark')

    setStates({ ...states, isSubmitting: true })

    delWatermark(APIRequest(authState.language, authState.token), watermarkId).then(function (results) {
      if (isBadResponse(results.data)) {
        axiosError(t)
        setStates({
          ...states,
          isSubmitting: false
        })

        return
      }

      setStates({ ...states, isLoaded: false, isSubmitting:false })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isSubmitting: false
      })
    })
  }

  if (states.error) {
    return (
      <ApiErrorResult errorData={states.errorData} actionTitle={t('Try to reload')} actionHandle={() => {
        setStates({ ...states, error:false, errorData:null, isLoaded:false })
      }} />
    )
  }

  return (
    <main className='admin-container'>
      <TableHeader
        title={t('Watermarks')}
        buttons={[
          <Button type="primary" icon={<PlusOutlined />} disabled={states.isSubmitting} onClick={handleAddWatermark}>{t('Add watermark')}</Button>
        ]}
        showSearch={true}
        onSearchChange={value => {
          if (!value) {
            return
          }

          setStates({
            ...states,
            filters: {
              ...states.filters,
              search: value
            }
          })
        }}
        onSearchSubmit={() => {
          setStates({
            ...states,
            isLoaded: false,
            page: 1,
          })
        }}
        onSearchClear={() => {
          setStates({
            ...states,
            isLoaded: false,
            page: 1,
            filters: {
              ...states.filters,
              search: null
            }
          })
        }}
      />

      <Table
        columns={columns}
        size='small'
        rowKey={(item) => item.id}
        dataSource={states.watermarks}
        pagination={{
          current: states.page,
          pageSize: states.limit,
          total: states.total,
          disabled: (!states.isLoaded),
          showTotal: (total) => getTablePagerInformations(t, states.page, states.limit, states.total),
          showSizeChanger: true,
          size: 'default'
        }}
        loading={!states.isLoaded || states.isSubmitting}
        onChange={handleTableChange}
        scroll={{ x: 680 }}
      />

      <Alert
        type='info'
        showIcon
        message={t('Warning')}
        description={
          <>
            {t('Any changes will not affect previously processed media thumbnails.')}
            <br />
            {t('To support edits, you need to regenerate media thumbnails.')}
          </>
        }
      />

      {contextHolder}
    </main>
  )
}
