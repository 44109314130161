import { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Typography, Space, Table, Button, Dropdown, Menu, Badge } from 'antd';
import { DownOutlined, EditOutlined, EyeInvisibleOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import invert from 'invert-color'
import { APIRequest, isBadResponse, getStickers } from '../../models/APIRequest';
import { Context as AuthContext } from '../../context/AuthContext';
import { ModalContext } from "../../context/ModalContext";
import ApiErrorResult from '../../components/ApiErrorResult';
import { axiosError } from '../../components/Notifications';
import StickerDeleteModal from '../../components/sticker/StickerDeleteModal';
import StickerActiveModal from '../../components/sticker/StickerActiveModal';
import TableHeader from '../../components/table/TableHeader';
import { transformSortOrderToApi } from '../../utils/ApiUtils';
import { getTablePagerInformations } from '../../utils/TemplateUtils';
import { GLOBAL_MODAL_TYPES } from "../../Constant";

export default function AdminStickerScreen(props) {
  const TAG = 'AdminStickerScreen'

  //Template
  const { t } = useTranslation()
  const { Text } = Typography
  const [ updateBreacrumbs ] = useOutletContext()
  const { openModal, closeModal } = useContext(ModalContext)
  const columns = [
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: 'ascend',
    },
    {
      title: t('Color'),
      dataIndex: 'color',
      sorter: false,
      showSorterTooltip: false,
      align:'center',
      width:250,
      render: (_, { title, color }) => {
        return (
          <Badge.Ribbon
            placement="start"
            text={<Text style={{ color:invert(color, true), textTransform:'uppercase', fontSize:12 }} strong>{title}</Text>}
            color={color}
            style={{ top:2, left:2 }}
          >
            <div style={{
              display:'flex',
              alignItems:'center',
              justifyContent:'flex-end',
              width:'100%',
              marginLeft:10,
              paddingRight:5,
              height:30,
              backgroundColor:invert(color, true),
              color:invert(invert(color, true), true),
              textTransform:'uppercase'
            }}>{color}</div>
          </Badge.Ribbon>
        )
      },
    },
    {
      title: t('Marked files'),
      dataIndex: 'marked_files',
      sorter: false,
      showSorterTooltip: false,
      align:'center',
      width:50,
      render: (_, { marked_files }) => {
        return marked_files.length
      },
    },
    {
      title: t('Enable'),
      dataIndex: 'active',
      key: 'active',
      sorter: true,
      showSorterTooltip: false,
      align:'center',
      width:50,
      filters: [
        {
          text: t('Yes'),
          value: 1,
        },
        {
          text: t('No'),
          value: 0,
        },
      ],
      filterMultiple: false,
      render: (_, { active }) => {
        if (active) {
          return <Text type="success"><EyeOutlined /></Text>
        } else {
          return <Text type="danger"><EyeInvisibleOutlined /></Text>
        }
      },
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      align:'center',
      render: (_, record) => {
        const contextMenu = [
          {
            label: <><EditOutlined /> {t('Edit')}</>,
            key: 'edit',
          }
        ]

        if (record.active) {
          contextMenu.push({
            label: <StickerActiveModal
              stickers={[record]}
              active={false}
              widgetType='menuItem'
              onUpdatedSticker={() => refreshTable()}
            />,
            key: 'unactive',
          })
        } else {
          contextMenu.push({
            label: <StickerActiveModal
              stickers={[record]}
              active={true}
              widgetType='menuItem'
              onUpdatedSticker={() => refreshTable()}
            />,
            key: 'active'
          })
        }
        
        contextMenu.push({ type: 'divider' })
        
        contextMenu.push({
          label: <StickerDeleteModal
            stickers={[record]}
            widgetType='menuItem'
            onDeletedSticker={() => refreshTable()}
          />,
          key: 'delete'
        })

        return (
          <Dropdown
            icon={<DownOutlined />}
            key={'actions' + record.id}
            loading={false}
            trigger={['click']}
            overlay={
              <Menu
                onClick={({ item, key }) => handleActions(key, record)}
                items={contextMenu}
              />
            }
          >
            <Button block>
              <Space>
                {t('Actions')}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        )
      },
      width: 130,
    }
  ]

  //State
  const { authState } = useContext(AuthContext)
  const [ states, setStates ] = useState({
    firstLoad: true,
    isLoaded: false,
    isSubmitting: false,
    error: false,
    errorData: null,
    stickers: [],
    selectedRowKeys: [],
    selectedStickers: [],
    page: 1,
    pageMax: 1,
    total: 0,
    limit: 10,
    sortColumn: null,
    sortOrder: null,
    filters: null
  })

  //States methods
  const loadData = () => {
    console.log(TAG, 'loadData')
    console.log(TAG, 'Getting data...')

    getStickers(APIRequest(authState.language, authState.token), true, states.page, states.limit, states.sortColumn, states.sortOrder, states.filters).then(function (results) {
      if (isBadResponse(results.data)) {
        setStates({
          ...states,
          isLoaded: false,
          firstLoad: false,
          error: true,
          errorData: results.data.data
        })

        return
      }

      setStates({
        ...states,
        isLoaded: true,
        firstLoad: false,
        stickers:  results.data.data.stickers,
        page: results.data.data.pager.page,
        pageMax: results.data.data.pager.page_max,
        total: results.data.data.pager.total,
        limit: results.data.data.pager.limit,
        error: false,
        errorData: null,
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: false,
        firstLoad: false,
        error: true,
        errorData: null,
      })
    })
  }

  //Events
  useEffect(() => {
    loadData()

    if (updateBreacrumbs) {
      console.log(TAG, 'updateBreacrumbs')
      updateBreacrumbs([{title: t('Stickers'), 'route': null},])
    }
  }, [])

  //Events
  useEffect(() => {
    if (states.firstLoad || states.error || states.isLoaded) {
      return
    }

    loadData()
  }, [states.isLoaded, states.error])

  //Template method
  const refreshTable = () => {
    console.log(TAG, 'refreshTable')

    setStates({ ...states, isLoaded: false, isSubmitting:false, selectedStickers:[], selectedRowKeys:[] })
  }

  const handleTableChange = (newPagination, filters, sorter) => {
    //Add search
    if (states.filters?.search) {
      filters['search'] = states.filters.search
    }

    setStates({
      ...states,
      selectedRowKeys: [],
      selectedStickers: [],
      page: newPagination.current,
      limit: newPagination.pageSize,
      sortColumn: sorter?.columnKey,
      sortOrder: transformSortOrderToApi(sorter?.order),
      isLoaded: false,
      filters: filters
    })
  }

  const handleActions = (key, record) => {
    if (key === 'edit') {
      openModal(
        GLOBAL_MODAL_TYPES.STICKER,
        { id: record.id, sticker: record },
        (stickerUpdated, close) => {
          if (stickerUpdated) {
            const stickers = Object.assign([], states.stickers)

            Object.keys(stickers).forEach(key => {
              if (stickers[key].id === record.id) {
                stickers[key] = stickerUpdated
              }
            })

            setStates({ ...states, stickers: stickers })
          }

          if (close) {
            closeModal()
          }
        },
        null
      )
    }
  }

  const handleAddSticker = () => {
    openModal(
      GLOBAL_MODAL_TYPES.STICKER,
      null,
      (folder, close) => {
        if (close) {
          closeModal()
        }

        refreshTable()
      },
      null
    )
  }

  const bulkActionsMenu = () => {
    return <Menu
      items={[
        {
          label: <StickerActiveModal
            stickers={states.selectedStickers}
            active={false}
            widgetType='menuItem'
            onUpdatedSticker={() => refreshTable()}
          />,
          key: 'unactive'
        },
        {
          label: <StickerActiveModal
            stickers={states.selectedStickers}
            active={true}
            widgetType='menuItem'
            onUpdatedSticker={() => refreshTable()}
          />,
          key: 'active'
        },
        {
          'type': 'divider'
        },
        {
          label: (
            <StickerDeleteModal
              stickers={states.selectedStickers}
              widgetType='menuItem'
              onDeletedSticker={() => refreshTable()}
            />
          ),
          key: 'bulkDel',
        }
      ]}
    />
  }

  if (states.error) {
    return (
      <ApiErrorResult errorData={states.errorData} actionTitle={t('Try to reload')} actionHandle={() => {
        setStates({ ...states, error:false, errorData:null, isLoaded:false })
      }} />
    )
  }

  return (
    <main className='admin-container'>
      <TableHeader
        title={t('Stickers')}
        buttons={[
          <Button type="primary" icon={<PlusOutlined />} disabled={states.isSubmitting} onClick={handleAddSticker}>{t('Add sticker')}</Button>,
        ]}
        showSearch={true}
        contentStyle={{ display:'flex', flexDirection:'row-reverse', alignItems:'center', justifyContent:'space-between' }}
        onSearchChange={value => {
          if (!value) {
            return
          }

          setStates({
            ...states,
            filters: {
              ...states.filters,
              search: value
            }
          })
        }}
        onSearchSubmit={() => {
          setStates({
            ...states,
            isLoaded: false,
            page: 1,
          })
        }}
        onSearchClear={() => {
          setStates({
            ...states,
            isLoaded: false,
            page: 1,
            filters: {
              ...states.filters,
              search: null
            }
          })
        }}
      >
        <Dropdown trigger='click' overlay={bulkActionsMenu} disabled={states.selectedStickers.length === 0}>
          <Badge count={states.selectedStickers.length} overflowCount={999}>
            <Button disabled={states.selectedStickers.length === 0}>
              <Space>
                {t('Bulk actions')}
                
                <DownOutlined />
              </Space>
            </Button>
          </Badge>
        </Dropdown>
      </TableHeader>

      <Table
        columns={columns}
        size='small'
        rowKey={(item) => item.id}
        dataSource={states.stickers}
        pagination={{
          current: states.page,
          pageSize: states.limit,
          total: states.total,
          disabled: (!states.isLoaded),
          showTotal: (total) => getTablePagerInformations(t, states.page, states.limit, states.total),
          showSizeChanger: true,
          size: 'default'
        }}
        rowSelection={{
          selectedRowKeys: states.selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            setStates({
              ...states,
              selectedRowKeys: selectedRowKeys,
              selectedStickers: selectedRows
            })
          }
        }}
        loading={!states.isLoaded || states.isSubmitting}
        onChange={handleTableChange}
        scroll={{ x: 680 }}
      />
    </main>
  )
}
