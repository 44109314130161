import { useState, useEffect, useContext } from 'react';
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Typography, Space, Table, Image, Button, Dropdown, Menu, Modal, Alert, Badge, Tooltip } from 'antd';
import { DownOutlined, DeleteOutlined, DownloadOutlined, ShareAltOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { APIRequest, isBadResponse, getTrashFiles, cleanTrash } from '../../models/APIRequest';
import { Context as AuthContext } from '../../context/AuthContext';
import ApiErrorResult from '../../components/ApiErrorResult';
import { axiosError } from '../../components/Notifications';
import DownloadModal from '../../components/DownloadModal';
import FileDeleteModal from '../../components/file/FileDeleteModal';
import FileEditWidget from '../../components/file/FileEditWidget';
import FileRestoreModal from '../../components/file/FileRestoreModal';
import FileStatus from '../../components/file/FileStatus';
import TableHeader from '../../components/table/TableHeader';
import { getShortTimeFormat } from '../../utils/DateUtils';
import { transformSortOrderToApi } from '../../utils/ApiUtils';
import { getTablePagerInformations, updateFileCollection, updatesFileCollection } from '../../utils/TemplateUtils';
import { FILE_STATUS_DRAFT, FILE_STATUS_ONLINE, FILE_STATUS_ARCHIVE } from "../../Constant";

export default function AdminTrashScreen(props) {
  const TAG = 'AdminTrashScreen'

  //Template
  const { t } = useTranslation()
  const { Text, Paragraph } = Typography
  const [ updateBreacrumbs ] = useOutletContext()
  const [ modal, contextHolder ] = Modal.useModal()
  const columns = [
    {
      //title: t('Deleted date'),
      dataIndex: 'file',
      width: 60,
      render: (_, file) => (
        <Image.PreviewGroup>
          <Image
            src={(file.thumb_large) ? file.thumb_large : file.thumb}
            placeholder={file.thumb}
            preview={(file.thumb_large !== null)}
            alt={file.title}
            style={{ maxHeight:50 }}
          />
        </Image.PreviewGroup>
      ),
    },
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      showSorterTooltip: false,
      width: 250,
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      showSorterTooltip: false,
      align:'center',
      width: 125,
      filters: [
        {
          text: t('Draft'),
          value: FILE_STATUS_DRAFT,
        },
        {
          text: t('Online'),
          value: FILE_STATUS_ONLINE,
        },
        {
          text: t('Archive'),
          value: FILE_STATUS_ARCHIVE,
        },
      ],
      filterMultiple: true,
      render: (_, { status }) => {
        return <FileStatus status={status} type='icon' withLabel={true} />
      },
    },
    {
      title: <Tooltip title={t('Can be share?')}><ShareAltOutlined /></Tooltip>,
      dataIndex: 'can_share',
      sorter: false,
      showSorterTooltip: false,
      align:'center',
      width:50,
      filters: [
        {
          text: t('Yes'),
          value: 1,
        },
        {
          text: t('No'),
          value: 0,
        },
      ],
      filterMultiple: false,
      render: (_, { can_share }) => {
        if (can_share) {
          return <Text type="success"><CheckOutlined /></Text>
        } else {
          return <Text type="danger"><CloseOutlined /></Text>
        }
      },
    },
    {
      title: <Tooltip title={t('Can be downloaded?')}><DownloadOutlined /></Tooltip>,
      dataIndex: 'can_download',
      sorter: false,
      showSorterTooltip: false,
      align:'center',
      width:50,
      filters: [
        {
          text: t('Yes'),
          value: 1,
        },
        {
          text: t('No'),
          value: 0,
        },
      ],
      filterMultiple: false,
      render: (_, { can_download }) => {
        if (can_download) {
          return <Text type="success"><CheckOutlined /></Text>
        } else {
          return <Text type="danger"><CloseOutlined /></Text>
        }
      },
    },
    {
      title: t('Deleted date'),
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
      width: 250,
      align:'center',
      render: (_, { date_upd }) => {
        return moment(date_upd).format(getShortTimeFormat(authState.language))
      },
    },
    {
      title: t('Deleted by'),
      dateIndex: 'user',
      key: 'user',
      sorter: true,
      showSorterTooltip: false,
      width: 250,
      responsive: ['xl'],
      render: (_, record) => {
        return record.user.name
      },
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      align:'center',
      //fixed: 'right',
      render: (_, record) => (
      <>
        <Dropdown
          icon={<DownOutlined />}
          key={'actions' + record.id}
          loading={false}
          trigger={['click']}
          overlay={
            <Menu
              items={[
                {
                  label: <DownloadModal widgetType="menuItem" files={[record]} />,
                  icon: <DownloadOutlined />,
                  key: 'download',
                },
                {
                  label: <FileEditWidget
                    widgetType="menuItem"
                    file={record}
                    onUpdateFile={(file) => onUpdateFile(file.id, file)}
                    onDeleteFile={() => refreshTable()}
                  />,
                  key: 'edit',
                },
                {
                  label: <FileRestoreModal
                    widgetType="menuItem"
                    files={[record]}
                    onRestoreFiles={() => refreshTable()}
                  />,
                  key: 'restore',
                },
                {
                  type: 'divider'
                },
                {
                  label: <FileDeleteModal
                    files={[record]}
                    hardDeletion={true}
                    widgetType='menuItem'
                    onDeletedFile={() => refreshTable()}
                  />,
                  key: 'delete'
                },
              ]}
            />
          }
        >
          <Button block>
            <Space>
              {t('Actions')}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </>
      ),
      width: 130,
    }
  ]

  //State
  const { authState } = useContext(AuthContext)
  const [ states, setStates ] = useState({
    firstLoad: true,
    isLoaded: false,
    error: false,
    errorData: null,
    files: [],
    selectedRowKeys: [],
    selectedFiles: [],
    page: 1,
    pageMax: 1,
    total: 0,
    limit: 10,
    sortColumn: null,
    sortOrder: null,
    filters: null
  })

  //States methods
  const loadData = () => {
    console.log(TAG, 'loadData')
    console.log(TAG, 'Getting data...')

    getTrashFiles(APIRequest(authState.language, authState.token), states.page, states.limit, states.sortColumn, states.sortOrder, states.filters).then(function (results) {
      if (isBadResponse(results.data)) {
        setStates({
          ...states,
          isLoaded: false,
          firstLoad: false,
          error: true,
          errorData: results.data.data
        })

        return
      }

      setStates({
        ...states,
        isLoaded: true,
        firstLoad: false,
        files:  results.data.data.files,
        page: results.data.data.pager.page,
        pageMax: results.data.data.pager.page_max,
        total: results.data.data.pager.total,
        limit: results.data.data.pager.limit,
        error: false,
        errorData: null,
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: false,
        firstLoad: false,
        error: true,
        errorData: null,
      })
    })
  }

  //Events
  useEffect(() => {
    loadData()

    if (updateBreacrumbs) {
      console.log(TAG, 'updateBreacrumbs')
      updateBreacrumbs([{title: t('Trash'), 'route': null},])
    }
  }, [])

  //Events
  useEffect(() => {
    if (states.firstLoad || states.error || states.isLoaded) {
      return
    }

    loadData()
  }, [states.isLoaded, states.error])

  //Template method
  const refreshTable = () => {
    console.log(TAG, 'refreshTable')

    setStates({ ...states, isLoaded: false, isSubmitting:false, selectedFiles:[], selectedRowKeys:[] })
  }

  const onUpdateFile = (fileId, updatedFile) => {
    console.log(TAG, 'onUpdateFile')

    const files = updateFileCollection(updatedFile, [...states.files])

    setStates({ ...states, files: files, selectedFiles:[], selectedRowKeys:[] })
  }

  const onUpdateFiles = (updatedFiles) => {
    console.log(TAG, 'onUpdateFiles')

    const files = updatesFileCollection(updatedFiles, [...states.files])

    setStates({ ...states, files: files, selectedFiles:[], selectedRowKeys:[] })
  }

  const handleTableChange = (newPagination, filters, sorter) => {
    //Add search
    if (states.filters?.search) {
      filters['search'] = states.filters.search
    }

    setStates({
      ...states,
      selectedFiles: [],
      selectedRowKeys: [],
      page: newPagination.current,
      limit: newPagination.pageSize,
      sortColumn: sorter?.columnKey,
      sortOrder: transformSortOrderToApi(sorter?.order),
      filters: filters,
      isLoaded: false,
    })
  }

  const cleaningTrash = (fileId) => {
    console.log(TAG, 'cleaningTrash')

    setStates({ ...states, isSubmitting: true })

    cleanTrash(APIRequest(authState.language, authState.token, null, 60)).then(function (results) {
      if (isBadResponse(results.data)) {
        axiosError(t)

        setStates({ ...states, isSubmitting:false })

        return
      }

      setStates({ ...states, isLoaded: false, isSubmitting:false })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: false,
        error: true
      })
    })
  }

  const handleCleanTrash = () => {
    modal.confirm({
      title: t('Clean trash'),
      content: (
        <>
          <Paragraph>{t('Are you sure to clean the trash?')}</Paragraph>
          
          <Alert type="warning" message={t('All media will be permanently deleted!')} showIcon  />
        </>
      ),
      width:525,
      okText: t('Deleted permanently all medias'),
      okType: 'danger',
      cancelText: t('Cancel'),
      onOk: () => cleaningTrash()
    })
  }

  const bulkActionsMenu = () => {
    return <Menu
      items={[
        {
          label: (
            <FileRestoreModal
              files={states.selectedFiles}
              widgetType="menuItem"
              onRestoreFiles={() => refreshTable()}
            />
          ),
          key: 'bulkRestore',
        },
        {
          label: (
            <FileEditWidget
              file={states.selectedFiles[0]}
              files={states.selectedFiles}
              widgetType="menuItem"
              onUpdateFiles={(files) => onUpdateFiles(files)}
              onDeleteFiles={() => refreshTable()}
            />
          ),
          key: 'bulkEdit',
        },
        {
          type: 'divider'
        },
        {
          label: (
            <FileDeleteModal
              files={states.selectedFiles}
              hardDeletion={true}
              widgetType='menuItem'
              onDeletedFile={() => refreshTable()}
            />
          ),
          key: 'bulkDel',
        }
      ]}
    />
  }

  if (states.error) {
    return (
      <ApiErrorResult errorData={states.errorData} actionTitle={t('Try to reload')} actionHandle={() => {
        setStates({ ...states, error:false, errorData: null, isLoaded:false })
      }} />
    )
  }

  return (
    <main className='admin-container'>
      <TableHeader
        title={t('Trash')}
        buttons={[
          <Button type="primary" icon={<DeleteOutlined />} disabled={states.isSubmitting} onClick={handleCleanTrash}>{t('Clean trash')}</Button>
        ]}
        showSearch={true}
        contentStyle={{ display:'flex', flexDirection:'row-reverse', alignItems:'center', justifyContent:'space-between' }}
        onSearchChange={value => {
          if (!value) {
            return
          }

          setStates({
            ...states,
            filters: {
              ...states.filters,
              search: value
            }
          })
        }}
        onSearchSubmit={() => {
          setStates({
            ...states,
            isLoaded: false,
            page: 1,
          })
        }}
        onSearchClear={() => {
          setStates({
            ...states,
            isLoaded: false,
            page: 1,
            filters: {
              ...states.filters,
              search: null
            }
          })
        }}
      >
        <Dropdown trigger='click' overlay={bulkActionsMenu} disabled={states.selectedFiles.length === 0}>
          <Badge count={states.selectedFiles.length} overflowCount={999}>
            <Button disabled={states.selectedFiles.length === 0}>
              <Space>
                {t('Bulk actions')}
                
                <DownOutlined />
              </Space>
            </Button>
          </Badge>
        </Dropdown>
      </TableHeader>

      <Table
        columns={columns}
        size='small'
        rowKey={(item) => item.id + '-' + item.date_upd}
        dataSource={states.files}
        pagination={{
          current: states.page,
          pageSize: states.limit,
          total: states.total,
          disabled: (!states.isLoaded),
          showTotal: (total) => getTablePagerInformations(t, states.page, states.limit, states.total),
          showSizeChanger: true,
          size: 'default'
        }}
        rowSelection={{
          selectedRowKeys: states.selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            setStates({
              ...states,
              selectedRowKeys: selectedRowKeys,
              selectedFiles: selectedRows
            })
          }
        }}
        loading={!states.isLoaded || states.isSubmitting}
        onChange={handleTableChange}
        scroll={{ x: 680 }}
      />
      
      {contextHolder}
    </main>
  )
}
