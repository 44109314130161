import { memo, useEffect, useState, useRef } from 'react';
import { Input, Button, message } from 'antd';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { SearchOutlined, CloseCircleFilled } from '@ant-design/icons';

const SearchInput = (props) => {
  const TAG = 'SearchInput'

  //Template
  const { t } = useTranslation()
  const searchInputRef = useRef()
  const location = useLocation()
  const onSearch = props?.onSearch
  const onClear = props?.onClear
  const searchRouteRegEx = /\/media\/search\/([0-9A-Za-z%]{1,})/
  const isSearchRoute = (location.pathname.match(searchRouteRegEx))

  if (!onSearch) {
    throw new Error(TAG + ' - PROPS onSearch is undefined')
  }

  //State
  const [ states, setStates ] = useState({
    error: false,
    value: (isSearchRoute) ? isSearchRoute[1].replaceAll('%20', ' ') : null
  })

  //Hooks
  useEffect(() => {
    const isSearchRoute = location.pathname.match(searchRouteRegEx)
    const value = (isSearchRoute) ? decodeURI(isSearchRoute[1]) : null

    setStates({ ...states, value: value })
  }, [location])

  //Template methods
  const searchHandle = () => {
    const search = searchInputRef.current.input.value
    const forbiddenCaracters = /[?/_\-$&]/g

    if (search.length < 3) {
      setStates({ ...states, error: true })
      message.error(t('%d characters minimum').replace('%d', 3))
      return

    } else if (search.search(forbiddenCaracters) >= 0) {
      setStates({ ...states, error: true })
      message.error(t('Characters %s is forbiden').replace('%s', '?/_-$')) //& make an JS error!
      return
    
    } else if (states.error) {
      setStates({ ...states, error: false })
    }

    onSearch(search)
  }

  return (
    <Input
      ref={searchInputRef}
      value={states.value}
      placeholder={t('Find a media, folder...')}
      suffix={(
        <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={searchHandle} />
      )}
      size='large'
      status={states.error ? 'error' : null}
      onPressEnter={searchHandle}
      onChange={(e) => setStates({ ...states, value: e.target.value })}
      allowClear={{
        clearIcon: (
          <CloseCircleFilled
            style={{ fontSize: 20, marginTop:4 }}
            onClick={() => {
              if (typeof onClear === 'function') {
                onClear()
              }
            }}
          />
        )
      }}
    />
  )
}

export default memo(SearchInput)