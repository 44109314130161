import { memo, useContext, useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { Button, Modal, Typography, Table, Grid } from 'antd';
import { HistoryOutlined, } from '@ant-design/icons';
import { Context as AuthContext } from '../context/AuthContext';
import { APIRequest, isBadResponse, getFileHistory } from '../models/APIRequest';
import { axiosError } from './Notifications';
import { getShortTimeFormat } from '../utils/DateUtils';
import { transformSortOrderToApi } from '../utils/ApiUtils';
import { getTablePagerInformations } from '../utils/TemplateUtils';

const FileHistoryModal = (props) => {
  const TAG = 'FileHistoryModal'

  //Template
  const { t } = useTranslation()
  const { Text } = Typography
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()
  const widgetType = props?.widgetType
  const widgetProps = props?.widgetProps
  const fileId = props?.fileId
  
  //States
  const DEFAULT_STATES = {
    isModalVisible: false,
    isLoaded: false,
    history: null,
    page: 1,
    pageMax: 1,
    total: 0,
    limit: 10,
    sortColumn: null,
    sortOrder: null,
    filters: null
  }
  const { authState } = useContext(AuthContext)
  const [ states, setStates ] = useState(DEFAULT_STATES)
  const columns = [
    {
      title: t('Date'),
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
      width: 150,
      align:'center',
      render: (date) => {
        return moment(date).format(getShortTimeFormat(authState.language))
      },
    },
    {
      title: t('Action'),
      dataIndex: 'action_label',
      key: 'action',
      sorter: true,
      showSorterTooltip: false,
      filters: [
        {value:'created', text:t('Created')},
        {value:'modified', text:t('Modified')},
        {value:'opened', text:t('Opened')},
        {value:'downloaded', text:t('Downloaded')},
        {value:'shared', text:t('Share')},
        {value:'deleted', text:t('Deleted')},
        {value:'restored', text:t('Restored')},
        {value:'hard_deleted', text:t('Hard deleted')},
      ],
      filterMultiple: true,
    },
    {
      title: t('User'),
      dataIndex: 'user',
      key: 'user',
      sorter: true,
      showSorterTooltip: false,
      render: (user) => {
        console.log(TAG, user)
        return (user !== null) ? user.name : t('Visitor')
      }
    }
  ]

  //Hooks
  useEffect(() => {
    if (!fileId) {
      return
    }

    loadData()
  }, [states.isModalVisible, states.isLoaded])

  //Hooks methods
  const loadData = () => {
    console.log(TAG, 'LoadData')

    if (!states.isModalVisible || states.isLoaded) {
      return
    }

    console.log(TAG, 'Getting data...')

    getFileHistory(APIRequest(authState.language, authState.token), fileId, states.page, states.limit, states.sortColumn, states.sortOrder, states.filters).then((results) => {
      if (isBadResponse(results.data)) {
        setStates({
          ...states,
          isLoaded: true,
          error: true
        })

        axiosError(t)
        return
      }

      setStates({
        ...states,
        isLoaded: true,
        history: results.data.data.history,
        page: results.data.data.pager.page,
        pageMax: results.data.data.pager.page_max,
        total: results.data.data.pager.total,
        limit: results.data.data.pager.limit,
        error: false
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: true,
        error: true
      })
    })
  }

  //Template methods
  const showModal = () => {
    setStates({
      ...states,
      isModalVisible: true
    })
  }

  const hideModal = () => {
    setStates(DEFAULT_STATES)
  }

  const handleTableChange = (newPagination, filters, sorter) => {
    //Add search
    if (states.filters?.search) {
      filters['search'] = states.filters.search
    }

    setStates({
      ...states,
      page: newPagination.current,
      limit: newPagination.pageSize,
      sortColumn: sorter?.columnKey,
      sortOrder: transformSortOrderToApi(sorter?.order),
      filters: filters,
      isLoaded: false,
    })
  }

  const getWidget = () => {
    if (widgetType === 'menuItem') {
      return (
        <Text onClick={() => showModal()} {...widgetProps}>
          {t('History')}
        </Text>
      )
    } else if (widgetType === 'button') {
      return (
        <Button
          icon={<HistoryOutlined />}
          onClick={() => showModal()}
          {...widgetProps}
        >
          {t('History')}
        </Button>
      )
    } else if (widgetType === 'buttonIcon') {
      return (
        <Button
          shape="circle"
          icon={<HistoryOutlined />}
          onClick={() => showModal()}
          {...widgetProps}
        />
      )
    } else {
      return (<div>Widget type not found ({widgetType})</div>)
    }
  }

  if (!fileId) {
    console.log(TAG, 'Bad fileId prop (array require)')
    return
  }

  return (
    <>
      {getWidget()}
      <Modal
        title={t('History')}
        open={states.isModalVisible}
        zIndex={110}
        width={(!screens.md) ? 'calc(100% - 32px)' : '80%'}
        onCancel={hideModal}
        footer={[
          <Button type="link" onClick={hideModal}>
            {t('Close')}
          </Button>,
        ]}
      >
        <Table
          columns={columns}
          size='small'
          rowKey={(item) => item.id + '-' + item.date}
          dataSource={states.history}
          pagination={{
            current: states.page,
            pageSize: states.limit,
            total: states.total,
            disabled: (!states.isLoaded),
            showTotal: (total) => getTablePagerInformations(t, states.page, states.limit, states.total),
            showSizeChanger: true,
            size: 'default'
          }}
          loading={!states.isLoaded}
          onChange={handleTableChange}
          scroll={{ x: 680 }}
        />
      </Modal>
    </>
  )
}

export default memo(FileHistoryModal)