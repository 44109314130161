import { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Typography, Space, Table, Button, Dropdown, Menu, Modal, notification } from 'antd';
import { DownOutlined, DeleteOutlined, EditOutlined, CloseOutlined, CheckOutlined, PlusOutlined, StopOutlined } from '@ant-design/icons';
import { APIRequest, isBadResponse, getBroadcastRestrictions, delBroadcastRestriction } from '../../models/APIRequest';
import { Context as AuthContext } from '../../context/AuthContext';
import { ModalContext } from "../../context/ModalContext";
import ApiErrorResult from '../../components/ApiErrorResult';
import { axiosError } from '../../components/Notifications';
import TableHeader from '../../components/table/TableHeader';
import { transformSortOrderToApi } from '../../utils/ApiUtils';
import { getTablePagerInformations } from '../../utils/TemplateUtils';
import { GLOBAL_MODAL_TYPES } from "../../Constant";

export default function AdminBroadcastScreen(props) {
  const TAG = 'AdminBroadcastScreen'

  //Template
  const { t } = useTranslation()
  const { Text } = Typography
  const [ updateBreacrumbs ] = useOutletContext()
  const { openModal, closeModal } = useContext(ModalContext)
  const [ modal, contextHolder ] = Modal.useModal()
  const columns = [
    {
      title: t('Code'),
      dataIndex: 'code',
      key: 'code',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: 'ascend',
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('Share'),
      dataIndex: 'can_share',
      key: 'can_share',
      sorter: true,
      showSorterTooltip: false,
      align: 'center',
      width:50,
      filters: [
        {
          text: t('Yes'),
          value: 1,
        },
        {
          text: t('No'),
          value: 0,
        },
      ],
      filterMultiple: false,
      render: (can_share) => {
        if (can_share === null) {
          return <Text type="danger"><StopOutlined /></Text>
        } else if (can_share) {
          return <Text type="success"><CheckOutlined /></Text>
        } else {
          return <Text type="danger"><CloseOutlined /></Text>
        }
      }
    },
    {
      title: t('Download'),
      dataIndex: 'can_download',
      key: 'can_download',
      sorter: true,
      showSorterTooltip: false,
      align: 'center',
      width:50,
      filters: [
        {
          text: t('Yes'),
          value: 1,
        },
        {
          text: t('No'),
          value: 0,
        },
      ],
      filterMultiple: false,
      render: (can_download) => {
        if (can_download === null) {
          return <Text type="danger"><StopOutlined /></Text>
        } else if (can_download) {
          return <Text type="success"><CheckOutlined /></Text>
        } else {
          return <Text type="danger"><CloseOutlined /></Text>
        }
      }
    },
    {
      title: t('Downloaded with watermark?'),
      dataIndex: 'dl_watermark',
      key: 'dl_watermark',
      sorter: true,
      showSorterTooltip: false,
      align: 'center',
      width:50,
      filters: [
        {
          text: t('Yes'),
          value: 1,
        },
        {
          text: t('No'),
          value: 0,
        },
      ],
      filterMultiple: false,
      render: (dl_watermark) => {
        if (dl_watermark === null) {
          return <Text type="danger"><StopOutlined /></Text>
        } else if (dl_watermark) {
          return <Text type="success"><CheckOutlined /></Text>
        } else {
          return <Text type="danger"><CloseOutlined /></Text>
        }
      },
    },
    {
      title: t('Protected'),
      dataIndex: 'protected',
      align: 'center',
      width:50,
      render: (_protected) => {
        if (_protected) {
          return <Text type="success"><CheckOutlined /></Text>
        } else {
          return <Text type="danger"><CloseOutlined /></Text>
        }
      },
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      align: 'center',
      render: (_, record) => {
        const contextMenu = [
          {
            label: t('Edit'),
            icon: <EditOutlined />,
            key: 'edit',
          }
        ]

        if (!record.protected) {
          contextMenu.push({ type: 'divider' })
          
          contextMenu.push({
            label: t('Delete'),
            icon: <DeleteOutlined />,
            key: 'delete',
            danger: true
          })
        }

        return (
          <Dropdown
            icon={<DownOutlined />}
            key={'actions' + record.id}
            loading={false}
            trigger={['click']}
            overlay={
              <Menu
                onClick={({ item, key }) => handleActions(key, record)}
                items={contextMenu}
              />
            }
          >
            <Button block>
              <Space>
                {t('Actions')}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        )
      },
      width: 130,
    }
  ]

  //State
  const { authState } = useContext(AuthContext)
  const [ states, setStates ] = useState({
    firstLoad: true,
    isLoaded: false,
    isSubmitting: false,
    error: false,
    errorData: null,
    broadcasts: [],
    page: 1,
    pageMax: 1,
    total: 0,
    limit: 10,
    sortColumn: null,
    sortOrder: null,
    filters: null
  })

  //States methods
  const loadData = () => {
    console.log(TAG, 'loadData')
    console.log(TAG, 'Getting data...')

    getBroadcastRestrictions(APIRequest(authState.language, authState.token), states.page, states.limit, states.sortColumn, states.sortOrder, states.filters).then(function (results) {
      if (isBadResponse(results.data)) {
        setStates({
          ...states,
          isLoaded: false,
          firstLoad: false,
          error: true,
          errorData: results.data.data
        })

        return
      }

      setStates({
        ...states,
        isLoaded: true,
        firstLoad: false,
        broadcasts: results.data.data.broadcast_restrictions,
        page: results.data.data.pager.page,
        pageMax: results.data.data.pager.page_max,
        total: results.data.data.pager.total,
        limit: results.data.data.pager.limit,
        error: false,
        errorData: null,
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: false,
        firstLoad: false,
        error: true,
        errorData: null,
      })
    })
  }

  //Events
  useEffect(() => {
    loadData()

    if (updateBreacrumbs) {
      console.log(TAG, 'updateBreacrumbs')
      updateBreacrumbs([{ title: t('Broadcast restrictions'), 'route': null }])
    }
  }, [])

  useEffect(() => {
    if (states.firstLoad || states.error || states.isLoaded) {
      return
    }

    loadData()
  }, [states.isLoaded, states.error])

  //Template method
  const handleTableChange = (newPagination, filters, sorter) => {
    //Add search
    if (states.filters?.search) {
      filters['search'] = states.filters.search
    }

    setStates({
      ...states,
      page: newPagination.current,
      limit: newPagination.pageSize,
      sortColumn: sorter?.columnKey,
      sortOrder: transformSortOrderToApi(sorter?.order),
      filters: filters,
      isLoaded: false,
    })
  }

  const handleActions = (key, record) => {
    if (key === 'edit') {
      openModal(
        GLOBAL_MODAL_TYPES.BROADCAST_RESTRICTION,
        { id: record.id, broadcast: record },
        (broadcastUpdated, close) => {
          console.log(TAG, broadcastUpdated)
          if (broadcastUpdated) {
            const broadcasts = Object.assign([], states.broadcasts)

            Object.keys(broadcasts).forEach(key => {
              if (broadcasts[key].id === record.id) {
                broadcasts[key] = broadcastUpdated
              }
            })

            setStates({ ...states, broadcasts: broadcasts })
          }

          if (close) {
            closeModal()
          }
        },
        null
      )

    } else if (key === 'delete') {
      modal.confirm({
        title: record.title,
        content: t('Are you sure you want to permanently delete this broadcast restrictions?'),
        okText: t('Delete'),
        okType: 'danger',
        cancelText: t('Cancel'),
        onOk: () => {
          deleteBroadcast(record.id)
        }
      })

    } else {
      console.log(TAG, 'Unknow action (' + key + ')...')
    }
  }

  const handleAddBroadcast = () => {
    openModal(
      GLOBAL_MODAL_TYPES.BROADCAST_RESTRICTION,
      null,
      (folder, close) => {
        if (close) {
          closeModal()
        }

        setStates({ ...states, isLoaded:false })
      },
      null
    )
  }

  const deleteBroadcast = (userId) => {
    setStates({ ...states, isSubmitting: true })

    delBroadcastRestriction(APIRequest(authState.language, authState.token), userId).then(function (results) {
      if (isBadResponse(results.data)) {
        if (results.data.data.code === 'ASSOCIATED_USER') {
          notification.error({
            message: t('Associated users'),
            description: t('Unable to delete because one or more users are associated'),
            duration: null
          })
        } else {
          axiosError(t)
        }

        setStates({ ...states, isSubmitting:false })

        return
      }

      setStates({ ...states, isLoaded: false, isSubmitting:false })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: false,
        error: true
      })
    })
  }

  if (states.error) {
    return (
      <ApiErrorResult errorData={states.errorData} actionTitle={t('Try to reload')} actionHandle={() => {
        setStates({ ...states, error:false, errorData:null, isLoaded:false })
      }} />
    )
  }

  return (
    <main className='admin-container'>
      <TableHeader
        title={t('Broadcast restrictions')}
        buttons={[
          <Button type="primary" icon={<PlusOutlined />} disabled={states.isSubmitting} onClick={handleAddBroadcast}>{t('Add broadcast restrictions')}</Button>
        ]}
      />

      <Table
        columns={columns}
        size='small'
        rowKey={(item) => item.id}
        dataSource={states.broadcasts}
        pagination={{
          current: states.page,
          pageSize: states.limit,
          total: states.total,
          disabled: (!states.isLoaded),
          showTotal: (total) => getTablePagerInformations(t, states.page, states.limit, states.total),
          showSizeChanger: true,
          size: 'default'
        }}
        loading={!states.isLoaded || states.isSubmitting}
        onChange={handleTableChange}
        scroll={{ x: 680 }}
      />
      {contextHolder}
    </main>
  )
}
