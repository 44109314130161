import {
  FileImageOutlined, SoundOutlined, VideoCameraOutlined, FilePdfOutlined, FileTextOutlined,
  FileZipOutlined, FileOutlined
} from '@ant-design/icons';
import { 
  FILE_TYPE_IMAGE, FILE_TYPE_AUDIO, FILE_TYPE_VIDEO, FILE_TYPE_PDF, FILE_TYPE_DOCUMENT, FILE_TYPE_ARCHIVE, FILE_TYPE_OTHER,
  FILE_MIME_TYPE
} from '../Constant';

export const getFileType = (type, t) => {
  let icon = <FileOutlined />
  let label = 'Unknow type'

    if (type === FILE_TYPE_IMAGE) {
      icon = <FileImageOutlined />
      label = t('Image')
    } else if (type === FILE_TYPE_AUDIO) {
      icon = <SoundOutlined />
      label = t('Audio')
    } else if (type === FILE_TYPE_VIDEO) {
      icon = <VideoCameraOutlined />
      label = t('Video')
    } else if (type === FILE_TYPE_PDF) {
      icon = <FilePdfOutlined />
      label = t('PDF')
    } else if (type === FILE_TYPE_DOCUMENT) {
      icon = <FileTextOutlined />
      label = t('Document')
    } else if (type === FILE_TYPE_ARCHIVE) {
      icon = <FileZipOutlined />
      label = t('Archive')
    } else if (type === FILE_TYPE_OTHER) {
      label = t('Other')
    }

    return {
      type: type,
      icon: icon,
      label: label
    }
}
export const getMimeFileType = (type) => {
  let typeFound = null

  Object.keys(FILE_MIME_TYPE).every(key => {
    const found = FILE_MIME_TYPE[key].some(item => item === type)

    if (found) {
      typeFound = key
      return false
    }

    return true
  })

  return typeFound
}

export const convertFileSizeToHuman = (bytes) => {
  if (bytes === null) {
    return null
  }

  const arBytes = [
    {
      'unit': 'TB',
      'value': Math.pow(1024, 4)
    },
    {
      'unit': 'GB',
      'value': Math.pow(1024, 3)
    },
    {
      'unit': 'MB',
      'value': Math.pow(1024, 2)
    },
    {
      'unit': 'KB',
      'value': 1024
    },
    {
      'unit': 'B',
      'value': 1
    }
  ]
  let returnVal = null

  arBytes.forEach(item => {
    if (returnVal === null && bytes >= item.value) {
      returnVal = (bytes / item.value).toFixed(2).toString() + ' ' + item.unit
    }
  })

  return returnVal
}

export const convertDurationToHuman = (seconds) => {
  if (seconds === null) {
    return null
  }

  const date = new Date(null);
  date.setSeconds(seconds);
  
  return date.toISOString().substr(11, 8);
}

export const getBase64 = (file, callback) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.addEventListener('load', () => callback(reader.result))
    reader.onerror = error => reject(error)
  })
}
