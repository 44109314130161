import { useState, useEffect, useContext } from 'react';
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Typography, Space, Table, Image, Button, Dropdown, Menu, Tooltip, Badge } from 'antd';
import { 
  DownOutlined, DownloadOutlined, CheckOutlined, CloseOutlined,
  ShareAltOutlined, HistoryOutlined, EyeOutlined
} from '@ant-design/icons';
import { APIRequest, isBadResponse, getListFiles } from '../../models/APIRequest';
import { Context as AuthContext } from '../../context/AuthContext';
import ApiErrorResult from '../../components/ApiErrorResult';
import { axiosError } from '../../components/Notifications';
import DownloadModal from '../../components/DownloadModal';
import TableHeader from '../../components/table/TableHeader';
import FileStatus from '../../components/file/FileStatus';
import FileRegenerateThumbModal from '../../components/file/FileRegenerateThumbModal';
import FileMoveModal from '../../components/file/FileMoveModal';
import FileDeleteModal from '../../components/file/FileDeleteModal';
import FileEditWidget from '../../components/file/FileEditWidget';
import FileHistoryModal from '../../components/FileHistoryModal';
import { getFileType } from '../../utils/FileUtils';
import { getShortTimeFormat } from '../../utils/DateUtils';
import { transformSortOrderToApi } from '../../utils/ApiUtils';
import { getTablePagerInformations, updateFileCollection, updatesFileCollection } from '../../utils/TemplateUtils';
import { FILE_STATUS_DRAFT, FILE_STATUS_ONLINE, FILE_STATUS_ARCHIVE, FILE_TYPE_SORT } from "../../Constant";

export default function AdminFileScreen(props) {
  const TAG = 'AdminFileScreen'

  //Template
  const { t } = useTranslation()
  const { Text } = Typography
  const [ updateBreacrumbs ] = useOutletContext()
  const fileTypeFilters = () => {
    const filters = []

    FILE_TYPE_SORT.forEach((item) => {
      const type = getFileType(item, t)

      filters.push({
        text: type.label,
        value: item,
      })
    })

    return filters
  }
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: false,
    },
    {
      width: 60,
      render: (_, file) => (
        <Image.PreviewGroup>
          <Image
            src={(file.thumb_large) ? file.thumb_large : file.thumb}
            placeholder={file.thumb}
            preview={(file.thumb_large !== null)}
            alt={file.title}
            style={{ maxHeight:50 }}
          />
        </Image.PreviewGroup>
      ),
    },
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      showSorterTooltip: false,
      width: 250,
    },
    {
      title: t('Type'),
      dataIndex: 'type',
      key: 'filetype',
      sorter: true,
      showSorterTooltip: false,
      filters: fileTypeFilters(),
      filterMultiple: true,
      render: (_, file) => {
        const type = getFileType(file.file_type.type, t)

        return type.label
      }
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      showSorterTooltip: false,
      align:'center',
      width: 125,
      filters: [
        {
          text: t('Draft'),
          value: FILE_STATUS_DRAFT,
        },
        {
          text: t('Online'),
          value: FILE_STATUS_ONLINE,
        },
        {
          text: t('Archive'),
          value: FILE_STATUS_ARCHIVE,
        },
      ],
      filterMultiple: true,
      render: (_, { status }) => {
        return <FileStatus status={status} type='icon' withLabel={true} />
      },
    },
    {
      title: <Tooltip title={t('Can be shared?')}><ShareAltOutlined /></Tooltip>,
      dataIndex: 'can_share',
      key: 'can_share',
      sorter: false,
      showSorterTooltip: false,
      align:'center',
      width:50,
      filters: [
        {
          text: t('Yes'),
          value: 1,
        },
        {
          text: t('No'),
          value: 0,
        },
      ],
      filterMultiple: false,
      render: (_, { can_share }) => {
        if (can_share) {
          return <Text type="success"><CheckOutlined /></Text>
        } else {
          return <Text type="danger"><CloseOutlined /></Text>
        }
      },
    },
    {
      title: <Tooltip title={t('Can be downloaded?')}><DownloadOutlined /></Tooltip>,
      dataIndex: 'can_download',
      key: 'can_download',
      sorter: false,
      showSorterTooltip: false,
      align:'center',
      width:50,
      filters: [
        {
          text: t('Yes'),
          value: 1,
        },
        {
          text: t('No'),
          value: 0,
        },
      ],
      filterMultiple: false,
      render: (_, { can_download }) => {
        if (can_download) {
          return <Text type="success"><CheckOutlined /></Text>
        } else {
          return <Text type="danger"><CloseOutlined /></Text>
        }
      },
    },
    {
      title: t('Folder'),
      dataIndex: 'folder',
      key: 'folder',
      sorter: true,
      showSorterTooltip: false,
      render: (_, { folder }) => {
        return folder?.title
      },
    },
    {
      title: <Tooltip title={t('Opened')}><EyeOutlined /></Tooltip>,
      dataIndex: 'stats',
      render: (_, { stats }) => {
        return stats?.opened
      },
    },
    {
      title: <Tooltip title={t('Downloaded')}><DownloadOutlined /></Tooltip>,
      dataIndex: 'stats',
      render: (_, { stats }) => {
        return stats?.downloaded
      },
    },
    {
      title: <Tooltip title={t('Share')}><ShareAltOutlined /></Tooltip>,
      dataIndex: 'stats',
      render: (_, { stats }) => {
        return stats?.share
      },
    },
    {
      title: t('Update date'),
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: 'ascend',
      width: 150,
      align:'center',
      render: (_, { date_upd }) => {
        return moment(date_upd).format(getShortTimeFormat(authState.language))
      },
    },
    {
      title: t('Created by'),
      dataIndex: 'user',
      key: 'user',
      sorter: true,
      showSorterTooltip: false,
      responsive: ['xl'],
      render: (_, record) => {
        return record.user.name
      },
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      align:'center',
      //fixed: 'right',
      render: (_, record) => (
      <>
        <Dropdown
          icon={<DownOutlined />}
          key={'actions' + record.id}
          loading={false}
          trigger={['click']}
          overlay={
            <Menu
              items={[
                {
                  label: <FileHistoryModal
                    widgetType="menuItem"
                    fileId={record.id}
                  />,
                  icon: <HistoryOutlined />,
                  key: 'history',
                },
                {
                  label: <DownloadModal widgetType="menuItem" files={[record]} />,
                  icon: <DownloadOutlined />,
                  key: 'download',
                },
                {
                  label: <FileEditWidget
                    widgetType="menuItem"
                    file={record}
                    onUpdateFile={(file) => onUpdateFile(file.id, file)}
                    onDeleteFile={(file) => refreshTable()}
                  />,
                  key: 'edit',
                },
                {
                  type: 'divider'
                },
                {
                  label: <FileDeleteModal
                    widgetType='menuItem'
                    files={[record]}
                    onDeletedFile={(files) => refreshTable()}
                  />,
                  key: 'delete'
                },
              ]}
            />
          }
        >
          <Button block>
            <Space>
              {t('Actions')}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </>
      ),
      width: 130,
    }
  ]

  //State
  const { authState } = useContext(AuthContext)
  const [ states, setStates ] = useState({
    firstLoad: true,
    isLoaded: false,
    error: false,
    errorData: null,
    files: [],
    selectedRowKeys: [],
    selectedFiles: [],
    page: 1,
    pageMax: 1,
    total: 0,
    limit: 10,
    sortColumn: null,
    sortOrder: null,
    filters: null
  })

  //States methods
  const loadData = () => {
    console.log(TAG, 'loadData')
    console.log(TAG, 'Getting data...')

    getListFiles(APIRequest(authState.language, authState.token), states.page, states.limit, states.sortColumn, states.sortOrder, states.filters).then(function (results) {
      if (isBadResponse(results.data)) {
        setStates({
          ...states,
          isLoaded: false,
          firstLoad: false,
          error: true,
          errorData: results.data.data
        })

        return
      }

      setStates({
        ...states,
        isLoaded: true,
        firstLoad: false,
        files:  results.data.data.files,
        page: results.data.data.pager.page,
        pageMax: results.data.data.pager.page_max,
        total: results.data.data.pager.total,
        limit: results.data.data.pager.limit,
        error: false,
        errorData: null,
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: false,
        firstLoad: false,
        error: true,
        errorData: null,
      })
    })
  }

  //Events
  useEffect(() => {
    loadData()

    if (updateBreacrumbs) {
      console.log(TAG, 'updateBreacrumbs')
      updateBreacrumbs([{title: t('Files'), 'route': null},])
    }
  }, [])

  useEffect(() => {
    if (states.firstLoad || states.error || states.isLoaded) {
      return
    }

    loadData()
  }, [states.isLoaded, states.error])

  //Template method
  const refreshTable = () => {
    console.log(TAG, 'refreshTable')

    setStates({ ...states, isLoaded: false, isSubmitting:false, selectedFiles:[], selectedRowKeys:[] })
  }

  const onUpdateFile = (fileId, updatedFile) => {
    console.log(TAG, 'onUpdateFile')

    const files = updateFileCollection(updatedFile, [...states.files])

    setStates({ ...states, files: files, selectedFiles:[], selectedRowKeys:[] })
  }

  const onUpdateFiles = (updatedFiles) => {
    console.log(TAG, 'onUpdateFiles')

    const files = updatesFileCollection(updatedFiles, [...states.files])

    setStates({ ...states, files: files, selectedFiles:[], selectedRowKeys:[] })
  }

  const handleTableChange = (newPagination, filters, sorter) => {
    //Add search
    if (states.filters?.search) {
      filters['search'] = states.filters.search
    }

    setStates({
      ...states,
      selectedFiles: [],
      selectedRowKeys: [],
      page: newPagination.current,
      limit: newPagination.pageSize,
      sortColumn: sorter?.columnKey,
      sortOrder: transformSortOrderToApi(sorter?.order),
      filters: filters,
      isLoaded: false,
    })
  }

  const bulkActionsMenu = () => {
    return <Menu
      items={[
        {
          label: (
            <FileEditWidget
              file={states.selectedFiles[0]}
              files={states.selectedFiles}
              widgetType="menuItem"
              onUpdateFiles={(files) => onUpdateFiles(files)}
              onDeleteFiles={(files) => refreshTable()}
            />
          ),
          key: 'bulkEdit',
        },
        {
          label: (
            <FileMoveModal
              files={states.selectedFiles}
              widgetType='menuItem'
              onMovedFile={(files) => refreshTable()}
            />
          ),
          key: 'bulkMove',
        },
        {
          label: (
            <FileRegenerateThumbModal
              files={states.selectedFiles}
              widgetType='menuItem'
              onRegenerateThumbFiles={(files) => onUpdateFiles(files)}
            />
          ),
          key: 'bulkRegenerateThumb',
        },
        {
          type: 'divider'
        },
        {
          label: (
            <FileDeleteModal
              files={states.selectedFiles}
              widgetType='menuItem'
              onDeletedFile={(files) => refreshTable()}
            />
          ),
          key: 'bulkDel',
        }
      ]}
    />
  }

  if (states.error) {
    return (
      <ApiErrorResult errorData={states.errorData} actionTitle={t('Try to reload')} actionHandle={() => {
        setStates({ ...states, error:false, errorData: null, isLoaded:false })
      }} />
    )
  }

  return (
    <main className='admin-container'>
      <TableHeader
        title={t('Media')}
        contentStyle={{ display:'flex', alignItems:'center', justifyContent:'start' }}
        showSearch={true}
        onSearchChange={value => {
          if (!value) {
            return
          }

          setStates({
            ...states,
            filters: {
              ...states.filters,
              search: value
            }
          })
        }}
        onSearchSubmit={() => {
          setStates({
            ...states,
            isLoaded: false,
            page: 1,
          })
        }}
        onSearchClear={() => {
          setStates({
            ...states,
            isLoaded: false,
            page: 1,
            filters: {
              ...states.filters,
              search: null
            }
          })
        }}
      >
        <Dropdown trigger='click' overlay={bulkActionsMenu} disabled={states.selectedFiles.length === 0}>
          <Badge count={states.selectedFiles.length} overflowCount={999}>
            <Button disabled={states.selectedFiles.length === 0}>
              <Space>
                {t('Bulk actions')}
                
                <DownOutlined />
              </Space>
            </Button>
          </Badge>
        </Dropdown>
      </TableHeader>

      <Table
        columns={columns}
        size='small'
        rowKey={(item) => item.id + '-' + item.date_upd}
        dataSource={states.files}
        pagination={{
          current: states.page,
          pageSize: states.limit,
          total: states.total,
          disabled: (!states.isLoaded),
          showTotal: (total) => getTablePagerInformations(t, states.page, states.limit, states.total),
          showSizeChanger: true,
          size: 'default'
        }}
        rowSelection={{
          selectedRowKeys: states.selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            setStates({
              ...states,
              selectedRowKeys: selectedRowKeys,
              selectedFiles: selectedRows
            })
          }
        }}
        loading={!states.isLoaded || states.isSubmitting}
        onChange={handleTableChange}
        scroll={{ x: 680 }}
      />
    </main>
  )
}
