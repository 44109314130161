import { memo } from 'react';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { Layout, Typography } from 'antd';
import LangSelector from "../LangSelector";
import packageJson from '../../../package.json';

const TemplateFooter = (props) => {
  //Template
  const { t } = useTranslation()
  const { Footer } = Layout
  const { Text } = Typography

  // - {moment(packageJson.date).format(getShortFormat(authState.language))}
  return (
    <Footer style={styles.footer}>
      <LangSelector style={{ maxWidth:'100%' }} />
      <Text style={styles.footerText}>SODIKART © {moment().year()} - {t('All rights reserved')}</Text>
      <Text style={styles.footerSecondaryText}>{t('Version')} {packageJson.version}</Text>
    </Footer>
  )
}

export default memo(TemplateFooter)

const styles = {
  footer: {
    backgroundColor:'#001529',
    textAlign:'center',
    display: 'flex',
    flexDirection:'column',
    alignItems:'center',
    gap:8
  },
  footerText: {
    color:'#FFF',
    fontSize: 16
  },
  footerSecondaryText: {
    color:'#AAA',
    fontSize: 14
  }
}