import { memo, useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Tree, Tooltip, Space, Typography } from 'antd';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import { APIRequest, isBadResponse, getFolderTree } from '../models/APIRequest';
import { Context as AuthContext } from '../context/AuthContext';
import { MediaContext } from '../context/MediaContext';
import ApiErrorResult from './ApiErrorResult';
import LoadingBlock from './LoadingBlock';
import { axiosError } from './Notifications';
import { encodeObjectForUrl, decodeObjectFromUrl } from '../utils/ApiUtils';
import { setStorageData, getStorageData, delStorageData } from '../utils/StorageUtils';
import { isContributor } from '../utils/AccessRightUtils';

const FileTree = (props) => {
  const TAG = 'FileTree'

  //Template
  const { t } = useTranslation()
  const { Text } = Typography
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const paramsIdFolder = (params?.idFolder) ? parseInt(params.idFolder) : null
  const propsIdFolder = (props?.idFolder) ? parseInt(props.idFolder) : null
  const propsOnSelect = props?.onSelect

  //State
  const { authState } = useContext(AuthContext)
  const { mediaState, setRefreshFileTree } = useContext(MediaContext)
  const [ states, setStates ] = useState({
    preloadStates: true,
    isLoaded: false,
    error: false,
    errorData: null,
    autoExpandParent: true,
    treeData: [],
    listData: [],
    tree: [],
    expandedKeys: [],
    idFolder: (paramsIdFolder) ? paramsIdFolder : propsIdFolder,
    selectedKeys: [(paramsIdFolder) ? paramsIdFolder : propsIdFolder]
  })
  
  //Hooks
  useEffect(() => {
    console.log(TAG, '[authState.language, authState.token]')

    //Delete oldStates
    delStorageData(TAG)

    setStates({...states,
      isLoaded: false
    })
  }, [authState.language, authState.token])

  useEffect(() => {
    if (mediaState.refreshFileTree === true) {
      delStorageData(TAG)
      setRefreshFileTree(false)

      setStates({...states,
        isLoaded: false
      })
    }
  }, [mediaState.refreshFileTree])

  useEffect(() => {
    if (!states.preloadStates) {
      return
    }

    const idFolder = (params?.idFolder) ? parseInt(params.idFolder) : null
    let oldStates = getStorageData(TAG)

    if (oldStates) {
      oldStates = JSON.parse(oldStates)

      if (oldStates && (!oldStates.isLoaded || oldStates?.idFolder != idFolder)) {
        oldStates = null
      } else {
        oldStates['tree'] = convertDataOfApi(oldStates.treeData)
      }
    } else {
      oldStates = null
    }

    if (oldStates) {
      console.log(TAG, 'Old states')
      setStates({...oldStates, preloadStates: false})
    } else {
      console.log(TAG, 'New states')
      setStates({
        ...states,
        preloadStates: false,
        idFolder: idFolder,
        selectedKeys: [idFolder],
        tree: convertDataOfApi(states.treeData),
        expandedKeys: getExpandedKeyById(idFolder)
      })
    }
  }, [states.preloadStates])

  useEffect(() => {
    if (states.preloadStates) {
      return
    }

    console.log(TAG, '[location]')
    const idFolder = (params?.idFolder) ? parseInt(params.idFolder) : null

    if (idFolder !== states.idFolder) {
      console.log(TAG, 'Is new idFolder !')
      setStates({
        ...states,
        idFolder: idFolder,
        tree: convertDataOfApi(states.treeData),
        expandedKeys: getExpandedKeyById(idFolder),
        selectedKeys: [idFolder],
      })
    } else {
      setStates({
        ...states,
        tree: convertDataOfApi(states.treeData),
        expandedKeys: getExpandedKeyById(idFolder),
      })
    }
  }, [location.pathname])

  useEffect(() => {
    loadData()
  }, [states.preloadStates, states.isLoaded])

  useEffect(() => {
    if (states.preloadStates) {
      return
    }

    console.log(TAG, '[states]')

    if (props.updateBreacrumbs) {
      console.log(TAG, 'updateBreacrumbs')
      props.updateBreacrumbs(getBreacrumbs(states.idFolder))
    }

    try {
      setStorageData(TAG, JSON.stringify(states))
    } catch(e) {
      console.log(TAG, e)
    }
  }, [states])

  /************************************************* STATE METHODS ********************************************************/
  const loadData = () => {
    console.log(TAG, 'LoadData')
    if (states.preloadStates || states.isLoaded) {
      return
    }

    console.log(TAG, 'Getting data...')
    getFolderTree(APIRequest(authState.language, authState.token), (!isContributor(authState))).then(response => {
      if (isBadResponse(response.data)) {
        setStates({...states,
          isLoaded: true,
          error: true,
          errorData: {
            code: response.data?.data?.code,
            message: response.data?.data?.message,
            extraData: response.data?.data?.extra_data
          }
        })

        return true
      }

      setStates({...states,
        isLoaded: true,
        treeData: response.data.data.tree,
        listData: convertDataToList(response.data.data.tree),
        tree: convertDataOfApi(response.data.data.tree),
        expandedKeys: getExpandedKeyById(states.idFolder, convertDataToList(response.data.data.tree)),
        error: false
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: true,
        error: true
      })
    })
  }

  const convertDataOfApi = (data) => {
    if (!data) {
      return
    }

    return data.map(item => {
      let title = (
        <Space size={4}>
          {item.title}
          {!item.active && (
            <Tooltip title={t('Visible only to contributors')}>
              <Text type="danger"><EyeInvisibleOutlined /></Text>
            </Tooltip>
          )}
        </Space>
      )

      if (item.childrens) {
        return {
          title,
          key: item.id,
          children: convertDataOfApi(item.childrens)
        }
      }

      return {
        title,
        key: item.id,
      }
    })
  }

  const convertDataToList = (data) => {
    const list = []

    if (!data) {
      return list
    }

    const recursive = (data) => {
      data.forEach(item => {
        list.push({
          id: item.id,
          parentId: item.id_parent,
          title: item.title
        })

        if (item.childrens) {
          recursive(item.childrens)
        }
      })
    }

    recursive(data)

    return list
  }

  const getExpandedKeyById = (id, listData) => {
    if (!id) {
      return []
    }

    const data = (listData) ? listData : states.listData
    const expandedKeys = [...states.expandedKeys]

    data.every(item => {
      if (id === item.id) {
        expandedKeys.push(item.id)

        return false
      }

      return true
    })

    return expandedKeys
  }

  const getBreacrumbs = (id) => {
    if (!id) {
      return []
    }

    const breacrumbs = []
    const recursive = (id) => {
      states.listData.forEach(item => {
        if (item.id === id) {
          breacrumbs.push({
            title: item.title,
            route: (breacrumbs.length) ? '/media/explore/' + item.id : null
          })
          
          if (item.parentId) {
            recursive(item.parentId)
          }
        }
      })
    }

    recursive(id)

    return breacrumbs
  }

  /************************************************** VIEW METHODS ********************************************************/
  const onExpand = (expandedKeys) => {
    console.log(TAG, 'onExpand: ' + expandedKeys)
    setStates({
      ...states,
      expandedKeys: expandedKeys,
      autoExpandParent: false,
    })
  }

  const onSelect = (keys, e) => {
    console.log(TAG, 'onSelect: ' + keys)

    if (!keys[0]) {
      return
    }

    let filtersUrl = ''

    if (params?.filter) {
      const filters = decodeObjectFromUrl(params.filter)

      if (filters) {
        filtersUrl = '/filter/' + encodeObjectForUrl({
          ...filters,
          time: moment().unix(),
          isDefault: false,
          filters: null, //Don't conserve current filters
        })
      }
    }

    if (typeof propsOnSelect === 'function') {
      propsOnSelect()
    }

    navigate('/media/explore/' + keys[0] + filtersUrl)
  }

  /******************************************************* VIEW ***********************************************************/
  
  if (!states.isLoaded) {
    return (
      <LoadingBlock style={{ minHeight:150 }} />
    )
  }

  if (states.error) {
    return (
      <ApiErrorResult template="alert" style={{ margin:10 }} errorData={states.errorData} actionTitle={t('Try to reload')} actionHandle={() => {
        setStates({...states, isLoaded:false, error:false, errorData:null })
      }} />
    )
  }

  return (
    <div style={{ padding:10 }}>
      <Tree
        treeData={states.tree}
        selectedKeys={states.selectedKeys}
        expandedKeys={states.expandedKeys}
        autoExpandParent={states.autoExpandParent}
        onExpand={onExpand}
        onSelect={onSelect}
        showLine={{showLeafIcon: false}}
      />
    </div>
  )
}

export default memo(FileTree)