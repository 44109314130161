export const checkIsAlreadyInCart = (cart, idFile) => {
    if (!cart || !idFile) {
      return false
    }

    let test = false

    cart.forEach(id => {
      if (id === idFile) {
        test = true
      }
    })

    return test
  }
