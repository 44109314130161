import { memo, useState, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Space, Typography, Badge, Checkbox, Divider } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import invert from 'invert-color';
import { Context as AuthContext } from '../../context/AuthContext';
import DownloadModal from '../DownloadModal';
import ShareModal from '../ShareModal';
import BasketButton from '../BasketButton';
import FileEditWidget from '../file/FileEditWidget';
import FileDuplicateWidget from '../file/FileDuplicateWidget';
import FileDeleteModal from '../file/FileDeleteModal';
import FileStatus from '../file/FileStatus';
import { isContributor, canEditFile } from '../../utils/AccessRightUtils';
import { STICKER_NEW_COLOR } from "../../Constant";

const FileBlockList = (props) => {
  const TAG = 'FileBlockList'

  //Props
  const file = props?.file
  const isChecked = props?.isChecked
  const disabled = props?.disabled
  const onClick = props?.onClick
  const onCheckFile = props?.onCheckFile
  const onDeleteFile = props?.onDeleteFile
  const onUpdateFile = props?.onUpdateFile
  const canAddBasket = (props?.canAddBasket === false) ? false : true
  const canDownload = (props?.canDownload === false) ? false : true
  const canShare = (props?.canShare === false) ? false : true
  const share = props?.share
  const readOnly = (props?.readOnly === true || share) ? true : false

  //Template
  const { t } = useTranslation()
  const { Text, Paragraph } = Typography
  const { authState } = useContext(AuthContext)

  //States
  const [ isHovered, setIsHovered] = useState(false)

  //Template methods
  const getStickers = () => {
    const views = []
    const topIncrease = 30
    let top = 15

    if (isContributor(authState)) {
      views.push(
        <FileStatus status={file.status} type='ribbon' style={{ top:top, zIndex:9 }} key='FileBlockStatus' />
      )

      top += topIncrease
    }

    if (file.is_new === true) {
      views.push(
        <Badge.Ribbon
          key='FileBlockRebbonNew'
          placement="start"
          text={<Text style={{ color:invert(STICKER_NEW_COLOR, true), textTransform:'uppercase', fontSize:12 }} strong><ClockCircleOutlined /> {t('New!')}</Text>}
          color={STICKER_NEW_COLOR}
          style={{ top:top, zIndex:9 }}
        />
      )
    }


    if (!file.stickers || !file.stickers.length) {
      return views
    }

    //Reset top (different placement)
    top = 15

    file.stickers.forEach((item) => {
      views.push(
        <Badge.Ribbon
          key={'FileBlockRebbon' + item.id}
          text={<Text style={{ color:invert(item.color, true), textTransform:'uppercase', fontSize:12 }} strong>{item.title}</Text>}
          color={item.color}
          style={{ top:top, zIndex:9 }}
        />
      )
      top += topIncrease
    })

    return views
  }

  const getTitle = () => {
    let title = (
        <Paragraph
          ellipsis={{ rows:2, expandable:false }}
          style={{ textAlign:'center', margin:0, width:'100%', marginRight:10 }}
        >
          {file.title}
        </Paragraph>
    )

    if (!readOnly && canEditFile(authState, file)) {
      title = (
        <div style={{ display:'flex', width:'100%', height:60, alignItems:'center', justifyContent:'space-between' }}>
          <Checkbox
            name={'fileCheckbox' + file.id}
            value={file.id}
            checked={isChecked}
            onChange={(e) => onCheckFile(file, e.target.checked)}
            style={{ margin:10 }}
          />
          <div
            style={{ display:'flex', width:'100%', height:60, alignItems:'center', cursor:'pointer'}}
            onClick={(e) => {
              if (canEditFile(authState, file)) {
                onCheckFile(file, (!isChecked))
              } else {
                onClick()
              }
            }}
          >
            {title}
          </div>
        </div>
      )
    } else {
      return (
        <div style={{ display:'flex', width:'100%', height:60, alignItems:'center' }}>
          {title}
        </div>
      )
    }

    return title
  }

  const getUserActions = () => {
    const views = []

    if (canAddBasket) {
       views.push(<BasketButton type="icon" file={file} withTooltip={false} key="UserActionsBasketButton" />)
    }
    if (canShare) {
      views.push(<ShareModal widgetType="buttonIcon" files={[file]} key="UserActionsShareModal" />)
    }
    if (canDownload) {
      views.push(<DownloadModal widgetType="buttonIcon" files={[file]} share={share} key="UserActionsDownloadModal" />)
    }

    if (!disabled && !readOnly && canEditFile(authState, file)) {
      views.push(<Divider type="vertical" style={{ marginLeft:2, marginRight:2 }} key="UserActionsDivider" />)
      views.push(
        <FileEditWidget
          key="UserActionsFileEditWidget"
          file={file}
          widgetType="buttonIcon"
          widgetProps={{ type:'dashed' }}
          onCancel={() => setIsHovered(false)}
          onUpdateFile={(file) => {
            if (typeof onUpdateFile === 'function') {
              onUpdateFile(file.id, file)
            }
          }}
          onDeleteFile={(file) => {
            if (typeof onDeleteFile === 'function') {
              onDeleteFile(file.id)
            }
          }}
        />
      )
      views.push(
        <FileDuplicateWidget
          key="UserActionsFileDuplicateWidget"
          file={file}
          widgetType="buttonIcon"
          widgetProps={{ type:'dashed' }}
        />
      )
      views.push(
        <FileDeleteModal
          key="UserActionsFileDeleteModal"
          files={[file]}
          widgetType='buttonIcon'
          widgetProps={{ type:'dashed', danger:true }}
          onCancel={() => setIsHovered(false)}
          onDeletedFile={(files) => {
            if (files && files[0] && typeof onDeleteFile === 'function') {
              onDeleteFile(files[0])
            }
          }}
        />
      )
    }

    if (!views.length) {
      return null
    }

    return (
      <div
        key="UserActionsContainer"
        style={{
          position:'absolute',
          left:0,
          right:0,
          bottom:isHovered ? 0 : -55,
          padding:10,
          backgroundColor:'rgba(255, 255, 255, 0.8)',
          transition:'bottom .15s linear',
          zIndex: 10
        }}
      >
        <Space style={{ width:'100%', justifyContent:'center' }} direction="horizontal" wrap={true}>
          {views}
        </Space>
      </div>
    )
  }

  if (!file) {
    return (<>Bad file props</>)
  }

  return (
    <div style={{ padding:10, overflow:'hidden', position:'relative' }}>
      {getStickers()}

      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ overflow:'hidden', position:'relative', cursor:onClick ? 'pointer' : 'auto' }}
      >
        <div
          style={{
            height:300,
            backgroundColor:'#FFF',
            backgroundImage:'url(' + file.thumb + ')',
            backgroundSize:(file.file_type.type === 'pdf') ? 'contain' : 'cover',
            backgroundPosition:'center',
            backgroundRepeat:'no-repeat',
            padding:0
          }}
          onClick={onClick}
        >
        </div>
        {getUserActions()}
      </div>

      <Divider style={{ margin:0 }} />
      <div style={{ backgroundColor:'#FFF', height:60 }}>
        {getTitle()}
      </div>
    </div>
  )
}

export default memo(FileBlockList)