import { memo, useContext, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Modal, Alert, Space, List, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Context as AuthContext } from '../../context/AuthContext';
import { APIRequest, isBadResponse, delFiles, delHardFiles } from '../../models/APIRequest';
import { axiosError } from '../Notifications';
import { isContributor } from '../../utils/AccessRightUtils';

const FileDeleteModal = (props) => {
  const TAG = 'FileDeleteModal'

  //Template
  const { t } = useTranslation()
  const { Text } = Typography
  const files = props?.files
  const hardDeletion = (props?.hardDeletion === true)
  const widgetType = props?.widgetType
  const widgetLabel = (props?.widgetLabel) ? props?.widgetLabel : ((hardDeletion) ? t('Delete') : t('Trash'))
  const widgetProps = props?.widgetProps
  const onCancel = props?.onCancel
  const onDeletedFile = props?.onDeletedFile
  
  //States
  const DEFAULT_STATES = {
    isModalVisible: false,
    isSubmitting: false
  }
  const { authState } = useContext(AuthContext)
  const [ states, setStates ] = useState(DEFAULT_STATES)

  //Check right & availability
  if (!isContributor(authState)) {
    console.log(TAG, 'Bad rights')
    return null
  } else if (!files || !files.length) {
    console.log(TAG, 'Bad files prop (array require)')
    return null
  }

  //Methods template
  const showModal = () => {
    setStates({...states, isModalVisible: true})
  }

  const hideModal = () => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
    
    setStates({
      ...states,
      ...DEFAULT_STATES
    })
  }

  const handleDeleteFiles = () => {
    console.log(TAG, 'handleDeleteFiles')

    setStates({ ...states, isSubmitting: true })

    const fileIds = []
    files.forEach((file) => fileIds.push(file.id))

    let instance

    if (hardDeletion) {
      instance = delHardFiles(APIRequest(authState.language, authState.token), fileIds)
    } else {
      instance = delFiles(APIRequest(authState.language, authState.token), fileIds)
    }

    instance.then(function (results) {
      if (isBadResponse(results.data)) {
        axiosError(t)

        setStates({ ...states, isSubmitting: false })

        return
      }

      if (typeof onDeletedFile === 'function') {
        onDeletedFile(results.data.data.files_deleted)
      }
      
      hideModal()
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({ ...states, isSubmitting: false })
    })
  }

  const getWidget = () => {
    if (widgetType === 'menuItem') {
      return (
        <button
          type="button"
          className="menu-item-button"
          onClick={showModal}
        >
          <Text type='danger' {...widgetProps}>
            <DeleteOutlined /> {widgetLabel}
          </Text>
        </button>
      )
    } else if (widgetType === 'buttonIcon') {
      return (
        <Button
          shape='circle'
          type='dashed'
          icon={<DeleteOutlined />}
          onClick={showModal}
          {...widgetProps}
        />
      )
    } else if (widgetType === 'button') {
      return (
        <Button
          icon={<DeleteOutlined />}
          onClick={showModal}
          {...widgetProps}
        >
          {widgetLabel}
        </Button>
      )
    } else {
      return 'Unknow widget type'
    }
  }

  //Modal
  return (
    <>
      {getWidget()}

      <Modal
        title={(hardDeletion) ? t('Permanently delete media selection') : t('Move media to trash')}
        open={states.isModalVisible}
        zIndex={110}
        onCancel={hideModal}
        footer={[
          <Button type="link" key="deleteSelectionNo" onClick={hideModal}>
            {t('Cancel')}
          </Button>,
          <Button type="primary" key="deleteSelectionYes" disabled={states.isSubmitting} loading={states.isSubmitting} onClick={handleDeleteFiles}>
            {t('Delete')}
          </Button>,
        ]}
      >
        <Space direction="vertical" style={{ width:'100%' }}>
          <List
            size="small"
            dataSource={files}
            renderItem={item => (
              <List.Item style={{ display:'flex' }}>
                <div style={{ width:50, textAlign:'center', marginRight:10 }}>
                  <img src={item.thumb} style={{ maxWidth:50, maxHeight:30 }} alt={item.title} />
                </div>
                <div style={{ flex:1 }}>
                  {item.title}
                </div>
              </List.Item>
            )}
          />

          {hardDeletion ? (
            <Alert
              key='FileDeleteSoft'
              description={t('Are you sure you want to permanently delete the above media?')}
              type="info"
              showIcon
            />
          ) : (
            <Alert
              key='FileDeleteHard'
              description={t('Are you sure you want to move the above media to the trash?')}
              type="warning"
              showIcon
            />
          )}
        </Space>
      </Modal>
    </>
  )
}

export default memo(FileDeleteModal)
