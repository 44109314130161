import { memo, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Select } from 'antd';
import { Context as AuthContext } from '../context/AuthContext'
import ReactCountryFlag from "react-country-flag"
import { LANGUAGE_AVALAIBLE } from '../Constant'

const LangSelector = (props) => {
  //Template
  const { i18n } = useTranslation()
  const { Option } = Select;
  const style = (props?.style) ? props.style : null
  
  //States
  const { authState, updateLanguage } = useContext(AuthContext)

  const onChange = (value) => {
    updateLanguage(value)
    i18n.changeLanguage(value)
  }

  const getLabel = (item) => {
    if (!item) {
      return null
    }

    return (
      <span style={{ display:'flex', alignItems:'center' }}>
        <ReactCountryFlag
          countryCode={item.countryCode}
          svg
        />
        <span style={{ marginLeft:5 }}>{item.title}</span>
      </span>
    )
  }

  return (
    <Select
      value={authState.language}
      onChange={onChange}
      filterOption={(inputValue, option) => {
          if (!option?.children || inputValue === null) {
            return false
          }

          return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
      }}
      style={style}
    >
      {LANGUAGE_AVALAIBLE.map((item) => {
        return (
          <Option value={item.route} key={"langSelector" + item.countryCode}>
            {getLabel(item)}
          </Option>
        )
      })}
    </Select>
  );
}

export default memo(LangSelector)