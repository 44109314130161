import { useState, useEffect, useContext, memo } from 'react';
import { useTranslation } from "react-i18next";
import { List, Divider, Typography } from 'antd';
import { useLocation } from "react-router-dom";
import { APIRequest, isBadResponse, getFolderChildrends } from '../../models/APIRequest';
import { Context as AuthContext } from '../../context/AuthContext';
import { MediaContext } from '../../context/MediaContext';
import ApiErrorResult from '../ApiErrorResult';
import { axiosError } from '../Notifications';
import FolderBlockList from "./FolderBlockList";
import { setStorageData, getStorageData } from '../../utils/StorageUtils';

const SubFolder = (props) => {
  const TAG = "SubFolder"

  //Template
  const { t } = useTranslation()
  const { Text } = Typography
  const idFolder = props?.idFolder
  const onClickFolder = props?.onClickFolder
  const onDeleteFolderProps = props?.onDeleteFolder
  const location = useLocation()

  //State
  const DEFAULT_STATES = {
    preloadStates: true,
    error: false,
    errorData: null,
    
    idFolder: parseInt(idFolder),

    isLoaded: false,
    folders: []
  }
  const { authState } = useContext(AuthContext)
  const { setRefreshFileTree } = useContext(MediaContext)
  const [ states, setStates ] = useState(DEFAULT_STATES)

  //Hooks
  useEffect(() => {
    console.log(TAG, '[location]')

    processOldStates()
  }, [location])

  useEffect(() => {
    if (!states.preloadStates) {
      return
    }

    console.log(TAG, '[]')

    processOldStates()
  }, [])

  useEffect(() => {
    if (states.preloadStates) {
      return
    }

    console.log(TAG, '[idFolder]')

    if (!idFolder) {
      console.log(TAG, 'idFolder not defined')
      return
    }

    setStates({
      ...DEFAULT_STATES,
      preloadStates: false,
      isLoaded: (!idFolder),
      contentIsLoaded: false
    })
  }, [idFolder])

  useEffect(() => {
    if (states.preloadStates || states.isLoaded) {
      return
    }

    console.log(TAG, '[states.preloadStates, states.isLoaded]')

    loadData()
  }, [states.preloadStates, states.isLoaded])

  useEffect(() => {
    if (states.preloadStates) {
      return
    }

    console.log(TAG, '[authState.language, authState.token]')

    setStates({
      ...states,
      isLoaded: false
    })

  }, [authState.language, authState.token])

  useEffect(() => {
    console.log(TAG, '[states]')

    try {
      setStorageData(TAG, JSON.stringify(states))
    } catch(e) {
      console.log(TAG, e)
    }
  }, [states])

  /************************************************* STATE METHODS ********************************************************/
  const processOldStates = () => {
    console.log(TAG, 'processOldStates')

    let oldStates = getStorageData(TAG)

    if (oldStates) {
      try {
        oldStates = JSON.parse(oldStates)

        if (oldStates && (oldStates.error || !oldStates.isLoaded || oldStates?.idFolder != idFolder)) { //Use != NOT !===
          oldStates = null
        
        }
      
      } catch(thrown) {
        console.log(TAG, thrown)
        oldStates = null
      }
    } else {
      oldStates = null
    }

    if (oldStates !== null) {
      console.log(TAG, 'processOldStates: oldStates use')

      setStates({
        ...DEFAULT_STATES,
        ...oldStates,
        preloadStates: false
      })
    
    } else {
      console.log(TAG, 'processOldStates: New states')

      setStates({
        ...DEFAULT_STATES,
        preloadStates: false
      })
    }
  }

  const loadData = () => {
    console.log(TAG, 'loadData - Getting data...')

    getFolderChildrends(APIRequest(authState.language, authState.token), states.idFolder).then(function (results) {
      if (isBadResponse(results.data)) {
        setStates({
          ...states,
          isLoaded: true,
          error: true,
          errorData: results.data.data,
        })

        return
      }

      setStates({
        ...states,
        isLoaded: true,
        error: false,
        errorData: null,
        folders: results.data.data.folders
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: true,
        error: true,
        errorData: null,
      })
    })
  }

  //Template method
  const onDeleteFolder = (folderId, filesMovedToParent) => {
    console.log(TAG, 'onDeleteFolder')

    const currentFolders = [...states.folders]

    if (typeof onDeleteFolderProps === 'function') {
      const folder = currentFolders.filter(folder => folder.id === folderId)

      if (folder && folder[0]) {
        onDeleteFolderProps(folderId, filesMovedToParent)
      }
    }

    const folders = currentFolders.filter(folder => folder.id !== folderId)

    setStates({
      ...states,
      folders: folders
    })

    setRefreshFileTree(true)
  }

  const onUpdateFolder = (folderId, updatedFolder) => {
    console.log(TAG, 'onUpdateFolder (' + folderId + ')')

    if (!states.folders.length) {
      console.log(TAG, 'onUpdateFolder - No folders in states!')
      return
    }

    let folderKey = -1

    states.folders.forEach((item, key) => {
      if (item.id === folderId) {
        folderKey = key
      }
    })

    if (folderKey === -1) {
      console.log(TAG, 'onUpdateFolder - folderKey not found!')
      return
    }

    const folders = [...states.folders]
    folders[folderKey] = updatedFolder

    setStates({...states, folders: folders})
    setRefreshFileTree(true)
  }

  //Template
  if (states.error) {
    return (
      <ApiErrorResult
        errorData={states.errorData}
        actionTitle={t('Try to reload')}
        template="alert"
        style={{ marginTop:20 }}
        actionHandle={() => {
        setStates({
          ...states,
          preloadStates: false,
          isLoaded: false,
          error: false,
          errorData: null
        })
      }} />
    )
  }

  if (!states.folders?.length) {
    return null
  }

  return (
    <>
      <Divider orientation="center" plain><Text type='secondary'>{t('Subfolders')}</Text></Divider>
      <List
        grid={{
          gutter: [16, 16],
          xs: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 3,
          xxl: 4,
        }}
        dataSource={states.folders}
        rowKey={item => item.id}
        renderItem={item => (
          <List.Item>
            <FolderBlockList
              folder={item}
              onClick={() => onClickFolder(item.id, {})}
              onDeleteFolder={onDeleteFolder}
              onUpdateFolder={onUpdateFolder}
            />
          </List.Item>
        )}
      />
    </>
  )
}
export default memo(SubFolder)