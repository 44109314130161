import React, { useReducer } from 'react';
import { getStorageData } from '../utils/StorageUtils';

const TAG = 'MediaMediaContext'

const authReducer = (state, action) => {
  switch (action.type) {
    case 'setRefreshFileTree':
      return {
        refreshFileTree: action.payload.refreshFileTree
      }
    default:
      return state
  }
}

const setRefreshFileTree = dispatch => {
  console.log(TAG, 'setRefreshFileTree')

  return async(value) => {
    dispatch({
      type: 'setRefreshFileTree',
      payload: {
        refreshFileTree: value
      }
    })
  }
}

const retrieveMediaContext = dispatch => {
  console.log(TAG, 'retrieve')
  
  return async() => {
    let refreshFileTree = false

    try {
      refreshFileTree = getStorageData('refreshFileTree')
    } catch(e) {
      console.log(TAG, e)
    }
  
    dispatch({
      type: 'retrieve',
      payload: {
        refreshFileTree: refreshFileTree,
      },
    })
  }
}

const createDataMediaContext = ( reducer, action, defaultValue ) => {
  const MediaContext = React.createContext();

  const MediaProvider = ({ children }) => {
    const [ mediaState, dispatch ] = useReducer(reducer, defaultValue)
    const boundActions = {}

    for (let key in action) {
      boundActions[key] = action[key](dispatch)
    }

    return React.useMemo(() => (
      <MediaContext.Provider value={{ mediaState, ...boundActions }}>
        { children }
      </MediaContext.Provider>
    ))
  }

  return { MediaContext: MediaContext, MediaProvider: MediaProvider }
}

export const {MediaProvider, MediaContext} = createDataMediaContext(
  authReducer,
  { retrieveMediaContext, setRefreshFileTree },
  { refreshFileTree: false },
)