import { Navigate } from "react-router-dom";
import { isContributor, isAdmin, isSuperAdmin } from '../utils/AccessRightUtils';

export const IsLoggedGuard = ({ authState, children }) => {
  if (!authState.isLogged) {
    return <Navigate to="/" replace />
  }

  return children
}

export const IsContributorGuard = ({ authState, children }) => {
  if (!isContributor(authState)) {
    return <Navigate to="/" replace />
  }

  return children
}

export const IsAdminGuard = ({ authState, children }) => {
  if (!isAdmin(authState)) {
    return <Navigate to="/" replace />
  }

  return children
}

export const IsSuperAdminGuard = ({ authState, children }) => {
  if (!isSuperAdmin(authState)) {
    return <Navigate to="/" replace />
  }

  return children
}
