import { memo, useContext, useState, useEffect, createRef } from 'react';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { Button, Modal, Divider, Typography, Form, List, Alert, Input, Radio, Segmented, DatePicker, Switch, Result } from 'antd';
import { CheckOutlined, CloseOutlined, ShareAltOutlined, LockOutlined, UnlockOutlined, LinkOutlined, MailOutlined } from '@ant-design/icons';
import { Context as AuthContext } from '../context/AuthContext';
import { APIRequest, isBadResponse, addShare } from '../models/APIRequest';
import { axiosError, objectError, formError } from './Notifications';
import LoadingBlock from './LoadingBlock';
import { getShortFormat } from '../utils/DateUtils';
import { isUser } from '../utils/AccessRightUtils';

const ShareModal = (props) => {
  const TAG = 'ShareModal'

  //Template
  const { t } = useTranslation()
  const { Paragraph, Text } = Typography
  const widgetType = props?.widgetType
  const widgetProps = props?.widgetProps
  const files = props?.files
  const formRef = createRef()
  
  //States
  const DEFAULT_STATES = {
    isModalVisible: false,
    isLoaded: false,
    isSubmitting: false,
    submitSuccess: false,
    shareType: 'link',
    sharePassword: false,
    link: null,
    formValues: {
      recipient_email: null,
      recipient_name: null,
      title: null,
      description: null,
      date_expire: moment().add(7,'d'),
      secure_password: null,
      multiple_opening: true
    },
    cantShare: [],
  }
  const { authState } = useContext(AuthContext)
  const [ states, setStates ] = useState(DEFAULT_STATES)

  //Hooks
  useEffect(() => {
    if (!files) {
      return
    }

    loadData()
  }, [states.isModalVisible , states.isLoaded])

  //Hooks methods
  const loadData = () => {
    console.log(TAG, 'LoadData')

    if (!states.isModalVisible || states.isLoaded) {
      return
    }
    
    console.log(TAG, 'Getting data...')

    const cantShare = []

    files.forEach((file) => {
      if (!file.can_share_access) {
        cantShare.push({
          key: 'cantShare' + file.id,
          thumb: file.thumb,
          title: file.title,
          description: file.description,
        })
      }
    })

    setStates({
      ...states,
      isLoaded: true,
      cantShare: cantShare,
      error: false
    })

  }

  //Methods template
  const showModal = () => {
    setStates({...states, isModalVisible: true})
  }

  const hideModal = () => {
    setStates({
      ...states,
      ...DEFAULT_STATES
    })
  }

  //Template methods
  const submitForm = () => {
    console.log(TAG, 'submitForm')

    //Make formData
    const formDatas = {}

    files.forEach((file, key) => {
      if (!file.can_share_access) {
        return
      }

      formDatas['files[' + key + ']'] = file.id
    })

    if (Object.keys(states.formValues).length) {
      Object.entries(states.formValues).forEach(format => {
        if (format[0] === 'date_expire') {
          formDatas[format[0]] = (format[1]) ? format[1].format('YYYY-MM-DD') : null
        } else if (format[0] === 'secure_password') {
          formDatas['password'] = format[1]
        } else {
          formDatas[format[0]] = format[1]
        }
      })
    }

    addShare(APIRequest(authState.language, authState.token), formDatas).then(response => {
      if (isBadResponse(response.data)) {
        objectError(t)

        setStates({
          ...states,
          isSubmitting: false
        })

        return
      }

      setStates({
        ...states,
        isSubmitting: false,
        submitSuccess: true,
        link: response.data.data.link,
        error: false
      })

    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isSubmitting: false
      })
    })
  }

  const checkShareIsAvailable = (files) => {
    let test = false

    if (!files || !files.length) {
      return test
    }

    files.every(file => {
      if (file.can_share_access) {
        test = true
        
        return false
      }

      return true
    })

    return test
  }

  const isUnrestrictedAccessShare = (files) => {
    let restrictedFiles = 0
    let unrestrictedFiles = 0

    if (!files || !files.length) {
      return false
    }

    files.forEach(file => {
      if (file.can_share_access) {
        unrestrictedFiles++;
      }
      if (file.can_share) {
        restrictedFiles++;
      }
    })

    return (unrestrictedFiles > restrictedFiles)
  }

  const getCantShare = () => {
    const views = []

    if (!states.cantShare) {
      return views
    }

    states.cantShare.forEach((file) => {
      views.push(
        <List.Item key={file.key}>
          <List.Item.Meta
            avatar={<img src={file.thumb} alt={file.title} style={{ width:60 }}  />}
            title={file.title}
            description={file.description}
          />
        </List.Item>
      )
    })

    if (views.length) {
      return (
        <>
          <Divider>{t('Media cannot be shared')}</Divider>
          <Alert type="error" message={
            <List>
              {views}
            </List>
          } />
        </>
      )
    } else {
      return null
    }
  }

  const getWidget = () => {
    const isUnrestricted = isUnrestrictedAccessShare(files)

    if (widgetType === 'menuItem') {
      return (
        <Text onClick={() => showModal()} {...widgetProps}>
          {isUnrestricted ? <UnlockOutlined /> : null} {t('Share')}
        </Text>
      )
    } else if (widgetType === 'button') {
      return (
        <Button
          icon={isUnrestricted ? <UnlockOutlined /> : <ShareAltOutlined />}
          disabled={!files.length}
          onClick={() => showModal()}
          {...widgetProps}
          type={isUnrestricted ? 'dashed' : widgetProps?.type}
        >
          {t('Share')}
        </Button>
      )
    } else if (widgetType === 'buttonIcon') {
      return (
        <Button
          shape="circle"
          icon={<ShareAltOutlined />}
          disabled={!files.length}
          onClick={() => showModal()}
          {...widgetProps}
          type={isUnrestricted ? 'dashed' : widgetProps?.type}
        />
      )
    } else {
      return (<div>Widget type not found ({widgetType})</div>)
    }
  }

  const getLoading = () => {
    if (!states.isLoaded) {
      return <LoadingBlock />
    } else if (states.isSubmitting) {
      return <LoadingBlock text={t('Please wait, we prepare your share...')} />
    }

    return null
  }

  const getSuccess = () => {
    if (states.submitSuccess) {
      return (
        <Result
          status="success"
          title={t('Successfully created share')}
          subTitle={(
            <>
            <Paragraph>{t('The link of your share is:')}</Paragraph>
            <Paragraph>{states.link}</Paragraph>
            {(states.formValues.password && states.formValues.password !== "") && (
              <Alert message={t('Do not forget to communicate the password to the recipient.')} type="warning" />
            )}
            </>
          )}
          extra={[
            <Button type="primary" key="console" onClick={() => hideModal()}>
              {t('Close')}
            </Button>,
          ]}
        />
      )
    }

    return null
  }

  //Check right & availability
  if (!files || !files.length) {
    console.log(TAG, 'Bad files prop (array require)')
    return null
  } else if (!isUser(authState) || !checkShareIsAvailable(files)) {
    return null
  }

  //Modal
  return (
    <>
      {getWidget()}
      <Modal
        title={t('Share') + ((states.isLoaded && files.length > 1) ? ' - ' + t('%d files').replace('%d', files.length) : '')}
        open={states.isModalVisible}
        zIndex={110}
        destroyOnClose={true}
        onCancel={hideModal}
        footer={(!states.submitSuccess) ? [
          <Button type="link" key="shareNo" onClick={() => {
            hideModal()
          }}>
            {t('Cancel')}
          </Button>,
          <Button type="primary" key="shareYes" disabled={states.isSubmitting} loading={states.isSubmitting} onClick={() => {
            formRef.current.submit()
          }}>
            {t('Share')}
          </Button>,
        ] : null}
      >
        {getLoading()}
        {getSuccess()}

        {(states.isLoaded && !states.submitSuccess) && (
          <>
            <Alert
              message={t('Important')}
              description={t('Shared media will not be subject to access rights verification.')}
              type="warning"
              showIcon
            />
            {getCantShare()}
            <Form
              name="share"
              ref={formRef}
              layout="vertical"
              initialValues={states.formValues}
              onValuesChange={(changedValues, allValues) => {
                setStates({...states, formValues: {...states.formValues, ...allValues}})
              }}
              onFinish={() => submitForm()}
              onFinishFailed={({ values, errorFields, outOfDate }) => formError(errorFields, t)}
            >
              <Divider>{t('Share type')}</Divider>
              <Segmented
                block
                options={[
                  { label: t('Get share link'), value: 'link', icon: <LinkOutlined />, },
                  { label: t('Send sharing by email'), value: 'mail', icon: <MailOutlined />, }
                ]}
                value={states.shareType}
                onChange={(value) => {
                  formRef.current.setFieldsValue({
                    ...states.formValues,
                    recipient_email: null,
                    recipient_name: null
                  })

                  setStates({
                    ...states,
                    shareType: value,
                      formValues: {
                        ...states.formValues,
                        recipient_email: null,
                        recipient_name: null
                      }
                  })
                }}
              />
              
              {(states.shareType === 'mail') && (
                <>
                  <Divider>{t('Recipient')}</Divider>
                  <Form.Item
                    label={t('Email')}
                    name="recipient_email"
                    required={true}
                    rules={[
                      { required: true, message: t('Please enter a recipient email') },
                      { type: 'email', message: t('Please enter a valid email') },
                      { max: 255, message: t('Recipient email must not exceed %d% characters').replace('%d%', 255) }
                    ]}
                  >
                    <Input type="email" />
                  </Form.Item>
                  <Form.Item
                    label={t('Name')}
                    name="recipient_name"
                    required={true}
                    rules={[
                      { required: true, message: t('Please enter a recipient name') },
                      { max: 75, message: t('Recipient name must not exceed %d% characters').replace('%d%', 75) }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </>
              )}
              
              <Divider>{t('Information')}</Divider>
              <Form.Item
                label={t('Title')}
                name='title'
                required={true}
                rules={[
                  { required: true, message: t('Please enter a title') },
                  { max: 100, message: t('The title must not exceed %d% characters').replace('%d%', 100) }
                ]}
              >
                <Input
                  showCount
                  maxLength={100}
                />
              </Form.Item>
              <Form.Item
                label={t('Description')}
                name='description'
                required={false}
                rules={[
                  {max: 1000, message: t('Description must not exceed %d% characters').replace('%d%', 1000)},
                ]}
              >
                <Input.TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  showCount
                  maxLength={1000}
                />
              </Form.Item>

              <Divider>{t('Security')}</Divider>
              <Form.Item
                label={t('Expire date')}
                name='date_expire'
                required={true}
                rules={[{ required: true, message: t('Please enter an expiration date') }]}
              >
                <DatePicker
                  format={getShortFormat(authState.language)}
                  disabledDate={d => !d || d.isBefore(Date.now())}
                  getPopupContainer={trigger => trigger.parentNode}
                />
              </Form.Item>
              <Form.Item
                label={t('Secure your share with a password?')}
              >
                <Segmented
                  block
                  options={[
                    { label: t('Yes'), value: true, icon: <LockOutlined />, },
                    { label: t('No'), value: false, icon: <UnlockOutlined />, }
                  ]}
                  value={states.sharePassword}
                  onChange={(value) => {
                    formRef.current.setFieldsValue({
                      ...states.formValues,
                      secure_password: null
                    })

                    setStates({
                      ...states,
                      sharePassword: value,
                      formValues: {
                        ...states.formValues,
                        secure_password: null
                      }
                    })
                  }}
                />
              </Form.Item>
              {(states.sharePassword === true) && (
                <Form.Item
                  name='secure_password'
                  rules={[
                    {min: 8, message: t('Password must be at least %d% characters').replace('%d%', 8)},
                    {max: 16, message: t('Password must not exceed %d% characters').replace('%d%', 16)},
                  ]}
                  required={states.sharePassword}
                  extra={t('The password will not be communicated in the email')}
                >
                  <Input.Password
                    prefix={<LockOutlined style={{ color:'#CCC' }} />}
                    showCount
                    maxLength={16}
                  />
                </Form.Item>
              )}
              <Form.Item
                label={t('Can be opened multiple times?')}
                name='multiple_opening'
                required={true}
              >
                <Switch
                  checked={states.formValues.multiple_opening}
                  checkedChildren={<><CheckOutlined /> {t('Yes')}</>}
                  unCheckedChildren={<><CloseOutlined /> {t('No')}</>}
                />
              </Form.Item>
            </Form>
          </>
        )}
      </Modal>
    </>
  )
}

export default memo(ShareModal)
