//import CryptoJS from 'crypto-js'
//import { ENCRYPT_KEY } from '../Constant'
import { LANGUAGE_DEFAULT_FORM_VALUE, LANGUAGE_DEFAULT_FORM_MULTIPLE_VALUE } from '../Constant'

//Soft to API
export const transformFormLangToFormData = (datas) => {
  const formLangDatas = {}

  for (const [key, item] of Object.entries(datas)) {
    for (const [langKey, langItem] of Object.entries(item)) {
      if (typeof langItem == 'object') {
        Object.assign(formLangDatas, transformObjectToFormData(key + '_' + langKey, langItem))
      } else {
        formLangDatas[key + '_' + langKey] = langItem
      }
    }
  }

  return formLangDatas
}

export const transformObjectToFormData = (name, value) => {
  const formData = {}
  
  if (value && typeof value == 'object') {
    value.forEach((itemValue, key) => {
      formData[name + '[' + key + ']'] = itemValue
    })
  } else {
    formData[name] = value
  }

  return formData
}

export const transformTableFiltersToUrl = (filters) => {
  let url = ''

  if (!filters || typeof filters !== 'object') {
    return url
  }
  
  Object.keys(filters).forEach(key => {
    if (filters[key]) {
      if (typeof filters[key] === 'object') {
        filters[key].forEach(item => {
          url += key + ((filters[key].length > 1) ? '[]' : '') + '=' + item + '&'
        })
      } else {
        url += key + '=' + filters[key] + '&'
      }
    }
  })

  return url
}

//API to soft
export const transformLangApi = (langKey) => {
  if (langKey === 'fr-fr') {
    return 'fr'
  } else if (langKey === 'en-gb') {
    return 'enGB'
  }

  return langKey
}

export const transformApiLangToFormLang = (datas, multiple) => {
  const formLangDatas = {}

  if (!datas) {
    return (multiple === true) ? LANGUAGE_DEFAULT_FORM_MULTIPLE_VALUE : LANGUAGE_DEFAULT_FORM_VALUE
  }

  for (const [key, value] of Object.entries(datas)) {
    formLangDatas[transformLangApi(key)] = value
  }

  return formLangDatas
}

export const transformApiObjectToFormData = (data, key) => {
  const formData = []
  
  if (data && typeof data == 'object') {
    data.forEach((item) => {
      formData.push(item[key])
    })
  }

  return formData
}

export const transformSortOrderToApi = (order) => {
  if (!order) {
    return null
  }

  if (order === 'ascend')
    return 'asc'
  else
    return 'desc'
}

export const encodeObjectForUrl = (filters) => {
  return window.btoa(JSON.stringify(filters)).replace('/', '$')
}

export const decodeObjectFromUrl = (crypted) => {
  try {
    const decrypted = window.atob(crypted.replace('$', '/'))
    const json = JSON.parse(decrypted)

    return json

  } catch(thrown) {
    console.log('ApiUtils:encodeFileSortAndFilter - Bad object')
    return null
  }
}
