import { LANGUAGE_DEFAULT, LANGUAGE_AVALAIBLE } from '../Constant'

export const convertI18nLanguage = (language) => {
  let lang = LANGUAGE_DEFAULT

  if (!language) {
    return lang
  }

  const languageIso = language.substring(0, 2)

  LANGUAGE_AVALAIBLE.every((item) => {
    if (item.route === languageIso) {
      lang = item.route

      return false
    }

    return true
  })

  return lang
}