import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { Provider as AuthProvider } from './context/AuthContext';
import { MediaProvider } from './context/MediaContext';
import App from './App';
//import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV !== "development") {
    console.log = () => {};
}

ReactDOM.render(
  <AuthProvider>
    <MediaProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MediaProvider>
  </AuthProvider>,
  document.getElementById('root')
)

//reportWebVitals()