import { useState, useContext, useEffect, createRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Form,
  Space,
  Grid,
  Input,
  DatePicker,
  Button,
  AutoComplete,
  Select
} from 'antd';
import { Context as AuthContext } from '../../../context/AuthContext';
import { formError } from '../../../components/Notifications';
import FormItemLang from '../../../components/form/FormItemLang';
import { getShortFormat } from '../../../utils/DateUtils';

export default function MediaInformationsContent(props) {
  const TAG = 'MediaInformationContent'

  if (!props?.formDatas) {
    throw new Error(TAG + ' - PROPS formDatas is undefined')
  } else if (!props?.setInformationsStates) {
    throw new Error(TAG + ' - PROPS setInformationsStates is undefined')
  } else if (!props?.informationsStates) {
    throw new Error(TAG + ' - PROPS informations is undefined')
  }

  //Template
  const { t } = useTranslation()
  const { TextArea } = Input
  const { Option } = Select
  const { useBreakpoint } = Grid
  const formRef = createRef()
  const screens = useBreakpoint()

  //States
  const { authState } = useContext(AuthContext)
  const formDatas = props.formDatas
  const setInformationsStates = props.setInformationsStates
  const [ formLayout, setFormLayout ] = useState('horizontal')
  const [ formLang, setFormLang ] = useState(authState.language)
  const [ states, setStates ] = useState(props.informationsStates)

  //Template methods
  const handleCopyLang = (name, currentLang, languages) => {
    const value = {}

    languages.forEach(lang => {
      value[lang.route] = states[name][currentLang]
    })

    const newStates = {...states, [name]: value}

    formRef.current.setFieldsValue(newStates)
    setInformationsStates(newStates)
    setStates(newStates)
  }

  //Hooks
  useEffect(() => {
    setFormLayout((!screens.lg) ? 'vertical' : 'horizontal')
  }, [screens])

  const formItemLayout = (formLayout === 'horizontal')
      ? {labelCol:{span: 8}, wrapperCol:{span: 10}}
      : null

  return (
    <Form
      ref={formRef}
      {...formItemLayout}
      layout={formLayout}
      initialValues={states}
      onValuesChange={(changedValues, allValues) => {
        setInformationsStates({ ...states, ...allValues })
        setStates({ ...states, ...allValues })
      }}
      onFinishFailed={({ values, errorFields, outOfDate }) => formError(errorFields, t)}
      onFinish={() => {
        props.onFinish()
      }}
    >
      <FormItemLang
        label={t('Title')}
        name='title'
        required={true}
        rules={[
          {required: true, message: t('Title (%lang%) is required')},
          {max: 75, message: t('Title (%lang%) must not exceed %d% characters').replace('%d%', 75)},
        ]}

        currentLang={formLang}
        onLangChange={(value) => {setFormLang(value)}}
        onCopyLang={handleCopyLang}
      >
        <Input />
      </FormItemLang>
      <FormItemLang
        label={t('Description')}
        name='description'
        required={false}
        rules={[
          {max: 150, message: t('Description (%lang%) must not exceed %d% characters').replace('%d%', 150)},
        ]}

        currentLang={formLang}
        onLangChange={(value) => {setFormLang(value)}}
        onCopyLang={handleCopyLang}
      >
        <TextArea
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </FormItemLang>

      <Form.Item
        label={t('Youtube video')}
        extra={t('Video code only, example: heGV4qKb23Q')}
        name='youtube_code'
        rules={[
          {max: 16, message: t('Youtube video must not exceed %d% characters').replace('%d%', 16)},
        ]}
      >
        <Input
          showCount
          maxLength={16}
        />
      </Form.Item>

      <FormItemLang
        label={t('Tags')}
        name='keywords'
        required={false}

        currentLang={formLang}
        onLangChange={(value) => {setFormLang(value)}}
        onCopyLang={handleCopyLang}
      >
        <Select mode="tags" placeholder={t('Add tags (used in search engine)')}>
          {formDatas.tags.map(item => 
            <Option value={item} key={"tagsOption" + item}>{item}</Option>
          )}
        </Select>
      </FormItemLang>

      <Form.Item
        label={t('Date of media')}
        name='media_date'
      >
        <DatePicker
          format={getShortFormat(authState.language)}
          disabledDate={d => !d || d.isAfter(Date.now())}
        />
      </Form.Item>

      <Form.Item
        label={t('Author')}
        name='media_author'
        rules={[
          {max: 75, message: t('Author must not exceed %d% characters').replace('%d%', 75)},
        ]}
      >
        <AutoComplete
          options={formDatas.authors}
          filterOption={(inputValue, option) => {
            if (!option?.value || inputValue === null) {
              return false
            }

            return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }}
        />
      </Form.Item>

      <Form.Item
        label={t('Country')}
        name='id_country'
      >
        <Select
          showSearch
          allowClear
          placeholder={t('Search a country')}
          optionFilterProp="children"
          filterOption={(inputValue, option) => {
            if (!option?.value || inputValue === null) {
              return false
            }

            return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
          }}
          filterSort={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
        >
          {formDatas.countries.map(item => 
            <Option value={item.id} key={"countryOption" + item.id}>{item.name}</Option>
          )}
        </Select>

      </Form.Item>

      <Form.Item
        label={t('Location')}
        name='media_location'
        rules={[
          {max: 75, message: t('Location must not exceed %d% characters').replace('%d%', 75)},
        ]}
      >
        <AutoComplete
          options={formDatas.locations}
          filterOption={(inputValue, option) => {
            if (!option?.value || inputValue === null) {
              return false
            }

            return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 0, span: 24 }} style={{textAlign:'center'}}>
        <Space>
          <Button onClick={props.onBack}>
              {t('Previous')}
            </Button>
          <Button type="primary" htmlType="submit">
            {t('Next')}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

/* keyword */