import { memo } from 'react';
import { Spin, Typography, Progress } from 'antd';

const LoadingBlock = (props) => {
  const { Paragraph } = Typography
  const text = props?.text
  const progressPercent = props?.progressPercent
  const style = props?.style
  const size = (props?.size) ? props.size : 'large'

  return (
    <main style={{ display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', minHeight:280, ...style }}>
      {progressPercent !== undefined ? (
        <Progress percent={progressPercent} type="circle" />
      ) : (
        <Spin size={size} />
      )}
      {text && (
        <Paragraph type="secondary" style={{ marginTop:20 }}>{text}</Paragraph>
      )}
    </main>
  )
}

export default memo(LoadingBlock)