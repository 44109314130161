import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Upload, Modal, Space, Button, Typography, Divider, Grid, Col, Row, message, notification } from 'antd';
import { UPLOAD_NUMBER_LIMIT, UPLOAD_ACCEPT_MIME, FILE_TYPE_IMAGE } from '../../../Constant';
import { getMimeFileType, getFileType, getBase64 } from '../../../utils/FileUtils';
import { UploadOutlined } from '@ant-design/icons';

export default function MediaUploadContent(props) {
  const TAG = 'MediaUploadContent'

  if (!props?.formDatas) {
    throw new Error(TAG + ' - PROPS formDatas is undefined')
  } else if (!props?.setFilesStates) {
    throw new Error(TAG + ' - PROPS setFilesStates is undefined')
  } else if (!props?.filesStates) {
    throw new Error(TAG + ' - PROPS filesStates is undefined')
  }

  //Template
  const { t } = useTranslation()
  const { Text } = Typography
  const { Dragger } = Upload
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()

  //States
  const setFilesStates = props.setFilesStates
  const [states, setStates] = useState({
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: []
  })
  let curFileList = states.fileList

  //Event
  useEffect(() => {
    console.log(TAG, '[]')

    setStates({
      ...states,
      fileList: props.filesStates
    })
  }, [])

  //Template method
  const handleChange = ({ file, fileList }) => {
    if (file.status === 'removed') {
      curFileList = curFileList.filter((item) => item.uid !== file.uid)
      setStates({ ...states, fileList:curFileList })
      setFilesStates(curFileList)
    }
  }

  const handlePreviewCancel = () => {
    setStates({...states, previewVisible: false})
  }

  const handlePreview = (file) => {
    if (!file.url && !file.preview) {
      getBase64(file.originFileObj, (preview) => {
         setStates({
          ...states,
          previewImage: preview,
          previewVisible: true,
          previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        })
      })
    }
  }

  const getFileIcon = (file) => {
    const fileType = getFileType(getMimeFileType(file.type), t)

    return fileType.icon
  }

  return (
    <div style={{ maxWidth:800, margin:'0 auto' }}>
      <Dragger
        name="file"
        listType="text"
        multiple
        maxCount={100}
        accept={UPLOAD_ACCEPT_MIME}
        fileList={states.fileList}
        customRequest={({ file }) => {
          const fileType = getMimeFileType(file.type)
          const _file = Object.assign(file, {
            status: 'done',
            thumbUrl: null,
            originFileObj: file,
            fileType: fileType
          })

          if (fileType === FILE_TYPE_IMAGE) {
            const reader = new FileReader()

            reader.onloadend = () => {
              _file['thumbUrl'] = reader.result
              curFileList.push(_file)
              setStates({ ...states, fileList:curFileList })
              setFilesStates(curFileList)
            }
            reader.onerror = () => {
              setStates({ ...states, fileList:curFileList })
              setFilesStates(curFileList)
            }
            reader.onabort = () => {
              setStates({ ...states, fileList:curFileList })
              setFilesStates(curFileList)
            }
            reader.readAsDataURL(file)
          
          } else {
            curFileList.push(_file)
            setStates({ ...states, fileList:curFileList })
            setFilesStates(curFileList)
          }
        }}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={file => {
          let isValid = true

          //Check type mime
          if (UPLOAD_ACCEPT_MIME.indexOf(file.type) === -1) {
            notification.error({
              message: file.name,
              description: t('The type (%fileType) is not accepted').replace('%fileType', (file.type) ? file.type : 'unknown'),
              duration: null
            })
            //message.error(t('The type of %fileName is not accepted (%fileType)').replace('%fileName', file.name).replace('%fileType', file.type))
            isValid = false
          }

          //Check size limit
          /*if (isValid) {
            if ((file.size / 1024 / 1024) > UPLOAD_SIZE_LIMIT) {
              notification.error({
                message: file.name,
                description: t('File must smaller than %limitMB').replace('%limit', UPLOAD_SIZE_LIMIT),
                duration: null
              })
              isValid = false
            }
          }*/

          //Check already exists
          if (isValid) {
            states.fileList.forEach((item) => {
              if (item.name === file.name) {
                notification.warning({
                  message: file.name,
                  description: t('This file is already added'),
                })
                //message.error(t('The file %fileName is already added').replace('%fileName', file.name))
                isValid = false
              }
            })
          }

          return isValid || Upload.LIST_IGNORE
        }}
        itemRender={(originNode, file, currFileList, actions) => (
          <>
            <Row gutter={[8, 8]} justify="space-around" align="middle" style={{ textAlign:'center', marginTop:10 }}>
              <Col flex={!screens.md ? '1 0 100%' : 'none' }>
                {file.thumbUrl
                  ? <img src={file.thumbUrl} className="img-fluid" style={{ maxHeight:70, }} alt={file.name} />
                  : <div style={{ fontSize:46, color:'#888', textAlign:'center' }}>{getFileIcon(file)}</div>
                }
              </Col>
              <Col flex={!screens.md ? '1 0 100%' : 'auto' } style={!screens.md ? null : {textAlign:'center'}}>
                <Text ellipsis={true}>{file.name}</Text>
              </Col>
              <Col flex={!screens.md ? '1 0 100%' : '120px' }>
                <Button onClick={actions.remove} block={screens.md}>{t('Remove')}</Button>
              </Col>
            </Row>
            {(!screens.md) && <Divider style={{ marginTop:10, marginBottom:0 }} />}
          </>
        )}
        iconRender={null}
      >
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">{t('Click or drag file(s) to this area to upload')}</p>
        <Text type="secondary">{t('You can upload a maximum of %number files.').replace('%number', UPLOAD_NUMBER_LIMIT)}</Text>
        {/*<br /><Text type="secondary">{t('Maximum file size is %limitMB.').replace('%limit', UPLOAD_SIZE_LIMIT)}</Text>*/}
      </Dragger>

      <div style={{textAlign:'center', marginTop:40 }}>
        <Space>
          <Button type="primary" onClick={() => {
            if (!states.fileList || !states.fileList.length) {
              message.error(t('You must add at least one media'))
              return
            }

            if (props?.onFinish) {
              props.onFinish()
            }

          }}>
            {t('Next')}
          </Button>
        </Space>
      </div>

      <Modal
        open={states.previewVisible}
        title={states.previewTitle}
        onCancel={handlePreviewCancel}
        footer={null}
      >
        <img src={states.previewImage} alt={t('Image preview')} style={{ width: '100%' }} />
      </Modal>
    </div>
  )
}