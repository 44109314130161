import { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useOutletContext } from 'react-router-dom';
import { Typography, Tree, Button, Space, Tooltip, Dropdown, Menu, Modal, Alert, Spin, message } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined, EditOutlined, DeleteOutlined, SwapOutlined, WarningOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { APIRequest, isBadResponse, getFolderTree, sortTree, activeFolder, delFolder, recalcTreePosition } from '../../models/APIRequest';
import { Context as AuthContext } from '../../context/AuthContext';
import { ModalContext } from "../../context/ModalContext";
import ApiErrorResult from '../../components/ApiErrorResult';
import LoadingBlock from '../../components/LoadingBlock';
import { axiosError } from '../../components/Notifications';
import TableHeader from '../../components/table/TableHeader';
import { GLOBAL_MODAL_TYPES } from '../../Constant';

export default function AdminFolderTreeScreen() {
  const TAG = 'AdminFolderTreeScreen'

  //Template
  const { t } = useTranslation()
  const [ updateBreacrumbs ] = useOutletContext()
  const [ modal, contextHolder ] = Modal.useModal()
  const { Text } = Typography

  //States
  const { authState } = useContext(AuthContext)
  const { openModal, closeModal } = useContext(ModalContext)
  const [ states, setStates ] = useState({
    isLoaded: false,
    error: false,
    errorData: null,
    treeData: [],
    tree: [],
    expandedKeys: [],
    isSubmitting: false
  })

  //States methods
  const loadData = () => {
    console.log(TAG, 'loadData')

    if (states.isLoaded) {
      return
    }

    console.log(TAG, 'Getting data...')
    getFolderTree(APIRequest(authState.language, authState.token), false).then(function (results) {
      if (isBadResponse(results.data)) {
        setStates({
          ...states,
          isLoaded: true,
          isSubmitting: false,
          error: true,
          errorData: results.data.data,
        })

        return
      }

      setStates({
        ...states,
        isLoaded: true,
        isSubmitting: false,
        treeData: results.data.data.tree,
        error: false,
        errorData: null,
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: true,
        isSubmitting: false,
        error: true,
        errorData: null,
      })
    })
  }

  const convertDataOfApi = data => {
    if (!data) {
      return
    }

    const getTitle = (item) => {
      const contextMenu = [
        {
          label: t('Edit'),
          icon: <EditOutlined />,
          key: 'edit',
        }
      ]

      if (item.active) {
        contextMenu.push({
          label: t('Unactivate'),
          icon: <EyeInvisibleOutlined />,
          key: 'unactive',
        })
      } else {
        contextMenu.push({
          label: t('Activate'),
          icon: <EyeOutlined />,
          key: 'active',
        })
      }
      
      contextMenu.push({
        label: t('Delete'),
        icon: <DeleteOutlined />,
        key: 'delete',
      })

      return (
        <Dropdown overlay={<Menu items={contextMenu} onClick={(menuItem) => handleContextFolder(menuItem.key, item)} />} trigger={['click']}>
          <Space>
            <Text>{item.title}</Text>
            {!item.active && (
              <Tooltip title={t('Invisible in Media Explorer')}>
                <Text type="danger"><EyeInvisibleOutlined /></Text>
              </Tooltip>
            )}
          </Space>
        </Dropdown>
      )
    }

    return data.map(item => {
      if (item.childrens) {
        return {
          title: getTitle(item),
          key: item.id,
          children: convertDataOfApi(item.childrens)
        }
      }

      return {
        title: getTitle(item),
        key: item.id,
      }
    })
  }

  //Events
  useEffect(() => {
    if (!states.isLoaded && updateBreacrumbs) {
      console.log(TAG, 'updateBreacrumbs')
      updateBreacrumbs([{title: t('Folder tree'), 'route': null},])
    }

    loadData()
  }, [states.isLoaded])

  useEffect(() => {
    setStates({ ...states, tree: convertDataOfApi(states.treeData)})
  }, [states.treeData])

  useEffect(() => {
    if (states.preloadStates) {
      return
    }

    console.log(TAG, '[authState.language, authState.token]')

    setStates({ ...states, isLoaded:false })

  }, [authState.language, authState.token])

  //Template methods
  const onDrop = (info) => {
    const dropKey = info.node.key
    const dragKey = info.dragNode.key
    const dropPos = info.node.pos.split('-')
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data)
        }

        if (data[i].children) {
          loop(data[i].children, key, callback)
        }
      }
    }

    const data = [...states.tree]

    let dragObj
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1)
      dragObj = item
    })

    if (!info.dropToGap) {
      loop(data, dropKey, (item) => {
        item.children = item.children || []

        item.children.unshift(dragObj)
      })
    } else if (
      (info.node.props.children || []).length > 0 &&
      info.node.props.expanded &&
      dropPosition === 1
    ) {
      loop(data, dropKey, (item) => {
        item.children = item.children || []

        item.children.unshift(dragObj)
      })
    } else {
      let ar = []
      let i
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr
        i = index
      })

      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj)
      } else {
        ar.splice(i + 1, 0, dragObj)
      }
    }

    //Save
    setStates({
      ...states,
      isSubmitting: true
    })

    const formData = getSortFormData(info)

    sortTree(APIRequest(authState.language, authState.token), formData).then(function (results) {
      if (isBadResponse(results.data, true, t)) {
        setStates({
          ...states,
          isSubmitting: false
        })

        return
      }

      setStates({
        ...states,
        tree: data,
        isSubmitting: false
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isSubmitting: false
      })
    })
  }

  const getSortFormData = (info) => {
    const dropKey = info.node.key
    const dragKey = info.dragNode.key
    const dropPos = info.node.pos.split('-')
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])
    const formData = {
      type: null,
      id_folder: parseInt(dragKey),
      id_folder_reference: parseInt(dropKey),
      position: null
    }

    if (!info.dropToGap) {
      console.log(TAG, '#1 Folder ' + dragKey + ' moved on placed 1 of parent ID ' + dropKey)
      formData['type'] = 'parent'
      formData['position'] = 1
    
    } else if (
      (info.node.props.children || []).length > 0 &&
      info.node.props.expanded &&
      dropPosition === 1
    ) {
      console.log(TAG, '#2 Folder ' + dragKey + ' moved on ' + dropPosition + ' position of parent ID ' + dropKey)
      formData['type'] = 'parent'
      formData['position'] = dropPosition
    
    } else {
      if (dropPosition === -1) {
        console.log(TAG, '#3 Folder ' + dragKey + ' moved before ID ' + dropKey)
        formData['type'] = 'before'
      
      } else {
        console.log(TAG, '#4 Folder ' + dragKey + ' moved after ID ' + dropKey)
        formData['type'] = 'after'
      }
    }

    return formData
  }

  const handleActiveFolder = (idFolder, active, callback) => {
    activeFolder(APIRequest(authState.language, authState.token), idFolder, active).then(function (results) {
      if (isBadResponse(results.data, true, t)) {
        callback(false)
        
        return
      }

      callback(true)
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      callback(false)
    })
  }

  const handleAddFolder = () => {
    openModal(
      GLOBAL_MODAL_TYPES.FOLDER,
      null,
      (folder, close) => {
        if (close) {
          closeModal()
        }

        setStates({ ...states, isLoaded:false })
      },
      null
    )
  }

  const handleRepairFolders = () => {
    setStates({ ...states, isSubmitting:true })

    recalcTreePosition(APIRequest(authState.language, authState.token)).then(function (results) {
      if (isBadResponse(results.data, true, t)) {
        axiosError(t)
        setStates({ ...states, isSubmitting:false })
        
        return
      }

      message.success(t('Folders have been repaired'))
      setStates({ ...states, isSubmitting:false, isLoaded:false })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)
      setStates({ ...states, isSubmitting:false })
    })
  }

  const handleDelFolder = (idFolder, callback) => {
    delFolder(APIRequest(authState.language, authState.token), idFolder).then((results) => {
      if (isBadResponse(results.data, true, t)) {
        callback(false)

        return
      }

      callback(true)
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      callback(false)
    })
  }

  const handleContextFolder = (key, item) => {
      console.log(key, states)
    if (key === 'edit') {
      openModal(
        GLOBAL_MODAL_TYPES.FOLDER,
        { id:item.id, folder:item },
        (folder, close) => {
          if (close) {
            closeModal()
          }

          setStates({ ...states, isLoaded:false })
        },
        null
      )
    
    } else if (key === 'active' || key === 'unactive') {
      setStates({
        ...states,
        isSubmitting: true
      })

      handleActiveFolder(item.id, (key === 'active') ? 1 : 0, (result) => {
        if (result) {
          setStates({
            ...states,
            isLoaded: false
          })

          return
        }

        setStates({
          ...states,
          isSubmitting: false
        })
      })
    
    } else if (key === 'delete') {
      modal.confirm({
        title: item.title,
        content: (
          <>
            {t('Are you sure to delete this folder?')}
            <br />
            {t('All subfolders and media inside this folder will be moved to its parent folder.')}
          </>
        ),
        okText: t('Delete'),
        okType: 'danger',
        cancelText: t('Cancel'),
        onOk: () => {
          setStates({
            ...states,
            isSubmitting: true
          })

          handleDelFolder(item.id, (result) => {
            if (result) {
              setStates({
                ...states,
                isLoaded: false
              })

              return
            }

            setStates({
              ...states,
              isSubmitting: false
            })
          })
        }
      })
    }
  }

  //Views
  if (states.error) {
    return (
      <ApiErrorResult errorData={states.errorData} actionTitle={t('Try to reload')} actionHandle={() => {
        setStates({ ...states, error:false, errorData: null, isLoaded:false })
      }} />
    )
  }

  if (!states.isLoaded) {
    return <LoadingBlock />
  }

  return (
    <main className='admin-container'>
      <TableHeader
        title={t('Folder tree')}
        buttons={[
          <Button type="default" icon={<SettingOutlined />} disabled={states.isSubmitting} onClick={handleRepairFolders}>{t('Repair folders')}</Button>,
          <Button type="primary" icon={<PlusOutlined />} disabled={states.isSubmitting} onClick={handleAddFolder}>{t('Add folder')}</Button>,
        ]}
        style={{ marginBottom:20 }}
      />

      <Space direction="vertical" style={{ width:'100%' }}>
        <Spin tip={t('Loading...')} spinning={states.isSubmitting}>
          <Tree
            draggable
            blockNode
            expandedKeys={states.expandedKeys}
            onExpand={(expandedKeys) => {
              setStates({ ...states, expandedKeys:expandedKeys })
            }}
            onDrop={onDrop}
            treeData={states.tree}
            disabled={states.isSubmitting}
            style={{ border:'1px solid #DDD', backgroundColor:'#FCFCFC', padding:20 }}
          />
        </Spin>

        <Alert
          type='info'
          showIcon
          message={t('How its work')}
          description={
            <>
              <SwapOutlined /> {t('To sort, just drag and drop folder where you want.')}
              <br />
              <EditOutlined /> {t('To edit folder, click on this to open contextual menu.')}
              <br />
              <WarningOutlined /> {t('All subfolders of an inactive folder will also be invisible even if they are activated.')}
            </>
          }
        />
      </Space>
      {contextHolder}
    </main>
  )
}