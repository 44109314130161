import React from "react";
import UseModal from "./UseModal";
import MediaEditModal from "../components/globalModal/MediaEditModal";
import FolderModal from "../components/globalModal/FolderModal";
import StickerModal from "../components/globalModal/StickerModal";
import UserModal from "../components/globalModal/UserModal";
import WatermarkModal from "../components/globalModal/WatermarkModal";
import BroadcastRestrictionModal from "../components/globalModal/BroadcastRestrictionModal";

let ModalContext
const { Provider } = (ModalContext = React.createContext())

const ModalProvider = ({ children }) => {
  const { modalType, showModal, modalProps, openModal, closeModal, modalOnOk } = UseModal()

  return (
    <Provider value={{ modalType, showModal, modalProps, openModal, closeModal, modalOnOk }}>
      <MediaEditModal />
      <FolderModal />
      <StickerModal />
      <UserModal />
      <WatermarkModal />
      <BroadcastRestrictionModal />
      {children}
    </Provider>
  )
}

export { ModalContext, ModalProvider }