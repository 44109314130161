import axios from 'axios';
import { notification } from 'antd';
import {
  API_BASE_URL, API_TOKEN, API_TOKEN_VARNAME, 
  ACCOUNT_LOGIN_VARNAME, ACCOUNT_PASSWORD_VARNAME, ACCOUNT_NEW_PASSWORD_VARNAME, ACCOUNT_TOKEN_VARNAME,
  SHARE_PASSWORD_VARNAME
} from './APIConfig';
import { transformTableFiltersToUrl } from '../utils/ApiUtils';

const TAG = 'APIRequest';

export const getBaseUrl = (lang) => {
  const requestLang = (lang != null && lang === 'fr') ? 'fr-fr' : 'en-gb'

  return API_BASE_URL + requestLang + '/api/'
}

export const APIRequest = (lang, token, requestConfig, timeout) => {
  let configs = {
    timeout: (timeout !== undefined) ? timeout * 1000 : 10000,
    baseURL: getBaseUrl(lang),
    headers: {
      //'content-type': 'multipart/form-data',
      [API_TOKEN_VARNAME]: API_TOKEN
    },
    transformRequest: (data, headers) => {
      return tranformObjectToFormData(data)
    }
  }

  if (token) {
    configs.headers = {...configs.headers, [ACCOUNT_TOKEN_VARNAME]: token}
  }

  if (requestConfig) {
    configs = {...configs, ...requestConfig}
  }

  return {
    instance: axios.create(configs),
    abortController: new AbortController()
  }
}

export const tranformObjectToFormData = (data) => {
  if (typeof data != 'object' || data === null) {
    //console.log(TAG, 'tranformObjectToFormData - Not an object')
    return data
  }

  let formData = new FormData()
  let value = null

  Object.keys(data).map(function(key, index) {
    if (data[key] === null || data[key] === undefined)
      value = ''
    else if (typeof data[key] == 'boolean')
      value = data[key] === true ? 1 : 0
    else
    value = data[key]

    formData.append(key, value)

    return null
  })

  return formData
}

export function isCancel(thrown) {
  return axios.isCancel(thrown)
}

export function addAuthToken(_APIRequest, token) {
  if (typeof token === 'string') {
    _APIRequest.instance.defaults.headers.common[ACCOUNT_TOKEN_VARNAME] = token
  } else {
    console.log(TAG, 'addAuthToken - token is not a string (' + typeof token + ')')
  }

  return _APIRequest
}

export function isBadResponse(response, pushNotification, t) {
  if (typeof response !== 'object' || response.success === false) {
    let message
    let code

    if (response?.data?.code) {
      code = response?.data?.code

      if (response.data.code === 'BAD_TOKEN') {
        return true
      }
    }

    if (response?.data?.message) {
      message = response?.data?.message
    }

    if (pushNotification) {
      let description = ""

      if (message) {
        description += message
      } else {
        description += t('Sorry, something went wrong...')
      }

      if (code) {
        description += "\n" + code + ")"
      }

      notification.error({
        message: t('Oupsss'),
        description: description
      })
    }

    return true
  }

  return false
}

// ---------------------- Params ----------------------
export function getParams(_APIRequest, names) {
  let url = 'param/gets?'
  
  if (names != null)
    url += 'names=' + names

  return _APIRequest.instance.get(url)
}

export function updateParams(_APIRequest, formData) {
  return _APIRequest.instance.post('param/update', formData)
}

export function clearCache(_APIRequest) {
  return _APIRequest.instance.get('param/clear_cache')
}

// ---------------------- Home ----------------------
export function getHome(_APIRequest) {
  let url = 'home/get'

  return _APIRequest.instance.get(url)
}

export function updateHomeParameters(_APIRequest, formData) {
  return _APIRequest.instance.post('home/update', formData)
}

// ---------------------- Login ----------------------
export function login(_APIRequest, login, password) {
	_APIRequest.instance.defaults.headers.common[ACCOUNT_LOGIN_VARNAME] = login
	_APIRequest.instance.defaults.headers.common[ACCOUNT_PASSWORD_VARNAME] = password

	return _APIRequest.instance.get('login/sign_in')
}

// ---------------------- Login ----------------------
export function loginFromApi(_APIRequest, token) {
	_APIRequest.instance.defaults.headers.common[ACCOUNT_LOGIN_VARNAME] = token

	return _APIRequest.instance.get('login/create_session_from_token')
}

export function lostPassword(_APIRequest, login) {
  _APIRequest.instance.defaults.headers.common[ACCOUNT_LOGIN_VARNAME] = login
  
  return _APIRequest.instance.get('login/lost_password')
}

export function changePassword(_APIRequest, currentPassword, newPassword) {
  _APIRequest.instance.defaults.headers.common[ACCOUNT_PASSWORD_VARNAME] = currentPassword
  _APIRequest.instance.defaults.headers.common[ACCOUNT_NEW_PASSWORD_VARNAME] = newPassword

  return _APIRequest.instance.get('login/change_password')
}

// ---------------------- System rights ----------------------
export function getSystemRights(_APIRequest) {
  let url = 'system_right/gets'

  return _APIRequest.instance.get(url)
}

// ---------------------- Access rights ----------------------
export function getAccessRights(_APIRequest) {
  let url = 'access_right/gets'

  return _APIRequest.instance.get(url)
}

// ---------------------- Broadcast restriction ----------------------
export function getBroadcastRestrictions(_APIRequest, page, limit, sortColumn, sortOrder, filters) {
  let url = 'broadcast_restriction/gets?'

  if (page != null)
    url += 'page=' + page + '&'
  if (limit != null)
    url += 'limit=' + limit + '&'
  if (sortColumn != null)
    url += 'sort_column=' + sortColumn + '&'
  if (sortOrder != null)
    url += 'sort_order=' + sortOrder + '&'
  if (filters)
    url += transformTableFiltersToUrl(filters)

  return _APIRequest.instance.get(url)
}

export function getAllBroadcastRestrictions(_APIRequest) {
  return getBroadcastRestrictions(_APIRequest, 1, 999)
}

export function addBroadcastRestriction(_APIRequest, formData) {
  return _APIRequest.instance.post('broadcast_restriction/add', formData)
}

export function updateBroadcastRestriction(_APIRequest, idUser, formData) {
  return _APIRequest.instance.post('broadcast_restriction/update/' + idUser, formData)
}
export function delBroadcastRestriction(_APIRequest, idUser) {
  return _APIRequest.instance.get('broadcast_restriction/del/' + idUser)
}

// ---------------------- Users ----------------------
export function getUsers(_APIRequest, page, limit, sortColumn, sortOrder, filters) {
  let url = 'user/gets?'

  if (page != null)
    url += 'page=' + page + '&'
  if (limit != null)
    url += 'limit=' + limit + '&'
  if (sortColumn != null)
    url += 'sort_column=' + sortColumn + '&'
  if (sortOrder != null)
    url += 'sort_order=' + sortOrder + '&'
  if (filters)
    url += transformTableFiltersToUrl(filters)

  return _APIRequest.instance.get(url)
}

export function addUser(_APIRequest, formData) {
  return _APIRequest.instance.post('user/add', formData)
}

export function updateUser(_APIRequest, idUser, formData) {
  return _APIRequest.instance.post('user/update/' + idUser, formData)
}

export function activeUser(_APIRequest, idUser, active) {
  return _APIRequest.instance.post('user/active/' + idUser, { active:active })
}

export function delUser(_APIRequest, idUser) {
  return _APIRequest.instance.get('user/del/' + idUser)
}

export function delUserAvatar(_APIRequest, idUser) {
  return _APIRequest.instance.get('user/del_avatar/' + idUser)
}

// ---------------------- Watermarks ----------------------
export function getWatermarks(_APIRequest, page, limit, sortColumn, sortOrder, filters) {
  let url = 'watermark/gets?'

  if (page != null)
    url += 'page=' + page + '&'
  if (limit != null)
    url += 'limit=' + limit + '&'
  if (sortColumn != null)
    url += 'sort_column=' + sortColumn + '&'
  if (sortOrder != null)
    url += 'sort_order=' + sortOrder + '&'
  if (filters)
    url += transformTableFiltersToUrl(filters)

  return _APIRequest.instance.get(url)
}

export function addWatermark(_APIRequest, formData) {
  return _APIRequest.instance.post('watermark/add', formData)
}

export function updateWatermark(_APIRequest, idWatermark, formData) {
  return _APIRequest.instance.post('watermark/update/' + idWatermark, formData)
}

export function delWatermark(_APIRequest, idWatermark) {
  return _APIRequest.instance.get('watermark/del/' + idWatermark)
}
export function delWatermarks(_APIRequest, idWatermarks) {
  const formData = {
    watermark_ids: idWatermarks.join(',')
  }

  return _APIRequest.instance.post('watermark/dels', formData)
}

// ---------------------- Stickers ----------------------
export function getStickers(_APIRequest, all, page, limit, sortColumn, sortOrder, filters) {
  let url = 'sticker/gets?'

  if (page != null)
    url += 'page=' + page + '&'
  if (limit != null)
    url += 'limit=' + limit + '&'
  if (sortColumn != null)
    url += 'sort_column=' + sortColumn + '&'
  if (sortOrder != null)
    url += 'sort_order=' + sortOrder + '&'
  if (all != null)
    url += 'all=' + ((all) ? 1 : 0) + '&'
  if (filters)
    url += transformTableFiltersToUrl(filters)

  return _APIRequest.instance.get(url)
}

export function addSticker(_APIRequest, formData) {
  return _APIRequest.instance.post('sticker/add', formData)
}

export function updateSticker(_APIRequest, idSticker, formData) {
  return _APIRequest.instance.post('sticker/update/' + idSticker, formData)
}

export function activeSticker(_APIRequest, idSticker, active) {
  return _APIRequest.instance.post('sticker/active/' + idSticker, { active:active })
}

export function activeStickers(_APIRequest, idStickers, active) {
  const formData = {
    sticker_ids: idStickers.join(','),
    active: active
  }

  return _APIRequest.instance.post('sticker/actives', formData)
}

export function delSticker(_APIRequest, idSticker) {
  return _APIRequest.instance.get('sticker/del/' + idSticker)
}
export function delStickers(_APIRequest, idStickers) {
  const formData = {
    sticker_ids: idStickers.join(',')
  }

  return _APIRequest.instance.post('sticker/dels', formData)
}

// ---------------------- Tree ----------------------
export function getFolderTree(_APIRequest, onlyActive) {
  let url = 'folder/get_tree?'

  if (onlyActive != null)
    url += 'only_active=' + ((onlyActive) ? 1 : 0)

  return _APIRequest.instance.get(url)
}

export function getFolderChildrends(_APIRequest, idParent, onlyActive) {
  let url = 'folder/get_childrens?'

  if (idParent != null)
    url += 'id_parent=' + parseInt(idParent) + '&'
  if (onlyActive != null)
    url += 'only_active=' + ((onlyActive) ? 1 : 0)

  return _APIRequest.instance.get(url)
}

export function getFolderFromTree(_APIRequest, onlyActive) {
  let url = 'folder/get_folder_from_tree?'

  if (onlyActive != null)
    url += 'only_active=' + ((onlyActive) ? 1 : 0)

  return _APIRequest.instance.get(url)
}

export function getSearchFolders(_APIRequest, search) {
  let url = 'search/folders?'

  if (search != null)
      url += 'search=' + search

  return _APIRequest.instance.get(url)
}

export function sortTree(_APIRequest, formData) {
  return _APIRequest.instance.post('folder/sort', formData)
}

export function recalcTreePosition(_APIRequest, formData) {
  return _APIRequest.instance.get('folder/recalc_position')

}
// ---------------------- Folders ----------------------
export function getFolder(_APIRequest, idFolder) {
  return _APIRequest.instance.get('folder/get/' + idFolder)
}

export function addFolder(_APIRequest, formData) {
  return _APIRequest.instance.post('folder/add', formData)
}

export function updateFolder(_APIRequest, idFolder, formData) {
  return _APIRequest.instance.post('folder/update/' + idFolder, formData)
}

export function activeFolder(_APIRequest, idFolder, active) {
  return _APIRequest.instance.post('folder/active/' + idFolder, { active:active })
}

export function delFolderCover(_APIRequest, idFolder) {
  return _APIRequest.instance.get('folder/del_cover/' + idFolder)
}

export function delFolderThumb(_APIRequest, idFolder) {
  return _APIRequest.instance.get('folder/del_thumb/' + idFolder)
}

export function delFolder(_APIRequest, idFolder) {
  return _APIRequest.instance.get('folder/del/' + idFolder)
}

// ---------------------- Files ----------------------
/*
Default in API if null
  idParent: null
  restricted: true
  status: null
*/
export function getFiles(_APIRequest, idFolder, restricted, status, ids, page, limit, sortColumn, sortOrder, filters) {
  let url = 'file/gets?'

  if (restricted != null)
      url += 'restricted=' + restricted + '&'
  if (idFolder != null)
      url += 'id_folder=' + idFolder + '&'
  if (status != null)
    url += 'status=' + status + '&'
  if (ids != null)
    url += 'ids=' + ids + '&'
  if (page != null)
    url += 'page=' + page + '&'
  if (limit != null)
    url += 'limit=' + limit + '&'
  if (sortColumn != null)
    url += 'sort_column=' + sortColumn + '&'
  if (sortOrder != null)
    url += 'sort_order=' + sortOrder + '&'

  if (filters) {
    Object.keys(filters).forEach(key => {
      if (typeof filters[key] === "object") {
        url += key + '=' + filters[key].join(',')  + '&'
      } else {
        url += key + '=' + filters[key] + '&'
      }
    })
  }

  return _APIRequest.instance.get(url)
}

export function getSearchFiles(_APIRequest, search, page, limit, sortColumn, sortOrder, filters) {
  let url = 'search/files?'

  if (search != null)
      url += 'search=' + search + '&'
  if (page != null)
    url += 'page=' + page + '&'
  if (limit != null)
    url += 'limit=' + limit + '&'
  if (sortColumn != null)
    url += 'sort_column=' + sortColumn + '&'
  if (sortOrder != null)
    url += 'sort_order=' + sortOrder + '&'

  if (filters) {
    Object.keys(filters).forEach(key => {
      if (typeof filters[key] === "object") {
        url += key + '=' + filters[key].join(',')  + '&'
      } else {
        url += key + '=' + filters[key] + '&'
      }
    })
  }

  return _APIRequest.instance.get(url)
}

export function getTrashFiles(_APIRequest, page, limit, sortColumn, sortOrder, filters) {
  let url = 'file/list_gets?deleted=1&'

  if (page != null)
    url += 'page=' + page + '&'
  if (limit != null)
    url += 'limit=' + limit + '&'
  if (sortColumn != null)
    url += 'sort_column=' + sortColumn + '&'
  if (sortOrder != null)
    url += 'sort_order=' + sortOrder + '&'
  if (filters)
    url += transformTableFiltersToUrl(filters)

  return _APIRequest.instance.get(url)
}

export function getListFiles(_APIRequest, page, limit, sortColumn, sortOrder, filters) {
  let url = 'file/list_gets?'

  if (page != null)
    url += 'page=' + page + '&'
  if (limit != null)
    url += 'limit=' + limit + '&'
  if (sortColumn != null)
    url += 'sort_column=' + sortColumn + '&'
  if (sortOrder != null)
    url += 'sort_order=' + sortOrder + '&'
  if (filters)
    url += transformTableFiltersToUrl(filters)

  return _APIRequest.instance.get(url)
}

export function getFile(_APIRequest, idFile, fileCode) {
  let url = 'file/get/' + idFile

  if (fileCode != null)
    url += '/' + fileCode

  return _APIRequest.instance.get(url)
}

export function getFileHistory(_APIRequest, idFile, page, limit, sortColumn, sortOrder, filters) {
  let url = 'file_history/gets/' + idFile + '?'

  if (page != null)
    url += 'page=' + page + '&'
  if (limit != null)
    url += 'limit=' + limit + '&'
  if (sortColumn != null)
    url += 'sort_column=' + sortColumn + '&'
  if (sortOrder != null)
    url += 'sort_order=' + sortOrder + '&'
  if (filters)
    url += transformTableFiltersToUrl(filters)

  return _APIRequest.instance.get(url)
}

export function fileMarkedOpen(_APIRequest, idFile) {
  return _APIRequest.instance.get('file/marked_open/' + idFile)
}

export function uploadFile(_APIRequest, formData, config) {
  return _APIRequest.instance.post('file/upload', formData, config)
}

export function addFiles(_APIRequest, formData, config) {
  return _APIRequest.instance.post('file/add', formData, config)
}

export function restoreFile(_APIRequest, idFile, idFolder) {
  return _APIRequest.instance.post('file/restore/' + idFile + '/' + idFolder)
}

export function restoreFiles(_APIRequest, idFiles, idFolder) {
  const formData = {
    file_ids: idFiles.join(','),
    id_folder: idFolder
  }

  return _APIRequest.instance.post('file/restores', formData)
}

export function updateFile(_APIRequest, idFile, formData) {
  return _APIRequest.instance.post('file/update/' + idFile, formData)
}

export function updateSourceFile(_APIRequest, idFile, formData) {
  return _APIRequest.instance.post('file/update_source_file/' + idFile, formData)
}

export function bulkUpdateFile(_APIRequest, formData) {
  return _APIRequest.instance.post('file/update_bulk', formData)
}

export function regenerateThumbFile(_APIRequest, idFile, idWatermark) {
  const formData = {
    id_watermark: idWatermark
  }

  return _APIRequest.instance.post('file/regenerate_thumb/' + idFile, formData)
}

export function moveFiles(_APIRequest, idFiles, idFolder) {
  const formData = {
    file_ids: idFiles.join(','),
    id_folder: idFolder
  }

  return _APIRequest.instance.post('file/moves', formData)
}

export function delFileThumb(_APIRequest, idFile) {
  return _APIRequest.instance.get('file/del_thumb/' + idFile)
}

export function delFile(_APIRequest, idFile) {
  return _APIRequest.instance.get('file/del/' + idFile)
}

export function delFiles(_APIRequest, idFiles) {
  const formData = {
    file_ids: idFiles.join(',')
  }

  return _APIRequest.instance.post('file/dels', formData)
}

export function delHardFile(_APIRequest, idFile) {
  return _APIRequest.instance.get('file/del_hard/' + idFile)
}

export function delHardFiles(_APIRequest, idFiles) {
  const formData = {
    file_ids: idFiles.join(',')
  }

  return _APIRequest.instance.post('file/dels_hard', formData)
}

export function cleanTrash(_APIRequest) {
  return _APIRequest.instance.get('file/trash_clean')
}

// ---------------------- Cart ----------------------
export function getCarts(_APIRequest, page, limit, sortColumn, sortOrder, filters) {
  let url = 'cart/gets?'

  if (page != null)
    url += 'page=' + page + '&'
  if (limit != null)
    url += 'limit=' + limit + '&'
  if (sortColumn != null)
    url += 'sort_column=' + sortColumn + '&'
  if (sortOrder != null)
    url += 'sort_order=' + sortOrder
  if (filters)
    url += transformTableFiltersToUrl(filters)

  return _APIRequest.instance.get(url)
}

export function getCart(_APIRequest, idCart) {
  return _APIRequest.instance.get('cart/get/' + idCart)
}

export function addCart(_APIRequest, formData, config) {
  return _APIRequest.instance.post('cart/add', formData, config)
}

export function delCart(_APIRequest, idCart) {
  return _APIRequest.instance.get('cart/del/' + idCart)
}

export function delCarts(_APIRequest, idCarts) {
  const formData = {
    cart_ids: idCarts.join(',')
  }

  return _APIRequest.instance.post('cart/dels', formData)
}

// ---------------------- Share ----------------------
export function getShares(_APIRequest, all, page, limit, sortColumn, sortOrder, filters) {
  let url = 'share/gets?'

  if (page != null)
    url += 'page=' + page + '&'
  if (limit != null)
    url += 'limit=' + limit + '&'
  if (sortColumn != null)
    url += 'sort_column=' + sortColumn + '&'
  if (sortOrder != null)
    url += 'sort_order=' + sortOrder + '&'
  if (all === true)
    url += 'all=1&'
  if (filters)
    url += transformTableFiltersToUrl(filters)

  return _APIRequest.instance.get(url)
}

export function getShare(_APIRequest, idShare, secureKey, password) {
  if (password) {
    _APIRequest.instance.defaults.headers.common[SHARE_PASSWORD_VARNAME] = password
  }

  return _APIRequest.instance.get('share/get/' + idShare + '/' + secureKey)
}

export function addShare(_APIRequest, formData) {
  return _APIRequest.instance.post('share/add', formData)
}

export function delShare(_APIRequest, idShare) {
  return _APIRequest.instance.get('share/del/' + idShare)
}

export function delShares(_APIRequest, idShares) {
  const formData = {
    share_ids: idShares.join(',')
  }

  return _APIRequest.instance.post('share/dels', formData)
}

// ---------------------- Download ----------------------
export function prepareDonwload(_APIRequest, formData, idShare, shareSecureKey, sharePassword) {
  if (formData && idShare && shareSecureKey) {
    formData['id_share'] = idShare
    formData['share_security_key'] = shareSecureKey

    if (sharePassword) {
      _APIRequest.instance.defaults.headers.common[SHARE_PASSWORD_VARNAME] = sharePassword
    }
  }

  return _APIRequest.instance.post('download/prepare', formData)
}

export function download(_APIRequest, formData, idShare, shareSecureKey, sharePassword, config) {
  if (formData && idShare && shareSecureKey) {
    formData['id_share'] = idShare
    formData['share_security_key'] = shareSecureKey

    if (sharePassword) {
      _APIRequest.instance.defaults.headers.common[SHARE_PASSWORD_VARNAME] = sharePassword
    }
  }

  return _APIRequest.instance.post('download/get', formData, { signal: _APIRequest.abortController.signal })
}

// ---------------------- Countries ----------------------
export function getCountries(_APIRequest, search) {
  return _APIRequest.instance.get('country/gets')
}

// ---------------------- Export format ----------------------
export function getExportFormats(_APIRequest) {
  return _APIRequest.instance.get('export_format/gets')
}

// ---------------------- Autocomplete ----------------------
export function getTagAc(_APIRequest, search) {
  let url = 'autocomplete/keywords'

  if (search != null)
      url += '/' + search

  return _APIRequest.instance.get(url)
}

export function getAuthorAc(_APIRequest, search) {
  let url = 'autocomplete/authors'

  if (search != null)
      url += '/' + search

  return _APIRequest.instance.get(url)
}

export function getLocationAc(_APIRequest, search) {
  let url = 'autocomplete/locations'

  if (search != null)
      url += '/' + search

  return _APIRequest.instance.get(url)
}
