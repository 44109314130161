import { useState, useEffect, useContext, useRef, memo } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { Outlet, useParams, useNavigate, useLocation, useOutletContext } from "react-router-dom";
import { DeleteOutlined, SettingOutlined, PushpinOutlined, DownOutlined } from '@ant-design/icons';
import { Typography, Empty, Divider, List, Button, Tag, Grid, Checkbox, Menu, Dropdown, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { APIRequest, isBadResponse, getFiles, getSearchFiles } from '../../models/APIRequest';
import { Context as AuthContext } from '../../context/AuthContext';
import { MediaContext } from '../../context/MediaContext';
import LoadingBlock from '../../components/LoadingBlock';
import FileBlockList from '../../components/list/FileBlockList';
import SubFolder from '../../components/list/SubFolder';
import FolderHeader from '../../components/list/FolderHeader';
import LoadMoreBlock from '../../components/list/LoadMoreBlock';
import MediaSortForm from '../../components/list/MediaSortForm';
import FileEditWidget from '../../components/file/FileEditWidget';
import FileMoveModal from '../../components/file/FileMoveModal';
import FileRegenerateThumbModal from '../../components/file/FileRegenerateThumbModal';
import FileDeleteModal from '../../components/file/FileDeleteModal';
import ApiErrorResult from '../../components/ApiErrorResult';
import { axiosError } from '../../components/Notifications';
import { 
  FOLDER_ROOT_ID, FILE_TYPE_SORT,
  FILE_SORT_DEFAULT_COLUMN, FILE_SORT_DEFAULT_ORDER, SEARCH_SORT_DEFAULT_COLUMN, SEARCH_SORT_DEFAULT_ORDER,
  FOLDER_TYPE_PRODUCT
} from '../../Constant';
import { getFileType } from '../../utils/FileUtils';
import { encodeObjectForUrl, decodeObjectFromUrl } from '../../utils/ApiUtils';
import { setStorageData, getStorageData, delMediaStorage } from '../../utils/StorageUtils';
import { canEditFile } from '../../utils/AccessRightUtils';
import { removeInFileCollection, removesInFileCollection, updateFileCollection, updatesFileCollection } from '../../utils/TemplateUtils';

const MediaScreen = () => {
  const TAG = "MediaScreen"

  //Template
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()
  const [ updateBreacrumbs, updateMediaFilters, setMediaFiltersLoading ] = useOutletContext()
  const { Title, Text } = Typography
  const idFolderParam = (params?.idFolder) ? params?.idFolder : FOLDER_ROOT_ID
  const searchParam = params?.search ? params.search : null
  const fileListContainer = useRef()
  const mainContainer = useRef()

  //States
  const DEFAULT_PAGER = {
    page: 1,
    pageSize: 0,
    total: 0,
    limit: 30,
  }
  const DEFAULT_FILTER_STATES = {
    isDefault: true,
    time: moment().unix(),
    sort: {
      column: (searchParam) ? SEARCH_SORT_DEFAULT_COLUMN : FILE_SORT_DEFAULT_COLUMN,
      order: (searchParam) ? SEARCH_SORT_DEFAULT_ORDER : FILE_SORT_DEFAULT_ORDER,
    },
    filters: null
  }
  const DEFAULT_STATES = {
    oldStatesIsloaded: false,
    isLoaded: false,
    loadMore: true,
    loadMoreCallBack: null,
    error: false,
    errorData: null,
    
    idFolder: (idFolderParam) ? parseInt(idFolderParam) : null,
    search: searchParam,

    displayType: null,
    files: [],
    checkedFiles: [],
    bulkIndeterminateChecked: false,
    bulkAllChecked: false,
    filters: null,
    filterValues: DEFAULT_FILTER_STATES,

    ...DEFAULT_PAGER
  }
  const { authState } = useContext(AuthContext)
  const { setRefreshFileTree } = useContext(MediaContext)
  const [ states, setStates ] = useState(DEFAULT_STATES)

  //Hooks
  useEffect(() => {
    if (!states.isLoaded) {
      return
    }

    console.log(TAG, '[authState.language, authState.token]')
    
    delMediaStorage()
    navigate(location.pathname, { replace:true })
  }, [authState.language, authState.token])

  useEffect(() => {
    console.log(TAG, '[params.idFolder, params.filter, params.search, location]')

    //Check is valid filters if have
    if (params?.filter && !decodeObjectFromUrl(params.filter)) {
      console.log(TAG, 'Filters is not valid!')

      navigate('/media/explore/' + states.idFolder, { replace: true })
      return
    }

    let idFolder = params?.idFolder
    const search = params?.search
    const filters = (params?.filter) ? decodeObjectFromUrl(params.filter) : DEFAULT_FILTER_STATES

    if (!params.search) {
      idFolder = (params?.idFolder) ? params?.idFolder : FOLDER_ROOT_ID
    }

    let oldStates = getOldStates(idFolder, search, filters)

    if (oldStates) {
      console.log(TAG, 'processOldStates: Using oldStates')

      setStates({
        ...DEFAULT_STATES,
        ...oldStates,
        oldStatesIsloaded: true,
        isLoaded: true,
        loadMore: false,
        idFolder: parseInt(idFolder),
        search: params.search,
        filterValues: filters,
        /*
        ...DEFAULT_PAGER,
        total: oldStates.total,
        */
      })

    } else {
      console.log(TAG, 'processOldStates: New states')

      //Try to get oldstate for better transition
      oldStates = getOldStatesObject(TAG)
      
      //Go to top
      let tolerance = 200
      if (
        fileListContainer?.current &&
        //mainContainer &&
        oldStates &&
        oldStates?.filterValues?.time != filters?.time &&
        ((idFolder && oldStates?.idFolder === parseInt(idFolder)) || (search && oldStates?.search === search)) &&
        (window.scrollY > (fileListContainer.current.offsetTop + tolerance) || window.scrollY < (fileListContainer.current.offsetTop - tolerance))
      ) {
        //mainContainer.current.style.minHeight = mainContainer.offsetHeight
        fileListContainer.current.scrollIntoView({ behavior:'smooth' })
      } else if (
        mainContainer?.current &&
        oldStates &&
        ((idFolder && oldStates?.idFolder !== parseInt(idFolder)) || (search && oldStates?.search !== search)) &&
        window.scrollY > mainContainer.current.offsetTop
      ) {
        //mainContainer.current.style.minHeight = fileListContainer.offsetHeight //Fix scroll bug
        //mainContainer.current.scrollIntoView({ behavior:'smooth' })
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      } else {
        //mainContainer.current.style.minHeight = 0
      }

      //Shwo loading on filter
      if (!oldStates || oldStates?.error === true || oldStates?.idFolder != idFolder || oldStates?.search != search) {
        setMediaFiltersLoading(true)
      }

      setStates({
        ...DEFAULT_STATES,
        ...oldStates,
        oldStatesIsloaded: true,
        checkedFiles: [],
        bulkIndeterminateChecked: false,
        bulkAllChecked: false,
        isLoaded: true,
        loadMore: true,
        idFolder: parseInt(idFolder),
        search: params.search,
        filterValues: filters,
        ...DEFAULT_PAGER,
        total: oldStates?.total
      })
    }
  }, [params.idFolder, params.filter, params.search, location])

  useEffect(() => {
    if (!states.isLoaded || !states.loadMore || (states.loadMore && states.page > 1 && states.page > states.pageSize)) {
      return
    }

    console.log(TAG, '[states.isLoaded, states.loadMore]')

    if (updateBreacrumbs && states.search) {
      updateBreacrumbs([{title: t('Search (%s)').replace('%s', states.search), 'route': null},])
    }

    loadData()
  }, [states.isLoaded, states.loadMore])

  useEffect(() => {
    console.log(TAG, '[states]')

    try {
      setStorageData(TAG, JSON.stringify(states))
    } catch(e) {
      console.log(TAG, e)
    }
  }, [states])

  /************************************************* STATE METHODS ********************************************************/
  const getOldStatesObject = () => {
    let oldStates = getStorageData(TAG)

    if (oldStates) {
      try {
        oldStates = JSON.parse(oldStates)
      } catch(thrown) {
        console.log(TAG, 'getOldStatesObject - oldStates no found')
        oldStates = null
      }
    }

    return oldStates
  }

  const getOldStates = (idFolder, search, filters) => {
    console.log(TAG, 'getOldStates')

    let oldStates = getOldStatesObject()

    if (oldStates) {
      if (oldStates?.error === true) {
        console.log(TAG, 'getOldStates - oldStates have error flag')
        oldStates = null
      
      } else if (oldStates?.idFolder != idFolder) {
        console.log(TAG, 'getOldStates - Folder ID is different')
        oldStates = null
      
      } else if (oldStates?.search != search) {
        console.log(TAG, 'getOldStates - Search is different')
        oldStates = null
      
      } else if (!oldStates?.filterValues?.isDefault && oldStates?.filterValues?.time != filters?.time) {
        console.log(TAG, 'getOldStates - Fitlers time is different (' + oldStates?.filterValues?.time + ' != ' + filters?.time + ')')
        oldStates = null

      } else if (oldStates?.filterValues?.isDefault != filters?.isDefault) {
        console.log(TAG, 'getOldStates - Fitlers not have same default')
        oldStates = null
      
      } else {
        console.log(TAG, 'getOldStates - oldStates OK!')

        updateMediaFilters(oldStates.filters)
      }
    }

    return oldStates
  }

  const loadData = () => {
    console.log(TAG, 'loadData - Getting data...')

    let request

    if (states.search) {
      request = getSearchFiles(
        APIRequest(authState.language, authState.token, null, 30),
        states.search,
        states.page,
        states.limit,
        states.filterValues.sort.column,
        states.filterValues.sort.order,
        states.filterValues?.filters,
      )
    } else {
      request = getFiles(
        APIRequest(authState.language, authState.token),
        states.idFolder,
        null,
        null,
        null,
        states.page,
        states.limit,
        states.filterValues.sort.column,
        states.filterValues.sort.order,
        states.filterValues?.filters,
      )
    }

    request.then(function (results) {
      if (isBadResponse(results.data)) {
        setStates({
          ...states,
          isLoaded: true,
          loadMore: false,
          error: true,
          errorData: results.data.data,
        })

        if (typeof states.loadMoreCallBack === 'function') {
          states.loadMoreCallBack(false)
        }

        return
      }

      regularizeFilter(results.data.data.filters)

      if (results.data.data.pager.page === 1) {
        updateMediaFilters(results.data.data.filters)
      }

      setStates({
        ...states,
        isLoaded: true,
        loadMore: false,
        error: false,
        errorData: null,

        displayType: results.data.data.display_type,
        files: (results.data.data.files) ? (results.data.data.pager.page === 1) ? results.data.data.files : [...states.files, ...results.data.data.files] : [],
        filters: results.data.data.filters,

        page: results.data.data.pager.page,
        pageSize: results.data.data.pager.page_max,
        total: results.data.data.pager.total,
        limit: results.data.data.pager.limit,
      })

      if (typeof states.loadMoreCallBack === 'function') {
        states.loadMoreCallBack(true)
      }
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: true,
        loadMore: false,
        error: true,
        errorData: null,
      })

      if (typeof states.loadMoreCallBack === 'function') {
        states.loadMoreCallBack(false)
      }
    })
  }

  const regularizeFilter = (newFilters) => {
    if (!states.filterValues?.filters) {
      return
    }

    const statesFilters = Object.assign({}, states.filterValues.filters)
    let isOk = true

    Object.keys(newFilters).forEach(key => {
      if (!statesFilters[key]) {
        console.log(TAG, 'Break filter (' + key + ')')
        return
      }

      if (!newFilters[key].options.length || (statesFilters[key] && !statesFilters[key].length)) {
        console.log(TAG, 'Delete filter (' + key + ')')
        delete statesFilters[key]
        isOk = false
      
      } else {
        console.log(TAG, 'Check filter values (' + key + ')')

        statesFilters[key].forEach(value => {
          if (!newFilters[key].options.find((item => item.value == value))) {
            console.log(TAG, 'Unknow value (' + value + ') > delete')
          
            statesFilters[key] = statesFilters[key].filter((item) => item != value)
            isOk = false
          }
        })
      }
    })

    if (!isOk) {
      console.log(TAG, 'Bad filter, redirect...')

      const crypted = encodeObjectForUrl({
        ...states.filterValues,
        time: moment().unix(),
        isDefault: false,
        filters: statesFilters
      })
      
      if (states.search) {
        navigate('/media/search/' + states.search + '/filter/' + crypted, { replace:true })
      } else {
        navigate('/media/explore/' + states.idFolder + '/filter/' + crypted, { replace:true })
      }
    } else {
      console.log(TAG, 'Filter is OK!')
    }
  }

  /*********************************************** TEMPLATE METHODS ********************************************************/
  //Folder
  const handleFolder = (folderId, options) => {
    let filtersUrl = ''

    if (!states.filterValues.isDefault) {
      filtersUrl = '/filter/' + encodeObjectForUrl({
        ...states.filterValues,
        time: moment().unix(),
        isDefault: false,
        filters: null, //Don't conserve current filters
      })
    }

    navigate('/media/explore/' + folderId + filtersUrl, options)
  }

  const onDeleteCurrentFolder = (folderId, parentFolderId) => {
    console.log(TAG, 'onDeleteCurrentFolder')

    setRefreshFileTree(true)

    if (parentFolderId) {
      handleFolder(parentFolderId, { replace: true })
    } else {
      navigate('/media')
    }
  }

  const onDeleteFolder = (folderId, filesMovedToParent) => {
    if (filesMovedToParent) {
      delMediaStorage()

      setStates({
        ...states,
        ...DEFAULT_PAGER,
        error:false,
        errorData: null,
        isLoaded: true,
        loadMore: true
      })
    }
  }

  //Files
  const checkOpenedFile = () => {
    return location.pathname.match(/(file)\/([0-9]{1,})/)
  }

  const handleFile = (file) => {
    if (checkOpenedFile() || !location.pathname.match(/^\/media\/(explore|search)/)) {
      return
    }

    navigate(location.pathname + '/file/' + file.id)
  }

  const onCheckFile = (file, checked) => {
    let checkedFiles = [...states.checkedFiles]

    if (checked) {
      checkedFiles.push(file)
    } else {
      checkedFiles = checkedFiles.filter((item) => item.id !== file.id)
    }
    
    setStates({ ...states, ...getBulkCheckStates(checkedFiles, states.files) })
  }

  const onDeleteFile = (fileId) => {
    console.log(TAG, 'onDeleteFile')

    const files = removeInFileCollection(fileId, [...states.files])
    const checkedFiles = removeInFileCollection(fileId, [...states.checkedFiles])
    const total = states.total - (states.files.length - files.length)

    setStates({ ...states, ...getBulkCheckStates(checkedFiles, files), files: files, total: total })
  }

  const onDeleteFiles = (fileIds, otherStates) => {
    console.log(TAG, 'onDeleteFiles')

    const files = removesInFileCollection(fileIds, [...states.files])
    const checkedFiles = removesInFileCollection(fileIds, [...states.checkedFiles])
    const total = states.total - (states.files.length - files.length)

    setStates({ ...states, ...otherStates, ...getBulkCheckStates(checkedFiles, files), files: files, total: total })
  }

  const onUpdateFile = (fileId, updatedFile) => {
    console.log(TAG, 'onUpdateFile')

    const files = updateFileCollection(updatedFile, [...states.files])
    const checkedFiles = updateFileCollection(updatedFile, [...states.checkedFiles])

    setStates({ ...states, ...getBulkCheckStates(checkedFiles, files), files: files })
  }

  const onUpdateFiles = (updatedFiles) => {
    console.log(TAG, 'onUpdateFiles')

    const files = updatesFileCollection(updatedFiles, [...states.files])
    const checkedFiles = updatesFileCollection(updatedFiles, [...states.checkedFiles])

    setStates({ ...states, ...getBulkCheckStates(checkedFiles, files), files: files })
  }

  const onCheckAll = (e) => {
    let checkedFiles = []

    if (e.target.checked) {
      checkedFiles = [...states.files.filter(file => canEditFile(authState, file))]
    }
    
    setStates({...states, ...getBulkCheckStates(checkedFiles, states.files) })
  }

  const getBulkCheckStates = (checkedFiles, files) => {
    const bulkCheckStates = {
      checkedFiles: checkedFiles,
      bulkIndeterminateChecked: false,
      bulkAllChecked: false
    }

    if (!checkedFiles || !files) {
      return bulkCheckStates
    }

    if (checkedFiles.length === files.filter(file => canEditFile(authState, file)).length) {
      bulkCheckStates['bulkAllChecked'] = true
    } else if (checkedFiles.length) {
      bulkCheckStates['bulkIndeterminateChecked'] = true
    }

    return bulkCheckStates
  }

  //Template
  const handleChangeFileSort = (column, order) => {
    const crypted = encodeObjectForUrl({
      ...states.filterValues,
      time: moment().unix(),
      isDefault: false,
      sort: {
        column: column,
        order: order,
      },
    })
    
    if (!crypted) {
      console.log(TAG, 'Error in crypting filters...')
    }

    if (states.search) {
      navigate('/media/search/' + states.search + '/filter/' + crypted)
    } else {
      navigate('/media/explore/' + states.idFolder + '/filter/' + crypted)
    }
  }

  const getStates = () => {
    return states
  }

  const getParentName = () => {
    return TAG
  }

  const loadMore = (callBack) => {
    if (states.page >= states.pageSize) {
      return false
    }

    setStates({
      ...states,
      loadMore: true,
      loadMoreCallBack: callBack,
      page: states.page + 1
    })

    return true
  }

  const getSortForm = () => {
    return (
      <MediaSortForm
        key={'MediaSortForm' + states.filterValues.time}
        onChange={handleChangeFileSort}
        panelExtra={t('%d on %d media loaded').replace('%d', states.files.length).replace('%d', states.total)}
        sortColumn={states.filterValues.sort.column}
        sortOrder={states.filterValues.sort.order}
        isSearch={(states.search)}
      />
    )
  }

  const getFilterResume = (filters) => {
    if (!filters) {
      return null
    }

    const filtersResume = []

    Object.keys(filters).forEach(key => {
      if (states.filterValues?.filters && states.filterValues.filters[key]) {
        const items = []

        states.filterValues.filters[key].forEach((item) => {
          const filter = filters[key].options.filter((option) => option.value == item)

          if (filter && filter[0]) {
            items.push({
              label: filter[0].label,
              value: filter[0].value
            })
          }
        })

        filtersResume.push({
          key: key,
          title: filters[key].title,
          items: items
        })
      }
    })

    if (!filtersResume.length) {
      return null
    }

    return (
      <div style={{ marginBottom:10, padding:10, backgroundColor:'#FFF' }}>
        <>
          <Text style={{ marginRight:10 }}>{t('Filter')}:</Text>
          {filtersResume.map(filter => {
            return (
              <span style={{ marginRight:10 }} key={"filterResume" + filter.key}>
                <Text strong style={{ marginRight:10 }}>{filter.title}</Text>
                  <span>
                  {filter.items.map(item => {
                    let label = item.label

                    if (filter.key === 'folders') {
                      const labels = label.split(' > ')

                      if (labels.length == 2) {
                        label = <>
                          <Text>{labels[1]}</Text>
                          <Text type='secondary'> <PushpinOutlined /> {labels[0]}</Text>
                        </>
                      } else {
                        label = <Text>{labels[0]}</Text>
                      }
                    }

                    return (
                      <Tag
                        closable
                        onClose={() => deleteFilter(filter.key, item.value)}
                        key={"filterResume" + filter.key + item.value}
                      >
                        {label}
                      </Tag>
                    )
                  })}
                </span>
                <Divider type="vertical" style={{ margin:'0 2px' }} />
              </span>
            )
          })}
          <Button
            size="small"
            type="primary"
            icon={<DeleteOutlined />}
            danger={true}
            onClick={() => clearFilter()}
          >
            {t('Clear filter')}
          </Button>
        </>
      </div>
    )
  }

  const deleteFilter = (filterKey, value) => {
    if (!states.filterValues?.filters) {
      console.log(TAG, 'deleteFilter - Filters is undefined or null')
      return
    }

    const filters = Object.assign({}, states.filterValues.filters)

    if (!filters[filterKey]) {
      console.log(TAG, 'deleteFilter - Filters key not found (' + filterKey + ')')
      return
    }

    const newValues = filters[filterKey].filter((item) => item !== value)

    if (!newValues || newValues === '' || !newValues.length) {
      delete filters[filterKey]
    } else {
      filters[filterKey] = newValues
    }

    const crypted = encodeObjectForUrl({
      ...states.filterValues,
      time: moment().unix(),
      isDefault: false,
      filters: filters,
    })
    
    if (!crypted) {
      console.log(TAG, 'Error in crypting filters...')
      return
    }

    if (states.search) {
      navigate('/media/search/' + states.search + '/filter/' + crypted)
    } else {
      navigate('/media/explore/' + states.idFolder + '/filter/' + crypted)
    }
  }

  const clearFilter = (filterKey, value) => {
    const crypted = encodeObjectForUrl({
      ...states.filterValues,
      time: moment().unix(),
      isDefault: false,
      filters: null
    })
    
    if (!crypted) {
      console.log(TAG, 'Error in crypting filters...')
      return
    }

    if (states.search) {
      navigate('/media/search/' + states.search + '/filter/' + crypted)
    } else {
      navigate('/media/explore/' + states.idFolder + '/filter/' + crypted)
    }
  }

  const getFileList = () => {
    /*if (!states.isLoaded) {
      return <LoadingBlock />
    }*/

    return (
      <Spin spinning={states.isLoaded && states.loadMore && states.page === 1}>
        {(states.isLoaded && (states.loadMore || states.files.length > 0)) && (
          <>
            {getSortForm()}
            {getFilterResume(states.filters)}
          </>
        )}
        {/*(states.loadMore && states.page === 1) && (
          <LoadingBlock />
        )*/}
        <InfiniteScroll
          dataLength={states.files.length}
          next={() => loadMore()}
          hasMore={states.page < states.pageSize}
          loader={<LoadMoreBlock />}
          endMessage={states.total > 0 && <Divider plain><Text type='secondary'>{t('End of media')}</Text></Divider>}
          style={{ overflow:'hidden', margin: -10 }}
        >
          <List
            grid={{
              gutter: [6, 6],
              xs: 1,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 3,
              xxl: 4,
            }}
            locale={{
              emptyText: (states.isLoaded && !states.loadMore && states.page === 1 && !states.files.length) ? getNoMediaFound() : ' ' //Tweak to hide noData!
            }}
            dataSource={states.files}
            rowKey={item => item.id + item.date_upd}
            renderItem={item => (
              <List.Item style={{ marginBottom:0 }}>
                <FileBlockList
                  file={item}
                  isChecked={states.checkedFiles.some((file) => file.id === item.id)}
                  onClick={() => handleFile(item)}
                  onDeleteFile={onDeleteFile}
                  onUpdateFile={onUpdateFile}
                  onCheckFile={onCheckFile}
                  //refreshList={() => setStates({...states, parentFolderIsLoaded: false})}
                />
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </Spin>
    )
  }

  const getProductFileList = () => {
    if (!states.isLoaded) {
      return <LoadingBlock />
    }

    //Prepare files by type
    const typeFiles = {}

    states.files.forEach(file => {
      if (!typeFiles[file.file_type.type]) {
        typeFiles[file.file_type.type] = []
      }

      typeFiles[file.file_type.type].push(file)
    })

    if (states.loadMore && states.page === 1) {
      return <LoadingBlock />
    }

    //Return view
    return (
      <>
        {(states.isLoaded && (states.loadMore || states.files.length > 0)) && (
          <>
            {getSortForm()}
            {getFilterResume(states.filters)}
          </>
        )}

        {FILE_TYPE_SORT.map(type => {
          const files = typeFiles[type]

          if (!files) {
            return null
          }

          //Get type
          const _type = getFileType(type, t)

          return (
            <div style={{ marginBottom:20 }} key={'filesType' + type}>
              <div style={{ backgroundColor:'#FAFAFA', color:'#333', padding:10, marginBottom:20, textTransform:'uppercase', display:'flex', justifyContent:'space-between', alignItems:'center' }}>
                <Title level={4} style={{ margin:0 }}>{_type.icon} {_type.label}</Title>
                <>{t('%d media').replace('%d', files.length)}</>
              </div>
              <div style={{ overflow:'hidden', margin: -10 }}>
                <List
                  grid={{
                    gutter: [16, 16],
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 3,
                    xxl: 4,
                  }}
                  locale={{
                    emptyText: (states.isLoaded && !states.loadMore && states.page === 1 && !states.files.length) ? getNoMediaFound() : ' ' //Tweak to hide noData!
                  }}
                  dataSource={files}
                  rowKey={item => item.id + item.date_upd}
                  renderItem={item => (
                    <List.Item style={{ marginBottom:0 }}>
                      <FileBlockList
                        file={item}
                        isChecked={states.checkedFiles.some((file) => file.id === item.id)}
                        onClick={() => handleFile(item)}
                        onDeleteFile={onDeleteFile}
                        onUpdateFile={onUpdateFile}
                        onCheckFile={onCheckFile}
                        //refreshList={() => setStates({...states, parentFolderIsLoaded: false})}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </div>
          )
        })}
      </>
    )
  }

  const getNoMediaFound = () => {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <span>
            {t('No media found')}...
          </span>
        }
      />
    )
  }

  const getHeader = () => {
    if (states.search) {
      return (
        <Title level={4} style={{ textAlign:'center' }}>
          {t('Result of the search "%s"').replace('%s', states.search)}
        </Title>
      )
    
    } else {
      return (
        <FolderHeader
          idFolder={states.idFolder}
          onDeleteFolder={onDeleteCurrentFolder}
          onLoadError={onLoadError}
        />
      )
    }
  }

  const onLoadError = (errorData) => {
    setStates({
      ...states,
      error: true,
      errorData: errorData
    })
  }

  const getBulkAction = () => {
    if (states.checkedFiles.length <= 0) {
      return null
    }

    const menu = (
      <Menu
        items={[
          {
            label: (
              <Button
                size={(screens.md) ? 'middle' : 'small'}
                block
                onClick={() => {

                }}
              >
                <Checkbox
                  onChange={onCheckAll}
                  indeterminate={states.bulkIndeterminateChecked}
                  checked={states.bulkAllChecked}
                >
                  {states.bulkAllChecked ? t('Uncheck all') : t('Check all')}
                </Checkbox>
              </Button>
            ),
            key: 'bulkCheckAll',
          },
          {
            type: 'divider',
          },
          {
            label: (
              <FileEditWidget
                widgetType="button"
                widgetProps={{ size:(screens.md) ? 'middle' : 'small', block:true }}
                widgetLabel={t('Bulk edition')}
                files={states.checkedFiles}
                onUpdateFiles={(files) => onUpdateFiles(files)}
                onDeleteFiles={(files) => onDeleteFiles(files)}
              />
            ),
            key: 'bulkEdit',
          },
          {
            label: (
              <FileMoveModal
                files={states.checkedFiles}
                widgetType='button'
                widgetLabel={t('Move selection')}
                widgetProps={{ size:(screens.md) ? 'middle' : 'small', block:true }}
                onMovedFile={(files, folderId) => onDeleteFiles(files)}
              />
            ),
            key: 'bulkMove',
          },
          {
            label: (
              <FileRegenerateThumbModal
                files={states.checkedFiles}
                widgetType='button'
                widgetProps={{ size:(screens.md) ? 'middle' : 'small', block:true }}
                onRegenerateThumbFiles={(files) => onUpdateFiles(files)}
              />
            ),
            key: 'bulkRegenerateThumb',
          },
          {
            label: (
              <FileDeleteModal
                files={states.checkedFiles}
                widgetType='button'
                widgetLabel={t('Delete selection')}
                widgetProps={{ danger:true, size:(screens.md) ? 'middle' : 'small', block:true }}
                onDeletedFile={(files) => onDeleteFiles(files)}
              />
            ),
            key: 'bulkDel',
          }
        ]}
      />
    )

    return (
      <div style={{ position:'fixed', zIndex:50, top:20, left:'50%', marginLeft:(screens.xl) ? 150 : 0, transform:'translateX(-50%)' }}>
        <Dropdown overlay={menu} placement="bottom" getPopupContainer={(trigger) => trigger.parentElement}>
          <Button size='large' type='primary' icon={<SettingOutlined />}>
            {t('%d selected media').replace('%d', states.checkedFiles.length)} <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    )
  }

  if (states.error) {
    return (
      <ApiErrorResult errorData={states.errorData} actionTitle={t('Try to reload')} actionHandle={() => {
        setStates({
          ...states,
          error:false,
          errorData: null,
          isLoaded: true,
          loadMore: true
        })
      }} />
    )
  }

  return (
    <main ref={mainContainer}>
      {getHeader()}
      {!states.search && (
        <>
          <SubFolder
            idFolder={states.idFolder}
            onClickFolder={handleFolder}
            onDeleteFolder={onDeleteFolder}
          />
          <Divider orientation="center" plain><Text type='secondary'>{t('Media')}</Text></Divider>
        </>
      )}

      <div ref={fileListContainer}>
        {(states.displayType === FOLDER_TYPE_PRODUCT) ? getProductFileList() : getFileList()}
      </div>

      {getBulkAction()}

      <Outlet context={[ onDeleteFile, onUpdateFile, getParentName, getStates, loadMore ]} />
    </main>
  )
}

export default memo(MediaScreen)