import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Form, Select, Button, Typography, Grid, Row, Col, Collapse } from 'antd';
import { ReloadOutlined, CaretUpOutlined, CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { FILE_SORT_DEFAULT_COLUMN, FILE_SORT_DEFAULT_ORDER } from '../../Constant';

const MediaSortForm = (props) => {
  const TAG = "MediaSortForm"

  //Template
  const { t } = useTranslation()
  const { Text } = Typography
  const { Option } = Select
  const { useBreakpoint } = Grid
  const { Panel } = Collapse
  const screens = useBreakpoint()
  const panelExtra = props?.panelExtra
  const onChange = props?.onChange
  const sortColumn = props?.sortColumn
  const sortOrder = props?.sortOrder
  const isSearch = props?.isSearch
  const columnsItems = [
    { value: 'weight', label: t('Relevance'), searchOnly: true },
    { value: 'date', label: t('Date'), searchOnly: false },
    { value: 'title', label: t('Title'), searchOnly: false },
    { value: 'filesize', label: t('File size'), searchOnly: false },
  ]
  const orderItems = [
    { value: 'asc', label: t('Ascending'), icon: <CaretUpOutlined /> },
    { value: 'desc', label: t('Descending'), icon: <CaretDownOutlined /> },
  ]

  //States
  const DEFAULT_STATES = {
    column: (sortColumn) ? sortColumn : FILE_SORT_DEFAULT_COLUMN,
    order: (sortOrder) ? sortOrder : FILE_SORT_DEFAULT_ORDER,
  }
  const [ states, setStates ] = useState(DEFAULT_STATES)

  //Template method
  const getContent = () => {
    return (
      <Row justify="space-around" align="middle" key="form">
        <Col xs={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }}>
          <Form
            layout={(!screens.xs) ? 'inline' : 'vertical'}
            onFinish={() => {
              onChange(states.column, states.order)
            }}
            initialValues={states}
          >
            <Form.Item
              key="FileSortColumn"
              name="column"
              label={(!screens.xs) ? t('Sort') : null}
              style={(screens.xs) ? { margin:'5px 0' } : null}
            >
              <Select
                onChange={(value) => setStates({ ...states, column: value })}
                style={{ minWidth: 125 }}
              >
                {columnsItems.map(item => {
                  if (item.searchOnly === true && !isSearch) {
                    return null
                  }

                  return <Option value={item.value} key={'FileSortColumn' + item.value}>{item.label}</Option>
                })}
              </Select>
            </Form.Item>
            <Form.Item
              key="FileSortOrder"
              name="order"
              style={(screens.xs) ? { margin:'5px 0' } : null}
            >
              <Select
                onChange={(value) => setStates({ ...states, order: value })}
                style={{ minWidth: 125 }}
              >
                {orderItems.map(item => <Option value={item.value} key={'FileSortOrder' + item.value}>{item.icon} {item.label}</Option>)}
              </Select>
            </Form.Item>
            <Form.Item
              name="button"
              style={(screens.xs) ? { margin:'5px 0' } : null}
            >
              <Button icon={<ReloadOutlined />} htmlType="submit" block={(screens.xs)}>{t('Reload')}</Button>
            </Form.Item>
          </Form>
        </Col>
        {!screens.xs && getExtra()}
      </Row>
    )
  }

  const getExtra = () => {
    if (!panelExtra) {
      return null
    }

    if (screens.xs) {
      return (
        <div style={{ textAlign:'right', margin:'10px 0' }}>
          <Text>{panelExtra}</Text>
        </div>
      )

    } else {
      return (
        <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} style={{ textAlign:'right', marginTop:(screens.md) ? 0 : 10 }}>
          <Text>{panelExtra}</Text>
        </Col>
      )
    }
  }

  const getColumnLabelFromValue = (column) => {
    let label = 'Not found'

    columnsItems.every(item => {
      if (column === item.value) {
        label = item.label

        return false
      }

      return true
    })

    return label
  }

  const getOrderLabelFromValue = (order) => {
    let label = 'Not found'

    orderItems.every(item => {
      if (order === item.value) {
        label = item.label

        return false
      }

      return true
    })

    return label
  }

  //Return
  if (screens.xs) {
    return (
      <>
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          style={{ backgroundColor:'#FFF' }}
        >
          <Panel
            key="form"
            header={t('Sort')}
            forceRender={true}
            extra={<Text>{getColumnLabelFromValue(sortColumn)} / {getOrderLabelFromValue(sortOrder)}</Text>}
            style={{ margin:0 }}
          >
            {getContent()}
          </Panel>
        </Collapse>
        {getExtra()}
      </>
    )
  
  } else {
    return (
      <div style={{ backgroundColor:'#FFF', padding:10, marginBottom:'1em' }}>
        {getContent()}
      </div>
    )
  }
}

export default MediaSortForm