import { memo } from "react";
import { useTranslation } from "react-i18next";
import {
  Form,
  Input,
  Select,
  Button,
  Popconfirm
} from 'antd';
import ReactCountryFlag from "react-country-flag";
import { CopyOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { LANGUAGE_AVALAIBLE } from '../../Constant'

const FormItemLang = (props) => {
  const TAG = 'FormItemLang'

  if (!props?.currentLang) {
    throw new Error(TAG + ' - PROPS currentLang is undefined')
  } else if (!props?.onLangChange) {
    throw new Error(TAG + ' - PROPS onLangChange is undefined')
  } else if (!props?.name) {
    throw new Error(TAG + ' - PROPS name is undefined')
  }

  //Template
  const { t } = useTranslation()
  const { Option } = Select
  const onLangChange = props.onLangChange
  const onCopyLang = props.onCopyLang
  const currentLang = props.currentLang
  const input = props.children
  const label = props.label
  const name = props.name
  const required = props.required
  const rules = props.rules

  const getCurrentLanguageName = () => {
    const language = LANGUAGE_AVALAIBLE.filter(lang => lang.route === currentLang)

    if (!language || !language[0]) {
      console.log(TAG, 'getCurrentLanguageName not lang with code: ' + currentLang)
      return LANGUAGE_AVALAIBLE[0].title
    }

    return language[0].title
  }

  return (
    <Form.Item label={label} required={required} style={{marginBottom:0}}>
        {LANGUAGE_AVALAIBLE.map(lang => {
          let langRules = []

          if (rules) {
            rules.forEach((rule, key) => {
              langRules.push(Object.assign({}, rule))
              langRules[key].message = langRules[key].message.replace('%lang%', lang.title)
            })
          }

          return (
            <Form.Item
              key={name + lang.route}
              name={[name, lang.route]}
              rules={langRules}
              style={{display:(currentLang === lang.route) ? 'inline-block' : 'none', width:'calc(100% - 105px)'}}
            >
              {input}
            </Form.Item>
          )
        })}
        <Form.Item noStyle>
          <Input.Group compact style={{display:'inline-block', width:100, marginLeft:5}}>
            <Select
              value={currentLang}
              style={{ width:60 }}
              placement='bottomRight'
              onChange={onLangChange}
              getPopupContainer={trigger => trigger.parentNode}
            >
              {LANGUAGE_AVALAIBLE.map(lang => 
                <Option value={lang.route} key={'FormItemLangSelect' + lang.route}><ReactCountryFlag countryCode={lang.countryCode} svg /></Option>
              )}
            </Select>
            {onCopyLang && 
              <Popconfirm
                placement="bottomRight"
                icon={<QuestionCircleOutlined />}
                title={t('Are you sure to copy language %lang to all another lang?').replace('%lang', getCurrentLanguageName())}
                onConfirm={() => {
                  onCopyLang(name, currentLang, LANGUAGE_AVALAIBLE)
                }}
                okText={t('Yes')}
                cancelText={t('No')}
              >
              <Button icon={<CopyOutlined />} style={{ width:40 }} key="back" />
              </Popconfirm>
            }
          </Input.Group>
        </Form.Item>
    </Form.Item>
  )
}

export default memo(FormItemLang)