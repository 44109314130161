export const getShortFormat = (lang) => {
  if (['enGB', 'fr'].includes(lang)) {
    return 'DD/MM/YYYY'
  } else {
    return 'MM/DD/YYYY'
  }
}

export const getShortTimeFormat = (lang) => {
  return getShortFormat(lang) + ' HH:mm:ss'
}
