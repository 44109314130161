import { useState } from "react";
import { GLOBAL_MODAL_TYPES } from "../Constant";

export default function UseModal () {
  const TAG = 'UseModal'
  const [modalType, setModalType] = useState();
  const [modalProps, setModalProps] = useState({});
  const [modalOnOk, setModalOnOk] = useState(null);
  const [modalOnCancel, setModalOnCancel] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const openModal = (type, props, onOk, onCancel) => {
    //Check type
    if (!GLOBAL_MODAL_TYPES.hasOwnProperty(type)) {
      console.log(TAG, 'Modal type ' + type + ' unknow')
      return
    
    //Check show
    } else if (showModal) {
      if (type === modalType) {
        console.log(TAG, 'Modal ' + type + ' is already displayed')
        return
      }

      closeModal()
    }

    setModalType(type)
    setModalProps(props)
    setModalOnOk(() => onOk)
    setModalOnCancel(() => onCancel)
    setShowModal(true)
  }

  const closeModal = () => {
    /*if (!showModal) {
      console.log(TAG, 'Modal is already hidden')
      return
    }*/

    setShowModal(false)

    if (modalOnCancel) {
      modalOnCancel()
    }
  }

  return { modalType, showModal, modalProps, openModal, closeModal, modalOnOk }
}