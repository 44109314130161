import React, { useReducer } from 'react';
import i18n from 'i18next';
import { convertI18nLanguage } from '../utils/LangUtils';
import { delStorageData, setStorageData, getStorageData, delMediaStorage } from '../utils/StorageUtils';

const TAG = 'AuthContext'
const STORE_LANGUAGE_KEY = "i18nextLng"

const authReducer = (state, action) => {
  switch (action.type) {
    case 'retrieve': 
      return {
        language: action.payload.language,
        isLogged: (action.payload.token != null),
        token: action.payload.token,
        data: action.payload.data,
        cart: action.payload.cart,
      }
    case 'updateLanguage':
      return {...state, language: action.payload.language}
    case 'updateData':
      return {...state, data: action.payload.data}
    case 'updateCart':
      return {...state, cart: action.payload.cart}
    case 'signout':
      return {...state, isLogged:false, token: null, id: null, data: null}
    case 'signin':
    case 'signup':
      return {
        language: action.payload.language,
        isLogged: (action.payload.token != null),
        token: action.payload.token,
        data: action.payload.data,
        cart: action.payload.cart,
      }
    default:
      return state
  }
}

const retrieve = dispatch => {
  console.log(TAG, 'retrieve')
  
  return async() => {
    let language = null
    let token = null
    let data = null
    let cart = []

    try {
      language = getStorageData(STORE_LANGUAGE_KEY)
      token = getStorageData('userToken')

      const userDataJson = getStorageData('userData')
      data = JSON.parse(userDataJson)

      const userCartJson = getStorageData('userCart')

      if (userCartJson) {
        cart = JSON.parse(userCartJson)
      }
    } catch(e) {
      console.log(TAG, e)
    }

    if (!language) {
      language = convertI18nLanguage(i18n.language)
    } else {
      language = convertI18nLanguage(language)
    }
  
    dispatch({
      type: 'retrieve',
      payload: {
        language: language,
        isLogged: (token != null),
        token: token,
        data: data,
        cart: cart
      },
    })
  }
}

const updateData = dispatch => {
  console.log(TAG, 'updateData')

  return async({data}) => {
    try {
      setStorageData('userData', JSON.stringify(data))
    } catch(e) {
      console.log(TAG, e)
    }

    dispatch({
      type: 'updateData',
      payload: {
        data: data
      },
    })
  }
}

const updateCart = dispatch => {
  console.log(TAG, 'updateCart')

  return async(items) => {
    try {
      setStorageData('userCart', JSON.stringify(items))
    } catch(e) {
      console.log(TAG, e)
    }

    dispatch({
      type: 'updateCart',
      payload: {
        cart: items
      },
    })
  }
}

const updateLanguage = dispatch => {
  console.log(TAG, 'updateLanguage')

  return async(language) => {
    try {
      setStorageData(STORE_LANGUAGE_KEY, convertI18nLanguage(language))
    } catch(e) {
      console.log(TAG, e)
    }

    dispatch({
      type: 'updateLanguage',
      payload: {
        language: language
      },
    })
  }
}

const signin = dispatch => {
  console.log(TAG, 'signin')

  return async({token, data}) => {
    let language = null
    let cart = JSON.stringify([])

    try {
      setStorageData('userToken', token)
      setStorageData('userData', JSON.stringify(data))
      delMediaStorage()
      
      language = getStorageData(STORE_LANGUAGE_KEY)

      const userCartJson = getStorageData('userCart')

      if (userCartJson) {
        cart = JSON.parse(userCartJson)
      }
    } catch(e) {
      console.log(TAG, e)
    }

    if (!language) {
      language = convertI18nLanguage(i18n.language)
    } else {
      language = convertI18nLanguage(language)
    }

    if (typeof cart !== 'object') {
      cart = []
    }

    dispatch({
      type: 'signin',
      payload: {
        language: language,
        isLogged: true,
        token: token,
        data: data,
        cart: cart
      },
    })
  }
}

const signout = dispatch => {
  console.log(TAG, 'signout')

  return async() => {
    try {
      delStorageData('userToken')
      delStorageData('userData')
      delMediaStorage()
    } catch(e) {
      console.log(TAG, e)
    }

    dispatch({ type: 'signout' })
  }
}

const createDataContext = ( reducer, action, defaultValue ) => {
  const Context = React.createContext();

  const Provider = ({ children }) => {
    const [ authState, dispatch ] = useReducer(reducer, defaultValue)
    const boundActions = {}

    for (let key in action){
      boundActions[key] = action[key](dispatch)
    }

    return React.useMemo(() => (
      <Context.Provider value={{ authState, ...boundActions }}>
        { children }
      </Context.Provider>
    ))
  }

  return { Context: Context, Provider: Provider }
}

export const {Provider, Context} = createDataContext(
  authReducer,
  { signin, signout, retrieve, updateData, updateCart, updateLanguage },
  { language: null, token: null, isLogged: false, data: null, cart: [] },
)
