import { useEffect, useState, useContext, createRef } from 'react';
import { useTranslation } from "react-i18next";
import { Grid, Modal, Form, Input, Switch, Button, Space } from 'antd';
import { ModalContext } from "../../context/ModalContext";
import { Context as AuthContext } from '../../context/AuthContext';
import { APIRequest, isBadResponse, updateSticker, addSticker } from '../../models/APIRequest';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { axiosError, formError } from '../Notifications';
import ApiErrorResult from '../ApiErrorResult';
import FormItemLang from '../form/FormItemLang';
import { Panel as ColorPickerPanel } from 'rc-color-picker';
import 'rc-color-picker/assets/index.css';
import { transformApiLangToFormLang, transformFormLangToFormData } from '../../utils/ApiUtils';
import { GLOBAL_MODAL_TYPES, LANGUAGE_DEFAULT_FORM_VALUE } from "../../Constant";

export default function StickerModal() {
  const TAG = 'StickerModal'

  //Template
  const { t } = useTranslation()
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()
  const formRef = createRef()

  //States
  const DEFAULT_STATES = {
    title: LANGUAGE_DEFAULT_FORM_VALUE,
    color: '#ff5220',
    active: false
  }
  const { modalType, showModal, modalProps, closeModal, modalOnOk } = useContext(ModalContext)
  const isEditing = (modalProps?.id && modalProps?.sticker)
  const { authState } = useContext(AuthContext)
  const [ formLang, setFormLang ] = useState(authState.language)
  const [ formLayout, setFormLayout ] = useState('horizontal')
  const [ states, setStates ] = useState(DEFAULT_STATES)
  const [data, setData] = useState({
    isSubmitting: false,
    submitError: false,
    submitErrorData: null,
  })

  //Hooks
  useEffect(() => {
    if (modalType !== GLOBAL_MODAL_TYPES.STICKER || !showModal) {
      return
    }

    if (isEditing) {
      setStates({
        title: transformApiLangToFormLang(modalProps.sticker.langs.title),
        color: modalProps.sticker.color,
        active: modalProps.sticker.active,
      })
    } else {
      setStates(DEFAULT_STATES)
    }
  }, [modalProps])

  useEffect(() => {
    setFormLayout((!screens.xl) ? 'vertical' : 'horizontal')
  }, [screens])

  //Template methods
  const handleCopyLang = (name, currentLang, languages) => {
    const value = {}

    languages.forEach(lang => {
      value[lang.route] = states[name][currentLang]
    })

    const newStates = {...states, [name]: value}

    formRef.current.setFieldsValue(newStates)
    setStates(newStates)
  }

  const submitForm = () => {
    console.log(TAG, 'submitForm')

    //Loading
    setData({...data,
      isSubmitting: true
    })

    //Make formData
    const formDatas = {
      ...transformFormLangToFormData({
        title: states.title,
        //content: states.content
      }),
      color: states.color,
      active: states.active,
    }

    //Method
    const request = (isEditing)
      ? updateSticker(APIRequest(authState.language, authState.token, null, 0), modalProps.id, formDatas)
      : addSticker(APIRequest(authState.language, authState.token, null, 0), formDatas)

    request.then(response => {
      if (isBadResponse(response.data)) {
        setData({
          ...data,
          isSubmitting: false,
          submitError: true,
          submitErrorData: {
            code: response.data?.data?.code,
            message: response.data?.data?.message,
            extraData: response.data?.data?.extra_data
          }
        })

        return
      }

      resetModal()
      modalOnOk(response.data.data.sticker, true)
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setData({
        ...data,
        isSubmitting: false,
        submitError: true,
        submitErrorData: {
          code: null,
          message: thrown
        }
      })
    })
  }

  const resetModal = () => {
    setData({
      ...data,
      isSubmitting: false,
      submitError: false,
      submitErrorData: null,
    })
    setStates(DEFAULT_STATES)
  }

  const closeThisModal = () => {
    resetModal()
    closeModal()
  }

  const formItemLayout = 
    (formLayout === 'horizontal')
      ? {labelCol:{span: 6}, wrapperCol:{span: 12}}
      : null

  return (
    <Modal
      title={(isEditing) ? modalProps.sticker.title : t('Add sticker')}
      open={modalType !== GLOBAL_MODAL_TYPES.STICKER ? false : showModal}
      closable={!states.isSubmitting}
      zIndex={120}
      width={(!screens.md) ? 'calc(100% - 32px)' : 'calc(100% - 20%)'}
      destroyOnClose={true}
      onCancel={() => {
        closeThisModal()
      }}
      footer={
        <div style={{textAlign:'center'}}>
          <Space>
            <Button onClick={closeThisModal}>
              {t('Cancel')}
            </Button>
            <Button type="primary" loading={data.isSubmitting} disabled={data.isSubmitting} onClick={() => {
              formRef.current.submit()
            }}>
              {t('Save')}
            </Button>
          </Space>
        </div>
      }
    >
      {!data.isSubmitting && data.submitError && (
        <ApiErrorResult errorData={data.submitErrorData} template="alert" status="error" style={{marginBottom:20}} />
      )}

      <Form
        ref={formRef}
        {...formItemLayout}
        layout={formLayout}
        initialValues={states}
        onValuesChange={(changedValues, allValues) => {
          setStates({ ...states, ...allValues })
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => formError(errorFields, t)}
        onFinish={() => {
          submitForm()
        }}
      >
        <FormItemLang
          label={t('Title')}
          name='title'
          required={true}
          rules={[
            {required: true, message: t('Title (%lang%) is required')},
            {max: 50, message: t('Title (%lang%) must not exceed %d% characters').replace('%d%', 50)},
          ]}
          currentLang={formLang}
          onLangChange={(value) => {setFormLang(value)}}
          onCopyLang={handleCopyLang}
        >
          <Input
            showCount
            maxLength={50}
          />
        </FormItemLang>
        <Form.Item
          label={t('Color')}
        >
          <ColorPickerPanel
            color={states.color}
            enableAlpha={false}
            onChange={(e) => setStates({ ...states, color:e.color })}
            //onClose={closeHandler}
            style={{ zIndex:999 }}
          />
        </Form.Item>
        <Form.Item
          label={t('Active')}
          name='active'
          required={true}
          extra={t('Show/Hide this sticker on all associated media.')}
        >
          <Switch
            checked={states.active}
            checkedChildren={<><CheckOutlined /> {t('Yes')}</>}
            unCheckedChildren={<><CloseOutlined /> {t('No')}</>}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
