import { useState, useEffect, useContext } from 'react';
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Typography, Space, Table, Image, Button, Dropdown, Menu, Modal, Tooltip, Tag, List, Badge, message  } from 'antd';
import { DownOutlined, DownloadOutlined, FileImageOutlined, HistoryOutlined, CopyOutlined, CheckOutlined, CloseOutlined, LockOutlined } from '@ant-design/icons';
import { APIRequest, isBadResponse, getShares } from '../../models/APIRequest';
import { Context as AuthContext } from '../../context/AuthContext';
import ApiErrorResult from '../../components/ApiErrorResult';
import { axiosError } from '../../components/Notifications';
import FileBlockList from '../../components/list/FileBlockList';
import DownloadModal from '../../components/DownloadModal';
import ShareDeleteModal from '../../components/share/ShareDeleteModal';
import TableHeader from '../../components/table/TableHeader';
import { getShortTimeFormat, getShortFormat } from '../../utils/DateUtils';
import { transformSortOrderToApi } from '../../utils/ApiUtils';
import { getTablePagerInformations } from '../../utils/TemplateUtils';

/*
*
!!!!!!!!!!!!! USE ALSO FOR ADMIN PANEL !!!!!!!!!!!!!
*
*/

export default function AccountShareScreen(props) {
  const TAG = 'AccountShareScreen'

  //Template
  const { t } = useTranslation()
  const { Text } = Typography
  const forAdmin = (props?.forAdmin === true)
  const [ updateBreacrumbs ] = useOutletContext()
  const [ modalMedia, contextHolderModalMedia ] = Modal.useModal()
  const columns = [
    (forAdmin) ? {
      title: t('User'),
      dataIndex: 'user',
      key: 'user',
      sorter: true,
      showSorterTooltip: false,
      filterMode: 'tree',
      filterSearch: true,
      render: (_, record) => record.user?.name,
    } : {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('Recipient'),
      dataIndex: 'recipient_name',
      sorter: false,
      showSorterTooltip: false,
      onFilter: (value, record) => alert(value),
      render: (_, { recipient_name, recipient_email }) => (
        <>{recipient_name}<br /><Text italic>{recipient_email}</Text></>
      ),
    },
    {
      title: t('Medias'),
      dataIndex: 'files',
      responsive: ['xl'],
      render: (_, { files }) => {
        return <Badge count={t('%d media').replace('%d', files.length)} />
      },
    },
    /*{
      title: t('Creation date'),
      dataIndex: 'date_add',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
      align:'center',
      render: (_, { date_add }) => {
        return moment(date_add).format(getShortTimeFormat(authState.language))
      },
    },*/
    {
      title: t('Expiration date'),
      dataIndex: 'date_expire',
      key: 'date_expire',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
      align:'center',
      render: (_, { date_expire }) => {
        return moment(date_expire).format(getShortFormat(authState.language))
      },
    },
    {
      title: <Tooltip title={t('Password secured?')}><LockOutlined /></Tooltip>,
      dataIndex: 'is_secure_by_password',
      key: 'is_secure_by_password',
      sorter: false,
      showSorterTooltip: false,
      align:'center',
      width:50,
      render: (_, { is_secure_by_password }) => {
        if (is_secure_by_password) {
          return <Text type="success"><CheckOutlined /></Text>
        } else {
          return <Text type="danger"><CloseOutlined /></Text>
        }
      },
    },
    {
      title: <Tooltip title={t('Can be opened multiple times?')}><HistoryOutlined /></Tooltip>,
      dataIndex: 'multiple_opening',
      key: 'multiple_opening',
      sorter: false,
      showSorterTooltip: false,
      align:'center',
      width:50,
      render: (_, { multiple_opening }) => {
        if (multiple_opening) {
          return <Text type="success"><CheckOutlined /></Text>
        } else {
          return <Text type="danger"><CloseOutlined /></Text>
        }
      },
    },
    {
      title: t('Status'),
      dataIndex: 'is_expired',
      key: 'status',
      showSorterTooltip: false,
      align:'center',
      filters: [
        {
          text: t('Expired'),
          value: 'expired',
        },
        {
          text: t('Available'),
          value: 'available',
        },
      ],
      filterMultiple: false,
      render: (_, { is_expired }) => {
        return (is_expired) ? <Tag color="error">{t('Expired')}</Tag> : <Tag color="success">{t('Available')}</Tag>
      },
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      align:'center',
      //fixed: 'right',
      render: (_, record) => (
      <>
        <Dropdown
          icon={<DownOutlined />}
          key={'actions' + record.id}
          loading={false}
          trigger={['click']}
          overlay={
            <Menu
              onClick={({ item, key }) => handleActions(key, record)}
              items={[
                (record.files.length > 0) ? {
                  label: t('Show media'),
                  icon: <FileImageOutlined />,
                  key: 'showMedia',
                } : null,
                (record.history) ? {
                  label: t('History'),
                  icon: <HistoryOutlined />,
                  key: 'history',
                } : null,
                (record.files && record.files.length) ? {
                  label: <DownloadModal widgetType="menuItem" files={record.files} />,
                  icon: <DownloadOutlined />,
                  key: 'download',
                } : null,
                (record.files && record.files.length) ? {
                  label: t('Copy share link'),
                  icon: <CopyOutlined />,
                  key: 'copyLink',
                } : null,
                {
                  type: 'divider'
                },
                {
                  label: <ShareDeleteModal
                    shares={[record]}
                    widgetType='menuItem'
                    onDeletedShare={(shares) => refreshTable()}
                  />,
                  key: 'delete'
                },
              ]}
            />
          }
        >
          <Button block>
            <Space>
              {t('Actions')}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </>
      ),
      width: 130,
    }
  ]

  //State
  const { authState } = useContext(AuthContext)
  const [ states, setStates ] = useState({
    firstLoad: true,
    isLoaded: false,
    error: false,
    errorData: null,
    shares: [],
    selectedRowKeys: [],
    selectedShares: [],
    page: 1,
    pageMax: 1,
    total: 0,
    limit: 10,
    sortColumn: null,
    sortOrder: null,
    filters: null
  })

  //States methods
  const loadShares = () => {
    console.log(TAG, 'loadShares')
    console.log(TAG, 'Getting data...')

    getShares(APIRequest(authState.language, authState.token), forAdmin, states.page, states.limit, states.sortColumn, states.sortOrder, states.filters).then(function (results) {
      if (isBadResponse(results.data)) {
        setStates({
          ...states,
          isLoaded: false,
          firstLoad: false,
          error: true,
          errorData: results.data.data
        })

        return
      }

      setStates({
        ...states,
        isLoaded: true,
        firstLoad: false,
        shares: results.data.data.shares,
        page: results.data.data.pager.page,
        pageMax: results.data.data.pager.page_max,
        total: results.data.data.pager.total,
        limit: results.data.data.pager.limit,
        error: false,
        errorData: null
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: false,
        firstLoad: false,
        error: true,
        errorData: null
      })
    })
  }

  //Events
  useEffect(() => {
    loadShares()

    if (updateBreacrumbs) {
      console.log(TAG, 'updateBreacrumbs')
      updateBreacrumbs([{title: t('Shares'), 'route': null},])
    }
  }, [])

  useEffect(() => {
    if (states.firstLoad || states.error || states.isLoaded) {
      return
    }

    loadShares()
  }, [states.isLoaded, states.error])

  //Template method
  const handleTableChange = (newPagination, filters, sorter) => {
    //Add search
    if (states.filters?.search) {
      filters['search'] = states.filters.search
    }

    setStates({
      ...states,
      selectedShares: [],
      selectedRowKeys: [],
      isLoaded: false,
      page: newPagination.current,
      limit: newPagination.pageSize,
      sortColumn: sorter?.columnKey,
      sortOrder: transformSortOrderToApi(sorter?.order),
      filters: filters,
    })
  }

  const refreshTable = () => {
    console.log(TAG, 'refreshTable')

    setStates({ ...states, isLoaded: false, selectedShares:[], selectedRowKeys:[] })
  }

  const bulkActionsMenu = () => {
    return <Menu
      items={[
        {
          label: <ShareDeleteModal
            shares={states.selectedShares}
            widgetType='menuItem'
            onDeletedShare={(shares) => refreshTable()}
          />,
          key: 'bulkDelete',
        },
      ]}
    />
  }

  const handleActions = (key, record) => {
    if (key === 'showMedia') {
      modalMedia.info({
        title: record.title,
        icon: null,
        closable: true,
        maskClosable: true,
        destroyOnClose: true,
        bodyStyle: {
          backgroundColor: '#f0f2f5'
        },
        width: 'calc(100% - 20%)',
        zIndex: 90,
        okText: t('Close'),
        okButtonProps: { style: { display: 'none' } },
        content: (
          <List
            grid={{
              gutter: [6, 6],
              xs: 1,
              sm: 1,
              md: 1,
              lg: 2,
              xl: 3,
              xxl: 4,
            }}
            dataSource={record.files}
            rowKey={item => item.id + item.date_upd}
            renderItem={item => (
              <List.Item style={{ marginBottom:0 }}>
                <FileBlockList
                  file={item}
                  readOnly={true}
                  canAddBasket={false}
                  canShare={false}
                />
              </List.Item>
            )}
          />
        ),
      })
    } else if (key === 'history') {
      Modal.info({
        icon: null,
        title: t('Share history'),
        content: (
          <List
            bordered
            dataSource={record.history}
            renderItem={item => (
              <List.Item>
                <Text>
                  <Text strong>[{moment(item.date).format(getShortTimeFormat(authState.language))}] {item.action_label}</Text>
                  {item.user && (
                    <> ({t('By %s').replace('%s', item.user.name)})</>
                  )}
                </Text>
                {item.opener_ip && (
                  <Text>{t('User IP: %s').replace('%s', item.opener_ip)}</Text>
                )}
                
              </List.Item>
            )}
          />
        ),
        okText: t('Close'),
        width: 'calc(100% - 30%)'
      })
    } else if (key === 'copyLink') {
      if (navigator.clipboard.writeText(record.link)) {
        message.success(t('Share link copied to clipboard.'));
      }
    } else {
      console.log(TAG, 'Unknow action (' + key + ')...')
    }
  }

  if (states.error) {
    return (
      <ApiErrorResult errorData={states.errorData} actionTitle={t('Try to reload')} actionHandle={() => {
        setStates({ ...states, error:false, errorData: null, isLoaded:false })
      }} />
    )
  }

  return (
    <main className='admin-container'>
      <TableHeader
        title={t('Shares')}
        contentStyle={{ display:'flex', alignItems:'start' }}
        showSearch={true}
        onSearchChange={value => {
          if (!value) {
            return
          }

          setStates({
            ...states,
            filters: {
              ...states.filters,
              search: value
            }
          })
        }}
        onSearchSubmit={() => {
          setStates({
            ...states,
            isLoaded: false,
            page: 1,
          })
        }}
        onSearchClear={() => {
          setStates({
            ...states,
            isLoaded: false,
            page: 1,
            filters: {
              ...states.filters,
              search: null
            }
          })
        }}
      >
        <Dropdown trigger='click' overlay={bulkActionsMenu} disabled={states.selectedShares.length === 0}>
          <Badge count={states.selectedShares.length} overflowCount={999}>
            <Button disabled={states.selectedShares.length === 0}>
              <Space>
                {t('Bulk actions')}
                
                <DownOutlined />
              </Space>
            </Button>
          </Badge>
        </Dropdown>
      </TableHeader>

      <Table
        columns={columns}
        size='small'
        rowKey={(item) => item.id}
        dataSource={states.shares}
        pagination={{
          current: states.page,
          pageSize: states.limit,
          total: states.total,
          disabled: (!states.isLoaded),
          showTotal: (total) => getTablePagerInformations(t, states.page, states.limit, states.total),
          showSizeChanger: true,
          size: 'default',
        }}
        rowSelection={{
          selectedRowKeys: states.selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            setStates({
              ...states,
              selectedRowKeys: selectedRowKeys,
              selectedShares: selectedRows
            })
          }
        }}
        loading={!states.isLoaded}
        onChange={handleTableChange}
        scroll={{ x: 680 }}
      />

      {contextHolderModalMedia}
    </main>
  )
}