import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Grid, BackTop } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import HeaderLogo from "../../components/HeaderLogo";
import TemplateFooter from "../../components/template/TemplateFooter";
import LogedMenu from "../../components/LogedMenu";
import HeaderLogin from "../../components/HeaderLogin";
import { getStorageData } from '../../utils/StorageUtils';
import { FOLDER_ROOT_ID } from '../../Constant';

function AccountLayoutScreen(props) {
  const TAG = 'AccountLayoutScreen'

  //Route
  const navigate = useNavigate()

  //Template
  const { t } = useTranslation()
  const { Header, Content, Sider } = Layout
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()
  const defaultBreadcrumbs = [
    {title: t('Home'), 'route': '/'},
    {title: t('Account'), 'route': '/account'}
  ]

  //States
  const [ breadcrumbs, setBreadcrumbs ] = useState(defaultBreadcrumbs)

  //Template methods
  const updateBreacrumbs = (breadcrumbs) => {
    if (!breadcrumbs) {
      return
    }

    setBreadcrumbs(defaultBreadcrumbs.concat(breadcrumbs.reverse()))
  }

  const backToMedia = () => {
    const idFolder = getStorageData('MediaLastFolderId')

    navigate('/media/explore/' + ((idFolder) ? idFolder : FOLDER_ROOT_ID))
  }

  return (
    <Layout style={{minHeight:'100vh'}}>
      <Header style={(screens.xs) ? { height:'auto', paddingLeft:20, paddingRight:20 } : null}>
        <HeaderLogo />
        <div style={{float:'right', height:'100%', display:'flex', marginLeft:20, alignItems:'center'}}>
          <HeaderLogin />
        </div>
        <Menu theme="dark" mode="horizontal">
          <Menu.Item key="medias" onClick={backToMedia}><ArrowLeftOutlined /> {t('Back to media library')}</Menu.Item>
        </Menu>
      </Header>
      <Content style={{ padding:'0 24px 24px' }}>
        <Breadcrumb style={{ margin:'16px auto' }}>
          {breadcrumbs.map((item) => (
            <Breadcrumb.Item key={"Breadcrumb" + item.route}>
            {item.route ? (
              <Link to={item.route}>{item.title}</Link>
            ) : (
              <span>{item.title}</span>
            )}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <Layout className="site-content-background">
          <Sider
            theme="light"
            className="site-menu-in-content-collapsible"
            collapsible={true}
            breakpoint={['lg']}
          >
            <LogedMenu theme="darks" mode="inline" />
          </Sider>
          <Content>
            <Outlet context={[updateBreacrumbs]} />
          </Content>
        </Layout>
      </Content>
      <TemplateFooter />
      <BackTop style={{ right:40, bottom:40 }} />
    </Layout>
  )
}

export default AccountLayoutScreen
