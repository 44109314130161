import { memo } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Grid } from 'antd';

const HeaderLogo = (props) => {
  //Template
  const { t } = useTranslation()
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()

  //Route
  const navigate = useNavigate()

  //Method
  const getContainerStyles = () => {
    if (!screens.sm) {
      return styles.xs
    } else if (!screens.lg) {
      return styles.sm
    } else {
      return styles.md
    }
  }

  return (
    <div style={getContainerStyles()} onClick={() => navigate('/')}>
      <img
        src="/asset/system/sodikart-logo-white.png"
        style={{ maxWidth:200, width:'100%' }}
        alt={t('Logo SODI')}
      />
    </div>
  )
}

export default memo(HeaderLogo)

const styles = {
  md: { float:'left', height:'100%', width:280, cursor:'pointer', textAlign:'center' },
  sm: { float:'left', height:'100%', cursor:'pointer', padding:'0 25px', textAlign:'center' },
  xs: { height:64, width:'100%', cursor:'pointer', padding:'0 25px', textAlign:'center' }
}