import { useContext, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Modal, Form, Input, Typography, Alert, Avatar, Dropdown } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Context as AuthContext } from '../context/AuthContext';
import { APIRequest, isBadResponse, login, lostPassword } from '../models/APIRequest';
import { axiosError } from './Notifications';
import LogedMenu from './LogedMenu';

export default function HeaderLogin() {
  const TAG = 'HeaderLogin'

  //Template
  const { t } = useTranslation()
  const { Paragraph } = Typography
  const [ formLogin ] = Form.useForm()
  const [ formLostPassword ] = Form.useForm()
  
  //States
  const DEFAULT_STATES = {
    isModalVisible: false,
    forgotPassword: false,
    loginSubmition: false,
    loginSubmitionFail: false,
    lostPasswordSubmition: false,
    lostPasswordSubmitionFail: false,
    lostPasswordSuccess: false
  }
  const { authState, signin } = useContext(AuthContext)
  const [ states, setStates ] = useState(DEFAULT_STATES)

  //Methods template
  const showModal = () => {
    setStates({...states, isModalVisible: true})
  }

  const hideModal = () => {
    formLogin.resetFields()
    formLostPassword.resetFields()

    setStates(DEFAULT_STATES)
  }

  const onLoginSubmitSuccess = (values) => {
    setStates({...states, loginSubmition: true})

    login(APIRequest(authState.language), values.email, values.password).then(response => {
      if (isBadResponse(response.data)) {
        setStates({...states, loginSubmition: false, loginSubmitionFail: true})

        return
      }

      signin({
        token: response.data.data.token,
        data: response.data.data.session_data
      })
      
      setStates({...states, loginSubmition: false})
      hideModal()
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({...states, loginSubmition: false})
    })
  }

  const onLostPasswordSubmitSuccess = (values) => {
    setStates({...states, lostPasswordSubmition: true})

    lostPassword(APIRequest(authState.language), values.email).then(response => {
      if (isBadResponse(response.data)) {
        axiosError(t)
        setStates({...states, lostPasswordSubmition: false, lostPasswordSubmitionFail:true, lostPasswordSuccess: false })

        return
      }

      setStates({ ...states, lostPasswordSuccess: true })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({ ...states, loginSubmition: false, lostPasswordSubmitionFail:false, lostPasswordSuccess: false })
    })
  }

  const getModalFooter = () => {
    if (states.forgotPassword) {
      return [
        <Button type="link" key="loginBtnBackLogin" onClick={() => {
          setStates({...states, forgotPassword: false})
        }}>
          {t('Back to login')}
        </Button>,
        <Button type="primary" key="loginBtnChange" loading={states.lostPasswordSubmition} disabled={states.lostPasswordSuccess} onClick={() => {
          formLostPassword.submit()
        }}>
          {t('Change my password')}
        </Button>,
      ]
    }

    return [
      <Button type="link" key="loginBtnForget" onClick={() => {
        setStates({...states, forgotPassword: true})
      }}>
        {t('Forgot password')}
      </Button>,
      <Button type="primary" key="loginBtnLogin" loading={states.loginSubmition} onClick={() => {
        formLogin.submit()
      }}>
        {t('Log in')}
      </Button>,
    ]
  }

  if (authState.isLogged) {
    return (
      <div style={{ display:'flex', minHeight:64, alignItems:'center' }}>
        <Dropdown overlay={<LogedMenu />} trigger={['click']}>
          <Avatar
            src={(authState.data?.avatar) ? authState.data.avatar : null}
            icon={(!authState.data?.avatar) ? <UserOutlined /> : null}
            style={{ cursor:'pointer' }}
            size={42}
          />
        </Dropdown>
      </div>
    )
  }

  return (
    <>
      <Button type="primary" size={'large'} onClick={showModal}>
        {t('Login')}
      </Button>
      <Modal
        title={t('Login')}
        open={states.isModalVisible}
        onCancel={hideModal}
        footer={getModalFooter()}
      >
        {states.forgotPassword === false && (
          <Form
            name="basic"
            onFinish={onLoginSubmitSuccess}
            autoComplete="off"
            form={formLogin}
          >
            <Paragraph style={{ textAlign:'center' }}>
              {t('Use your SODIKART CONNECT login.')}
            </Paragraph>
            {states.loginSubmitionFail && (
              <Alert
                message={t('Oupsss')}
                description={t('Username or password is incorrect.')}
                type="error"
                closable
                afterClose={() => {
                  setStates({ ...states, loginSubmitionFail: false })
                }}
                style={{ marginBottom: 20 }}
              />
            )}
            <Form.Item
              name="email"
              rules={[
                { required: true, message: t('Please enter your email') },
                { type: 'email', message: t('Please enter a valid email') },
              ]}
            >
              <Input
                prefix={<UserOutlined style={styles.inputIcon} />}
                placeholder={t('Email')}
                type="email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: t('Please enter your password') }]}
            >
              <Input.Password
                prefix={<LockOutlined style={styles.inputIcon} />}
                type="password"
                placeholder={t('Password')}
                onPressEnter={() => {
                  formLogin.validateFields()
                    .then((values) => {
                      onLoginSubmitSuccess(values)
                    });
                }}
              />
            </Form.Item>
          </Form>
        )}

        {(states.forgotPassword === true && !states.lostPasswordSuccess) && (
          <Form
            name="lostPassword"
            onFinish={onLostPasswordSubmitSuccess}
            autoComplete="off"
            form={formLostPassword}
          >
            <Alert
              description={t('Enter your SODIKART CONNECT account email.')}
              type="info"
              showIcon
              style={{ marginBottom:25 }}
            />
            {states.lostPasswordSubmitionFail && (
              <Alert
                message={t('Oupsss')}
                description={t('Unknown username.')}
                type="error"
                closable
                afterClose={() => {
                  setStates({ ...states, lostPasswordSubmitionFail: false })
                }}
                style={{ marginBottom: 20 }}
              />
            )}
            <Form.Item
              name="email"
              rules={[{ required: true, message: t('Please enter your email') }]}
            >
              <Input
                prefix={<UserOutlined style={styles.inputIcon} />}
                placeholder={t('Email')}
                type="email"
              />
            </Form.Item>
          </Form>
        )}

        {(states.forgotPassword === true && states.lostPasswordSuccess) && (
          <Alert
            message={t('Forgot password')}
            description={t('A new password has been sent to your mailbox.')}
            type="success"
            showIcon
            style={{ marginBottom:25 }}
          />
        )}
      </Modal>
    </>
  )
}

const styles = {
  inputIcon: {
    color:'#CCC'
  }
}