import { useState, useEffect, useContext, memo } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Badge, List, Drawer, Space, Typography, Popconfirm, Empty, } from 'antd';
import { ShoppingCartOutlined, CloseOutlined, RestOutlined, SaveOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from "react-router-dom";
import { APIRequest, isBadResponse, getFiles } from '../models/APIRequest';
import { axiosError } from './Notifications';
import { Context as AuthContext } from '../context/AuthContext';
import DownloadModal from './DownloadModal';
import ShareModal from './ShareModal';
import SaveCartModal from './SaveCartModal';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { isUser } from '../utils/AccessRightUtils';

const Basket = () => {
  const TAG = "Basket"

  //Template
  const { t } = useTranslation()
  const { Text, Paragraph } = Typography
  const { authState, updateCart } = useContext(AuthContext)
  const location = useLocation()
  const navigate = useNavigate()
  const { windowWidth, windowHeight } = useWindowDimensions()

  //States
  const [states, setStates] = useState({
    isLoaded: false,
    error: false,
    files: [],
    drawerVisible: false,
    saveCartModalVisible: false
  })

  //Hooks
  useEffect(() => {
    if (states.isLoaded) {
      return
    }

    console.log(TAG, '[states.isLoaded]')

    loadData()
  }, [states.isLoaded])

  useEffect(() => {
    if (!states.isLoaded) {
      return
    }

    console.log(TAG, '[authState.language]')

    setStates({...states, isLoaded: false})
  }, [authState.language])

  useEffect(() => {
    if (!states.drawerVisible) {
      return
    }

    console.log(TAG, '[states.drawerVisible]')

    setStates({...states, isLoaded: false})
  }, [states.drawerVisible])

  //Hooks methods
  const loadData = () => {
    console.log(TAG, 'LoadData')

    if (states.isLoaded) {
      return
    }

    if (!authState?.cart || !authState.cart.length) {
      setStates({
        ...states,
        isLoaded: true,
        files: [],
        error: false
      })

      return
    }

    console.log(TAG, 'Getting data...')
    
    getFiles(APIRequest(authState.language, authState.token), null, null, null, authState.cart.join(','), 1, authState.cart.length).then((results) => {
      if (isBadResponse(results.data)) {
        setStates({
          ...states,
          isLoaded: true,
          files: [],
          error: true
        })

        axiosError(t)
        return
      }

      setStates({
        ...states,
        isLoaded: true,
        files: (results.data.data.files) ? results.data.data.files : [],
        error: false
      })

      if (results.data.data.files) {
        const cartItems = []

        results.data.data.files.forEach(file => {
          cartItems.push(file.id)
        })

        updateCart(cartItems)
      }
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: true,
        files: [],
        error: true
      })
    })
  }

  //Template methods
  const showDrawer = () => {
    setStates({...states, drawerVisible: true})
  }

  const hideDrawer = () => {
    setStates({...states, drawerVisible: false})
  }

  const clearCart = () => {
    updateCart([])

    setStates({ ...states, files:[]})
  }

  const deleteItem = (id) => {
    if (authState?.cart) {
      updateCart(authState.cart.filter((itemId) => {
        return itemId !== id
      }))
    }

    setStates({ ...states, isLoaded:false})
  }

  const handleFileClick = (file) => {
    navigate(location.pathname + '/basket/file/' + file.id)
  }

  return (
    <>
      <Badge count={authState.cart.length} offset={[-40, 15]} color="#ff5220" overflowCount={99}>
        <Button size="large" icon={<ShoppingCartOutlined />} onClick={showDrawer} />
      </Badge>
      <Drawer
        width={(windowWidth > 400) ? 400 : windowWidth - 15}
        zIndex={99}
        title={(
          <>
            <Popconfirm
              title={t('Are you sure to delete this media?')}
              onConfirm={() => clearCart()}
              okText={t('Yes')}
              cancelText={t('No')}
              placement="bottomRight"
            >
              <Button size="small" style={{float:'right'}} icon={<RestOutlined />}>
                {t('Clear the basket')}
              </Button>
            </Popconfirm>
            <Space direction="vertical" size={0} style={{ display: 'flex' }}>
              <Text>{t('Basket')}</Text>
              <Text type="secondary">{t('%d media(s)').replace('%d', states.files.length)}</Text>
            </Space>
          </>
        )}
        footer={(
          <Space direction="vertical" size="small" style={{ display: 'flex' }}>
            <DownloadModal widgetType="button" widgetProps={{ type:'primary', block:true, size:'large' }} files={states.files} />
            <ShareModal widgetType="button" widgetProps={{ type:'default', block:true, size:'large' }} files={states.files} />
            
            {isUser(authState) && (
              <>
                <Button icon={<SaveOutlined />} block disabled={!states.files.length} onClick={() => setStates({...states, saveCartModalVisible: true})}>
                  {t('Save this basket')}
                </Button>
        
                <SaveCartModal
                  files={states.files}
                  visible={states.saveCartModalVisible}
                  onHide={() => setStates({...states, saveCartModalVisible: false})}
                />
              </>
            )}
          </Space>
        )}
        bodyStyle={{padding:'0 10px'}}
        footerStyle={{textAlign:'center'}}
        placement="right"
        onClose={hideDrawer}
        open={states.drawerVisible}
      >
        <List
          itemLayout="vertical"
          dataSource={states.files}
          loading={!states.isLoaded}
          locale={{emptyText: <Empty description={t('No media')} image={Empty.PRESENTED_IMAGE_SIMPLE} />}}
          renderItem={item => (
            <List.Item>
              <div style={{ display:'flex', alignItems:'flex-start'}}>
                <div style={{ width:60, textAlign:'center', border:'1px solid #d1d1d1', boxSizing:'unset' }}>
                  <img src={item.thumb} alt={item.title} style={{ width:60, cursor:'pointer' }} onClick={() => handleFileClick(item)} />
                </div>
                <Paragraph ellipsis={{ rows:2 }} style={{ flexGrow:1, padding:'0 10px' }}>{item.title}</Paragraph>
                <Popconfirm
                  title={t('Are you sure to delete this media?')}
                  onConfirm={() => deleteItem(item.id)}
                  okText={t('Yes')}
                  cancelText={t('No')}
                  placement="left"
                >
                  <Button size="small" icon={<CloseOutlined />} />
                </Popconfirm>
              </div>
            </List.Item>
           )}
        />
      </Drawer>
    </>
  )
}

export default memo(Basket)
