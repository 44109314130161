import { useEffect, useState, useContext, createRef } from 'react';
import { useTranslation } from "react-i18next";
import { Grid, Modal, Form, Input, Switch, Segmented, Button, Space, Alert } from 'antd';
import { ModalContext } from "../../context/ModalContext";
import { Context as AuthContext } from '../../context/AuthContext';
import { APIRequest, isBadResponse, updateBroadcastRestriction, addBroadcastRestriction } from '../../models/APIRequest';
import { CheckOutlined, CloseOutlined, StopOutlined } from '@ant-design/icons';
import { axiosError, formError } from '../Notifications';
import ApiErrorResult from '../ApiErrorResult';
import { isSuperAdmin } from '../../utils/AccessRightUtils';

import { GLOBAL_MODAL_TYPES } from "../../Constant";

export default function BroadcastRestrictionModal() {
  const TAG = 'BroadcastRestrictionModal'

  //Template
  const { t } = useTranslation()
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()
  const formRef = createRef()

  //States
  const DEFAULT_STATES = {
    code: null,
    name: null,
    can_share: null,
    can_download: null,
    dl_watermark: null,
    protected: false,
  }
  const { modalType, showModal, modalProps, closeModal, modalOnOk } = useContext(ModalContext)
  const isEditing = (modalProps?.id && modalProps?.broadcast)
  const { authState } = useContext(AuthContext)
  const [ formLayout, setFormLayout ] = useState('horizontal')
  const [ states, setStates ] = useState(DEFAULT_STATES)
  const [data, setData] = useState({
    isSubmitting: false,
    submitError: false,
    submitErrorData: null,
  })
  const superAdmin = isSuperAdmin(authState)

  //Hooks
  useEffect(() => {
    if (modalType !== GLOBAL_MODAL_TYPES.BROADCAST_RESTRICTION || !showModal) {
      return
    }

    if (isEditing) {
      setStates({
        code: modalProps.broadcast.code,
        name: modalProps.broadcast.name,
        can_share: modalProps.broadcast.can_share,
        can_download: modalProps.broadcast.can_download,
        dl_watermark: modalProps.broadcast.dl_watermark,
        protected: modalProps.broadcast.protected,
      })
    } else {
      setStates(DEFAULT_STATES)
    }
  }, [modalProps])

  useEffect(() => {
    setFormLayout((!screens.xl) ? 'vertical' : 'horizontal')
  }, [screens])

  const transformSegmentedValueForApi = (value) => {
    if (value === null) {
      return -1
    } else if (value === true) {
      return 1
    } else {
      return 0
    }
  }

  const submitForm = () => {
    console.log(TAG, 'submitForm')

    //Loading
    setData({...data,
      isSubmitting: true
    })

    //Make formData
    const formDatas = {
      code: states.code,
      name: states.name,
      can_share: transformSegmentedValueForApi(states.can_share),
      can_download: transformSegmentedValueForApi(states.can_download),
      dl_watermark: transformSegmentedValueForApi(states.dl_watermark),
      protected: states.protected,
    }

    //Method
    const request = (isEditing)
      ? updateBroadcastRestriction(APIRequest(authState.language, authState.token, null, 0), modalProps.id, formDatas)
      : addBroadcastRestriction(APIRequest(authState.language, authState.token, null, 0), formDatas)

    request.then(response => {
      if (isBadResponse(response.data)) {
        setData({
          ...data,
          isSubmitting: false,
          submitError: true,
          submitErrorData: {
            code: response.data?.data?.code,
            message: response.data?.data?.message,
            extraData: response.data?.data?.extra_data
          }
        })

        return
      }

      resetModal()
      modalOnOk(response.data.data.broadcast_restriction, true)
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setData({
        ...data,
        isSubmitting: false,
        submitError: true,
        submitErrorData: {
          code: null,
          message: thrown
        }
      })
    })
  }

  const resetModal = () => {
    setData({
      ...data,
      isSubmitting: false,
      submitError: false,
      submitErrorData: null,
    })
    setStates(DEFAULT_STATES)
  }

  const closeThisModal = () => {
    resetModal()
    closeModal()
  }

  const formItemLayout = 
    (formLayout === 'horizontal')
      ? {labelCol:{span: 6}, wrapperCol:{span: 12}}
      : null

  return (
    <Modal
      title={(isEditing) ? modalProps.broadcast.name : t('Add broadcast restrictions')}
      open={modalType !== GLOBAL_MODAL_TYPES.BROADCAST_RESTRICTION ? false : showModal}
      closable={!states.isSubmitting}
      zIndex={120}
      width={(!screens.md) ? 'calc(100% - 32px)' : 'calc(100% - 20%)'}
      destroyOnClose={true}
      onCancel={() => {
        closeThisModal()
      }}
      footer={
        <div style={{textAlign:'center'}}>
          <Space>
            <Button onClick={closeThisModal}>
              {t('Cancel')}
            </Button>
            <Button type="primary" loading={data.isSubmitting} disabled={data.isSubmitting} onClick={() => {
              formRef.current.submit()
            }}>
              {t('Save')}
            </Button>
          </Space>
        </div>
      }
    >
      {!data.isSubmitting && data.submitError && (
        <ApiErrorResult errorData={data.submitErrorData} template="alert" status="error" style={{marginBottom:20}} />
      )}

      <Form
        ref={formRef}
        {...formItemLayout}
        layout={formLayout}
        initialValues={states}
        onValuesChange={(changedValues, allValues) => {
          setStates({ ...states, ...allValues })
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => formError(errorFields, t)}
        onFinish={() => {
          submitForm()
        }}
      >
        <Form.Item
          label={t('Code')}
          name='code'
          rules={[
            { required: true, message: t('Please enter a code') },
            { max: 20, message: t('Code must not exceed %d% characters').replace('%d%', 20) },
          ]}
          disabled={!superAdmin && states.protected}
        >
          <Input
            showCount
            maxLength={20}
          />
        </Form.Item>
        <Form.Item
          label={t('Name')}
          name='name'
          rules={[
            { required: true, message: t('Please enter a name') },
            { max: 50, message: t('Name must not exceed %d% characters').replace('%d%', 50) },
          ]}
          help={isEditing && (
            <Alert
              type='info'
              showIcon
              message={t('Be careful')}
              description={
                <>
                  {t('When you toggle an option to "not available", all media specific broadcast restrictions will also be affected.')}
                </>
              }
              style={{ margin:'20px 0'}}
            />
          )}
        >
          <Input
            showCount
            maxLength={50}
          />
        </Form.Item>
        <Form.Item
          label={t('Can be share?')}
          name='can_share'
          required={true}
        >
          <Segmented
            disabled={!superAdmin && states.can_share === null && states.protected}
            options={[
              {
                label: t('Not available'),
                value: null,
                icon: <StopOutlined />,
              },
              {
                label: t('No'),
                value: false,
                icon: <CloseOutlined />,
              },
              {
                label: t('Yes'),
                value: true,
                icon: <CheckOutlined />,
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label={t('Can be downloaded?')}
          name='can_download'
          required={true}
        >
          <Segmented
            disabled={!superAdmin && states.can_download === null && states.protected}
            options={[
              {
                label: t('Not available'),
                value: null,
                icon: <StopOutlined />,
              },
              {
                label: t('No'),
                value: false,
                icon: <CloseOutlined />,
              },
              {
                label: t('Yes'),
                value: true,
                icon: <CheckOutlined />,
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label={t('Downloaded with watermark?')}
          name='dl_watermark'
          required={true}
        >
          <Segmented
            disabled={!superAdmin && states.dl_watermark === null && states.protected}
            options={[
              {
                label: t('Not available'),
                value: null,
                icon: <StopOutlined />,
              },
              {
                label: t('No'),
                value: false,
                icon: <CloseOutlined />,
              },
              {
                label: t('Yes'),
                value: true,
                icon: <CheckOutlined />,
              },
            ]}
          />
        </Form.Item>
        {superAdmin && (
          <Form.Item
            label={t('Protected')}
            name='protected'
            required={true}
            extra={t('Can be edited only by super administrator.')}
          >
            <Switch
              checked={states.protected}
              checkedChildren={<><CheckOutlined /> {t('Yes')}</>}
              unCheckedChildren={<><CloseOutlined /> {t('No')}</>}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}
