import { memo, useEffect, useState, useContext, createRef } from 'react';
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Grid,
  Modal,
  Tabs,
  Form,
  Input,
  Radio,
  Checkbox,
  Select,
  Switch,
  DatePicker,
  AutoComplete,
  Button,
  Popconfirm,
  Space,
  Alert,
  Progress,
  Typography,
  Table,
  Descriptions,
  Row,
  Col,
  Upload, Divider, notification
} from 'antd';
import { ModalContext } from "../../context/ModalContext";
import { Context as AuthContext } from '../../context/AuthContext';
import {
  APIRequest,
  isBadResponse,
  getAccessRights,
  getStickers,
  getTagAc,
  getAuthorAc,
  getLocationAc,
  getCountries,
  getWatermarks,
  getAllBroadcastRestrictions,
  updateFile,
  regenerateThumbFile,
  delFileThumb,
  uploadFile,
  updateSourceFile
} from '../../models/APIRequest';
import { axiosError, objectError, formError } from '../Notifications';
import {
  CheckOutlined,
  CloseOutlined,
  SyncOutlined,
  RollbackOutlined,
  EditOutlined,
  StopOutlined,
  UploadOutlined, FileSyncOutlined
} from '@ant-design/icons';
import LoadingBlock from '../LoadingBlock';
import ApiErrorResult from '../ApiErrorResult';
import FileTreeSelect from '../form/FileTreeSelect';
import FormItemLang from '../form/FormItemLang';
import ImageUploader from '../form/ImageUploader';
import {getShortFormat} from '../../utils/DateUtils';
import {convertFileSizeToHuman, getBase64, getFileType, getMimeFileType} from '../../utils/FileUtils';
import { transformApiLangToFormLang, transformFormLangToFormData, transformObjectToFormData, transformApiObjectToFormData } from '../../utils/ApiUtils';
import {
  GLOBAL_MODAL_TYPES,
  FILE_STATUS_DRAFT, FILE_STATUS_ONLINE, FILE_STATUS_ARCHIVE, FILE_TYPE_IMAGE,
  LANGUAGE_DEFAULT_FORM_VALUE, LANGUAGE_DEFAULT_FORM_MULTIPLE_VALUE,
  THUMB_FILE_ACCEPT_MIME, THUMB_FILE_SIZE_LIMIT, UPLOAD_CHUNK_SIZE, DEFAULT_UPLOAD_PROGRESS_DATA
} from "../../Constant";
import { v4 as uuidv4 } from 'uuid';
import {getIconFromReactIcons} from "../../utils/TemplateUtils";
import {green} from "@ant-design/colors";

const MediaEditModal = () => {
  const TAG = 'MediaEditModal'

  //Template
  const { t } = useTranslation()
  const { TextArea } = Input
  const { Option } = Select
  const { TabPane } = Tabs
  const { useBreakpoint } = Grid
  const { Text, Title } = Typography
  const screens = useBreakpoint()
  const formRef = createRef()

  //States
  const DEFAULT_STATES = {
    title: LANGUAGE_DEFAULT_FORM_VALUE,
    description: LANGUAGE_DEFAULT_FORM_VALUE,
    youtube_code: null,
    keywords: LANGUAGE_DEFAULT_FORM_MULTIPLE_VALUE,
    id_country: null,
    media_date: '',
    media_author: '',
    media_location: '',
    thumb: null,
    status: FILE_STATUS_DRAFT,
    access_rights: [],
    broadcasts: null,
    stickers: [],
    id_folder: null,
    folders: [],
    id_watermark: null,
    terms_of_use: LANGUAGE_DEFAULT_FORM_VALUE,
  }
  const DEFAULT_DATA = {
    isLoaded: false,
    isInit: false,
    isSubmitting: false,
    isRegenerateThumb: false,
    submitError: false,
    submitErrorData: null,
    submitBulkFilesProcessed: 0,
    uploadedThumb: null,
    uploadedThumbImage: null,
    uploadedFile: [],
    isUploading: false,
    uploadingError: false,
    uploadingErrorData: null,

    file_type: null,
    media_width: null,
    media_height: null,
    media_duration: null,
    filesize: 0,

    folders: [],
    accessRights: [],
    accessRightsAllChecked: false,
    watermarks: [],
    stickers: [],
    broadcasts: [],
    broadcastsWantEdit: false,
    broadcastsCheckedAll: {},
    broadcastsIndeterminate: {},
  }
  const { modalType, showModal, modalProps, closeModal, modalOnOk } = useContext(ModalContext)
  const { authState } = useContext(AuthContext)
  const [ formLang, setFormLang ] = useState(authState.language)
  const [ formLayout, setFormLayout ] = useState('horizontal')
  const [ states, setStates ] = useState(DEFAULT_STATES)
  const [ data, setData ] = useState(DEFAULT_DATA)
  const [ uploadProgress, setUploadProgress ] = useState(DEFAULT_UPLOAD_PROGRESS_DATA)
  const id = modalProps?.id
  const file = modalProps?.file
  const files = modalProps?.files
  const bulkEdit = (files && files.length)
  const formItemLayout = (formLayout === 'horizontal')
      ? {labelCol:{span: 6}, wrapperCol:{span: 12}}
      : null

  //Hooks
  useEffect(() => {
    if (modalType !== GLOBAL_MODAL_TYPES.FILE_EDIT || !showModal || !data.isLoaded || data.isInit) {
      return
    } else if (!id || !file) {
      console.log(TAG, 'Bad modalProps (id or file)...')
      return
    }

    setFileStates(modalProps.file)
  }, [modalProps, data.isLoaded])

  useEffect(() => {
    if (modalType !== GLOBAL_MODAL_TYPES.FILE_EDIT || !showModal) {
      return
    } else if (showModal && (!id || !file)) {
      objectError(t)
      closeModal()
      return
    }

    loadData()
  }, [showModal, data.isLoaded])

  useEffect(() => {
    setFormLayout((!screens.xl) ? 'vertical' : 'horizontal')
  }, [screens])

  //Template methods
  const handleCopyLang = (name, currentLang, languages) => {
    const value = {}

    languages.forEach(lang => {
      value[lang.route] = states[name][currentLang]
    })

    const newStates = {...states, [name]: value}

    formRef.current.setFieldsValue(newStates)
    setStates(newStates)
  }
  
  //States methods
  const loadData = () => {
    console.log(TAG, 'loadData')

    if ( ! showModal || data.isLoaded) {
      return
    }

    console.log(TAG, 'Getting data...')

    Promise.all([
      getAccessRights(APIRequest(authState.language, authState.token)),
      getStickers(APIRequest(authState.language, authState.token)),
      getTagAc(APIRequest(authState.language, authState.token)),
      getAuthorAc(APIRequest(authState.language, authState.token)),
      getCountries(APIRequest(authState.language, authState.token)),
      getLocationAc(APIRequest(authState.language, authState.token)),
      getWatermarks(APIRequest(authState.language, authState.token)),
      getAllBroadcastRestrictions(APIRequest(authState.language, authState.token)),
    ])
    .then(function (results) {
      const accessRights = results[0]
      const accessRightsOption = []
      const stickers = results[1]
      const stickersOption = []
      const tags = results[2]
      const tagsOption = []
      const authors = results[3]
      const authorsOption = []
      const countries = results[4]
      const countriesOption = []
      const locations = results[5]
      const locationsOption = []
      const watermarks = results[6]
      const watermarksOption = []
      const broadcasts = results[7]
      const broadcastsOption = []

      //accessRights
      if (!isBadResponse(accessRights.data, false)) {
        accessRights.data.data.access_rights.forEach((item) => {
          accessRightsOption.push(item)
        })
      } else {
        axiosError(t)
        return
      }

      //accessRights
      if (!isBadResponse(stickers.data, false)) {
        stickers.data.data.stickers.forEach((item) => {
          stickersOption.push(item)
        })
      } else {
        axiosError(t)
        return
      }

      //Tags
      if (!isBadResponse(tags.data, false)) {
        tags.data.data.keywords.forEach((item) => {
          tagsOption.push(item)
        })
      } else {
        axiosError(t)
        return
      }

      //Authors
      if (!isBadResponse(authors.data, false)) {
        authors.data.data.authors.forEach((item) => {
          authorsOption.push({value: item, label: item})
        })
      } else {
        axiosError(t)
        return
      }

      //Countries
      if (!isBadResponse(countries.data, false)) {
        countries.data.data.countries.forEach((item) => {
          countriesOption.push(item)
        })

      } else {
        axiosError(t)
        return
      }

      //Locations
      if (!isBadResponse(locations.data, false)) {
        locations.data.data.locations.forEach((item) => {
          locationsOption.push({value: item, label: item})
        })
        
      } else {
        axiosError(t)
        return
      }

      //Watermarks
      if (!isBadResponse(watermarks.data, false)) {
        watermarks.data.data.watermarks.forEach((item) => {
          watermarksOption.push(item)
        })
        
      } else {
        axiosError(t)
        return
      }

      //Watermarks
      if (!isBadResponse(broadcasts.data, false)) {
        broadcasts.data.data.broadcast_restrictions.forEach((item) => {
          broadcastsOption.push(item)
        })
        
      } else {
        axiosError(t)
        return
      }

      setData({
        ...data,
        isLoaded: true,

        file_type: file.file_type,
        media_width: file.media_width,
        media_height: file.media_height,
        media_duration: file.media_duration,
        filesize: file.filesize,

        accessRights: accessRightsOption,
        stickers: stickersOption,
        tags: tagsOption,
        authors: authorsOption,
        countries: countriesOption,
        locations: locationsOption,
        watermarks: watermarksOption,
        broadcasts: broadcastsOption
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)
    })
  }

  //Event method
  const setFileStates = (file) => {
    //Broadcasts
    let broadcasts = null
    let broadcastsCheckedAll = {}
    let broadcastsIndeterminate = {}

    const haveWatermark = (file.id_watermark !== null)
    const broadcastsDefaultStates = getBroadcastsDefaultStates(haveWatermark, file.broadcasts)
    broadcasts = broadcastsDefaultStates.defaultStates
    broadcastsCheckedAll = broadcastsDefaultStates.checkedAll
    broadcastsIndeterminate = broadcastsDefaultStates.indeterminate

    const newStates = {
      ...DEFAULT_STATES,
      title: transformApiLangToFormLang(file.langs.title),
      description: transformApiLangToFormLang(file.langs.description),
      youtube_code: file.youtube_code,
      keywords: transformApiLangToFormLang(file.langs.keywords, true),
      id_country: (file?.country?.id) ? file.country.id : null,
      media_date: (file.media_date) ? moment(file.media_date) : null,
      media_author: file.media_author,
      media_location: file.media_location,
      thumb: (file.thumb_large) ? file.thumb_large : file.thumb,
      status: file.status,
      access_rights: transformApiObjectToFormData(file.access_rights, 'id'),
      broadcasts: broadcasts,
      stickers: transformApiObjectToFormData(file.stickers, 'id'),
      id_folder: file.folder.id,
      folders: transformApiObjectToFormData(file.folders, 'id'),
      id_watermark: (file?.id_watermark) ? file.id_watermark : null,
      terms_of_use: transformApiLangToFormLang(file.langs.terms_of_use)
    }

    setData({
      ...data,
      isInit: true,
      haveWatermark: haveWatermark,
      broadcastsWantEdit: (file.broadcasts !== null),
      broadcastsCheckedAll: broadcastsCheckedAll,
      broadcastsIndeterminate: broadcastsIndeterminate,
    })
    setStates(newStates)

    if (formRef?.current) {
      formRef.current.setFieldsValue(newStates)
    }
  }

  const getBroadcastsDefaultStates = (haveWatermark, broadcasts) => {
    //Check
    if (!data.broadcasts) {
      return
    }

    //Init
    let defaultStates

    if (broadcasts) {
      defaultStates = broadcasts

    } else {
      defaultStates = {
        can_share: [],
        can_download: [],
        dl_watermark: [],
      }

      data.broadcasts.forEach(broadcast => {
        if (broadcast.can_share) {
          defaultStates['can_share'].push(broadcast.id)
        }
        if (broadcast.can_download) {
          defaultStates['can_download'].push(broadcast.id)
        }
        if (haveWatermark && file.file_type.type === FILE_TYPE_IMAGE && broadcast.dl_watermark) {
          defaultStates['dl_watermark'].push(broadcast.id)
        }
      })
    }

    const broadcastCanShare = data.broadcasts.filter(item => (item.can_share)).length
    const broadcastCanDownload = data.broadcasts.filter(item => (item.can_download)).length
    const broadcastDlWatermark = data.broadcasts.filter(item => (item.dl_watermark)).length

    const broadcastsCheckedAll = {
      can_share: (defaultStates['can_share'].length === broadcastCanShare),
      can_download: (defaultStates['can_download'].length === broadcastCanDownload),
      dl_watermark: (haveWatermark && defaultStates['dl_watermark'].length === broadcastDlWatermark),
    }
    const broadcastsIndeterminate = {
      can_share: (defaultStates['can_share'].length && defaultStates['can_share'].length < broadcastCanShare),
      can_download: (defaultStates['can_download'].length && defaultStates['can_download'].length < broadcastCanDownload),
      dl_watermark: (haveWatermark && defaultStates['dl_watermark'].length && defaultStates['dl_watermark'].length < broadcastDlWatermark),
    }

    return {
      checkedAll: broadcastsCheckedAll,
      indeterminate: broadcastsIndeterminate,
      defaultStates: defaultStates
    }
  }

  //Template methods
  const submitForm = () => {
    console.log(TAG, 'submitForm')

    //Make formData
    let formDatas = {
      //Information
      ...transformFormLangToFormData({
        title: states.title,
        description: states.description,
        keywords: states.keywords
      }),
      id_country: (states.id_country !== null) ? states.id_country : 0,
      youtube_code: states.youtube_code,
      media_date: (states.media_date) ? states.media_date.format('YYYY-MM-DD') : null,
      media_author: states.media_author,
      media_location: states.media_location,

      //Parameters
      status: states.status,
      id_folder: (states.id_folder !== null) ? states.id_folder : 0,
      ...transformObjectToFormData('folders', states.folders),
      id_watermark: states.id_watermark,
      ...transformObjectToFormData('access_rights', states.access_rights),
      ...transformObjectToFormData('stickers', states.stickers),
      ...transformFormLangToFormData({
        terms_of_use: states.terms_of_use,
      })
    }

    //Broadcast
    if (data.broadcastsWantEdit && states.broadcasts) {
      Object.keys(states.broadcasts).forEach(actionType => {
        if (!data.haveWatermark && actionType === 'dl_watermark') {
          return
        }

        let i = 0

        formDatas['broadcasts[' + actionType + ']'] = 1
        states.broadcasts[actionType].forEach(groupId => {
          formDatas['broadcasts[' + actionType + '][' + i + ']'] = groupId

          i++
        })
      })
    }

    //Bulk editing
    if (bulkEdit) {
      submitBulk(formDatas)

      return
    }

    //Loading
    setData({
      ...data,
      isSubmitting: true,
      submitModalVisible: true
    })

    //Classic edition
    formDatas['thumb'] = data.uploadedThumb?.originFileObj
    
    updateFile(APIRequest(authState.language, authState.token, null, 0), modalProps.id, formDatas).then(response => {
      if (isBadResponse(response.data)) {
        setData({
          ...data,
          isSubmitting: false,
          submitError: true,
          submitErrorData: {
            code: response.data?.data?.code,
            message: response.data?.data?.message,
            extraData: response.data?.data?.extra_data
          }
        })

        return
      }

      closeThisModal()
      modalOnOk(response.data.data.file)
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setData({
        ...data,
        isSubmitting: false,
        submitError: true,
        submitErrorData: {
          code: null,
          message: thrown
        }
      })
    })
  }

  const submitBulk = async (formDatas) => {
    console.log(TAG, 'submitBulk')

    //Loading
    setData({
      ...data,
      isSubmitting: true,
      submitModalVisible: true
    })

    let filesProcessed = 0
    const updatedFiles = []

    setStates({ ...states, isSubmitting:true })

    for(const file of files) {
      console.log(TAG, 'submitBulk file ' + file.id)

      const fileUpdated = await updateFile(APIRequest(authState.language, authState.token, null, 0), file.id, formDatas)
        .then(response => {
          if (isBadResponse(response.data)) {
            axiosError(t)

            return null
          }

          return response.data.data.file
        })
        .catch((thrown) => {
          console.log(TAG, thrown)
          axiosError(t)

          return null
        })

      if (fileUpdated) {
        updatedFiles.push(fileUpdated)
      }

      filesProcessed = filesProcessed + 1

      setData({ ...data, isSubmitting:true, submitBulkFilesProcessed:filesProcessed })

      if (filesProcessed >= files.length) {
        setTimeout(() => {
          closeThisModal()
          modalOnOk(updatedFiles)
        }, 1500)
      }
    }
  }

  const submitChangeSourceFile = async () => {
    if (!data.uploadedFile.length) {
      return;
    }

    setData({
      ...data,
      isUploading: true,
    })

    const file = data.uploadedFile[0]
    const fileGuid = uuidv4() + "." + file.name.split('.').pop()
    const fileSize = file.size
    const chunkPartsCount = (fileSize % UPLOAD_CHUNK_SIZE === 0) ? fileSize / UPLOAD_CHUNK_SIZE : Math.floor(fileSize / UPLOAD_CHUNK_SIZE) + 1
    let beginingOfTheChunk = 0
    let endOfTheChunk = UPLOAD_CHUNK_SIZE
    let uploadError = false

    for (let chunkCurrentPart = 1; chunkCurrentPart <= chunkPartsCount; chunkCurrentPart++) {
      let success = await uploadChunk(fileGuid, fileSize, beginingOfTheChunk, chunkCurrentPart, chunkPartsCount, file.originFileObj.slice(beginingOfTheChunk, endOfTheChunk))

      if (success) {
        beginingOfTheChunk = endOfTheChunk
        endOfTheChunk = endOfTheChunk + UPLOAD_CHUNK_SIZE
      } else {
        console.log(TAG, 'uploadChunk ERROR')

        uploadError = true

        break
      }
    }

    if (uploadError) {
      console.log(TAG, 'File upload error')

      setData({
        ...data,
        isUploading: false,
        uploadedFile: [],
        uploadingError: true,
        uploadingErrorData: {
          code: 'UNKNOWN_ERROR',
          message: t('Sorry, something went wrong...'),
          extraData: null
        }
      })

      return
    }

    //Fix to show progress complete of last file
    setTimeout(() => {
      //Form data
      const formData = {
        'file[guid]': fileGuid,
        'file[name]': file.name,
        'file[size]': fileSize
      };

      updateSourceFile(APIRequest(authState.language, authState.token, null, 0), modalProps.id, formData).then(response => {
        if (isBadResponse(response.data)) {
          setData({
            ...data,
            isUploading: false,
            uploadedFile: [],
            uploadingError: true,
            uploadingErrorData: {
              code: response.data?.data?.code,
              message: response.data?.data?.message,
              extraData: response.data?.data?.extra_data
            }
          })

          return
        }

        setStates({
          ...states,
          thumb: (response.data.data.file.thumb_large) ? response.data.data.file.thumb_large : response.data.data.file.thumb
        })

        setData({
          ...data,
          isUploading: false,
          uploadedFile: [],
          submitError: false,
          submitErrorData: null,

          file_type: response.data.data.file.file_type,
          media_width: response.data.data.file.media_width,
          media_height: response.data.data.file.media_height,
          media_duration: response.data.data.file.media_duration,
          filesize:  response.data.data.file.filesize,
        })

        notification.success({
          message: t('Change file'),
          description: t('The file change was completed successfully'),
          placement: 'topRight',
          icon: getIconFromReactIcons(<FileSyncOutlined style={{color:green.primary}} />)
        })

        modalOnOk(response.data.data.file)
      })
        .catch((thrown) => {
          console.log(TAG, thrown)
          axiosError(t)

          setData({
            ...data,
            isUploading: false,
            uploadedFile: [],
            uploadingError: true,
            uploadingErrorData: {
              code: null,
              message: thrown
            }
          })
        })
    }, 750)
  }

  const uploadChunk = async (fileGuid, fileSize, beginingOfTheChunk, chunkCurrentPart, chunkPartsCount, chunk) => {
    const formDatas = {
      chunk: chunk,
      guid: fileGuid,
      parts_count: chunkPartsCount,
      current_part: chunkCurrentPart
    }

    let success = false

    await uploadFile(
      APIRequest(
        authState.language,
        authState.token,
        {
          onUploadProgress: function(progressEvent) {
            setUploadProgress({
              loadedPercentage: Math.round(((beginingOfTheChunk + progressEvent.loaded) * 100) / fileSize),
              loaded: convertFileSizeToHuman(beginingOfTheChunk + progressEvent.loaded),
              total: convertFileSizeToHuman(fileSize)
            })
          }
        },
        0
      ),
      formDatas
    ).then(response => {
      if (isBadResponse(response.data)) {
        return
      }

      success = true
    })

    return success
  }

  const handleBroadcastWantEdit = (bool) => {
    //Restore default
    if (!bool) {
      const broadcastsDefaultStates = getBroadcastsDefaultStates(data.haveWatermark)

      setStates({ ...states, broadcasts: broadcastsDefaultStates.defaultStates })
      setData({
        ...data,
        broadcastsWantEdit: false,
        broadcastsCheckedAll: broadcastsDefaultStates.checkedAll,
        broadcastsIndeterminate: broadcastsDefaultStates.indeterminate,
      })
    
    //Edit
    } else {
      setData({
        ...data,
        broadcastsWantEdit: true,
      })
    }
  }

  const getBroadcastCheckAllChange = (e, column) => {
    //Init
    const broadcasts = []
    const broadcastsCheckedAll = Object.assign({}, data.broadcastsCheckedAll)
    const broadcastsIndeterminate = Object.assign({}, data.broadcastsIndeterminate)
    let _states = Object.assign({}, states.broadcasts)

    //Prepare object data
    if (_states === null) {
      _states = {
        can_share: [],
        can_download: [],
        dl_watermark: [],
      }
    }

    //Check all
    if (e.target.checked) {
      data.broadcasts.forEach(item => {
        if (item[column] === null) {
          return
        }

        broadcasts.push(item.id)
      })

      broadcastsCheckedAll[column] = true
      broadcastsIndeterminate[column] = false
    } else {
      broadcastsCheckedAll[column] = false
      broadcastsIndeterminate[column] = false
    }

    //Update state
    _states[column] = broadcasts

    setData({
      ...data,
      broadcastsCheckedAll: broadcastsCheckedAll,
      broadcastsIndeterminate: broadcastsIndeterminate
    })
    setStates({ ...states, broadcasts: _states })
  }

  const getBroadcastSwitchChange = (bool, broadcastId, column) => {
    let _states = Object.assign({}, states.broadcasts)
    let _data = Object.assign({}, data)

    if (bool) {
      _states[column].push(broadcastId)
    } else {
      _states[column] = _states[column].filter(item => item !== broadcastId)
    }

    const broadcastLength = data.broadcasts.filter(item => (item[column])).length

    _data.broadcastsCheckedAll[column] = (_states[column].length === broadcastLength)
    _data.broadcastsIndeterminate[column] = (_states[column].length && _states[column].length < broadcastLength)

    setStates({ ...states, broadcasts: _states })
    setData(_data)
  }

  const getBroadcastIndeterminate = (column) => {
    return (data.broadcastsIndeterminate !== undefined && data.broadcastsIndeterminate[column] === true)
  }

  const getBroadcastCheckAll = (column) => {
    return (data.broadcastsCheckedAll !== undefined && data.broadcastsCheckedAll[column] === true)
  }

  const getBroadcastTable = () => {
    //Set columns
    const columns = [
      {
        title: t('Groups'),
        dataIndex: 'name',
        render: (name, record) => {
          if (record.id) {
            return <Text ellipsis={true}>{name}</Text>
          } else {
            return <Text ellipsis={true} strong>{name}</Text>
          }
        }
      },
      {
        title: t('Can be shared?'),
        dataIndex: 'can_share',
        render: (can_share, record) => {
          const disabled = (!data.broadcastsWantEdit)

          if (can_share === null) {
            return <Text disabled={disabled}><StopOutlined /> {t('Not available')}</Text>
          }

          if (record.id) {
            return <Switch
              checkedChildren={<><CheckOutlined /> {t('Yes')}</>}
              unCheckedChildren={<><CloseOutlined /> {t('No')}</>}
              checked={states.broadcasts && states.broadcasts['can_share'].some(item => item === record.id)}
              onChange={(can_share) => getBroadcastSwitchChange(can_share, record.id, 'can_share')}
              style={{ width:'100%'}}
              disabled={disabled}
            />
          } else {
            return (
              <Checkbox
                indeterminate={getBroadcastIndeterminate('can_share')}
                checked={getBroadcastCheckAll('can_share')}
                onChange={(e) => getBroadcastCheckAllChange(e, 'can_share')}
                disabled={disabled}
              >
                {t('Check all')}
              </Checkbox>
            )
          }
        }
      },
      {
        title: t('Can be downloaded?'),
        dataIndex: 'can_download',
        render: (can_download, record) => {
          const disabled = (!data.broadcastsWantEdit)

          if (can_download === null) {
            return <Text disabled={disabled}><StopOutlined /> {t('Not available')}</Text>
          }

          if (record.id) {
            return <Switch
              checkedChildren={<><CheckOutlined /> {t('Yes')}</>}
              unCheckedChildren={<><CloseOutlined /> {t('No')}</>}
              checked={states.broadcasts && states.broadcasts['can_download'].some(item => item === record.id)}
              onChange={(can_download) => getBroadcastSwitchChange(can_download, record.id, 'can_download')}
              style={{ width:'100%'}}
              disabled={disabled}
            />
          } else {
            return (
              <Checkbox
                indeterminate={getBroadcastIndeterminate('can_download')}
                checked={getBroadcastCheckAll('can_download')}
                onChange={(e) => getBroadcastCheckAllChange(e, 'can_download')}
                disabled={disabled}
              >
                {t('Check all')}
              </Checkbox>
            )
          }
        }
      }
    ]

    if (file.file_type.type === FILE_TYPE_IMAGE) {
      columns.push({
        title: t('Downloaded with watermark?'),
        dataIndex: 'dl_watermark',
        render: (dl_watermark, record) => {
          const disabled = (!data.broadcastsWantEdit || !data.haveWatermark)

          if (dl_watermark === null) {
            return <Text disabled={disabled}><StopOutlined /> {t('Not available')}</Text>
          }

          if (record.id) {
            return <Switch
              checkedChildren={<><CheckOutlined /> {t('Yes')}</>}
              unCheckedChildren={<><CloseOutlined /> {t('No')}</>}
              checked={states.broadcasts && states.broadcasts['dl_watermark'].some(item => item === record.id)}
              onChange={(dl_watermark) => getBroadcastSwitchChange(dl_watermark, record.id, 'dl_watermark')}
              disabled={disabled}
              style={{ width:'100%'}}
            />
          } else {
            return (
              <Checkbox
                indeterminate={getBroadcastIndeterminate('dl_watermark')}
                checked={getBroadcastCheckAll('dl_watermark')}
                onChange={(e) => getBroadcastCheckAllChange(e, 'dl_watermark')}
                disabled={disabled}
              >
                {t('Check all')}
              </Checkbox>
            )
          }
        }
      })
    }

    //Set rows data
    const dataSource = []

    dataSource.push({
      key: 0,
      id: null,
      name: null,
      level: 0
    })

    data.broadcasts.forEach(item => {
      dataSource.push({
        key: item.id,
        id: item.id,
        name: item.name,
        level: item.level,
        can_share: item.can_share,
        can_download: item.can_download,
        dl_watermark: item.dl_watermark,
      })
    })

    //Return table
    return (
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          current: 1,
          pageSize: 999,
          total: data.broadcasts.length,
          position: ['none', 'none'],
        }}
      />
    )
  }

  const handleDeleteThumb = () => {
    if (data.uploadedThumb) {
      setData({...data, uploadedThumb: null, uploadedThumbImage: null})
    
    } else if (states.thumb) {
      delFileThumb(APIRequest(authState.language, authState.token, null, 0), modalProps.id).then(response => {
        if (isBadResponse(response.data)) {
          objectError(t)
          return
        }

        modalOnOk(response.data.data.file, false)
        setStates({...states, thumb: null})
      })
      .catch((thrown) => {
        console.log(TAG, thrown)
        axiosError(t)
      })
    }
  }

  const handleRegenerateThumb = () => {
    if (data.uploadedThumb || states.thumb) {
      return
    }

    setData({...data, isRegenerateThumb: true})

    regenerateThumbFile(APIRequest(authState.language, authState.token, null, 0), modalProps.id).then(response => {
      if (isBadResponse(response.data)) {
        objectError(t)
        return
      }

      modalOnOk(response.data.data.file, false)
      setStates({...states, thumb: response.data.data.file.thumb})
      setData({...data, isRegenerateThumb: false})
    })
    .catch((thrown) => {
      console.log(TAG, thrown)

      setData({...data, isRegenerateThumb: false})
      axiosError(t)
    })
  }

  const onAccessRightsCheckAll = (e, stateKey, dataKey, data) => {
    const checked = []

    if (e.target.checked) {
      data.accessRights.forEach(item => {
        checked.push(item.id)
      })
    }

    const newStates = {...states, access_rights: checked}
    
    formRef.current.setFieldsValue(newStates)
    setStates(newStates)
    setData({...data, accessRightsAllChecked: e.target.checked})
  }

  const closeThisModal = () => {
    setStates(DEFAULT_STATES)
    setData(DEFAULT_DATA)
    closeModal()
  }

  const getFileIcon = (file) => {
    const fileType = getFileType(getMimeFileType(file.type), t)

    return fileType.icon
  }

  const getContent = () => {
    //Loading
    if (!data.isLoaded || !data.isInit) {
      return (
        <LoadingBlock />
      )
    }

    if (bulkEdit && data.isSubmitting) {
      return (
        <Space direction="vertical" align="center" style={{ width:'100%' }}>
          <Progress
            type="circle"
            percent={(data.submitBulkFilesProcessed / files.length) * 100}
            format={(percent) => data.submitBulkFilesProcessed + ' / ' + files.length}
          />
          <Text>{t('Media processing in progress')}...</Text>
        </Space>
      )
    }

    return (
      <>
        {bulkEdit && (
          <Alert
            type="info"
            message={
              <div style={{ display: 'flex', flexDirection:'column', alignItems:'center', gap:8 }}>
                <Text style={{ textAlign:'center' }}>{t('You can select the reference media that will pre-fill the form')}</Text>
                <Select
                  defaultValue={id}
                  style={{ textAlign:'center', width:'100%', maxWidth:600 }}
                  getPopupContainer={trigger => trigger.parentNode}
                  onChange={(value) => {
                    const file = files.filter(item => item.id === value)

                    if (!file || !file[0]) {
                      return false
                    }

                    Modal.confirm({
                      title: file[0].title,
                      content: t('Are you sure you want to use this media as a reference (all changes will be lost)?'),
                      okText: t('Yes'),
                      cancelText: t('Cancel'),
                      onOk: () => {
                        setFileStates(file[0])
                      }
                    })
                  }}
                >
                  {files.map(item => <Option key={'bulkEditFile' + item.id} value={item.id}>{item.title}</Option>)}
                </Select>
              </div>
            }
          />
        )}

        {(!data.isSubmitting && data.submitError) && (
          <ApiErrorResult errorData={data.submitErrorData} template="alert" status="error" style={{marginBottom:20}} />
        )}

        <Form
          ref={formRef}
          {...formItemLayout}
          layout={formLayout}
          initialValues={states}
          onValuesChange={(changedValues, allValues) => {
            const _states = { ...states, ...allValues }
            
            //Fix select clear value
            if (_states.id_watermark === undefined) {
              _states.id_watermark = null
            }

            if (states.id_watermark !== _states.id_watermark) {
              setData({ ...data, haveWatermark: (_states.id_watermark !== null) })
            }

            setStates(_states)
          }}
          onFinishFailed={({ values, errorFields, outOfDate }) => formError(errorFields, t)}
          onFinish={() => {
            submitForm()
          }}
        >
          <Tabs defaultActiveKey="1" centered>
            <TabPane tab={t('Informations')} key="informations">
              {!bulkEdit && (
                <Form.Item label={t('Thumbnail picture')} extra={t('Changing thumbnail will not affect the original file.')}>
                  <ImageUploader
                    name="thumb"
                    image={data.uploadedThumbImage || states.thumb}
                    acceptMime={THUMB_FILE_ACCEPT_MIME}
                    fileSizeLimit={THUMB_FILE_SIZE_LIMIT}
                    disabled={data.isRegenerateThumb}
                    onChangeDone={(info) => {
                      getBase64(info.file.originFileObj, (preview) => {
                        setData({...data, uploadedThumb: info.file, uploadedThumbImage: preview})
                      })
                    }}
                    onDelete={handleDeleteThumb}
                    extraAction={(!data.uploadedThumb && !states.thumb) && (
                      <Popconfirm
                        title={t('Are you sure to regenerate thumbnails from original media?')}
                        onConfirm={handleRegenerateThumb}
                        okText={t('Yes')}
                        cancelText={t('No')}
                      >
                        <Button icon={<SyncOutlined />} loading={data.isRegenerateThumb} block>{t('Regenerate thumbnails from original media')}</Button>
                      </Popconfirm>
                    )}
                  />
                </Form.Item>
              )}
              <FormItemLang
                label={t('Title')}
                name='title'
                required={true}
                rules={[
                  {required: true, message: t('Title (%lang%) is required')},
                  {max: 75, message: t('Title (%lang%) must not exceed %d% characters').replace('%d%', 75)},
                ]}
                currentLang={formLang}
                onLangChange={(value) => {setFormLang(value)}}
                onCopyLang={handleCopyLang}
              >
                <Input
                  showCount
                  maxLength={75}
                />
              </FormItemLang>
              <FormItemLang
                label={t('Description')}
                name='description'
                required={false}
                rules={[
                  {max: 150, message: t('Description (%lang%) must not exceed %d% characters').replace('%d%', 150)},
                ]}
                currentLang={formLang}
                onLangChange={(value) => {setFormLang(value)}}
                onCopyLang={handleCopyLang}
              >
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  showCount
                  maxLength={150}
                />
              </FormItemLang>

              <Form.Item
                label={t('Youtube video')}
                name='youtube_code'
                rules={[
                  {max: 16, message: t('Youtube video must not exceed %d% characters').replace('%d%', 16)},
                ]}
                extra={t('Video code only, example: heGV4qKb23Q')}
              >
                <Input
                  showCount
                  maxLength={16}
                />
              </Form.Item>

              <FormItemLang
                label={t('Tags')}
                name='keywords'
                required={false}
                currentLang={formLang}
                onLangChange={(value) => {setFormLang(value)}}
                onCopyLang={handleCopyLang}
              >
                <Select mode="tags" placeholder={t('Add tags (used in search engine)')} getPopupContainer={trigger => trigger.parentNode}>
                  {data.tags.map(item => 
                    <Option value={item} key={"tagsOption" + item}>{item}</Option>
                  )}
                </Select>
              </FormItemLang>

              <Form.Item
                label={t('Date of media')}
                name='media_date'
              >
                <DatePicker
                  format={getShortFormat(authState.language)}
                  disabledDate={d => !d || d.isAfter(Date.now())}
                  getPopupContainer={trigger => trigger.parentNode}
                />
              </Form.Item>

              <Form.Item
                label={t('Author')}
                name='media_author'
                rules={[
                  {max: 75, message: t('Author must not exceed %d% characters').replace('%d%', 75)},
                ]}
              >
                <AutoComplete
                  options={data.authors}
                  filterOption={(inputValue, option) => {
                    if (!option?.value || ! inputValue) {
                      return false
                    }

                    return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }}
                />
              </Form.Item>

              <Form.Item
                label={t('Country')}
                name='id_country'
              >
                <Select
                  showSearch
                  allowClear
                  placeholder={t('Search a country')}
                  optionFilterProp="children"
                  disabled={!data.isLoaded}
                  filterOption={(inputValue, option) => {
                    if (!option?.children || inputValue === null) {
                      return false
                    }

                    return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                  }}
                  filterSort={(optionA, optionB) => {
                    if (!optionB?.children || !optionA?.children) {
                      return null
                    }
                    
                    return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }}
                  getPopupContainer={trigger => trigger.parentNode}
                >
                  {data.countries.map(item => 
                    <Option value={item.id} key={"countryOption" + item.id}>{item.name}</Option>
                  )}
                </Select>

              </Form.Item>

              <Form.Item
                label={t('Location')}
                name='media_location'
                rules={[
                  {max: 75, message: t('Location must not exceed %d% characters').replace('%d%', 75)},
                ]}
              >
                <AutoComplete
                  options={data.locations}
                  filterOption={(inputValue, option) => {
                    if (!option?.value || ! inputValue) {
                      return false
                    }
                    
                    return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }}
                />
              </Form.Item>
            </TabPane>
            <TabPane tab={t('Parameters')} key="parameters">
              <Form.Item
                label={t('Status')}
                name='status'
                required={true}
              >
                <Radio.Group
                  options={[
                    {label: t('Draft (Only for you and administror)'), value: FILE_STATUS_DRAFT},
                    {label: t('Online'), value: FILE_STATUS_ONLINE},
                    {label: t('Archive'), value: FILE_STATUS_ARCHIVE},
                  ]}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
              
              <Form.Item
                label={t('Folders')}
                name='folders'
                extra={t('Select where the media can be found')}
                required={true}
                rules={[
                  {required: true, message: t('Folders is required')},
                ]}
              >
                <FileTreeSelect multiple={true} onChange={(ids, folders) => {
                  setData({
                    ...data,
                    folders: folders
                  })

                  //Automatic selection of default
                  if (states.id_folder === null && ids.length === 1) {
                    const newStates = { ...states, folders:ids, id_folder:ids[0] }

                    formRef.current.setFieldsValue(newStates)
                    setStates(newStates)
                  
                  //Default folder not exists
                  } else if (!ids.includes(states.id_folder)) {
                    const newStates = { ...states, folders:ids, id_folder:null }

                    formRef.current.setFieldsValue(newStates)
                    setStates(newStates)
                  }
                }} />
              </Form.Item>

              <Form.Item
                label={t('Default folder')}
                name='id_folder'
                extra={t('Select the default folder of the media')}
                required={true}
                rules={[
                  {required: true, message: t('Default folder is required')},
                ]}
                disabled={(data.folders.length === 0)}
              >
                <Select placeholder={t('Select a default folder')} disabled={(data.folders.length === 0)}>
                  {data.folders.map(item => 
                    <Option value={item.id} key={"defaultFolderOption" + item.id}>{item.title}</Option>
                  )}
                </Select>
              </Form.Item>

              {data.watermarks.length > 0 && (
                <Form.Item
                  label={t('Watermark')}
                  name='id_watermark'
                  extra={t('The watermark is visible only on picture.')}
                >
                  <Select placeholder={t('Select a watermark')} allowClear={true}>
                    {data.watermarks.map(item => 
                      <Option value={item.id} key={"WatermarkOption" + item.id}>{item.name}</Option>
                    )}
                  </Select>
                </Form.Item>
              )}
              
              <Form.Item label={t('Access rights')}>
                <Form.Item style={{marginBottom:0}}>
                  <Checkbox onChange={onAccessRightsCheckAll}>{data.accessRightsAllChecked ? t('Uncheck all') : t('Check all')}</Checkbox>
                </Form.Item>
                <Form.Item name='access_rights'>
                  <Checkbox.Group style={{ marginTop:6 }}>
                    {data.accessRights.map(item => 
                      <span key={"accessRightsOption" + item.id}>
                        <Checkbox value={item.id}>{item.name}</Checkbox>
                        <br />
                      </span>
                    )}
                  </Checkbox.Group>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={t('Stickers')}
                name='stickers'
              >
                <Select mode="multiple" allowClear placeholder={t('Add stickers on media')} getPopupContainer={trigger => trigger.parentNode}>
                  {data.stickers.map(item => 
                    <Option value={item.id} key={"stickersOption" + item.id}>{item.title}</Option>
                  )}
                </Select>
              </Form.Item>

              <FormItemLang
                label={t('Terms of use')}
                name='terms_of_use'
                rules={[
                  {max: 75, message: t('Terms of use must not exceed %d% characters').replace('%d%', 75)},
                ]}
                currentLang={formLang}
                onLangChange={(value) => {setFormLang(value)}}
                onCopyLang={handleCopyLang}
              >
                <Input
                  showCount
                  maxLength={75}
                />
              </FormItemLang>
            </TabPane>
            <TabPane tab={t('Broadcasting')} key="broadcasts">
              <Alert
                message={t('Broadcast restrictions')}
                description={
                  data.broadcastsWantEdit ? (
                    <Space direction="vertical">
                      <Button icon={<RollbackOutlined />} onClick={() => handleBroadcastWantEdit(false)}>{t('Restore to default')}</Button>
                    </Space>
                  ) : (
                    <Space direction="vertical">
                      <>{t('You can change this default broadcast restrictions to the one you want only for this media.')}</>
                      <Button icon={<EditOutlined />} onClick={() => handleBroadcastWantEdit(true)}>{t('Modify')}</Button>
                    </Space>
                  )
                }
                type="info"
                showIcon
                style={{ marginBottom:50, textAlign:'center' }}
              />
              {getBroadcastTable()}
            </TabPane>
            <TabPane tab={t('File')} key="file">
              <Row gutter={[16, 16]}>
                <Col sm={24} xs={24} lg={12}>
                  <Descriptions layout="vertical" bordered column={1} size="small">
                    <Descriptions.Item label={t('Type')} key={'itemDescType'}>
                      {data.file_type.name}
                    </Descriptions.Item>
                    {data.media_width && (
                      <Descriptions.Item label={t('Width')} key={'itemDescWidth'}>
                        {data.media_width + ' px'}
                      </Descriptions.Item>
                    )}
                    {data.media_height && (
                      <Descriptions.Item label={t('Height')} key={'itemDescHeight'}>
                        {data.media_height + ' px'}
                      </Descriptions.Item>
                    )}
                    {data.media_duration && (
                      <Descriptions.Item label={t('Duration')} key={'itemDescDuration'}>
                        {data.media_duration}
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item label={t('Size')} key={'itemDescSize'}>
                      {convertFileSizeToHuman(data.filesize * 1000)}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col sm={24} xs={24} lg={12}>
                  <div style={{width:'100%', textAlign:'center'}}>
                    <Title level={4}>{t('Change file')}</Title>
                    <Divider />
                    <Upload
                      name="source"
                      disabled={data.uploadedFile.length > 0}
                      maxCount={1}
                      beforeUpload={(file) => {
                        const fileType = getMimeFileType(file.type)
                        const _file = Object.assign(file, {
                          status: 'done',
                          thumbUrl: null,
                          originFileObj: file,
                          fileType: fileType
                        })

                        setData({
                          ...data,
                          uploadedFile: [_file]
                        })

                        return false
                      }}
                      itemRender={(originNode, file, currFileList, actions) => (
                        <Space direction="vertical">
                          {file.thumbUrl
                            ? <img src={file.thumbUrl} className="img-fluid" style={{ maxHeight:70, }} alt={file.name} />
                            : <div style={{ fontSize:46, color:'#888', textAlign:'center' }}>{getFileIcon(file)}</div>
                          }
                          <Text ellipsis={true}>{file.name}</Text>
                          {!data.isUploading && (
                            <>
                              <Alert
                                message={t('This change is irreversible!')}
                                description={(
                                  <Space direction="vertical">
                                    <Text>{t('The current file cannot be recovered. The file preview will be regenerated.')}</Text>
                                    <Button
                                      onClick={submitChangeSourceFile}
                                      type="primary"
                                    >
                                      {t('I Confirm the file change')}
                                    </Button>
                                  </Space>
                                )}
                                type="info"
                              />
                              <Button
                                onClick={() => {
                                  setData({...data, uploadedFile: []})
                                }}
                              >
                                {t('Cancel')}
                              </Button>
                            </>
                          )}
                        </Space>
                      )}
                      iconRender={null}
                      fileList={data.uploadedFile}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        style={{width:'100%'}}
                        disabled={data.uploadedFile.length > 0}
                      >
                        {t('Click to select file')}
                      </Button>
                    </Upload>
                    {data.isUploading && (
                      <Space direction="vertical" style={{marginTop:20}}>
                        <Text strong style={{ marginBottom:5 }}>{t('Uploading...')}</Text>
                        <Progress type="circle" percent={uploadProgress.loadedPercentage} />
                        {(uploadProgress.loaded && uploadProgress.total) && (
                          <Text style={{ marginTop:5 }}>{uploadProgress.loaded + ' / ' + uploadProgress.total}</Text>
                        )}
                      </Space>
                    )}
                    {data.uploadingError === true && (
                      <Alert
                        message={data.uploadingErrorData.code}
                        description={data.uploadingErrorData.message}
                        type="error"
                        style={{marginTop: 20}}
                        closable
                        onClose={() => {
                          setData({
                            ...data,
                            uploadingError: false,
                            uploadingErrorData: null
                          })
                        }}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Form>
      </>
    )
  }

  return (
    <Modal
      title={(bulkEdit) ? t('Bulk edit of %d media').replace('%d', files.length) : file?.title}
      open={modalType !== GLOBAL_MODAL_TYPES.FILE_EDIT ? false : showModal}
      zIndex={120}
      width={(!screens.md) ? 'calc(100% - 32px)' : 'calc(100% - 20%)'}
      onCancel={() => {
        closeThisModal()
      }}
      maskClosable={!data.isSubmitting}
      closable={!data.isSubmitting}
      destroyOnClose={true}
      footer={
        <div style={{textAlign:'center'}}>
          <Space>
            <Button disabled={data.isSubmitting} onClick={closeThisModal}>
              {t('Cancel')}
            </Button>
            <Button type="primary" loading={data.isSubmitting} disabled={data.isSubmitting} onClick={() => {
              formRef.current.submit()
            }}>
              {t('Save')}
            </Button>
          </Space>
        </div>
      }
    >
      {getContent()}
    </Modal>
  )
}

export default memo(MediaEditModal)
