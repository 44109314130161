export const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

export const nl2br = (str, split) => {
  if (!str) {
    return str
  }

  let splitRule = /\n\r|\r\n|\n|\r/

  if (split) {
    splitRule = split
  }

  return str.split(splitRule).map((text, key) => {
    return (
      <span key={key}>
        {text}
        <br />
      </span>
    )
  })
}