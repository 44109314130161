import { memo } from 'react';
import { useTranslation } from "react-i18next";
import invert from 'invert-color';
import { Tooltip, Badge, Typography } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined, InboxOutlined } from '@ant-design/icons';
import { FILE_STATUS_DRAFT, FILE_STATUS_ONLINE, FILE_STATUS_ARCHIVE } from "../../Constant";

const FileStatus = (props) => {
  const { t } = useTranslation()
  const { Text } = Typography

  //Props
  const type = props.type
  const status = props?.status
  const withTooltip = props?.withTooltip
  const withLabel = props?.withLabel

  //Get icon
  let icon = null
  let text = null
  let color = null
  const style = props?.style
  let view = null

  //Set
  if (status === FILE_STATUS_DRAFT) {
    icon = <EyeInvisibleOutlined />
    text = t('Draft')
    color = (type === 'icon') ? '#880000' : '#e36b6b'

  } else if (status === FILE_STATUS_ONLINE) {
    icon = <EyeOutlined />
    text = t('Online')
    color = (type === 'icon') ? '#008800' : '#70d570'
  
  } else if (status === FILE_STATUS_ARCHIVE) {
    icon = <InboxOutlined />
    text = t('Archived')
    color = (type === 'icon') ? '#71491c' : '#b8b8b8'
  }

  //Create view
  if (type === 'icon') {
    view = <Text style={{ ...style, color: color }}>
      {icon}
      {withLabel && <> {text}</>}
    </Text>
  
  } else if (type === 'ribbon') {
    view = <Badge.Ribbon
      text={<Text style={{ color:invert(color, true), textTransform:'uppercase', fontSize:12 }} strong>{icon} {text}</Text>}
      placement="start"
      color={color}
      style={style}
    />
  
  } else {
    view = 'Unknow type'
  }

  if (!withTooltip) {
    return view
  }

  return <Tooltip placement="top" title={text}>{view}</Tooltip>
}

export default memo(FileStatus)