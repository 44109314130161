import { memo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid } from 'antd';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';

const FileModalNavigation = (props) => {
  const TAG = 'FileModalNavigation'

  //Template
  const params = useParams()
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()
  const fileId = params?.idFile
  const getParentStates = props.getParentStates
  const changeFile = props.changeFile
  const loadMore = props.loadMore

  //States
  const DEFAULT_STATES = {
    fileId: (fileId) ? parseInt(fileId) : null,
    currentFileKey: null,
    previousFileKey: null,
    nextFileKey: null,
    isProcessing: false,
    isLoadingNextFiles: false,
  }
  const [ states, setStates ] = useState(DEFAULT_STATES)

  //Hooks
  useEffect(() => {
    setStates({ ...states, fileId:parseInt(params.idFile), isProcessing:false })
  }, [params.idFile])

  useEffect(() => {
    refreshNavigation()
  }, [states.idFile])

  //Hooks method
  const refreshNavigation = (fileKey, isProcessing, isLoadingNextFiles) => {
    console.log(TAG, 'refreshNavigation[' + fileKey + ']')
    const parentStates = getParentStates()

    if (!parentStates || !parentStates.files?.length) {
      return null
    }

    let currentFileKey = (fileKey !== undefined) ? parseInt(fileKey) : null

    if (currentFileKey === null) {
      for (const [key, item] of Object.entries(parentStates.files)) {
        if (item.id === states.fileId) {
          currentFileKey = parseInt(key)
        }
      }

      if (currentFileKey === null) {
        setStates(DEFAULT_STATES)
        return
      }
    }

    let previousFileKey = (currentFileKey > 0) ? currentFileKey - 1 : null
    let nextFileKey = (currentFileKey < parentStates.total - 1) ? currentFileKey + 1 : null

    setStates({
      ...states,
      currentFileKey: currentFileKey,
      previousFileKey: previousFileKey,
      nextFileKey: nextFileKey,
      isProcessing: (isProcessing === true) ? true : false,
      isLoadingNextFiles: (isLoadingNextFiles === true) ? true : false
    })
  }

  //Template methods
  const handleNavigation = (fileKey) => {
    if (states.isProcessing || states.isLoadingNextFiles) {
      return
    }

    console.log(TAG, 'handleNavigation')
    const parentStates = getParentStates()

    if (!parentStates || !parentStates.files?.length || !parentStates.files[fileKey]) {
      return
    } else if (parentStates.page < parentStates.pageSize && fileKey === parentStates.files.length - 1) {
      console.log(TAG, 'loadMore!')
      
      loadMore(() => {
        refreshNavigation(fileKey, false, false)
      })

      changeFile(parentStates.files[fileKey])
      refreshNavigation(fileKey, true, true)
      return
    }

    changeFile(parentStates.files[fileKey])
    refreshNavigation(fileKey, true)
  }

  //Views (remove if screen)
  if (!states.fileId || !screens.md) {
    return null
  }

  return (
    <>
      {states.previousFileKey !== null && (
        <Button
          onClick={(e) => {
            e.target.blur()
            handleNavigation(states.previousFileKey)
          }}
          type="primary"
          size="large"
          style={{ ...styles.button, left:-90 }}
          icon={<LeftCircleFilled />}
          disabled={(states.isLoadingNextFiles)}
          /*style={{ position:'absolute', left:0, top:0, bottom:0, height:'auto', opacity:.3, border:0, zIndex:101 }}
          onMouseEnter={(event) => event.target.style.opacity = .6}
          onMouseLeave={(event) => event.target.style.opacity = .3}
          */
        />
      )}
      {states.nextFileKey !== null && (
        <Button
          onClick={(e) => {
            e.target.blur()
            handleNavigation(states.nextFileKey)
          }}
          type="primary"
          size="large"
          style={{ ...styles.button, right:-90 }}
          icon={<RightCircleFilled />}
          loading={states.isLoadingNextFiles}
          disabled={(states.isLoadingNextFiles)}
          /*style={{ position:'absolute', right:0, top:0, bottom:0, height:'auto', opacity:.3, border:0, zIndex:101 }}
          onMouseEnter={(event) => event.target.style.opacity = .6}
          onMouseLeave={(event) => event.target.style.opacity = .3}
          */
        />
      )}
    </>
  )
}

const styles = {
  button: {
    position: 'absolute',
    top: 'calc((100vw / 10))',
    height: 200,
    width: 50,
    bottom: 0,
    zIndex: 101
  },
  buttonXs: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    height: 60,
    width: 60,
    bottom: 0,
    zIndex: 101
  }
}

export default memo(FileModalNavigation)