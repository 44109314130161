import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Layout, Typography, Spin, Image } from 'antd';

const LoadingScreen = () => {
  //Template
  const { t } = useTranslation()
  const { Content } = Layout
  const { Text } = Typography

  return (
    <Layout style={{minHeight:'100vh'}}>
      <Content style={{display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center'}}>
        <Image
          src="/asset/system/sodi-logo-black.png"
          preview={false}
          style={{ marginBottom:50 }}
        />

        <Spin size="large" />
        <Text type="secondary" style={{marginLeft: '1rem'}}>{t('Loading')}...</Text>
      </Content>
    </Layout>
  )
}

export default memo(LoadingScreen)