import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Result, Button } from 'antd';
import { useNavigate } from "react-router-dom";

function NotFoundScreen(props) {
  //Route
  const navigate = useNavigate()

  //Template
  const { t } = useTranslation()
  const withLogo = props?.withLogo
  const backText = (props.backText) ? props.backText : t('Back')
  const onClick = () => {
    if (props.backRoute) {
      navigate(props.backRoute, { replace: true })
    } else if (window.history.state && window.history.state.idx > 0) {
        navigate(-1)
    } else {
        navigate('/', { replace: true })
    }
  }

  return (
    <main>
      {withLogo && (
        <div style={{ margin:20, textAlign:'center' }}>
          <img
            src="/asset/system/sodikart-logo-black.png"
            alt="SODIKART"
          />
        </div>
      )}
      <Result
        status="404"
        title="404"
        subTitle={t('Sorry, the page you visited does not exist.')}
        extra={<Button type="primary" onClick={onClick}>{backText}</Button>}
      />
    </main>
  )
}

export default memo(NotFoundScreen)