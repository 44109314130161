import { useEffect, useContext, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Typography, Form, Divider, Input, Descriptions, Tag, Button, Modal } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { Context as AuthContext } from '../../context/AuthContext';
import { APIRequest, isBadResponse, changePassword } from '../../models/APIRequest';
import { axiosError } from '../../components/Notifications';
import TableHeader from '../../components/table/TableHeader';
import { SYSTEM_RIGHT_LEVEL_COLOR } from "../../Constant";

export default function AccountDetailScreen() {
  const TAG = 'AccountDetailScreen'

  //Template
  const { t } = useTranslation()
  const [ updateBreacrumbs ] = useOutletContext()
  const [ modal, contextHolder ] = Modal.useModal()
  const changePasswordFormRef = useRef()
  const { Text } = Typography

  //States
  const { authState } = useContext(AuthContext)

  //Events
  useEffect(() => {
    if (updateBreacrumbs) {
      console.log(TAG, 'updateBreacrumbs')
      updateBreacrumbs([{title: t('Informations'), 'route': null},])
    }
  }, [])

  //Template methods
  const changingPassword = (values) => {
    if (!authState) {
      return
    }

    changePassword(APIRequest(authState.language, authState.token), values.currentPassword, values.password).then(function (response) {
      const apiResponseCode = response.data?.data?.code

      if (apiResponseCode === "BAD_CURRENT_PASSWORD") {
        modal.error({
          title: t('An error has occurred'),
          content: t('The current password does not match your account password.'),
          okText: t('Close')
        })

        return

      } else if (apiResponseCode === "BAD_PASSWORD") {
        modal.error({
          title: t('An error has occurred'),
          content: t('The new password does not meet the security conditions.'),
          okText: t('Close')
        })

        return

      } else if (isBadResponse(response.data, true, t)) {
        axiosError(t)
        
        return
      }

      modal.success({
        title: t('Your password is now changed'),
        //content: t('The current password does not match your account password.'),
        okText: t('Close')
      })

      changePasswordFormRef.current.resetFields()

    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)
    })
  }

  return (
    <main className='admin-container'>
      <TableHeader
        title={t('My informations')}
        style={{ marginBottom:20 }}
      />

      {authState.data.is_system === true && (
        <Divider orientation="left">{t('Account')}</Divider>
      )}

      <Descriptions column={{ xxl: 2, xl: 2, lg: 1, md: 1, sm: 1, xs: 1 }} labelStyle={{ whiteSpace:'nowrap' }} bordered>
        <Descriptions.Item label={t('Name')}>{authState.data.name}</Descriptions.Item>
        <Descriptions.Item label={t('Username')}>{authState.data.email}</Descriptions.Item>
        <Descriptions.Item label={t('User type')}>
          <Text
            style={{
              backgroundColor: SYSTEM_RIGHT_LEVEL_COLOR[authState.data.system_right.level-1],
              color:'#FFF',
              padding:6,
            }}
            strong
          >
            {authState.data.system_right.name}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label={t('Broadcast restriction')}>{authState.data.broadcast_restriction.name}</Descriptions.Item>
        <Descriptions.Item label={t('Access rights')} span={2}>
          {authState.data.access_right && authState.data.access_right.map(item => {
            return <Tag>{item.name}</Tag>
          })}
        </Descriptions.Item>
      </Descriptions>

      {authState.data.is_system === true && (
        <>
          <Divider orientation="left">{t('Change your password')}</Divider>
          <div style={{ maxWidth:300 }}>
            <Form
              ref={changePasswordFormRef}
              name="changePasswordForm"
              onFinish={changingPassword}
              autoComplete="off"
              form={null}
              layout="vertical"
            >
              <Form.Item
                name="currentPassword"
                label={t('Current password')}
                rules={[{ required: true, message: t('Please enter your current password') }]}
              >
                <Input.Password
                  prefix={<LockOutlined style={{ color:'#CCC' }} />}
                  type="password"
                  placeholder={t('Password')}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label={t('New password')}
                rules={[
                  { required: true, message: t('Please enter your new password') },
                  { pattern:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/, message: t('Minimum eight characters, at least one uppercase letter, one lowercase letter and one number.') },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined style={{ color:'#CCC' }} />}
                  type="password"
                  placeholder={t('Password')}
                />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label={t('Confirm new password')}
                rules={[
                  { required: true, message: t('Please enter the password confirmation') },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }

                      return Promise.reject(new Error(t('The confirmation password is different to the password.')))
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined style={{ color:'#CCC' }} />}
                  type="password"
                  placeholder={t('Confirm password')}
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  {t('Change my password')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      )}

      {contextHolder}
    </main>
  );
}
