import i18n from 'i18next';
import { useTranslation } from "react-i18next";
import { Grid } from 'antd';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer, ScrollMode, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { SelectionMode } from '@react-pdf-viewer/selection-mode';
import { convertI18nLanguage } from '../../utils/LangUtils';
import LoadingBlock from '../LoadingBlock';
import fr_FR from '@react-pdf-viewer/locales/lib/fr_FR.json';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export default function FilePdfViewer(props) {
  const TAG = 'FilePdfViewer'

  //Template
  const { t } = useTranslation()
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()
  const file = props?.file
  const toolbarPluginInstance = toolbarPlugin()
  const { renderDefaultToolbar } = toolbarPluginInstance
  const transform = (slot) => ({
    ...slot,
    Download: () => <></>,
    Open: () => <></>,
    Print: () => <></>
  })
  console.log(file)
  const renderToolbar = (Toolbar) => <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) => [
      defaultTabs[0], // Bookmarks tab
      defaultTabs[1], // Thumbnails tab
    ],
    toolbarPlugin: {
      selectionModePlugin: {
        selectionMode: SelectionMode.Hand,
      },
      fullScreenPlugin: {
        getFullScreenTarget: (pagesContainer) => pagesContainer.closest('[data-testid="default-layout__body"]'),
        renderExitFullScreenButton: () => <></>,
      }
    },
  })
  const renderPage = (props) => {
    return (
      <div style={{ display:'flex', alignContent:'center' }}>
        {props.canvasLayer.children}
            {props.textLayer.children}
        {props.annotationLayer.children}
      </div>
    )
  }

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
      <div style={{ width:'100%', height:'calc((100vh - 200px))' }}>
          <Viewer
            localization={convertI18nLanguage(i18n.language) === 'fr' ? fr_FR : null}
            scrollMode={(screens.md === true) ? ScrollMode.Horizontal : ScrollMode.Vertical}
            renderLoader={(percentages) => (
              <LoadingBlock progressPercent={Math.round(percentages)} text={t('Loading PDF document...')} />
            )}
            defaultScale={SpecialZoomLevel.PageFit}
            plugins={[defaultLayoutPluginInstance]}
            fileUrl={file.link}
            httpHeaders={{
              'X-Api-Sodimedia-External-Key': '7ez.vx0!Szb1p94T_xc8Z'
            }}
            renderPage={renderPage}
          />
      </div>
    </Worker>
  )
}