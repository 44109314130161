import { useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  Upload,
  Space,
  Button,
  Popconfirm,
  Grid,
  notification
} from 'antd';
import { CloseOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';

export default function ImageUploader(props) {
  const TAG = 'ImageUploader'

  if (!props?.name) {
    throw new Error(TAG + ' - PROPS name is undefined')
  } else if (!props?.onChangeDone) {
    throw new Error(TAG + ' - PROPS onChangeDone is undefined')
  }

  //States
  const [isUploading, setIsUploading] = useState(false)

  //Template
  const { t } = useTranslation()
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()
  const name = props.name
  const image = props.image
  const acceptMime = props.acceptMime
  const fileSizeLimit = props.fileSizeLimit
  const onChangeDone = props.onChangeDone
  const onDelete = props?.onDelete
  const extraAction = props?.extraAction
  const disabled = (props.disabled === true)

  //Methods
  const beforeUpload = (file) => {
    let isValid = true

    //Check type mime
    if (acceptMime && acceptMime.indexOf(file.type) === -1) {
      notification.error({
        message: file.name,
        description: t('The type (%fileType) is not accepted').replace('%fileType', (file.type) ? file.type : 'unknown'),
        duration: null
      })
      isValid = false
    }

    //Check size limit
    if (isValid && fileSizeLimit) {
      if ((file.size / 1024 / 1024) > fileSizeLimit) {
        notification.error({
          message: file.name,
          description: t('File must smaller than %limitMB').replace('%limit', fileSizeLimit),
          duration: null
        })
        isValid = false
      }
    }

    return isValid
  }

  return (
    <div style={(image) ? (screens.md) ? styles.uploaderWImage : null : styles.uploader}>
      <div style={styles.uploaderImage} className="photoshop-bg photoshop-bg-dark">
        {image ? (
          <img
            src={image}
            alt={t('Picture')}
            style={{ width: 'auto', maxWidth: '100%' }}
          />
        ) : (
          <span style={{color:'#BBB'}}>{t('No picture')}</span>
        )}
      </div>
      <div style={(image) ? (screens.md) ? styles.uploaderContainerWImage : null : styles.uploaderContainer}>
        <Space direction={(screens.xs) ? 'vertical' : 'horizontal'} style={(!image) ? { marginTop:10 } : { padding:'10px 0' }}>
          <Upload
            name={name}
            listType="picture"
            showUploadList={false}
            customRequest={({onSuccess, onError, file}) => onSuccess()}
            beforeUpload={beforeUpload}
            onChange={(info) => {
              if (info.file.status === 'uploading') {
                setIsUploading(true)

              } else if (info.file.status === 'done') {
                setIsUploading(false)
                onChangeDone(info)
              }
            }}
          >
            <Button
              disabled={isUploading || disabled}
              type="primary"
              icon={(isUploading) ? <LoadingOutlined /> : <UploadOutlined />}
            >
              {(image) ? t('Change picture') : t('Upload a picture')}
            </Button>
          </Upload>
          {(image && typeof onDelete === 'function') && (
            <Popconfirm
              title={t('Are you sure to delete this picture?')}
              onConfirm={onDelete}
              okText={t('Yes')}
              cancelText={t('No')}
            >
              <Button
                type="primary"
                icon={<CloseOutlined />}
                disabled={isUploading || disabled}
                danger
              >
                {t('Delete picture')}
              </Button>
            </Popconfirm>
          )}
          {extraAction}
        </Space>
      </div>
    </div>
  )
}

//Styles CSS
const styles = {
  uploader: {display:'flex', flexDirection:'column', position:'relative'},
  uploaderWImage: {display:'flex', justifyContent:'center', position:'relative'},
  uploaderImage: {border:'1px dashed #CCC', display:'flex', padding:10, width:'100%', justifyContent:'center'},
  uploaderContainerWImage: {position:'absolute', margin:10, padding:'0 10px', bottom:10, backgroundColor:'rgba(0, 0, 0, 0.5)'},
  uploaderContainer: {},
}
