

export const removeInFileCollection = (fileId, filesCollection) => {
  return removesInFileCollection([fileId], filesCollection)
}

export const removesInFileCollection = (fileIds, filesCollection) => {
  if (!fileIds || !filesCollection) {
    return filesCollection
  }

  return filesCollection.filter(file => fileIds.includes(file.id) === false)
}

export const updateFileCollection = (updatedFile, filesCollection) => {
  return updatesFileCollection([updatedFile], filesCollection)
}

export const updatesFileCollection = (updatedFiles, filesCollection) => {
  if (!updatedFiles || !filesCollection) {
    return filesCollection
  }

  //Find key of updated file
  updatedFiles.forEach(updatedFile => {
    let fileKey = -1

    filesCollection.every((item, key) => {
      if (item.id === updatedFile.id) {
        fileKey = key

        return false
      }

      return true
    })

    //Update collection
    if (fileKey > -1) {
      filesCollection[fileKey] = updatedFile
    }
  })

  //Return
  return filesCollection
}


export const getIconFromReactIcons = (icon, label) => {
  return <span role="img" aria-label={label} className="anticon">{icon}</span>
}

export const getTablePagerInformations = (t, page, limit, total) => {
  const start = (page - 1) * limit
  const end = ((page - 1) * limit) + limit

  return t('Showing %start to %end of %total entries')
    .replace('%start', (start <= 0) ? 1 : start)
    .replace('%end', (end > total) ? total : end)
    .replace('%total', total)
}