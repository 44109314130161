import { memo, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { Context as AuthContext } from '../../context/AuthContext';
import { isContributor } from '../../utils/AccessRightUtils';

const FileDuplicateWidget = (props) => {
  const TAG = 'FileDuplicateWidget'

  //Template
  const { t } = useTranslation()
  const navigate = useNavigate()
  const file = props?.file
  const widgetType = props?.widgetType
  const widgetLabel = (props?.widgetLabel) ? props?.widgetLabel : t('Duplicate')
  const widgetProps = props?.widgetProps
  
  //States
  const { authState } = useContext(AuthContext)

  //Check right & availability
  if (!isContributor(authState)) {
    console.log(TAG, 'Bad rights')
    return null
  } else if (!file) {
    console.log(TAG, 'Bad file prop')
    return null
  }

  //Methods template
  const handleDuplicateFile = () => {
    navigate('/media/upload/duplicate/' + file.id)
  }

  const getWidget = () => {
    if (widgetType === 'menuItem') {
      return (
        <button
          type="button"
          className="menu-item-button"
          onClick={handleDuplicateFile}
        >
          <CopyOutlined /> {widgetLabel}
        </button>
      )
    } else if (widgetType === 'buttonIcon') {
      return (
        <Button
          shape='circle'
          type='dashed'
          icon={<CopyOutlined />}
          onClick={handleDuplicateFile}
          {...widgetProps}
        />
      )
    } else if (widgetType === 'button') {
      return (
        <Button
          icon={<CopyOutlined />}
          onClick={handleDuplicateFile}
          {...widgetProps}
        >
          {widgetLabel}
        </Button>
      )
    } else {
      return 'Unknow widget type'
    }
  }

  //Modal
  return getWidget()
}

export default memo(FileDuplicateWidget)
