import { memo, useEffect, useState, useContext, createRef } from 'react';
import { useTranslation } from "react-i18next";
import {
  Grid,
  Modal,
  Form,
  Input,
  Select,
  Switch,
  Button,
  Space,
  Divider
} from 'antd';
import { ModalContext } from "../../context/ModalContext";
import { Context as AuthContext } from '../../context/AuthContext';
import {
  APIRequest,
  isBadResponse,
  updateFolder,
  addFolder,
  delFolderThumb,
  delFolderCover
} from '../../models/APIRequest';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { axiosError, objectError, formError } from '../Notifications';
import ApiErrorResult from '../ApiErrorResult';
import FormItemLang from '../form/FormItemLang';
import FileTreeSelect from '../form/FileTreeSelect';
import ImageCropUploader from '../form/ImageCropUploader';
import { transformApiLangToFormLang, transformFormLangToFormData } from '../../utils/ApiUtils';
import { getBase64 } from '../../utils/FileUtils';
import { 
  GLOBAL_MODAL_TYPES,
  FOLDER_TYPES, LANGUAGE_DEFAULT_FORM_VALUE,
  THUMB_FILE_ACCEPT_MIME, THUMB_FILE_SIZE_LIMIT
} from "../../Constant";

const FolderModal = () => {
  const TAG = 'FolderModal'

  //Template
  const { t } = useTranslation()
  const { TextArea } = Input
  const { Option } = Select
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()
  const formRef = createRef()

  //States
  const DEFAULT_STATES = {
    idParent: null,
    type: null,
    title: LANGUAGE_DEFAULT_FORM_VALUE,
    description: LANGUAGE_DEFAULT_FORM_VALUE,
    content: LANGUAGE_DEFAULT_FORM_VALUE,
    active: null,
    cover: null,
    thumb: null
  }
  const { modalType, showModal, modalProps, closeModal, modalOnOk } = useContext(ModalContext)
  const isEditing = (modalProps?.id && modalProps?.folder)
  const { authState } = useContext(AuthContext)
  const [ formLang, setFormLang ] = useState(authState.language)
  const [ formLayout, setFormLayout ] = useState('horizontal')
  const [ states, setStates ] = useState(DEFAULT_STATES)
  const [data, setData] = useState({
    isSubmitting: false,
    submitError: false,
    submitErrorData: null,
    uploadedCover: null,
    uploadedCoverImage: null,
    uploadedThumb: null,
    uploadedThumbImage: null,
  })

  //Hooks
  useEffect(() => {
    if (modalType !== GLOBAL_MODAL_TYPES.FOLDER || !showModal) {
      return
    }

    if (isEditing) {
      setStates({
        type: modalProps.folder.type,
        title: transformApiLangToFormLang(modalProps.folder.langs.title),
        description: transformApiLangToFormLang(modalProps.folder.langs.description),
        content: transformApiLangToFormLang(modalProps.folder.langs.content),
        active: modalProps.folder.active,
        cover: modalProps.folder.cover,
        thumb: modalProps.folder.thumb
      })
    } else {
      setStates(DEFAULT_STATES)
    }
  }, [modalProps])

  useEffect(() => {
    setFormLayout((!screens.xl) ? 'vertical' : 'horizontal')
  }, [screens])

  //Template methods
  const handleCopyLang = (name, currentLang, languages) => {
    const value = {}

    languages.forEach(lang => {
      value[lang.route] = states[name][currentLang]
    })

    const newStates = {...states, [name]: value}

    formRef.current.setFieldsValue(newStates)
    setStates(newStates)
  }

  const submitForm = () => {
    console.log(TAG, 'submitForm')

    //Loading
    setData({...data,
      isSubmitting: true
    })

    //Make formData
    const formDatas = {
      ...transformFormLangToFormData({
        title: states.title,
        description: states.description,
        //content: states.content
      }),
      type: states.type,
      active: states.active,
      cover: data.uploadedCover?.originFileObj,
      thumb: data.uploadedThumb?.originFileObj,
    }

    if (!isEditing) {
      formDatas['id_parent'] = states.idParent
    }

    //Method
    const request = (isEditing)
      ? updateFolder(APIRequest(authState.language, authState.token, null, 0), modalProps.id, formDatas)
      : addFolder(APIRequest(authState.language, authState.token, null, 0), formDatas)

    request.then(response => {
      if (isBadResponse(response.data)) {
        setData({
          ...data,
          isSubmitting: false,
          submitError: true,
          submitErrorData: {
            code: response.data?.data?.code,
            message: response.data?.data?.message,
            extraData: response.data?.data?.extra_data
          }
        })

        return
      }

      resetModal()
      modalOnOk(response.data.data.folder, true)
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setData({
        ...data,
        isSubmitting: false,
        submitError: true,
        submitErrorData: {
          code: null,
          message: thrown
        }
      })
    })
  }

  const handleDeleteCover = () => {
    if (data.uploadedCover) {
      setData({...data, uploadedCover: null, uploadedCoverImage: null})
    } else if (states.cover) {
      delFolderCover(APIRequest(authState.language, authState.token, null, 0), modalProps.id).then(response => {
        if (isBadResponse(response.data)) {
          objectError(t)
          return
        }

        setStates({...states, cover: null})
        modalOnOk(response.data.data.folder, false)
      })
      .catch((thrown) => {
        console.log(TAG, thrown)
        axiosError(t)
      })
    }
  }

  const handleDeleteThumb = () => {
    if (data.uploadedThumb) {
      setData({...data, uploadedThumb: null, uploadedThumbImage: null})
    } else if (states.thumb) {
      delFolderThumb(APIRequest(authState.language, authState.token, null, 0), modalProps.id).then(response => {
        if (isBadResponse(response.data)) {
          objectError(t)
          return
        }

        setStates({...states, thumb: null})
        modalOnOk(response.data.data.folder, false)
      })
      .catch((thrown) => {
        console.log(TAG, thrown)
        axiosError(t)
      })
    }
  }

  const resetModal = () => {
    setData({
      ...data,
      isSubmitting: false,
      submitError: false,
      submitErrorData: null,
      uploadedCover: null,
      uploadedCoverImage: null,
      uploadedThumb: null,
      uploadedThumbImage: null,
    })
    setStates(DEFAULT_STATES)
  }

  const closeThisModal = () => {
    resetModal()
    closeModal()
  }

  //Template
  const formItemLayout = 
    (formLayout === 'horizontal')
      ? {labelCol:{span: 6}, wrapperCol:{span: 12}}
      : null

  return (
    <Modal
      title={(isEditing) ? modalProps.folder.title : t('Add folder')}
      open={modalType !== GLOBAL_MODAL_TYPES.FOLDER ? false : showModal}
      closable={!states.isSubmitting}
      zIndex={120}
      width={(!screens.md) ? 'calc(100% - 32px)' : 'calc(100% - 20%)'}
      destroyOnClose={true}
      onCancel={() => {
        closeThisModal()
      }}
      footer={
        <div style={{textAlign:'center'}}>
          <Space>
            <Button onClick={closeThisModal}>
              {t('Cancel')}
            </Button>
            <Button type="primary" loading={data.isSubmitting} disabled={data.isSubmitting} onClick={() => {
              formRef.current.submit()
            }}>
              {t('Save')}
            </Button>
          </Space>
        </div>
      }
    >
      {!data.isSubmitting && data.submitError && (
        <ApiErrorResult errorData={data.submitErrorData} template="alert" status="error" style={{marginBottom:20}} />
      )}

      <ImageCropUploader
        name="cover"
        image={data.uploadedCoverImage || states.cover}
        aspect={16/4}
        quality={1}
        acceptMime={THUMB_FILE_ACCEPT_MIME}
        fileSizeLimit={THUMB_FILE_SIZE_LIMIT}
        modalTitle={t('Customize image')}
        onChangeDone={(info) => {
          getBase64(info.file.originFileObj, (preview) => {
            setData({...data, uploadedCover: info.file, uploadedCoverImage: preview})
          })
        }}
        onDelete={handleDeleteCover}
      />

      <Divider />

      <Form
        ref={formRef}
        {...formItemLayout}
        layout={formLayout}
        initialValues={states}
        onValuesChange={(changedValues, allValues) => {
          setStates({ ...states, ...allValues })
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => formError(errorFields, t)}
        onFinish={() => {
          submitForm()
        }}
      >
        <Form.Item label={t('Thumbnail picture')}>
          <ImageCropUploader
            name="thumb"
            image={data.uploadedThumbImage || states.thumb}
            aspect={16/9}
            quality={1}
            acceptMime={THUMB_FILE_ACCEPT_MIME}
            fileSizeLimit={THUMB_FILE_SIZE_LIMIT}
            modalTitle={t('Customize image')}
            onChangeDone={(info) => {
              getBase64(info.file.originFileObj, (preview) => {
                setData({...data, uploadedThumb: info.file, uploadedThumbImage: preview})
              })
            }}
            onDelete={handleDeleteThumb}
          />
        </Form.Item>
        {!isEditing && (
          <Form.Item
            label={t('Parent folder')}
            name='idParent'
            rules={[
              {required: true, message: t('Parent folder is required')},
            ]}
          >
            <FileTreeSelect onlyActive={false} />
          </Form.Item>
        )}
        <Form.Item
          label={t('Type')}
          name='type'
          rules={[
            {required: true, message: t('Type is required')},
          ]}
        >
          <Select initialvalues={states.type} getPopupContainer={trigger => trigger.parentNode}>
            {FOLDER_TYPES.map(type => {
              let label = 'Undefined: ' + type

              if (type == 'product') {
                label = t('Product')
              } else if (type == 'default') {
                label = t('Default')
              }
              
              return <Option value={type} key={"type" + type}>{label}</Option>
            }
            )}
          </Select>
        </Form.Item>
        <FormItemLang
          label={t('Title')}
          name='title'
          required={true}
          rules={[
            {required: true, message: t('Title (%lang%) is required')},
            {max: 75, message: t('Title (%lang%) must not exceed %d% characters').replace('%d%', 75)},
          ]}
          currentLang={formLang}
          onLangChange={(value) => {setFormLang(value)}}
          onCopyLang={handleCopyLang}
        >
          <Input
            showCount
            maxLength={75}
          />
        </FormItemLang>
        <FormItemLang
          label={t('Description')}
          name='description'
          required={false}
          rules={[
            {max: 150, message: t('Description (%lang%) must not exceed %d% characters').replace('%d%', 150)},
          ]}

          currentLang={formLang}
          onLangChange={(value) => {setFormLang(value)}}
          onCopyLang={handleCopyLang}
        >
          <TextArea
            autoSize={{ minRows: 3, maxRows: 5 }}
            showCount
            maxLength={150}
          />
        </FormItemLang>
        <Form.Item
          label={t('Active')}
          name='active'
          required={true}
          extra={t('Show/Hide this folder, all subfolders and all associated media.')}
        >
          <Switch
            checked={states.active}
            checkedChildren={<><CheckOutlined /> {t('Yes')}</>}
            unCheckedChildren={<><CloseOutlined /> {t('No')}</>}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default memo(FolderModal)
