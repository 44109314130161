import { useState, useEffect, useContext, memo } from 'react';
import { useTranslation } from "react-i18next";
import { EditOutlined, DeleteOutlined, LoadingOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Typography, Space, Button, Popconfirm } from 'antd';
import { useNavigate } from "react-router-dom";
import { APIRequest, isBadResponse, getFolder, delFolder } from '../../models/APIRequest';
import { Context as AuthContext } from '../../context/AuthContext';
import ApiErrorResult from '../ApiErrorResult';
import { axiosError } from '../Notifications';
import {FOLDER_ROOT_ID, GLOBAL_MODAL_TYPES} from '../../Constant';
import { ModalContext } from "../../context/ModalContext";
import { MediaContext } from '../../context/MediaContext';
import { canEditFolder } from '../../utils/AccessRightUtils';
import { setStorageData, getStorageData } from '../../utils/StorageUtils';
import { nl2br } from '../../utils/StringUtils';

const FolderHeader = (props) => {
  const TAG = "FolderHeader"

  //Template
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { openModal, closeModal } = useContext(ModalContext)
  const { Title, Text } = Typography
  const idFolder = props?.idFolder
  const onDeleteFolder = props?.onDeleteFolder
  const onLoadError = props?.onLoadError

  //State
  const DEFAULT_STATES = {
    preloadStates: true,
    error: false,
    errorData: null,
    
    idFolder: parseInt(idFolder),

    isLoaded: false,
    folder: null
  }
  const { authState } = useContext(AuthContext)
  const { setRefreshFileTree } = useContext(MediaContext)
  const [ states, setStates ] = useState(DEFAULT_STATES)

  //Hooks
  useEffect(() => {
    if (!states.preloadStates) {
      return
    }

    console.log(TAG, '[]')

    processOldStates()
  }, [])

  useEffect(() => {
    if (states.preloadStates) {
      return
    }

    console.log(TAG, '[idFolder]')

    if (!idFolder) {
      console.log(TAG, 'idFolder not defined')
      return
    }

    setStates({
      ...DEFAULT_STATES,
      preloadStates: false,
      isLoaded: (!idFolder),
      contentIsLoaded: false
    })
  }, [idFolder])

  useEffect(() => {
    if (states.preloadStates || states.isLoaded) {
      return
    }

    console.log(TAG, '[states.preloadStates, states.isLoaded]')

    loadData()
  }, [states.preloadStates, states.isLoaded])

  useEffect(() => {
    if (states.preloadStates) {
      return
    }

    console.log(TAG, '[authState.language, authState.token]')

    setStates({
      ...states,
      isLoaded: false
    })

  }, [authState.language, authState.token])

  useEffect(() => {
    console.log(TAG, '[states]')

    try {
      setStorageData(TAG, JSON.stringify(states))
    } catch(e) {
      console.log(TAG, e)
    }
  }, [states])

  /************************************************* STATE METHODS ********************************************************/
  const processOldStates = () => {
    console.log(TAG, 'processOldStates')

    let oldStates = getStorageData(TAG)

    if (oldStates) {
      try {
        oldStates = JSON.parse(oldStates)

        if (oldStates && (oldStates.error || !oldStates.isLoaded || oldStates?.idFolder != idFolder)) { //Use != NOT !===
          oldStates = null
        
        }
      
      } catch(thrown) {
        console.log(TAG, thrown)
        oldStates = null
      }
    } else {
      oldStates = null
    }

    if (oldStates !== null) {
      console.log(TAG, 'processOldStates: oldStates use')

      setStates({
        ...DEFAULT_STATES,
        ...oldStates,
        preloadStates: false
      })
    
    } else {
      console.log(TAG, 'processOldStates: New states')

      setStates({
        ...DEFAULT_STATES,
        preloadStates: false
      })
    }
  }

  const loadData = () => {
    console.log(TAG, 'loadData - Getting data...')

    getFolder(APIRequest(authState.language, authState.token), states.idFolder).then(function (results) {
      if (isBadResponse(results.data)) {
        setStates({
          ...states,
          isLoaded: true,
          error: true,
          errorData: results.data.data,
        })

        return
      }
      setStates({
        ...states,
        isLoaded: true,
        error: false,
        errorData: null,
        folder: results.data.data.folder
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: true,
        error: true,
        errorData: null,
      })
    })
  }

  /*********************************************** TEMPLATE METHODS ********************************************************/
  const haveParentFolder = () => (states.isLoaded && states.folder?.id_parent > 0)

  const handleBack = () => {
    if (states.folder?.id_parent <= 0) {
      return
    }

    navigate('/media/explore/' + states.folder.id_parent)
  }
  
  const handleDel = () => {
    if (!states.folder?.id) {
      return
    }

    delFolder(APIRequest(authState.language, authState.token), states.folder.id).then((results) => {
      if (isBadResponse(results.data)) {
        axiosError(t)
        return
      }

      onDeleteFolder(states.folder.id, states.folder.id_parent)
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)
    })
  }

  const getFolderActions = () => {
    const actions = []

    if (!authState.isLogged || !canEditFolder(authState) || !states.folder) {
      return actions
    }

    return (
      <Space>
        <Button
          shape="circle"
          icon={<EditOutlined />}
          onClick={() => {
            openModal(
              GLOBAL_MODAL_TYPES.FOLDER,
              { id: states.folder.id, folder: states.folder },
              (folderUpdated, close) => {
                if (folderUpdated) {
                  setStates({...states, folder: folderUpdated })
                  setRefreshFileTree(true)
                }

                if (close) {
                  closeModal()
                }
              },
              null
            )
          }}
        />
        {idFolder !== FOLDER_ROOT_ID && (
          <Popconfirm
            title={
              <>
                {t('Are you sure to delete this folder?')}
                <br />
                {t('All subfolders and media inside this folder will be moved to its parent folder.')}
              </>
            }
            onConfirm={() => handleDel()}
            cancelButtonProps={{ type:'primary' }}
            okButtonProps={{ danger: true, type:'dashed' }}
            okText={t('Yes')}
            cancelText={t('No')}
            placement='bottomLeft'
          >
            <Button
              shape="circle"
              danger
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        )}
      </Space>
    )
  }

  if (states.error) {
    if (typeof onLoadError === 'function') {
      onLoadError(states.errorData)
    }
    
    return (
      <ApiErrorResult errorData={states.errorData} actionTitle={t('Try to reload')} template="alert" actionHandle={() => {
        setStates({
          ...states,
          preloadStates: false,
          isLoaded: false,
          error: false,
          errorData: null
        })
      }} />
    )
  }

  if (!states.isLoaded) {
    return (
      <div style={styles.container}>
        <div style={styles.titleContainer}>
          <Title level={4} style={styles.title}>
            <LoadingOutlined spin />
          </Title>
        </div>
      </div>
    )
  }

  return (
    <div style={{ ...styles.container, paddingLeft:(haveParentFolder()) ? 50 : 0 }}>
      {haveParentFolder() && (
        <Button type="primary" style={styles.back} onClick={handleBack}>
          <ArrowLeftOutlined style={{ fontSize:18 }} />
        </Button>
      )}
      <span style={styles.actionsContainer}>
        {getFolderActions()}
      </span>
      {(states.folder?.cover) && (
        <img src={states.folder.cover} alt="cover" className="img-fluid img-center" />
      )}
        <div style={(states.folder?.cover) ? styles.titleContainerWImage : styles.titleContainer}>
          <Title level={4} style={styles.title}>
            {states.folder?.title}
          </Title>
          {(states.folder?.description && states.folder.description !== "") && (
            <Text>
              {nl2br(states.folder.description)}
            </Text>
          )}
        </div>
    </div>
  )
}

const styles = {
  container: { position:'relative' },
  actionsContainer: { position:'absolute', top:10, right:10 },
  back: { position:'absolute', top:0, bottom:0, left:0, display:'block', width:50, height:'auto', zIndex:2 },
  titleContainer: { textAlign:'center', backgroundColor:'#FFF', padding:10 },
  titleContainerWImage: { position:'absolute', left:0, right:0, bottom:0, padding:10, textAlign:'center', backgroundColor:'rgba(225, 225, 225, .8)', zIndex:1},
  title: { textAlign:'center', margin:0 }
}

export default memo(FolderHeader)