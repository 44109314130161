import CryptoJS from 'crypto-js'
import { ENCRYPT_KEY } from '../Constant'

export const getStorageData = (key) => {
  const data = localStorage.getItem(key)

  if (!data) {
    return null
  }

  return decodeData(data)
}

export const setStorageData = (key, data) => {
  if (!data) {
    return
  }

  return localStorage.setItem(key, encodeData(data))
}

export const delStorageData = (key) => {
  return localStorage.removeItem(key)
}

export const delMediaStorage = () => {
  delStorageData('MediaScreen')
  delStorageData('FolderHeader')
  delStorageData('SubFolder')
  delStorageData('MediaFilterForm')
  delStorageData('FileTree')
}

const encodeData = (data) => {
  return CryptoJS.AES.encrypt(data, ENCRYPT_KEY, {
    keySize: 128 / 8,
    iv: ENCRYPT_KEY,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }).toString()
}

const decodeData = (data) => {
  return CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(data, ENCRYPT_KEY, {
    keySize: 128 / 8,
    iv: ENCRYPT_KEY,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }))
}
