import { useEffect, useState, useContext, memo } from 'react';
import { useTranslation } from "react-i18next";
import { Menu, Modal, notification } from 'antd';
import {
  HomeOutlined, ApartmentOutlined, DeleteOutlined, TagsOutlined, UserOutlined, ShareAltOutlined,
  FileOutlined, FileSyncOutlined, CopyrightOutlined, EyeOutlined
} from '@ant-design/icons';
import { useNavigate, useLocation } from "react-router-dom";
import { Context as AuthContext } from '../../context/AuthContext';
import { APIRequest, isBadResponse, clearCache } from '../../models/APIRequest';
import { axiosError } from '../Notifications';
import { green } from '@ant-design/colors';
import { getIconFromReactIcons } from '../../utils/TemplateUtils';
import { isSuperAdmin } from '../../utils/AccessRightUtils';

const AdminMenu = (props) => {
  const TAG = 'AdminMenu'

  //Template
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  //States
  const { authState } = useContext(AuthContext)
  const [ menuSelectedKeys, setMenuSelectedKeys ] = useState(null)

  //Hooks
  useEffect(() => {
    const keys = []

    if (location.pathname.match(/\/admin$/)) {
      keys.push('home')
    } else {
      const matches = location.pathname.match(/\/admin\/([a-zA-Z0-9._-]{1,})$/)
      
      if (matches && matches[1]) {
        keys.push(matches[1])
      }
    }

    setMenuSelectedKeys(keys)
  }, [location])
  
  //States
  const { signout } = useContext(AuthContext)

  const items = [
    { label: t('Home page'), key: 'home', icon: <HomeOutlined /> },
    { label: t('Folder tree'), key: 'folder-tree', icon: <ApartmentOutlined /> },
    { label: t('Media'), key: 'file', icon: <FileOutlined /> },
    { label: t('Stickers'), key: 'sticker', icon: <TagsOutlined /> },
    { label: t('Trash'), key: 'trash', icon: <DeleteOutlined /> },
    { type: 'divider' },
    { label: t('Shares'), key: 'share', icon: <ShareAltOutlined /> },
    { label: t('Watermarks'), key: 'watermark', icon: <CopyrightOutlined /> },
    { label: t('Users'), key: 'user', icon: <UserOutlined /> },
    { label: t('Broadcast restrictions'), key: 'broadcast', icon: <EyeOutlined /> }
  ]

  if (isSuperAdmin(authState)) {
    items.push({ type: 'divider' })
    items.push({ label: t('Clear system cache'), key: 'clear-cache', icon: <FileSyncOutlined /> })
  }

  const handleClearCache = (callback) => {
    Modal.confirm({
      title: t('Clear system cache'),
      content: t('Are you sure you want to clear system cache?'),
      okText: t('Yes'),
      cancelText: t('Cancel'),
      onOk: () => {
        clearCache(APIRequest(authState.language, authState.token)).then(function (results) {
          if (isBadResponse(results.data)) {
            axiosError(t)

            return
          }

          notification.success({
            message: t('System cache'),
            description: t('The system cache has been cleared'),
            placement: 'bottomRight',
            icon: getIconFromReactIcons(<FileSyncOutlined style={{color:green.primary}} />)
          })
        })
        .catch((thrown) => {
          console.log(TAG, thrown)
          axiosError(t)
        })
      }
    })
  }

  return <Menu
    {...props}
    items={items}
    selectedKeys={menuSelectedKeys}
    onClick={({ item, key }) => {
      if (key === 'logout') {
        signout()
      } else if (key === 'clear-cache') {
        handleClearCache()
      } else {
        navigate('/admin' + ((key !== 'home') ? '/' + key : ''))
      }
    }}
  />
}

export default memo(AdminMenu)
