import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Space, Button, Divider, Badge, Typography, Table, Checkbox, Switch, Alert } from 'antd';
import { CheckOutlined, CloseOutlined, EditOutlined, RollbackOutlined, StopOutlined } from '@ant-design/icons';
import { getFileType, getMimeFileType } from '../../../utils/FileUtils';
import { FILE_TYPE_IMAGE } from '../../../Constant';

export default function MediaBroadcastContent(props) {
  const TAG = 'MediaBroadcastContent'

  if (!props?.formDatas) {
    throw new Error(TAG + ' - PROPS formDatas is undefined')
  } else if (!props?.filesStates) {
    throw new Error(TAG + ' - PROPS filesStates is undefined')
  } else if (!props?.setBroadcastStates) {
    throw new Error(TAG + ' - PROPS setBroadcastStates is undefined')
  } else if (props?.parametersStates === undefined) {
    throw new Error(TAG + ' - PROPS parametersStates is undefined')
  }

  //Template
  const { t } = useTranslation()
  const { Text, Title } = Typography

  //States
  const formDatas = props.formDatas
  const setBroadcastStates = props.setBroadcastStates
  const [states, setStates] = useState({})
  const [data, setData] = useState({
    mediaType: [],
    wantEdit: false,
    broadcasts: [],
    checkedAll: {},
    indeterminate: {},
    files: props.filesStates,
    parameters: props.parametersStates,
    haveWatermark: (props.parametersStates.id_watermark !== null)
  })

  //Event
  useEffect(() => {
    console.log(TAG, '[]')

    //Have watermark
    const haveWatermark = (props.parametersStates.id_watermark !== null)
    console.log(TAG, 'haveWatermark: ' + haveWatermark)

    //Get media types
    const mediaType = []

    data.files.forEach(item => {
      const type = getMimeFileType(item.type)

      if (!mediaType.some(item => item.type === type)) {
        mediaType.push({
          ...getFileType(type, t),
          fileCount: data.files.filter(file => getMimeFileType(file.type) === type).length
        })
      }
    })

    //get default state
    const broadcastsDefaultStates = getBroadcastsDefaultStates(
      mediaType,
      formDatas.broadcasts,
      haveWatermark
    )
    
    setData({
      ...data,
      mediaType: mediaType,
      checkedAll: broadcastsDefaultStates.checkedAll,
      indeterminate: broadcastsDefaultStates.indeterminate,
      files: props.filesStates,
      parameters: props.parametersStates,
      haveWatermark: haveWatermark,
    })
    setStates(broadcastsDefaultStates.defaultStates)
    setBroadcastStates(null)
  }, [])

  //Event method
  const getBroadcastsDefaultStates = (mediaType, broadcasts, haveWatermark) => {
    //Check
    if (!data.mediaType) {
      return
    }

    //Init
    const checkedAll = {}
    const indeterminate = {}
    const defaultStates = {}

    mediaType.forEach(type => {
      const _type = type.type

      defaultStates[_type] = {
        can_share: [],
        can_download: [],
        dl_watermark: [],
      }

      broadcasts.forEach(broadcast => {
        if (broadcast.can_share) {
          defaultStates[_type]['can_share'].push(broadcast.id)
        }
        if (broadcast.can_download) {
          defaultStates[_type]['can_download'].push(broadcast.id)
        }
        if (_type === FILE_TYPE_IMAGE && haveWatermark && broadcast.dl_watermark) {
          defaultStates[_type]['dl_watermark'].push(broadcast.id)
        }
      })

      const broadcastCanShare = broadcasts.filter(item => (item.can_share)).length
      const broadcastCanDownload = broadcasts.filter(item => (item.can_download)).length
      const broadcastDlWatermark = broadcasts.filter(item => (item.dl_watermark)).length

      checkedAll[_type] = {
        can_share: (defaultStates[_type]['can_share'].length === broadcastCanShare),
        can_download: (defaultStates[_type]['can_download'].length === broadcastCanDownload),
        dl_watermark: (haveWatermark && defaultStates[_type]['dl_watermark'].length === broadcastDlWatermark),
      }
      indeterminate[_type] = {
        can_share: (defaultStates[_type]['can_share'].length && defaultStates[_type]['can_share'].length < broadcastCanShare),
        can_download: (defaultStates[_type]['can_download'].length && defaultStates[_type]['can_download'].length < broadcastCanDownload),
        dl_watermark: (haveWatermark && defaultStates[_type]['dl_watermark'].length && defaultStates[_type]['dl_watermark'].length < broadcastDlWatermark),
      }
    })

    return {
      checkedAll: checkedAll,
      indeterminate: indeterminate,
      defaultStates: defaultStates
    }
  }

  //Template method
  const onSubmit = () => {
    if (!data.wantEdit) {
      setBroadcastStates({})
    }

    props.onFinish()
  }

  const handleWantEdit = (bool) => {
    //Restore default
    if (!bool) {
      const broadcastsDefaultStates = getBroadcastsDefaultStates(
        data.mediaType,
        formDatas.broadcasts,
        data.haveWatermark
      )

      setData({
        ...data,
        wantEdit: bool,
        checkedAll: broadcastsDefaultStates.checkedAll,
        indeterminate: broadcastsDefaultStates.indeterminate,
      })
      setStates(broadcastsDefaultStates.defaultStates)
    
    //Edit
    } else {
      setData({
        ...data,
        wantEdit: true,
      })
    }

    setBroadcastStates(bool ? states : null)
  }

  const onCheckAllChange = (e, fileType, column) => {
    //Init
    const broadcasts = []
    const _states = Object.assign({}, states)
    const checkedAll = Object.assign({}, data.checkedAll)
    const indeterminate = Object.assign({}, data.indeterminate)

    //Prepare object data
    if (checkedAll[fileType] === undefined) {
      checkedAll[fileType] = {}
    }
    if (indeterminate[fileType] === undefined) {
      indeterminate[fileType] = {}
    }
    if (_states[fileType] === undefined) {
      _states[fileType] = {
        can_share: [],
        can_download: [],
        dl_watermark: [],
      }
    }

    //Check all
    if (e.target.checked) {
      formDatas.broadcasts.forEach(item => {
        if (item[column] === null) {
          return
        }

        broadcasts.push(item.id)
      })

      checkedAll[fileType][column] = true
      indeterminate[fileType][column] = false
    } else {
      checkedAll[fileType][column] = false
      indeterminate[fileType][column] = false
    }

    //Update state
    _states[fileType][column] = broadcasts

    setData({
      ...data,
      checkedAll: checkedAll,
      indeterminate: indeterminate
    })
    setStates(_states)
    setBroadcastStates(_states)
  }

  const onSwitchChange = (bool, broadcastId, fileType, column) => {
    let _states = Object.assign({}, states)
    let _data = Object.assign({}, data)

    if (bool) {
      _states[fileType][column].push(broadcastId)
    } else {
      _states[fileType][column] = _states[fileType][column].filter(item => item !== broadcastId)
    }

    const broadcastLength = formDatas.broadcasts.filter(item => (item[column])).length

    _data.checkedAll[fileType][column] = (_states[fileType][column].length === broadcastLength)
    _data.indeterminate[fileType][column] = (_states[fileType][column].length && _states[fileType][column].length < broadcastLength)

    setData(_data)
    setStates(_states)
    setBroadcastStates(_states)
  }

  const getIndeterminate = (fileType, column) => {
    return (data.indeterminate[fileType] !== undefined && data.indeterminate[fileType][column] === true)
  }

  const getCheckAll = (fileType, column) => {
    return (data.checkedAll[fileType] !== undefined && data.checkedAll[fileType][column] === true)
  }

  const getTable = (fileType) => {
    //Set columns
    const columns = [
      {
        title: t('Groups'),
        dataIndex: 'name',
        render: (name, record) => {
          if (record.id) {
            return <Text ellipsis={true}>{name}</Text>
          } else {
            return <Text ellipsis={true} strong>{name}</Text>
          }
        }
      },
      {
        title: t('Can be shared?'),
        dataIndex: 'can_share',
        render: (can_share, record) => {
          const disabled = (!data.wantEdit)

          if (can_share === null) {
            return <Text disabled={disabled}><StopOutlined /> {t('Not available')}</Text>
          }

          if (record.id) {
            return <Switch
              checkedChildren={<><CheckOutlined /> {t('Yes')}</>}
              unCheckedChildren={<><CloseOutlined /> {t('No')}</>}
              checked={states[fileType]['can_share'].some(item => item === record.id)}
              onChange={(can_share) => onSwitchChange(can_share, record.id, fileType, 'can_share')}
              style={{ width:'100%'}}
              disabled={disabled}
            />
          } else {
            return (
              <Checkbox
                indeterminate={getIndeterminate(fileType, 'can_share')}
                checked={getCheckAll(fileType, 'can_share')}
                onChange={(e) => onCheckAllChange(e, fileType, 'can_share')}
                disabled={disabled}
              >
                {t('Check all')}
              </Checkbox>
            )
          }
        }
      },
      {
        title: t('Can be downloaded?'),
        dataIndex: 'can_download',
        render: (can_download, record) => {
          const disabled = (!data.wantEdit)

          if (can_download === null) {
            return <Text disabled={disabled}><StopOutlined /> {t('Not available')}</Text>
          }

          if (record.id) {
            return <Switch
              checkedChildren={<><CheckOutlined /> {t('Yes')}</>}
              unCheckedChildren={<><CloseOutlined /> {t('No')}</>}
              checked={states[fileType]['can_download'].some(item => item === record.id)}
              onChange={(can_download) => onSwitchChange(can_download, record.id, fileType, 'can_download')}
              style={{ width:'100%'}}
              disabled={disabled}
            />
          } else {
            return (
              <Checkbox
                indeterminate={getIndeterminate(fileType, 'can_download')}
                checked={getCheckAll(fileType, 'can_download')}
                onChange={(e) => onCheckAllChange(e, fileType, 'can_download')}
                disabled={disabled}
              >
                {t('Check all')}
              </Checkbox>
            )
          }
        }
      }
    ]

    if (fileType === FILE_TYPE_IMAGE) {
      columns.push({
        title: t('Downloaded with watermark?'),
        dataIndex: 'dl_watermark',
        render: (dl_watermark, record) => {
          const disabled = (!data.wantEdit || !data.haveWatermark)

          if (dl_watermark === null) {
            return <Text disabled={disabled}><StopOutlined /> {t('Not available')}</Text>
          }

          if (record.id) {
            return <Switch
              checkedChildren={<><CheckOutlined /> {t('Yes')}</>}
              unCheckedChildren={<><CloseOutlined /> {t('No')}</>}
              checked={states[fileType]['dl_watermark'].some(item => item === record.id)}
              onChange={(dl_watermark) => onSwitchChange(dl_watermark, record.id, fileType, 'dl_watermark')}
              disabled={disabled}
              style={{ width:'100%'}}
            />
          } else {
            return (
              <Checkbox
                indeterminate={getIndeterminate(fileType, 'dl_watermark')}
                checked={getCheckAll(fileType, 'dl_watermark')}
                onChange={(e) => onCheckAllChange(e, fileType, 'dl_watermark')}
                disabled={disabled}
              >
                {t('Check all')}
              </Checkbox>
            )
          }
        }
      })
    }

    //Set rows data
    const dataSource = []

    dataSource.push({
      key: fileType + '_' + 0,
      id: null,
      name: null,
      level: 0
    })

    formDatas.broadcasts.forEach(item => {
      dataSource.push({
        key: fileType + '_' + item.id,
        id: item.id,
        name: item.name,
        level: item.level,
        can_share: item.can_share,
        can_download: item.can_download,
        dl_watermark: item.dl_watermark,
      })
    })

    //Return table
    return (
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          current: 1,
          pageSize: 999,
          total: formDatas.broadcasts.length,
          position: ['none', 'none'],
        }}
      />
    )
  }

  return (
    <div style={{ maxWidth:800, margin:'0 auto' }}>
      <Alert
        message={t('Broadcast restrictions')}
        description={
          data.wantEdit ? (
            <Space direction="vertical">
              <Button icon={<RollbackOutlined />} onClick={() => handleWantEdit(false)}>{t('Restore to default')}</Button>
            </Space>
          ) : (
            <Space direction="vertical">
              <>{t('You can change this default broadcast restrictions to the one you want.')}</>
              <Button icon={<EditOutlined />} onClick={() => handleWantEdit(true)}>{t('Modify')}</Button>
            </Space>
          )
        }
        type="info"
        showIcon
        style={{ marginBottom:50, textAlign:'center' }}
      />

      {data.mediaType.map(item => {
        return (
          <div key={'broadcast' + item.type}>
            <Divider orientation="left">
              <Badge count={item.fileCount} offset={[10, 0]}>
                <Title level={2}>{item.icon} {item.label}</Title>
              </Badge>
            </Divider>
            {getTable(item.type)}
          </div>
        )
      })}

      <div style={{textAlign:'center', marginTop:40 }}>
        <Space>
          <Button onClick={props.onBack}>
            {t('Previous')}
          </Button>
          <Button type="primary" onClick={onSubmit}>
            {t('Submit')}
          </Button>
        </Space>
      </div>
    </div>
  )
}