import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Spin, Typography } from 'antd';

const LoadMoreBlock = () => {
  const { t } = useTranslation()
  const { Text } = Typography

  return (
    <div style={{ padding:20, textAlign:'center' }}>
      <Spin /><br /><Text type='secondary'>{t('Loading more media...')}</Text>
    </div>
  )
}

export default memo(LoadMoreBlock)