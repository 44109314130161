import { Typography, notification } from 'antd';
import { MdOutlineRemoveShoppingCart, MdOutlineAddShoppingCart } from "react-icons/md";
import { green, red } from '@ant-design/colors';
import { getIconFromReactIcons } from '../utils/TemplateUtils';

const defaultDuration = 2.5

export function axiosError(t) {
  notification.error({
    key: 'notificationAxiosError',
    duration: defaultDuration,
    message: t('Unable to connect to server'),
    description: 'Check that you are connected to the Internet'
  })
}

export function objectError(t) {
  notification.error({
    key: 'notificationObjectError',
    duration: defaultDuration,
    message: t('Unable to load object'),
    description: t('Please contact SODIKART technical support if the problem persists')
  })
}

export function addToCart(t, file) {
  notification.success({
    duration: defaultDuration,
    message: t('Media added to the cart'),
    description: file.title,
    placement: 'topRight',
    icon: getIconFromReactIcons(<MdOutlineAddShoppingCart style={{color:green.primary}} />)
  })
}

export function maxFileInCart(t) {
  notification.warning({
    key: 'notificationMaxFileInCart',
    duration: defaultDuration,
    message: t('You have reached the maximum number of media in the basket'),
    placement: 'topRight',
  })
}

export function removeFromCart(t, file) {
  notification.success({
    duration: defaultDuration,
    message: t('Media removed from cart'),
    description: file.title,
    placement: 'topRight',
    icon: getIconFromReactIcons(<MdOutlineRemoveShoppingCart style={{color:red.primary}} />)
  })
}

export function formError(errorFields, t) {
  const errors = []

  errorFields.forEach(item => {
    item.errors.forEach(text => {
      errors.push(text)
    })
  })

  notification.error({
    key: 'notificationFormError',
    message: t('Error while validating the form.'),
    description: (
      <>
        <Typography.Text strong>{t('Check fields bellow:')}</Typography.Text>
        <ul style={{ margin:0, padding:10 }}>
          {errors.map(error => {
            return <li>{error}</li>
          })}
        </ul>
      </>
    ),
    duration: 4
  })
}
