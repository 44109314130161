import { memo, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Context as AuthContext } from '../../context/AuthContext';
import { ModalContext } from "../../context/ModalContext";
import { isContributor } from '../../utils/AccessRightUtils';
import { GLOBAL_MODAL_TYPES } from "../../Constant";

const FileEditWidget = (props) => {
  const TAG = 'FileEditWidget'

  //Template
  const { t } = useTranslation()
  const { Text } = Typography
  const file = props?.file
  const files = props?.files
  const widgetType = props?.widgetType
  const widgetLabel = (props?.widgetLabel) ? props.widgetLabel : t('Edit')
  const widgetProps = props?.widgetProps
  const onCancel = props?.onCancel
  const onUpdateFile = props?.onUpdateFile
  const onDeleteFile = props?.onDeleteFile
  const onUpdateFiles = props?.onUpdateFiles
  const onDeleteFiles = props?.onDeleteFiles
  
  //States
  const { authState } = useContext(AuthContext)
  const { openModal, closeModal } = useContext(ModalContext)

  //Check right & availability
  if (!isContributor(authState)) {
    console.log(TAG, 'Bad rights')
    return null
  }

  const handleEditFile = () => {
    console.log(TAG, 'handleEditFile')

    //Init params
    let params
    let bulk = false

    if (files && files[0]) {
      params = { id: files[0]['id'], file: files[0], files: files }
      bulk = true
    } else if (file) {
      params = { id: file.id, file: file }
    } else {
      return
    }

    openModal(
      GLOBAL_MODAL_TYPES.FILE_EDIT,
      params,
      (updated, close) => {
        if (updated) {
          if (bulk) {
            const updateFiles = []
            const deleteFiles = []

            updated.forEach(item => {
              let currentFile = files.filter(file => file.id === item.id)

              if (currentFile && currentFile[0] && currentFile[0].folder.id !== item.folder.id) {
                deleteFiles.push(item.id)
              } else {
                updateFiles.push(item)
              }
            })

            if (updateFiles.length && typeof onUpdateFiles === 'function') {
              onUpdateFiles(updateFiles)
            }
            if (deleteFiles.length && typeof onDeleteFiles === 'function') {
              onDeleteFiles(deleteFiles)
            }

          } else {
            if (updated.folder.id !== file.folder.id && typeof onDeleteFile === 'function') {
              onDeleteFile(updated)
            } else if (typeof onUpdateFile === 'function') {
              console.log(TAG, 'onUpdateFile')
              onUpdateFile(updated)
            }
          }
        }

        if (close) {
          closeModal()
        }
      },
      () => {
        if (typeof onCancel === 'function') {
          onCancel()
        }
      }
    )
  }

  const getWidget = () => {
    if (widgetType === 'menuItem') {
      return (
        <button
          type="button"
          className="menu-item-button"
          onClick={handleEditFile}
        >
          <Text {...widgetProps}><EditOutlined /> {widgetLabel}</Text>
        </button>
      )
    } else if (widgetType === 'button') {
      return (
        <Button
          icon={<EditOutlined />}
          onClick={handleEditFile}
          {...widgetProps}
        >
          {widgetLabel}
        </Button>
      )
    } else if (widgetType === 'buttonIcon') {
      return (
        <Button
          shape='circle'
          type='dashed'
          icon={<EditOutlined />}
          onClick={handleEditFile}
          {...widgetProps}
        />
      )
    } else {
      return (<>Widget type not found ({widgetType})</>)
    }
  }

  //Modal
  return getWidget()
}

export default memo(FileEditWidget)
