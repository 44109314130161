import { useState, useEffect, useContext } from "react";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import { Steps, Modal, Spin, Result, Button, Divider, Progress, Typography } from 'antd';
import { FormOutlined, SettingOutlined, UploadOutlined, CloudOutlined, ShareAltOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { 
  APIRequest, isBadResponse,
  getTagAc, getAuthorAc, getLocationAc, getCountries, getAccessRights, getStickers, getWatermarks, getAllBroadcastRestrictions,
  uploadFile, addFiles, getFile
} from '../../models/APIRequest';
import { Context as AuthContext } from '../../context/AuthContext';
import { axiosError } from '../../components/Notifications';
import LoadingBlock from '../../components/LoadingBlock';
import MediaInformationsContent from './upload/MediaInformationsContent';
import MediaParametersContent from './upload/MediaParametersContent';
import MediaUploadContent from './upload/MediaUploadContent';
import MediaBroadcastContent from './upload/MediaBroadcastContent';
import { delMediaStorage } from '../../utils/StorageUtils';
import { 
  transformFormLangToFormData, transformObjectToFormData,
  transformApiLangToFormLang, transformApiObjectToFormData
} from '../../utils/ApiUtils';
import { convertFileSizeToHuman } from '../../utils/FileUtils';
import { FILE_STATUS_DRAFT, UPLOAD_CHUNK_SIZE, LANGUAGE_DEFAULT_FORM_VALUE, LANGUAGE_DEFAULT_FORM_MULTIPLE_VALUE, DEFAULT_UPLOAD_PROGRESS_DATA } from '../../Constant';

export default function MediaUploadScreen() {
  const TAG = 'MediaUploadScreen'

  //Template
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const { authState } = useContext(AuthContext)
  const [ updateBreadcrumbs ] = useOutletContext()
  const { Step } = Steps
  const { Text } = Typography
  const idFile = (params?.idFile) ? params.idFile : null
  const steps = [
    {
      key: 0,
      title: t('Upload media'),
      description: t('Choice media(s) to upload'),
      icon: <UploadOutlined />
    },
    {
      key: 1,
      title: t('Informations'),
      description: t('About media'),
      icon: <FormOutlined />
    },
    {
      key: 2,
      title: t('Parameters'),
      description: t('Folder, access rights...'),
      icon: <SettingOutlined />,
    },
    {
      key: 3,
      title: t('Broadcasting'),
      description: t('Broadcast restrictions'),
      icon: <ShareAltOutlined />
    },
  ]

  //States
  const DEFAULT_STATES = {
    currentStep: 0,
    isSubmitting: false,
    isUploading: false,
    submitError: false,
    submitErrorData: null,
    submitModalVisible: false,
    uploadProgress: 0,
    informations: {
      title: LANGUAGE_DEFAULT_FORM_VALUE,
      description: LANGUAGE_DEFAULT_FORM_VALUE,
      youtube_code: '',
      keywords: LANGUAGE_DEFAULT_FORM_MULTIPLE_VALUE,
      id_country: null,
      media_date: '',
      media_author: '',
      media_location: ''
    },
    parameters: {
      status: FILE_STATUS_DRAFT,
      access_rights: [],
      stickers: [],
      id_folder: null,
      folders: [],
      id_watermark: null,
      terms_of_use: LANGUAGE_DEFAULT_FORM_VALUE
    },
    files: [],
    broadcasts: {},
    broadcastsRefresh: moment().unix(),
  }
  const [states, setStates] = useState(DEFAULT_STATES)
  const [ data, setData ] = useState({
    isLoaded: false,
    tags: [],
    authors: [],
    countries: [],
    locations: [],
    accessRights: [],
    accessRightGroups: [],
    stickers: [],
    watermarks: [],
    originalFile: null
  })
  const [ uploadedFilesCount, setUploadedFilesCount ] = useState(0)
  const [ fileToBeUpload, setFileToBeUpload ] = useState(null)
  const [ uploadProgress, setUploadProgress ] = useState(DEFAULT_UPLOAD_PROGRESS_DATA)

  //Event
  useEffect(() => {
    if (updateBreadcrumbs) {
      console.log(TAG, 'updateBreacrumbs')
      updateBreadcrumbs([
        {title: t('Upload'), 'route': null}
      ])
    }

    loadData()
  }, [])

  /*useEffect(() => {
    if (!data.originalFile) {
      return
    }

    setStates({
      ...states,
      informations: {
        title: transformApiLangToFormLang(data.originalFile.langs.title),
        description: transformApiLangToFormLang(data.originalFile.langs.description),
        youtube_code: data.originalFile.youtube_code,
        keywords: transformApiLangToFormLang(data.originalFile.langs.keywords, true),
        id_country: (data.originalFile?.country?.id) ? data.originalFile.country.id : null,
        media_date: (data.originalFile.media_date) ? moment(data.originalFile.media_date) : null,
        media_author: data.originalFile.media_author,
        media_location: data.originalFile.media_location
      },
      parameters: {
        status: data.originalFile.status,
        access_rights: transformApiObjectToFormData(data.originalFile.access_rights, 'id'),
        stickers: transformApiObjectToFormData(data.originalFile.stickers, 'id'),
        id_folder: data.originalFile.folder.id,
        folders: transformApiObjectToFormData(data.originalFile.folders, 'id'),
        id_watermark: (data.originalFile?.id_watermark) ? data.originalFile.id_watermark : null,
        terms_of_use: transformApiLangToFormLang(data.originalFile.langs.terms_of_use),
        can_share: data.originalFile.can_share,
        can_download: data.originalFile.can_download,
      },
    })

  }, [data.originalFile])*/

  //Load
  const loadData = () => {
    console.log(TAG, 'loadData')

    if (data.isLoaded) {
      return
    }

    console.log(TAG, 'Getting data...')

    const requests = [
      getTagAc(APIRequest(authState.language, authState.token)),
      getAuthorAc(APIRequest(authState.language, authState.token)),
      getCountries(APIRequest(authState.language, authState.token)),
      getLocationAc(APIRequest(authState.language, authState.token)),
      getAccessRights(APIRequest(authState.language, authState.token)),
      getStickers(APIRequest(authState.language, authState.token)),
      getWatermarks(APIRequest(authState.language, authState.token)),
      getAllBroadcastRestrictions(APIRequest(authState.language, authState.token)),
    ]

    if (idFile) {
      requests.push(getFile(APIRequest(authState.language, authState.token), idFile))
    }

    Promise.all(requests)
    .then(function (results) {
      const tags = results[0]
      const tagsOption = []
      const authors = results[1]
      const authorsOption = []
      const countries = results[2]
      const countriesOption = []
      const locations = results[3]
      const locationsOption = []
      const accessRights = results[4]
      const accessRightsOption = []
      const stickers = results[5]
      const stickersOption = []
      const watermarks = results[6]
      const watermarksOption = []
      const broadcasts = results[7]
      const broadcastsOption = []
      let originalFile = null

      //Tags
      if (!isBadResponse(tags.data, false)) {
        tags.data.data.keywords.forEach((item) => {
          tagsOption.push(item)
        })
      } else {
        axiosError(t)
      }

      //Authors
      if (!isBadResponse(authors.data, false)) {
        authors.data.data.authors.forEach((item) => {
          authorsOption.push({value: item, label: item})
        })
      } else {
        axiosError(t)
      }

      //Countries
      if (!isBadResponse(countries.data, false)) {
        countries.data.data.countries.forEach((item) => {
          countriesOption.push(item)
        })

      } else {
        axiosError(t)
      }

      //Locations
      if (!isBadResponse(locations.data, false)) {
        locations.data.data.locations.forEach((item) => {
          locationsOption.push({value: item, label: item})
        })
        
      } else {
        axiosError(t)
      }

      //accessRights
      if (!isBadResponse(accessRights.data, false)) {
        accessRights.data.data.access_rights.forEach((item) => {
          accessRightsOption.push(item)
        })
      } else {
        axiosError(t)
      }

      //Stickers
      if (!isBadResponse(stickers.data, false)) {
        stickers.data.data.stickers.forEach((item) => {
          stickersOption.push(item)
        })
      } else {
        axiosError(t)
      }

      //Watermarks
      if (!isBadResponse(watermarks.data, false)) {
        watermarks.data.data.watermarks.forEach((item) => {
          watermarksOption.push(item)
        })
      } else {
        axiosError(t)
      }

      //Watermarks
      if (!isBadResponse(broadcasts.data, false)) {
        broadcasts.data.data.broadcast_restrictions.forEach((item) => {
          broadcastsOption.push(item)
        })
      } else {
        axiosError(t)
      }

      if (idFile) {
        if (!isBadResponse(results[8].data, false)) {
          originalFile = results[8].data.data.file

          setStates({
            ...states,
            informations: {
              title: transformApiLangToFormLang(originalFile.langs.title),
              description: transformApiLangToFormLang(originalFile.langs.description),
              youtube_code: originalFile.youtube_code,
              keywords: transformApiLangToFormLang(originalFile.langs.keywords, true),
              id_country: (originalFile?.country?.id) ? originalFile.country.id : null,
              media_date: (originalFile.media_date) ? moment(originalFile.media_date) : null,
              media_author: originalFile.media_author,
              media_location: originalFile.media_location
            },
            parameters: {
              status: originalFile.status,
              access_rights: transformApiObjectToFormData(originalFile.access_rights, 'id'),
              stickers: transformApiObjectToFormData(originalFile.stickers, 'id'),
              id_folder: originalFile.folder.id,
              folders: transformApiObjectToFormData(originalFile.folders, 'id'),
              id_watermark: (originalFile?.id_watermark) ? originalFile.id_watermark : null,
              terms_of_use: transformApiLangToFormLang(originalFile.langs.terms_of_use),
              can_share: originalFile.can_share,
              can_download: originalFile.can_download,
            },
          })

        } else {
          axiosError(t)
        }
      }

      setData({
        isLoaded: true,
        tags: tagsOption,
        authors: authorsOption,
        countries: countriesOption,
        locations: locationsOption,
        accessRights: accessRightsOption,
        broadcasts: broadcastsOption,
        stickers: stickersOption,
        watermarks: watermarksOption,
        originalFile: originalFile
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)
    })
  }

  //States methods
  const setFilesStates = (files) => {
    setStates({
      ...states,
      broadcastsRefresh: moment().unix(),
      files: files
    })
  }

  const setInformationsStates = (informations) => {
    setStates({
      ...states,
      informations: informations
    })
  }

  const setParametersStates = (parameters) => {
    let broadcastsRefresh = states.broadcastsRefresh

    if (
      parameters.id_watermark !== states.parameters.id_watermark 
      || JSON.stringify(parameters.access_rights) !== JSON.stringify(states.parameters.access_rights)
    ) {
      broadcastsRefresh = moment().unix()
    }

    setStates({
      ...states,
      broadcastsRefresh: broadcastsRefresh,
      parameters: parameters
    })
  }

  const setBroadcastStates = (broadcasts) => {
    setStates({
      ...states,
      broadcasts: broadcasts
    })
  }

  //Template methods
  const submitForm = async () => {
    console.log(TAG, 'submitForm')

    setStates({
      ...states,
      submitModalVisible: true,
      isSubmitting: true,
      isUploading: true,
    })

    //Reset
    setUploadProgress(DEFAULT_UPLOAD_PROGRESS_DATA)
    setUploadedFilesCount(0)
    setFileToBeUpload(null)

    const _uploadedFiles = []
    let _uploadedFilesCount = 0

    for (const file of states.files) {
      const fileGuid = uuidv4() + "." + file.name.split('.').pop()
      const fileSize = file.size
      const chunkPartsCount = (fileSize % UPLOAD_CHUNK_SIZE === 0) ? fileSize / UPLOAD_CHUNK_SIZE : Math.floor(fileSize / UPLOAD_CHUNK_SIZE) + 1
      let beginingOfTheChunk = 0
      let endOfTheChunk = UPLOAD_CHUNK_SIZE
      let uploadError = false

      setFileToBeUpload(file)

      for (let chunkCurrentPart = 1; chunkCurrentPart <= chunkPartsCount; chunkCurrentPart++) {
        let success = await uploadChunk(fileGuid, fileSize, beginingOfTheChunk, chunkCurrentPart, chunkPartsCount, file.originFileObj.slice(beginingOfTheChunk, endOfTheChunk))

        if (success) {
          beginingOfTheChunk = endOfTheChunk
          endOfTheChunk = endOfTheChunk + UPLOAD_CHUNK_SIZE
        } else {
          console.log(TAG, 'uploadChunk ERROR')

          uploadError = true

          break
        }
      }

      if ( ! uploadError) {
        console.log(TAG, 'File ' + fileGuid + ' is uploaded!')

        _uploadedFiles.push({
          guid: fileGuid,
          size: fileSize,
          name: file.name
        })
        _uploadedFilesCount += 1

        setUploadedFilesCount(_uploadedFilesCount)
      } else {
        console.log(TAG, 'File error, skip but conserve in list !')
      }
    }

    //Fix to show progress complete of last file
    setTimeout(() => {
      uploadCompleted(_uploadedFiles)
    }, 750)
  }

  const uploadChunk = async (fileGuid, fileSize, beginingOfTheChunk, chunkCurrentPart, chunkPartsCount, chunk) => {
    const formDatas = {
      chunk: chunk,
      guid: fileGuid,
      parts_count: chunkPartsCount,
      current_part: chunkCurrentPart
    }

    let success = false

    await uploadFile(
      APIRequest(
        authState.language,
        authState.token,
        {
          onUploadProgress: function(progressEvent) {
            setUploadProgress({
              loadedPercentage: Math.round(((beginingOfTheChunk + progressEvent.loaded) * 100) / fileSize),
              loaded: convertFileSizeToHuman(beginingOfTheChunk + progressEvent.loaded),
              total: convertFileSizeToHuman(fileSize)
            })
          }
        },
        0
        ),
      formDatas
    ).then(response => {
      if (isBadResponse(response.data)) {
        return
      }

      success = true
    })

    return success
  }

  const uploadCompleted = (_uploadedFiles) => {
    setStates({
      ...states,
      submitModalVisible: true,
      isSubmitting: true,
      isUploading: false,
    })

    const formDatas = {
      //Information
      ...transformFormLangToFormData({
        title: states.informations.title,
        description: states.informations.description,
        keywords: states.informations.keywords
      }),
      id_country: (states.informations.id_country !== null) ? states.informations.id_country : 0,
      youtube_code: states.informations.youtube_code,
      media_date: (states.informations.media_date) ? states.informations.media_date.format('YYYY-MM-DD') : null,
      media_author: states.informations.media_author,
      media_location: states.informations.media_location,

      //Parameters
      status: states.parameters.status,
      id_folder: (states.parameters.id_folder !== null) ? states.parameters.id_folder : 0,
      ...transformObjectToFormData('folders', states.parameters.folders),
      id_watermark: (states.parameters.id_watermark !== null) ? states.parameters.id_watermark : 0,
      ...transformObjectToFormData('access_rights', states.parameters.access_rights),
      ...transformObjectToFormData('stickers', states.parameters.stickers),
      ...transformFormLangToFormData({
        terms_of_use: states.parameters.terms_of_use,
      })
    }

    //Broadcast
    if (states.broadcasts) {
      const haveWatermark = (states.parameters.id_watermark !== null)

      Object.keys(states.broadcasts).forEach(fileType => {
        Object.keys(states.broadcasts[fileType]).forEach(actionType => {
          if (!haveWatermark && actionType === 'dl_watermark') {
            return
          }

          let iBroadcast = 0
          
          formDatas['broadcasts[' + fileType + '][' + actionType + ']'] = 1
          states.broadcasts[fileType][actionType].forEach(groupId => {
            formDatas['broadcasts[' + fileType + '][' + actionType + '][' + iBroadcast + ']'] = groupId

            iBroadcast++
          })
        })
      })
    }

    //Files
    let iFile = 0
    _uploadedFiles.forEach(file => {
      formDatas['media_file[' + iFile + '][guid]'] = file.guid
      formDatas['media_file[' + iFile + '][name]'] = file.name
      formDatas['media_file[' + iFile + '][size]'] = file.size

      iFile++
    })

    addFiles(APIRequest(authState.language, authState.token, null, 0), formDatas).then(response => {
      if (isBadResponse(response.data)) {
        setStates({...states,
          submitModalVisible: true,
          isSubmitting: false,
          isUploading: false,
          submitError: true,
          submitErrorData: {
            code: response.data?.data?.code,
            message: response.data?.data?.message,
            extraData: response.data?.data?.extra_data
          }
        })

        return
      }

      setStates({...states,
        submitModalVisible: true,
        isSubmitting: false,
        isUploading: false,
        submitError: false,
        submitErrorData: null
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isSubmitting: false,
        submitModalVisible: true,
        submitError: true,
        submitErrorData: {
          code: null,
          message: thrown
        }
      })
    })
  }

  const getModalErrorContent = () => {
    return (
      <>
        {states.submitErrorData.code !== null && (
          <>{states.submitErrorData.code}<br /></>
        )}
        {states.submitErrorData.message}
        
        {Array.isArray(states.submitErrorData.extraData) && (
          <>
            <Divider />
            <ul style={{ margin:0, padding:5, listStyleType:'none' }}>
              {states.submitErrorData.extraData.map(item => {
                return <li>{item.message}</li>
              })}
            </ul>
          </>
        )}
      </>
    )
  }

  const backToMedia = (folderId) => {
    //Clear cache
    delMediaStorage()

    //Redirect
    navigate('/media/explore/' + folderId)
  }

  const uploadOtherMedia = () => {
    setStates({
      ...states,
      files: [],
      isSubmitting: false,
      submitError: false,
      isUploading: false,
      submitModalVisible: false,
      currentStep: 0,
    })
  }

  const nextStep = () => {
    setStates({...states, currentStep: states.currentStep + 1})
  }

  const prevStep = () => {
    setStates({...states, currentStep: states.currentStep - 1})
  }

  if (!data.isLoaded) {
    return <LoadingBlock />
  }

  return (
    <main className="site-content site-content-background">
      <Steps current={states.currentStep} onChange={(step) => setStates({ ...states, currentStep:step })}>
        {steps.map(item => (
          <Step key={item.key} title={item.title} icon={item.icon} />
        ))}
      </Steps>
      <div style={{margin:'40px 0'}}>
        <div style={{display: (data.isLoaded && states.currentStep === 0) ? 'block' : 'none'}}>
          <MediaUploadContent
            formDatas={data}
            filesStates={states.files}
            setFilesStates={setFilesStates}
            onFinish={() => {
              nextStep()
            }}
          />
        </div>

        <div style={{display: (data.isLoaded && states.currentStep === 1) ? 'block' : 'none'}}>
          <MediaInformationsContent
            formDatas={data}
            informationsStates={states.informations}
            setInformationsStates={setInformationsStates}
            onFinish={() => {
              nextStep()
            }}
            onBack={() => {
              prevStep()
            }}
          />
        </div>

        <div style={{display: (data.isLoaded && states.currentStep === 2) ? 'block' : 'none'}}>
          <MediaParametersContent
            formDatas={data}
            parametersStates={states.parameters}
            setParametersStates={setParametersStates}
            onFinish={() => {
              nextStep()
            }}
            onBack={() => {
              prevStep()
            }}
          />
        </div>

        <div style={{display: (data.isLoaded && states.currentStep === 3) ? 'block' : 'none'}}>
          <MediaBroadcastContent
            key={states.broadcastsRefresh}
            formDatas={data}
            setBroadcastStates={setBroadcastStates}
            filesStates={states.files}
            parametersStates={states.parameters}
            onFinish={() => {
              //console.log(TAG, states.broadcast)
              submitForm()
            }}
            onBack={() => {
              prevStep()
            }}
          />
        </div>
      </div>

      <Modal
        open={states.submitModalVisible}
        title={t('Uploading new medias')}
        footer={null}
        closable={states.submitError}
        maskClosable={states.submitError}
        keyboard={states.submitError}
        onOk={() => {
          setStates({...states, submitModalVisible:false})
        }}
        onCancel={() => {
          setStates({...states, submitModalVisible:false})
        }}
      >
        {states.isSubmitting && (
          <div style={{ display:'flex', flexDirection: 'column', margin:'25px 0', alignItems:'center', justifyContent:'center'}}>
            {states.isUploading ? (
              <>
                <Text strong style={{ marginBottom:5 }}>{t('Uploading...')}</Text>
                <Text style={{ marginBottom:15 }}>{fileToBeUpload?.name}</Text>
                <Progress type="circle" percent={uploadProgress.loadedPercentage} />
                {(uploadProgress.loaded && uploadProgress.total) && (
                  <Text style={{ marginTop:5 }}>{uploadProgress.loaded + ' / ' + uploadProgress.total}</Text>
                )}
                <Divider />
                <Text strong>{t('%currentLoad media loaded out of %total').replace('%currentLoad', uploadedFilesCount).replace('%total', states.files.length)}</Text>
                <Progress percent={Math.floor((uploadedFilesCount / states.files.length) * 100)} />
              </>
            ) : (
              <>
                <Spin size="large" style={{marginBottom:30}} />
                <span>{t('Media processing in progress')}...</span>
              </>
            )}
          </div>
        )}
        {!states.isSubmitting && states.submitError && (
          <div key="MediaUploadScreenResultError">
            <Result
              status="error"
              title={t('Sorry, something went wrong...')}
              subTitle={getModalErrorContent()}
            />
          </div>
        )}
        {!states.isSubmitting && !states.submitError && (
          <div key="MediaUploadScreenResultSuccess">
            <Result
              status="success"
              title={t('Media uploaded successfully!')}
              extra={[
                <Button type='primary' icon={<CloudOutlined />} onClick={() => backToMedia(states.parameters.id_folder)}>
                  {t('View media')}
                </Button>,
                <Button icon={<UploadOutlined />} onClick={() => uploadOtherMedia()}>
                  {t('Upload other media')}
                </Button>
              ]}
            />
          </div>
        )}
      </Modal>
    </main>
  )
}