import { useContext, useState, useEffect, createRef } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Modal, Typography, Form, Input, message } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Context as AuthContext } from '../context/AuthContext';
import { APIRequest, isBadResponse, addCart } from '../models/APIRequest';
import { axiosError, formError } from './Notifications';
import ApiErrorResult from './ApiErrorResult';

export default function SaveCartModal(props) {
  const TAG = 'SaveCartModal'

  //Template
  const { t } = useTranslation()
  const { Paragraph } = Typography
  const onHide = props?.onHide
  const formRef = createRef()
  
  //States
  const DEFAULT_STATES = {
    visible: props?.visible,
    isLoaded: false,
    isSubmitting: false,
    submitError: false,
    submitErrorData: null,
    files: props?.files,
    formValues: {}
  }
  const { authState } = useContext(AuthContext)
  const [ states, setStates ] = useState(DEFAULT_STATES)

  //Hooks
  useEffect(() => {
    if (formRef?.current) {
      formRef.current.resetFields()
    }
    
    setStates({...DEFAULT_STATES, visible: props?.visible, files: props?.files})
  }, [props])

  //Template methods
  const hideModal = () => {
    setStates({ ...states, formValues: {}})
    onHide()
  }

  const submitForm = () => {
    console.log(TAG, 'submitForm')

    //Loading
    setStates({
      ...states,
      isSubmitting: true
    })

    //Make formData
    const formDatas = {
      title: states.formValues.title
    }

    states.files.forEach((file, key) => {
      formDatas['files[' + key + ']'] = file.id
    })

    addCart(APIRequest(authState.language, authState.token), formDatas).then(response => {
      if (isBadResponse(response.data)) {
        setStates({
          ...states,
          isSubmitting: false,
          submitError: true,
          submitErrorData: {
            code: response.data?.data?.code,
            message: response.data?.data?.message,
            extraData: response.data?.data?.extra_data
          }
        })

        return
      }

      hideModal()
      message.success(t('Your "%s" cart has been saved').replace('%s', states.formValues.title))
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isSubmitting: false,
        submitError: true,
        submitErrorData: {
          code: null,
          message: thrown
        }
      })
    })
  }

  if (!states.files || !states.files.length) {
    console.log(TAG, 'Bad files prop (array require)')
    return
  }

  return (
    <Modal
      title={t('Save this basket')}
      open={states.visible}
      zIndex={110}
      destroyOnClose={true}
      onCancel={hideModal}
      footer={[
        <Button type="link" key="downloadNo" onClick={() => {
          hideModal()
        }}>
          {t('Cancel')}
        </Button>,
        <Button type="primary" key="downloadYes" disabled={states.isSubmitting} loading={states.isSubmitting} onClick={() => {
          formRef.current.submit()
        }}>
          {t('Save')}
        </Button>,
      ]}
    >
      <>
        <Paragraph>
          <InfoCircleOutlined style={{ paddingRight:5 }} />
          {t('You can find all your baskets in your personal space.')}
        </Paragraph>
        {(!states.isSubmitting && states.submitError) && (
          <ApiErrorResult errorData={states.submitErrorData} template="alert" status="error" style={{marginBottom:20}} />
        )}
        <Form
          name="download"
          ref={formRef}
          layout="vertical"
          initialValues={states.formValues}
          onValuesChange={(changedValues, allValues) => {
            setStates({...states, formValues: {...states.formValues, ...allValues} })
          }}
          onFinish={() => submitForm()}
          onFinishFailed={({ values, errorFields, outOfDate }) => formError(errorFields, t)}
          autoComplete="off"
        >
          <Form.Item
            label={t('Title')}
            name="title"
            rules={[{ required: true, message: t('Please input the title') }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </>
    </Modal>
  )
}
