import './i18n/i18n.config.js';
import './App.less';
import localeFr from 'antd/lib/locale/fr_FR';
import localeGb from 'antd/lib/locale/en_GB';
import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { ConfigProvider } from 'antd';
import { Context as AuthContext } from './context/AuthContext';
import { MediaContext } from './context/MediaContext';
import { Routes, Route, useLocation } from "react-router-dom";
import { IsLoggedGuard, IsContributorGuard, IsAdminGuard, IsSuperAdminGuard } from "./routes/HauthGuardedRoute";
import LoadingScreen from "./screens/LoadingScreen";
import NotFoundScreen from "./screens/NotFoundScreen";
import SessionExpiredScreen from "./screens/SessionExpiredScreen";
import WelcomeScreen from "./screens/WelcomeScreen";

import MediaLayoutScreen from "./screens/media/MediaLayoutScreen";
import MediaScreen from "./screens/media/MediaScreen";
import MediaUploadScreen from "./screens/media/MediaUploadScreen";
import MediaShareScreen from "./screens/media/MediaShareScreen";
import MediaFileModal from "./screens/media/MediaFileModal";

import AccountLayoutScreen from "./screens/account/AccountLayoutScreen";
import AccountDetailScreen from "./screens/account/AccountDetailScreen";
import AccountCartScreen from "./screens/account/AccountCartScreen";
import AccountShareScreen from "./screens/account/AccountShareScreen";

import AdminLayoutScreen from "./screens/admin/AdminLayoutScreen";
import AdminHomeScreen from "./screens/admin/AdminHomeScreen";
import AdminFolderTreeScreen from "./screens/admin/AdminFolderTreeScreen";
import AdminStickerScreen from "./screens/admin/AdminStickerScreen";
import AdminFileScreen from "./screens/admin/AdminFileScreen";
import AdminTrashScreen from "./screens/admin/AdminTrashScreen";
import AdminUserScreen from "./screens/admin/AdminUserScreen";
import AdminWatermarkScreen from "./screens/admin/AdminWatermarkScreen";
import AdminBroadcastScreen from "./screens/admin/AdminBroadcastScreen";
import ApiLogin from "./components/api/ApiLogin";

function App(props, context) {
  const TAG = 'MyApp'

  //Template
  const { t } = useTranslation()
  const location = useLocation()
  //console.log(TAG + 'background context: ', background)

  //Context & State
  const { authState, retrieve } = useContext(AuthContext)
  const { mediaState, retrieveMediaContext } = useContext(MediaContext)
  const [ isRetrieving, setIsRetrieving ] = useState(true)

  //Retrieve global auth context data
  useEffect(() => {
    console.log('Retrieve context')
    setTimeout(async() => {
      await retrieve()
      await retrieveMediaContext()

      console.log(TAG + ' - authState', authState)
      console.log(TAG + ' - mediaState', mediaState)
      setIsRetrieving(false)
    })
  }, [])

  if (isRetrieving) {
    return (
      <LoadingScreen />
    )
  }

  return (
    <ConfigProvider locale={authState.language === 'fr' ? localeFr : localeGb}>
      <Routes location={location}>
        <Route path="/" element={<WelcomeScreen />}/>
        <Route path="media/*" element={<MediaLayoutScreen />}>
          <Route path="explore/:idFolder/filter/:filter/*" element={<MediaScreen />}>
            <Route path="file/:idFile" element={<MediaFileModal />} />
            <Route path="basket/file/:idFile" element={<MediaFileModal />} />
          </Route>
          <Route path="explore/:idFolder/*" element={<MediaScreen />}>
            <Route path="file/:idFile" element={<MediaFileModal />} />
            <Route path="basket/file/:idFile" element={<MediaFileModal />} />
          </Route>

          <Route path="search/:search/filter/:filter/*" element={<MediaScreen />}>
            <Route path="file/:idFile" element={<MediaFileModal />} />
            <Route path="basket/file/:idFile" element={<MediaFileModal />} />
          </Route>
          <Route path="search/:search/*" element={<MediaScreen />}>
            <Route path="file/:idFile" element={<MediaFileModal />} />
            <Route path="basket/file/:idFile" element={<MediaFileModal />} />
          </Route>
          
          <Route path="share/:idShare/:secureKey/*" element={<MediaShareScreen />}>
            <Route path="file/:idFile/:fileCode" element={<MediaFileModal />} />
          </Route>

          <Route path="upload/duplicate/:idFile" element={
            <IsContributorGuard authState={authState}>
              <MediaUploadScreen />
            </IsContributorGuard>
          } />

          <Route path="upload/" element={
            <IsContributorGuard authState={authState}>
              <MediaUploadScreen />
            </IsContributorGuard>
          } />
          <Route path="*" element={<NotFoundScreen />} />
        </Route>
        <Route path="account/*" element={<IsLoggedGuard authState={authState}><AccountLayoutScreen /></IsLoggedGuard>}>
          <Route index element={<AccountDetailScreen />} />
          <Route path="baskets/" element={<AccountCartScreen />} />
          <Route path="shares/" element={<AccountShareScreen />} />
        </Route>
        <Route path="admin/*" element={<IsAdminGuard authState={authState}><AdminLayoutScreen /></IsAdminGuard>}>
          <Route index element={<AdminHomeScreen />} />
          <Route path="folder-tree/" element={<AdminFolderTreeScreen />} />
          <Route path="sticker/" element={<AdminStickerScreen />} />
          <Route path="file/" element={<AdminFileScreen />} />
          <Route path="trash/" element={<AdminTrashScreen />} />
          <Route path="share/" element={<AccountShareScreen forAdmin={true} />} />
          <Route path="watermark/" element={<AdminWatermarkScreen />} />
        </Route>
        <Route path="admin/*" element={<IsSuperAdminGuard authState={authState}><AdminLayoutScreen /></IsSuperAdminGuard>}>
          <Route path="user/" element={<AdminUserScreen />} />
          <Route path="broadcast/" element={<AdminBroadcastScreen />} />
        </Route>
				<Route path="api/login/:encryptedToken" element={<ApiLogin />}/>
        <Route path="session-expired" element={<SessionExpiredScreen />} />
        <Route path="*" element={<NotFoundScreen backText={t('Back to home')} withLogo={true} />} />
      </Routes>
    </ConfigProvider>
  )
}

export default App