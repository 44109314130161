import { useState, useEffect, useContext } from 'react';
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Space, Table, Image, Button, Dropdown, Menu, Modal, Tooltip, Badge } from 'antd';
import { DownOutlined, ShoppingCartOutlined, ShareAltOutlined, DownloadOutlined } from '@ant-design/icons';
import { APIRequest, isBadResponse, getCarts } from '../../models/APIRequest';
import { Context as AuthContext } from '../../context/AuthContext';
import ApiErrorResult from '../../components/ApiErrorResult';
import { axiosError } from '../../components/Notifications';
import DownloadModal from '../../components/DownloadModal';
import ShareModal from '../../components/ShareModal';
import CartDeleteModal from '../../components/cart/CartDeleteModal';
import TableHeader from '../../components/table/TableHeader';
import { getShortTimeFormat } from '../../utils/DateUtils';
import { transformSortOrderToApi } from '../../utils/ApiUtils';
import { getTablePagerInformations } from '../../utils/TemplateUtils';

export default function AccountCartScreen(props) {
  const TAG = 'AccountCartScreen'

  //Template
  const { t } = useTranslation()
  const [ updateBreacrumbs ] = useOutletContext()
  const columns = [
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      showSorterTooltip: false,
      width: 250,
    },
    {
      title: t('Media'),
      dataIndex: 'files',
      width: '60%',
      responsive: ['xl'],
      render: (_, record) => (
        <Image.PreviewGroup key={'preview' + record.id}>
          <Space size={4}>
            {record.files.map(file => {
              return (
                <Tooltip title={file.title} key={'preview' + record.id + 'Tooltip' + file.id}>
                  <Image
                    src={(file.thumb_large) ? file.thumb_large : file.thumb}
                    placeholder={file.thumb}
                    preview={(file.thumb_large !== null)}
                    alt={file.title}
                    style={{ maxWidth:50 }}
                  />
                </Tooltip>
              )
            })}
          </Space>
        </Image.PreviewGroup>
      ),
    },
    {
      title: t('Date'),
      dataIndex: 'date_add',
      key: 'date_add',
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
      width: 250,
      align:'center',
      render: (_, { date_add }) => {
        return moment(date_add).format(getShortTimeFormat(authState.language))
      },
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      align:'center',
      //fixed: 'right',
      render: (_, record) => (
      <>
        <Dropdown
          icon={<DownOutlined />}
          key={'actions' + record.id}
          loading={false}
          trigger={['click']}
          overlay={
            <Menu
              onClick={({ item, key }) => handleActions(key, record)}
              items={[
                {
                  label: t('Use this basket'),
                  icon: <ShoppingCartOutlined />,
                  key: 'use',
                },
                {
                  label: <DownloadModal widgetType="menuItem" files={record.files} />,
                  icon: <DownloadOutlined />,
                  key: 'download',
                },
                {
                  label: <ShareModal widgetType="menuItem" files={record.files} />,
                  icon: <ShareAltOutlined />,
                  key: 'share',
                },
                {
                  type: 'divider'
                },
                {
                  label: <CartDeleteModal
                    carts={[record]}
                    widgetType='menuItem'
                    onDeletedCart={(carts) => refreshTable()}
                  />,
                  key: 'delete',
                },
              ]}
            />
          }
        >
          <Button block>
            <Space>
              {t('Actions')}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </>
      ),
      width: 130,
    }
  ]

  //State
  const { authState, updateCart } = useContext(AuthContext)
  const [ states, setStates ] = useState({
    firstLoad: true,
    isLoaded: false,
    error: false,
    errorData: null,
    carts: [],
    selectedRowKeys: [],
    selectedCarts: [],
    page: 1,
    pageMax: 1,
    total: 0,
    limit: 10,
    sortColumn: null,
    sortOrder: null
  })

  //States methods
  const loadCarts = () => {
    console.log(TAG, 'loadCarts')
    console.log(TAG, 'Getting data...')

    getCarts(APIRequest(authState.language, authState.token), states.page, states.limit, states.sortColumn, states.sortOrder, states.filters).then(function (results) {
      if (isBadResponse(results.data)) {
        setStates({
          ...states,
          isLoaded: false,
          firstLoad: false,
          error: true,
          errorData: results.data.data
        })

        return
      }

      setStates({
        ...states,
        isLoaded: true,
        firstLoad: false,
        carts:  results.data.data.carts,
        page: results.data.data.pager.page,
        pageMax: results.data.data.pager.page_max,
        total: results.data.data.pager.total,
        limit: results.data.data.pager.limit,
        error: false,
        errorData: null,
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: false,
        firstLoad: false,
        error: true,
        errorData: null,
      })
    })
  }

  //Events
  useEffect(() => {
    loadCarts()

    if (updateBreacrumbs) {
      console.log(TAG, 'updateBreacrumbs')
      updateBreacrumbs([{title: t('Baskets'), 'route': null},])
    }
  }, [])
  
  useEffect(() => {
    if (states.firstLoad || states.error || states.isLoaded) {
      return
    }

    loadCarts()
  }, [states.isLoaded, states.error])

  //Template method
  const handleTableChange = (newPagination, filters, sorter) => {
    //Add search
    if (states.filters?.search) {
      filters['search'] = states.filters.search
    }

    setStates({
      ...states,
      selectedCarts: [],
      selectedRowKeys: [],
      page: newPagination.current,
      limit: newPagination.pageSize,
      sortColumn: sorter?.columnKey,
      sortOrder: transformSortOrderToApi(sorter?.order),
      isLoaded: false,
    })
  }

  const handleActions = (key, record) => {
    if (key === 'use') {
      Modal.confirm({
        title: record.title,
        content: t('Are you sure you want replace your current basket with this one?'),
        okText: t('Replace'),
        cancelText: t('Cancel'),
        onOk: () => {
          const cartItems = []

          record.files.forEach(file => {
            cartItems.push(file.id)
          })

          updateCart(cartItems)
        }
      })
    }
  }

  const refreshTable = () => {
    console.log(TAG, 'refreshTable')

    setStates({ ...states, isLoaded: false, selectedCarts:[], selectedRowKeys:[] })
  }

  const bulkActionsMenu = () => {
    return <Menu
      items={[
        {
          label: (
            <CartDeleteModal
              carts={states.selectedCarts}
              widgetType='menuItem'
              onDeletedCart={(carts) => refreshTable()}
            />
          ),
          key: 'bulkDel',
        }
      ]}
    />
  }

  if (states.error) {
    return (
      <ApiErrorResult errorData={states.errorData} actionTitle={t('Try to reload')} actionHandle={() => {
        setStates({ ...states, error:false, errorData: null, isLoaded:false })
      }} />
    )
  }

  return (
    <main className='admin-container'>
      <TableHeader
        title={t('Baskets')}
        contentStyle={{ display:'flex', alignItems:'start' }}
        showSearch={true}
        onSearchChange={value => {
          if (!value) {
            return
          }

          setStates({
            ...states,
            filters: {
              ...states.filters,
              search: value
            }
          })
        }}
        onSearchSubmit={() => {
          setStates({
            ...states,
            isLoaded: false,
            page: 1,
          })
        }}
        onSearchClear={() => {
          setStates({
            ...states,
            isLoaded: false,
            page: 1,
            filters: {
              ...states.filters,
              search: null
            }
          })
        }}
      >
        <Dropdown trigger='click' overlay={bulkActionsMenu} disabled={states.selectedCarts.length === 0}>
          <Badge count={states.selectedCarts.length} overflowCount={999}>
            <Button disabled={states.selectedCarts.length === 0}>
              <Space>
                {t('Bulk actions')}
                
                <DownOutlined />
              </Space>
            </Button>
          </Badge>
        </Dropdown>
      </TableHeader>
      <Table
        columns={columns}
        size='small'
        rowKey={(item) => item.id}
        dataSource={states.carts}
        pagination={{
          current: states.page,
          pageSize: states.limit,
          total: states.total,
          disabled: (!states.isLoaded),
          showTotal: (total) => getTablePagerInformations(t, states.page, states.limit, states.total),
          showSizeChanger: true,
          size: 'default',
        }}
        rowSelection={{
          selectedRowKeys: states.selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            setStates({
              ...states,
              selectedRowKeys: selectedRowKeys,
              selectedCarts: selectedRows
            })
          }
        }}
        loading={!states.isLoaded}
        onChange={handleTableChange}
        scroll={{ x: 680 }}
      />
    </main>
  )
}