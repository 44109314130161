export const RIGHT_SUPERADMIN = "superadmin"
export const RIGHT_ADMIN = "admin"
export const RIGHT_CONTRIBUTOR = "contributor"
export const RIGHT_USER = "user"
export const RIGHT_VISITOR = "visitor"
const TAG = "AccessRightUtils"

export const checkAuthState = (authState) => {
  return (authState?.data?.access_right)
}

export const haveAccessByCode = (authState, rightCode) => {
  if (!checkAuthState(authState)) {
    return false
  }

  let haveAccess = false

  authState.data.access_right.every((item) => {
    if (rightCode === item.code) {
      haveAccess = true
      return false
    }

    return true
  })

  return haveAccess
}

export const canEditFolder = (authState) => {
  return isAdmin(authState)
}

export const canEditFile = (authState, file) => {
  //< contributor
  if (!isContributor(authState)) {
    return false
    
  //= contributor
  } else if (!isAdmin(authState)) {
    if (!file?.user?.id || !authState?.data?.id_user) {
      return false
    }

    return (file.user.id === authState?.data?.id_user)
  
  //> contributor
  } else {
    return true
  }
}

export const canUpload = (authState) => {
  return isContributor(authState)
}

export const isVisitor = (authState) => {
  const code = authState?.data?.system_right?.code
  
  return (code === RIGHT_VISITOR)
}

export const isSuperAdmin = (authState) => {
  return authState?.data?.system_right?.code === RIGHT_SUPERADMIN
}

export const isAdmin = (authState, strict) => {
  const code = authState?.data?.system_right?.code

  if (strict) {
    return code === RIGHT_ADMIN
  } else {
    return (code === RIGHT_SUPERADMIN || code === RIGHT_ADMIN)
  }
}

export const isContributor = (authState, strict) => {
  const code = authState?.data?.system_right?.code
  
  if (strict) {
    return (code === RIGHT_CONTRIBUTOR)
  } else {
    return (code === RIGHT_SUPERADMIN || code === RIGHT_ADMIN || code === RIGHT_CONTRIBUTOR)
  }
}

export const isUser = (authState, strict) => {
  const code = authState?.data?.system_right?.code
  
  if (strict) {
    return (code === RIGHT_USER)
  } else {
    return (code === RIGHT_SUPERADMIN || code === RIGHT_ADMIN || code === RIGHT_CONTRIBUTOR || code === RIGHT_USER)
  }
}

export const isSystemUser = (authState) => {
  return (authState?.data?.is_system === true)
}