import { useState, memo } from 'react';
import { useTranslation } from "react-i18next";
import { PageHeader, Button, Input, Space } from 'antd';
import { SearchOutlined, CloseCircleFilled } from '@ant-design/icons';

const TableHeader = (props) => {
  const TAG = 'TableHeader'

  //Template
  const { t } = useTranslation()
  const title = props?.title
  const buttons = (typeof props?.buttons === 'object') ? props.buttons : []
  const showSearch = (props?.showSearch === true)
  const onSearchChange = props?.onSearchChange
  const onSearchSubmit = props?.onSearchSubmit
  const onSearchClear = props?.onSearchClear
  const searchPlaceholder = props?.searchPlaceholder
  const style = (props?.style !== null) ? props.style : null
  const contentStyle = (props?.contentStyle !== null) ? props.contentStyle : null

  //States
  const [ states, setStates ] = useState({
    search: null
  })

  //Template methods
  const submitHandle = () => {
    if (typeof onSearchSubmit === 'function') {
      onSearchSubmit()
    }
  }
  
  const getSearchInput = () => {
    return (
      <Input
        placeholder={searchPlaceholder ? searchPlaceholder : t('Search...')}
        style={{ maxWidth:300, justifySelf:'flex-end' }}
        suffix={(
          <Button
            type="primary"
            size='small'
            shape="circle"
            icon={<SearchOutlined />}
            onClick={submitHandle}
          />
        )}
        value={states.search}
        onPressEnter={submitHandle}
        onChange={e => {
          setStates({
            ...states,
            search: e.target.value
          })

          if ((!e.target.value || e.target.value === '') && typeof onSearchClear === 'function') {
            onSearchClear()
          } else if (typeof onSearchChange === 'function') {
            onSearchChange(e.target.value)
          }
        }}
        allowClear={{
          clearIcon: (
            <CloseCircleFilled
              style={{ fontSize: 20, marginTop:4 }}
              onClick={() => {
                setStates({
                  ...states,
                  search: null
                })

                if (typeof onSearchClear === 'function') {
                  onSearchClear()
                }
              }}
            />
          )
        }}
      />
    )
  }

  const getExtra = () => {
    if (!showSearch && !buttons.length) {
      return null
    }

    if (buttons.length) {
      return buttons
    } else if (showSearch) {
      return getSearchInput()
    }
  }

  const getContent = () => {
    if (!showSearch && (!buttons.length || (buttons.length && !showSearch)) && !props?.children) {
      return null
    }

    let content

    if (showSearch && buttons.length) {
      content = getSearchInput()
    } else if (buttons.length) {
      content =  (
        <Space style={{ width:'100%', justifyContent:'flex-end' }}>
          {buttons}
        </Space>
      )
    }

    if (!content && !props?.children) {
      return null
    }

    return (
      <div style={(contentStyle) ? contentStyle : { display:'flex', justifyContent:'flex-end' }}>
        {content}
        {props?.children}
      </div>
    )
  }

  return (
    <PageHeader
      className='ant-table-header'
      ghost={false}
      title={title}
      extra={getExtra()}
      style={style}
    >
      {getContent()}
    </PageHeader>
  )
}

export default memo(TableHeader)