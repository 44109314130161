import { memo, useState, useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Tooltip } from 'antd';
import { MdOutlineRemoveShoppingCart, MdOutlineAddShoppingCart } from "react-icons/md";
import { Context as AuthContext } from '../context/AuthContext';
import { addToCart, removeFromCart, maxFileInCart } from './Notifications';
import { checkIsAlreadyInCart } from '../utils/CartUtils';
import { getIconFromReactIcons } from '../utils/TemplateUtils';
import { BASKET_MAX_FILE } from '../Constant';

const BasketButton = (props) => {
  const TAG = 'BasketButton'

  //Template
  const { t } = useTranslation()
  const { authState, updateCart, retrieve } = useContext(AuthContext)
  const type = props?.type
  const buttonProps = props?.buttonProps
  const withTooltip = (props?.withTooltip === true) ? true : false
  
  //States
  const [ file, setFile ] = useState(props?.file)
  const [ isAlreadyInCart, setIsAlreadyInCart] = useState(checkIsAlreadyInCart(authState?.cart, file?.id))

  //Hooks
  useEffect(() => {
    setFile(props.file)
  }, [props.file])

  useEffect(() => {
    setIsAlreadyInCart(checkIsAlreadyInCart(authState?.cart, file?.id))
  }, [authState.cart, file])

  //Template methods
  const getText= () => {
    return isAlreadyInCart ? t('Remove from basket') : t('Add to basket')
  }

  const getIcon = () => {
    return getIconFromReactIcons(isAlreadyInCart ? <MdOutlineRemoveShoppingCart  /> : <MdOutlineAddShoppingCart className="anticon" />, getText())
  }

  const getButton = () => {
    if (type === 'icon') {
      return (
        <Button
          danger={isAlreadyInCart}
          shape="circle"
          icon={getIcon()}
          onClick={() => handleClick()}
          {...buttonProps}
        />
      )
    } else {
      return (
        <Button icon={getIcon()} onClick={() => handleClick()} {...buttonProps}>
          {getText()}
        </Button>
      )
    }
  }

  const handleClick = () => {
    //Remove from cart
    if (authState?.cart && isAlreadyInCart) {
      updateCart(authState.cart.filter(function(itemId) {
        return itemId !== file.id
      }))

      retrieve()
      removeFromCart(t, file)
    //Add to cart
    } else {
      if (authState?.cart) {
        const items = authState.cart

        if (items.length >= BASKET_MAX_FILE) {
          maxFileInCart(t)
          return
        }

        items.push(file.id)

        updateCart(items)
      } else {
        updateCart([file.id])
      }

      retrieve()
      addToCart(t, file)
    }
  }

  if (!file || !file?.id) {
    console.log(TAG, 'Props file not valid');
    return
  }

  if (withTooltip) {
    return (
      <Tooltip title={getText()} placement="left" mouseEnterDelay={1.5}>
        {getButton()}
      </Tooltip>
    )
  } else {
    return getButton()
  }
}

export default memo(BasketButton)
