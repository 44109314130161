import { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Grid, Button, BackTop, Drawer, Typography } from 'antd';
import { UploadOutlined, CloudOutlined, ReloadOutlined, FilterOutlined, CloseOutlined } from '@ant-design/icons';
import { MdOutlineMenu } from "react-icons/md";
import { Context as AuthContext } from '../../context/AuthContext';
import { MediaContext } from '../../context/MediaContext';
import { ModalProvider } from "../../context/ModalContext";
import LoadingScreen from "../../screens/LoadingScreen";
import HeaderLogo from "../../components/HeaderLogo";
import TemplateFooter from "../../components/template/TemplateFooter";
import MediaFilterForm from "../../components/list/MediaFilterForm";
import HeaderLogin from "../../components/HeaderLogin";
import Basket from "../../components/Basket";
import SearchInput from '../../components/SearchInput';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { canUpload } from '../../utils/AccessRightUtils';
import { getStorageData, delStorageData, delMediaStorage } from '../../utils/StorageUtils';
import { FOLDER_ROOT_ID, SIDER_WIDTH } from '../../Constant';

function MediaLayoutScreen(props) {
  const TAG = 'MediaLayoutScreen'

  //Route
  const navigate = useNavigate()
  const location = useLocation()

  //Template
  const { t } = useTranslation()
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()
  const { windowWidth, windowHeight } = useWindowDimensions()
  const { Text } = Typography
  const { Header, Content, Sider } = Layout
  const defaultBreadcrumbs = [
    { title: t('Home'), 'route': '/' },
    { title: t('Media'), 'route': '/media/explore/' + FOLDER_ROOT_ID }
  ]

  //States
  const [ filters, setFilters ] = useState({
    filters: null,
    loading: true
  })
  const { authState } = useContext(AuthContext)
  const { setRefreshFileTree } = useContext(MediaContext)
  const [ breadcrumbs, setBreadcrumbs ] = useState(defaultBreadcrumbs)
  const [ menuSelectedKeys, setMenuSelectedKeys ] = useState(null)
  const [ filterDrawerVisible, setFilterDrawerVisible ] = useState(false)
  const [ menuDrawerVisible, setMenuDrawerVisible ] = useState(false)
  const [ siderFileFilterFixed, setSiderFileFilterFixed ] = useState(false)

  //Hooks
  useEffect(() => {
    //Scroll spy
    let _siderFileFilterFixed = false

    const handleScroll = (event) => {
      if (window.scrollY >= 64 && !_siderFileFilterFixed) {
        setSiderFileFilterFixed(true)
        _siderFileFilterFixed = true
      } else if (window.scrollY < 64 && _siderFileFilterFixed) {
        setSiderFileFilterFixed(false)
        _siderFileFilterFixed = false
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)

      //Delete temp storage
      delMediaStorage()
    }
  }, [])

  useEffect(() => {
    //Set active menu keys
    const keys = []

    if (location.pathname.match(/\/media$/) || location.pathname.match(/\/media\/(explore|file|search)/)) {
      keys.push('medias')
    } else if (location.pathname.match(/\/media\/upload/)) {
      keys.push('upload')
    }

    setMenuSelectedKeys(keys)
  }, [location])
  
  const routeHaveSider = () => {
    return (screens.xl && routeHaveFilters())
  }
  
  const routeHaveFilterDrawer = () => {
    return (!screens.xl && routeHaveFilters())
  }

  const routeHaveFilters = () => {
    return (location.pathname.match(/\/media$/) || location.pathname.match(/\/media\/(explore|search)/))
  }

  const routeHaveBasket = () => {
    return (location.pathname.match(/\/media$/) || location.pathname.match(/\/media\/(explore|search)/))
  }

  //Template methods
  const getMenuItems = () => {
    const menuItems = [
      { label: t('Home'), key: 'medias', icon: <CloudOutlined /> }
    ]

    if (canUpload(authState)) {
      menuItems.push({ label: t('Upload'), key: 'upload', icon: <UploadOutlined /> })
    }

    if (routeHaveFilters()) {
      menuItems.push({ label: t('Refresh'), key: 'refresh', icon: <ReloadOutlined /> })
    }

    return menuItems
  }

  //Template methods
  const getSearchInput = () => {
    return (
      <SearchInput
        onSearch={(search) =>  {
          let urlFilter = ''

          /*if (params?.filter ) {
            urlFilter = '/filter/' + params?.filter
          }*/

          navigate('/media/search/' + search + urlFilter)
        }}
        onClear={() => {
          const lastFolderId = getStorageData('MediaLastFolderId')
          navigate('explore/' + ((lastFolderId) ? lastFolderId : FOLDER_ROOT_ID))
        }}
      />
    )
  }

  const getMenu = (props) => {
    return (
      <Menu
        {...props}
        items={getMenuItems()}
        selectedKeys={menuSelectedKeys}
        onClick={({ item, key }) => {
          if (key === 'medias') {
            navigate('explore/' + FOLDER_ROOT_ID)
          
          } else if (key === 'upload') {
            navigate('upload')
          
          } else if (key === 'refresh') {
            setRefreshFileTree(true)
            
            delMediaStorage()
            delStorageData('MediaLastFolderId')

            navigate(location.pathname, { replace:true })
          } else {
            navigate('')
          }

          setMenuDrawerVisible(false)
        }}
      />
    )
  }

  //OUTLET
  const updateBreacrumbs = (breadcrumbs) => {
    if (!breadcrumbs) {
      return
    }

    setBreadcrumbs(defaultBreadcrumbs.concat(breadcrumbs.reverse()))
  }

  const setMediaFiltersLoading = (bool) => {
    setFilters({
      ...filters,
      loading: bool,
    })
  }

  const updateMediaFilters = (filters) => {
    setFilters({
      filters: filters,
      loading: false,
    })
  }

  if (screens.xl === undefined) {
    return <LoadingScreen />
  }

  return (
    <ModalProvider>
      {routeHaveFilterDrawer() && (
        <>
          <Button
            type="primary"
            size="large"
            icon={<FilterOutlined />}
            style={{ position:'fixed', bottom:20, left:20, zIndex:50, opacity:0.9 }}
            onClick={() => setFilterDrawerVisible(true)}
          >
            {t('Filters')}
          </Button>
          <Drawer
            title={<Text style={{ color:'#FFF' }}>{t('Filters')}</Text>}
            width={(windowWidth > 400) ? 400 : windowWidth - 15}
            placement="left"
            onClose={() => setFilterDrawerVisible(false)}
            open={filterDrawerVisible}
            bodyStyle={{ padding:0 }}
            headerStyle={{ backgroundColor:'#243749' }}
            closeIcon={<CloseOutlined style={{ color:'#FFF' }} />}
            forceRender={true}
          >
            <MediaFilterForm
              filters={filters}
              updateBreacrumbs={updateBreacrumbs}
              withHeader={false}
              closeDrawer={() => setFilterDrawerVisible(false)}
            />
          </Drawer>
        </>
      )}
      <Layout>
        {(!screens.xl) ? (
          <>
          <Header style={{ height:'auto', paddingLeft:20, paddingRight:20 }}>
            {screens.sm && (
              <div style={styles.headerDrawerItem} onClick={() => setMenuDrawerVisible(true)}>
                <MdOutlineMenu style={{ color:'#FFF', fontSize:28 }} />
              </div>
            )}
            <HeaderLogo />
            {!screens.sm && (
              <div style={styles.headerDrawerItem} onClick={() => setMenuDrawerVisible(true)}>
                <MdOutlineMenu style={{ color:'#FFF', fontSize:28 }} />
              </div>
            )}
            {routeHaveBasket() && (
              <div style={styles.headerRightItem}>
                <Basket />
              </div>
            )}
            <div style={styles.headerRightItem}>
              <HeaderLogin />
            </div>
          </Header>
          <Drawer
            title={<Text style={{ color:'#FFF' }}>{t('Menu')}</Text>}
            width={(windowWidth > 400) ? 400 : windowWidth - 15}
            placement="left"
            onClose={() => setMenuDrawerVisible(false)}
            open={menuDrawerVisible}
            bodyStyle={{ padding:0 }}
            headerStyle={{ backgroundColor:'#243749' }}
            closeIcon={<CloseOutlined style={{ color:'#FFF' }} />}
          >
            {getMenu({mode: 'vertical'})}
          </Drawer>
          </>
        ) : (
          <Header style={{ paddingLeft:20, paddingRight:20 }}>
            <HeaderLogo />

            {routeHaveBasket() && (
              <div style={styles.headerRightItem}>
                <Basket />
              </div>
            )}
            <div style={styles.headerRightItem}>
              <HeaderLogin />
            </div>
            {!routeHaveFilterDrawer() && (
              <div style={{ ...styles.headerRightItem, width:'35%' }}>
                {getSearchInput()}
              </div>
            )}
            
            {getMenu({ mode:'horizontal', theme:'dark' })}
          </Header>
        )}
        <Layout style={{ minHeight:'calc(100vh)' }}>
          {routeHaveFilterDrawer() && (
            <div style={{ padding:10, backgroundColor:'#AAA' }}>
              {getSearchInput()}
            </div>
          )}

          {routeHaveSider() && (
            <Sider width={SIDER_WIDTH} theme="light" style={{ position:'relative' }} onMouseEnter={(event) => event.target.blur()}>
              <div style={(siderFileFilterFixed) ? styles.siderFileFilterFixed : styles.siderFileFilter}>
                <MediaFilterForm filters={filters} updateBreacrumbs={updateBreacrumbs} />
              </div>
            </Sider>
          )}

          <Layout>
            <Breadcrumb style={{ margin:'16px 24px' }}>
              {breadcrumbs.map((item) => (
                <Breadcrumb.Item key={"Breadcrumb" + item.route}>
                {item.route ? (
                  <Link to={item.route}>{item.title}</Link>
                ) : (
                  <span>{item.title}</span>
                )}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
            <Content onMouseEnter={(event) => event.target.blur()} style={{ padding: '0 24px 24px' }}>
              <Outlet context={[ updateBreacrumbs, updateMediaFilters, setMediaFiltersLoading ]} />
            </Content>
            <TemplateFooter />
          </Layout>
        </Layout>
        <BackTop style={{ right:20, bottom:20 }} />
      </Layout>
    </ModalProvider>
  )
}

const styles = {
  headerDrawerItem: {
    float:'left',
    height:64,
    display:'flex',
    alignItems:'center'
  },
  headerRightItem: {
    float:'right',
    height:64,
    display:'flex',
    marginLeft:20,
    alignItems:'center'
  },
  siderFileFilter: {
    overflow:'auto',
    maxHeight:'calc(100vh - 64px)'
  },
  siderFileFilterFixed: {
    position:'fixed',
    top:0,
    bottom:0,
    width:SIDER_WIDTH,
    overflow:'auto'
  }
}

export default MediaLayoutScreen
