import { memo, useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { TreeSelect } from 'antd';
import { APIRequest, isBadResponse, getFolderFromTree } from '../../models/APIRequest';
import { Context as AuthContext } from '../../context/AuthContext';
import { axiosError } from './../Notifications';

const FileTreeSelect = (props) => {
  const TAG = 'FileTreeSelect'

  //Template
  const { t } = useTranslation()
  const value = props?.value
  const onlyActive = (props?.onlyActive == false) ? false : true
  const onChange = props?.onChange
  const style = props?.style
  const multiple = (props?.multiple === true) ? true : false

  //State
  const { authState } = useContext(AuthContext)
  const [ states, setStates ] = useState({
    isLoaded: false,
    error: false,
    data: [],
    treeData: [],
  })

  /************************************************* STATE METHODS ********************************************************/
  const loadData = () => {
    console.log(TAG, 'LoadData')

    if (states.isLoaded) {
      return
    }

    console.log(TAG, 'Getting data...')

    getFolderFromTree(APIRequest(authState.language, authState.token), onlyActive).then(response => {
      if (isBadResponse(response.data)) {
        setStates({...states,
          isLoaded: true,
          error: true
        })

        return true
      }

      setStates({...states,
        isLoaded: true,
        data: convertDataToList(response.data.data.tree),
        treeData: convertDataOfApi(response.data.data.tree),
        error: false
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        isLoaded: true,
        error: true
      })
    })
  }

  const convertDataOfApi = (data) => {
    if (!data) {
      return
    }

    return data.map(item => {
      if (item.childrens) {
        return {
          title: (<span>{item.title}</span>),
          value: item.id,
          children: convertDataOfApi(item.childrens)
        }
      }

      return {
        title: (<span>{item.title}</span>),
        value: item.id,
      }
    })
  }

  const convertDataToList = (data) => {
    const list = []

    if (!data) {
      return list
    }

    const recursive = (data) => {
      data.forEach(item => {
        list.push({
          id: item.id,
          parentId: item.id_parent,
          title: item.title
        })

        if (item.childrens) {
          recursive(item.childrens)
        }
      })
    }

    recursive(data)

    return list
  }
  
  //Hooks
  useEffect(() => {
    if (states.isLoaded) {
      handleChange(value ? value : null)
    }

    loadData()
  }, [states.isLoaded])

  useEffect(() => {
    setStates({...states,
      isLoaded: false
    })
  }, [authState.language])

  //Template methods
  const handleChange = (value) => {
    if (typeof onChange !== 'function' || !value) {
      return
    }

    if (Array.isArray(value)) {
      const idFolders = value
      const folders = states.data.filter((item) => idFolders.includes(item.id))

      onChange(idFolders, folders)

    } else {
      const idFolder = value
      const folder = states.data.filter((item) => item.id === idFolder)

      onChange(idFolder, folder[0])
    }
  }

  /******************************************************* VIEW ***********************************************************/
  return (
    <TreeSelect
      treeData={states.treeData}
      multiple={multiple}
      defaultValue={value ? value : null}
      treeDefaultExpandedKeys={value ? (multiple) ? value : [value] : null}
      placeholder={(multiple) ? t('Please select at least one folder') : t('Please select a folder')}
      onChange={handleChange}
      disabled={!states.isLoaded}
      status={states.error ? 'error' : null}
      style={style}
      getPopupContainer={trigger => trigger.parentNode}
    />
  )
}

export default memo(FileTreeSelect)