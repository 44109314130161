import { useState, useEffect, useContext, createRef } from 'react';
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Form, Input, Button, Space, Divider, message } from 'antd';
import { Context as AuthContext } from '../../context/AuthContext';
import { APIRequest, isBadResponse, getParams, updateParams } from '../../models/APIRequest';
import { axiosError, formError } from '../../components/Notifications';
import ApiErrorResult from '../../components/ApiErrorResult';
import LoadingBlock from '../../components/LoadingBlock';
import FileTreeSelect from '../../components/form/FileTreeSelect';
import FormItemLang from '../../components/form/FormItemLang';
import ImageCropUploader from '../../components/form/ImageCropUploader';
import TableHeader from '../../components/table/TableHeader';
import { getBase64 } from '../../utils/FileUtils';
import { transformApiLangToFormLang, transformFormLangToFormData } from '../../utils/ApiUtils';
import { THUMB_FILE_ACCEPT_MIME, THUMB_FILE_SIZE_LIMIT } from "../../Constant";

export default function AdminHomeScreen() {
  const TAG = 'AdminHomeScreen'

  //Template
  const { t } = useTranslation()
  const [ updateBreacrumbs ] = useOutletContext()
  const formRef = createRef()
  const { TextArea } = Input

  //States
  const DEFAULT_STATES = {
    folders: [],
    intro: {},
    banner: null,
  }
  const { authState } = useContext(AuthContext)
  const [ formLang, setFormLang ] = useState(authState.language)
  const [ states, setStates ] = useState(DEFAULT_STATES)
  const [data, setData] = useState({
    isLoaded: false,
    isSubmitting: false,
    error: false,
    errorData: null,
    submitError: false,
    submitErrorData: null,
    uploadedBanner: null,
    uploadedBannerImage: null,
  })

  //Events
  useEffect(() => {
    if (updateBreacrumbs) {
      console.log(TAG, 'updateBreacrumbs')
      updateBreacrumbs([{title: t('Home'), 'route': null},])
    }

    if (data.isLoaded) {
      return
    }

    console.log(TAG, '[states.isLoaded]')

    loadData()
  }, [data.isLoaded])

  useEffect(() => {
    if (!data.isLoaded) {
      return
    }

    console.log(TAG, '[authState.language, authState.token]')

    setData({
      ...data,
      isLoaded: false
    })

  }, [authState.language, authState.token])

  //States methods
  const loadData = () => {
    console.log(TAG, 'loadData - Getting data...')

    getParams(APIRequest(authState.language, authState.token), 'home_intro,home_folders,home_banner').then(function (results) {
      if (isBadResponse(results.data)) {
        setData({
          ...data,
          isLoaded: true,
          error: true,
          errorData: results.data.data,
        })

        return
      }

      setStates({
        folders: results.data.data.params.home_folders.value.split(',').map(id => parseInt(id)),
        intro: transformApiLangToFormLang(results.data.data.params.home_intro.langs),
        banner: results.data.data.params.home_banner.value,
      })

      setData({
        ...data,
        isLoaded: true,
        error: false,
        errorData: null
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setData({
        ...states,
        isLoaded: true,
        error: true,
        errorData: null,
      })
    })
  }

  //Template methods
  const handleCopyLang = (name, currentLang, languages) => {
    const value = {}

    languages.forEach(lang => {
      value[lang.route] = states[name][currentLang]
    })

    const newStates = {...states, [name]: value}

    formRef.current.setFieldsValue(newStates)
    setStates(newStates)
  }

  const submitForm = () => {
    console.log(TAG, 'submitForm')

    //Loading
    setData({...data,
      isSubmitting: true
    })

    //Make formData
    const formDatas = {
      home_intro: true,
      ...transformFormLangToFormData({ home_intro: states.intro }),
      home_folders: states.folders,
      home_banner: data.uploadedBanner?.originFileObj,
    }

    //Method
    updateParams(APIRequest(authState.language, authState.token), formDatas).then(response => {
      if (isBadResponse(response.data)) {
        setData({
          ...data,
          isSubmitting: false,
          submitError: true,
          submitErrorData: {
            code: response.data?.data?.code,
            message: response.data?.data?.message,
            extraData: response.data?.data?.extra_data
          }
        })

        return
      }

      message.success(t('Update successfully'))

      setData({
        ...data,
        isSubmitting: false,
        submitError: false,
        submitErrorData: null
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setData({
        ...data,
        isSubmitting: false,
        submitError: true,
        submitErrorData: {
          code: null,
          message: thrown
        }
      })
    })
  }


  if (!data.isLoaded) {
    return (
      <LoadingBlock />
    )
  }

  if (data.error) {
    return (
      <ApiErrorResult errorData={data.errorData} actionTitle={t('Try to reload')} actionHandle={() => {
        setData({
          ...data,
          isLoaded: false,
          error: false,
          errorData: null,
        })
      }} />
    )
  }

  return (
    <main className='admin-container'>
      <TableHeader
        title={t('Home page')}
        showSearch={false}
        style={{ marginBottom:20 }}
      />

      {!data.isSubmitting && data.submitError && (
        <ApiErrorResult errorData={data.submitErrorData} template="alert" style={{ marginBottom:20 }} />
      )}

      <Form
        ref={formRef}
        layout="vertical"
        initialValues={states}
        onValuesChange={(changedValues, allValues) => {
          setStates({ ...states, ...allValues })
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => formError(errorFields, t)}
        onFinish={() => {
          submitForm()
        }}
      >
        <Form.Item
          label={t('Banner picture')}
          required={true}
        >
          <ImageCropUploader
            name="banner"
            image={data.uploadedBannerImage || states.banner}
            aspect={16/6}
            quality={1}
            acceptMime={THUMB_FILE_ACCEPT_MIME}
            fileSizeLimit={THUMB_FILE_SIZE_LIMIT}
            modalTitle={t('Customize image')}
            onChangeDone={(info) => {
              getBase64(info.file.originFileObj, (preview) => {
                setData({...data, uploadedBanner: info.file, uploadedBannerImage: preview})
              })
            }}
          />
        </Form.Item>
        <FormItemLang
          label={t('Introduction')}
          name='intro'
          currentLang={formLang}
          onLangChange={(value) => {setFormLang(value)}}
          onCopyLang={handleCopyLang}
        >
          <TextArea
            autoSize={{ minRows: 3, maxRows: 10 }}
          />
        </FormItemLang>
        <Form.Item
          label={t('Highlight folders')}
          name='folders'
          required={true}
          rules={[
            {required: true, message: t('Highlight folders is required')},
          ]}
        >
          <FileTreeSelect multiple={true} onChange={values => setStates({ ...states, folders: values})} />
        </Form.Item>
        <Divider />
        <Form.Item>
          <Space>
            <Button onClick={() => setStates({ ...states, isLoaded: false })}>
              {t('Cancel')}
            </Button>
            <Button type="primary" loading={data.isSubmitting} disabled={data.isSubmitting} onClick={() => {
              formRef.current.submit()
            }}>
              {t('Save')}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </main>
  )
}