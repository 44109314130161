import { useEffect, useState, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Menu } from 'antd';
import { UserOutlined, ShoppingCartOutlined, LogoutOutlined, ShareAltOutlined, SettingOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from "react-router-dom";
import { Context as AuthContext } from '../context/AuthContext';
import { isAdmin, isUser } from "../utils/AccessRightUtils";

const LogedMenu = (props) => {
  const TAG = 'LogedMenu'

  //Template
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  //States
  const [ menuSelectedKeys, setMenuSelectedKeys ] = useState(null)

  //Hooks
  useEffect(() => {
    const keys = []

    if (location.pathname.match(/\/account$/)) {
      keys.push('informations')
    } else {
      const matches = location.pathname.match(/\/account\/([a-zA-Z0-9._-]{1,})$/)
      
      if (matches && matches[1]) {
        keys.push(matches[1])
      }
    }

    setMenuSelectedKeys(keys)
  }, [location])
  
  //States
  const { authState, signout } = useContext(AuthContext)

  const getItems = () => {

    const items = [
      { label: t('Informations'), key: 'informations', icon: <UserOutlined /> },
    ]

    if (isUser(authState)) {
      items.push({ label: t('Baskets'), key: 'baskets', icon: <ShoppingCartOutlined /> })
      items.push({ label: t('Shares'), key: 'shares', icon: <ShareAltOutlined /> })
    }

    if (isAdmin(authState)) {
      items.push({ type: 'divider' })
      items.push({ label: t('Administration'), key: 'admin', icon: <SettingOutlined /> })
    }

    items.push({ type: 'divider' })
    items.push({ label: t('Log out'), key: 'logout', icon: <LogoutOutlined /> })

    return items
  }

  return <Menu
    {...props}
    items={getItems()}
    selectedKeys={menuSelectedKeys}
    onClick={({ item, key }) => {
      if (key === 'logout') {
        signout()
      } else if (key === 'admin') {
        navigate('/admin')
      } else {
        navigate('/account' + ((key !== 'informations') ? '/' + key : ''))
      }
    }}
  />
}

export default LogedMenu