import { useEffect, useState, useContext, createRef } from 'react';
import { useTranslation } from "react-i18next";
import {
  Grid,
  Modal,
  Form,
  Input,
  Switch,
  Checkbox,
  Button,
  Space,
  Select,
} from 'antd';
import { ModalContext } from "../../context/ModalContext";
import { Context as AuthContext } from '../../context/AuthContext';
import {
  APIRequest,
  isBadResponse,
  getAccessRights,
  getSystemRights,
  getAllBroadcastRestrictions,
  updateUser,
  addUser,
  delUserAvatar
} from '../../models/APIRequest';
import { CheckOutlined, CloseOutlined, LockOutlined } from '@ant-design/icons';
import { axiosError, objectError, formError } from '../Notifications';
import LoadingBlock from '../LoadingBlock';
import ApiErrorResult from '../ApiErrorResult';
import ImageCropUploader from '../form/ImageCropUploader';
import { transformObjectToFormData, transformApiObjectToFormData } from '../../utils/ApiUtils';
import { getBase64 } from '../../utils/FileUtils';
import { GLOBAL_MODAL_TYPES, THUMB_FILE_ACCEPT_MIME, THUMB_FILE_SIZE_LIMIT } from "../../Constant";

export default function UserModal() {
  const TAG = 'UserModal'

  //Template
  const { t } = useTranslation()
  const { useBreakpoint } = Grid
  const { Option } = Select
  const screens = useBreakpoint()
  const formRef = createRef()

  //States
  const DEFAULT_STATES = {
    name: null,
    email: null,
    password: null,
    avatar: null,
    id_system_right: null,
    id_broadcast_restriction: null,
    access_rights: [],
    active: null
  }
  const { modalType, showModal, modalProps, closeModal, modalOnOk } = useContext(ModalContext)
  const isEditing = (modalProps?.id && modalProps?.user)
  const isProtected = (isEditing && modalProps.user.id === 1)
  const { authState } = useContext(AuthContext)
  const [ formLayout, setFormLayout ] = useState('horizontal')
  const [ states, setStates ] = useState(DEFAULT_STATES)
  const [ data, setData ] = useState({
    isLoaded: false,
    error: false,
    isSubmitting: false,
    submitError: false,
    submitErrorData: null,
    uploadedAvatar: null,
    uploadedAvatarImage: null,

    accessRights: [],
    systemRights: [],
    broadcastRestrictions: [],
  })

  //Hooks
  useEffect(() => {
    if (modalType !== GLOBAL_MODAL_TYPES.USER || !showModal) {
      return
    }

    if (isEditing) {
      const accessRightUser = transformApiObjectToFormData(modalProps.user.access_rights, 'id')

      if (!accessRightUser.includes(11)) {
        accessRightUser.push(11)
      }

      setStates({
        name: modalProps.user.name,
        email: modalProps.user.email,
        active: modalProps.user.active,
        avatar: modalProps.user.avatar,
        id_system_right: modalProps.user.system_right.id,
        id_broadcast_restriction: modalProps.user.broadcast_restriction.id,
        access_rights: accessRightUser,
      })
    } else {
      setStates(DEFAULT_STATES)
    }
  }, [modalProps])

  useEffect(() => {
    if (modalType !== GLOBAL_MODAL_TYPES.USER || !showModal) {
      return
    }

    loadData()
  }, [showModal, data.isLoaded])

  useEffect(() => {
    setFormLayout((!screens.xl) ? 'vertical' : 'horizontal')
  }, [screens])
  
  //States methods
  const loadData = () => {
    console.log(TAG, 'loadData')

    if ( ! showModal || data.isLoaded) {
      return
    }

    console.log(TAG, 'Getting data...')

    Promise.all([
      getAccessRights(APIRequest(authState.language, authState.token)),
      getSystemRights(APIRequest(authState.language, authState.token)),
      getAllBroadcastRestrictions(APIRequest(authState.language, authState.token)),
    ])
    .then(function (results) {
      const accessRights = results[0]
      const accessRightsOption = []
      const systemRights = results[1]
      const systemRightsOption = []
      const broadcastRestrictions = results[2]
      const broadcastRestrictionsOption = []

      //Access right
      if (!isBadResponse(accessRights.data, false)) {
        accessRights.data.data.access_rights.forEach((item) => {
          accessRightsOption.push(item)
        })
      } else {
        setData({
          ...states,
          isLoaded: true,
          error: true,
          submitErrorData: {
            code: accessRights.data?.data?.code,
            message: accessRights.data?.data?.message,
            extraData: accessRights.data?.data?.extra_data
          }
        })

        return
      }

      //System right
      if (!isBadResponse(systemRights.data, false)) {
        systemRights.data.data.system_rights.forEach((item) => {
          systemRightsOption.push(item)
        })
      } else {
        setData({
          ...states,
          isLoaded: true,
          error: true,
          submitErrorData: {
            code: systemRights.data?.data?.code,
            message: systemRights.data?.data?.message,
            extraData: systemRights.data?.data?.extra_data
          }
        })

        return
      }

      //System right
      if (!isBadResponse(broadcastRestrictions.data, false)) {
        broadcastRestrictions.data.data.broadcast_restrictions.forEach((item) => {
          broadcastRestrictionsOption.push(item)
        })
      } else {
        setData({
          ...states,
          isLoaded: true,
          error: true,
          submitErrorData: {
            code: systemRights.data?.data?.code,
            message: systemRights.data?.data?.message,
            extraData: systemRights.data?.data?.extra_data
          }
        })

        return
      }

      setData({
        ...states,
        isLoaded: true,
        accessRights: accessRightsOption,
        systemRights: systemRightsOption,
        broadcastRestrictions: broadcastRestrictionsOption,
        error: false,
        errorData: null,
      })

    })
    .catch((thrown) => {
      console.log(TAG, thrown)

      setData({
        ...data,
        isLoaded: true,
        error: true,
        errorData: null,
      })
    })
  }

  //Template methods
  const submitForm = () => {
    console.log(TAG, 'submitForm')

    //Loading
    setData({...data,
      isSubmitting: true
    })

    //Make formData
    const formDatas = {
      name: states.name,
      email: states.email,
      active: (isProtected) ? true : states.active,
      avatar: data.uploadedAvatar?.originFileObj,
      id_system_right: states.id_system_right,
      id_broadcast_restriction: states.id_broadcast_restriction,
      ...transformObjectToFormData('access_rights', states.access_rights),
    }

    if (!isEditing || states.password) {
      formDatas['password'] = states.password
    }

    //Method
    const request = (isEditing)
      ? updateUser(APIRequest(authState.language, authState.token, null, 0), modalProps.id, formDatas)
      : addUser(APIRequest(authState.language, authState.token, null, 0), formDatas)

    request.then(response => {
      if (isBadResponse(response.data)) {
        setData({
          ...data,
          isSubmitting: false,
          submitError: true,
          submitErrorData: {
            code: response.data?.data?.code,
            message: response.data?.data?.message,
            extraData: response.data?.data?.extra_data
          }
        })

        return
      }

      resetModal()
      modalOnOk(response.data.data.user, true)
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setData({
        ...data,
        isSubmitting: false,
        submitError: true,
        submitErrorData: {
          code: null,
          message: thrown
        }
      })
    })
  }

  const handleDeleteAvatar = () => {
    if (data.uploadedAvatar) {
      setData({...data, uploadedAvatar: null, uploadedAvatarImage: null})
    } else if (states.avatar) {
      delUserAvatar(APIRequest(authState.language, authState.token, null, 0), modalProps.id).then(response => {
        if (isBadResponse(response.data)) {
          objectError(t)
          return
        }

        setStates({...states, avatar: null})
        modalOnOk(response.data.data.user, false)
      })
      .catch((thrown) => {
        console.log(TAG, thrown)
        axiosError(t)
      })
    }
  }

  const resetModal = () => {
    setStates(DEFAULT_STATES)
    setData({
      ...data,
      isSubmitting: false,
      submitError: false,
      submitErrorData: null,
      uploadedAvatar: null,
      uploadedAvatarImage: null,
      uploadedThumb: null,
      uploadedThumbImage: null,
    })
  }

  const closeThisModal = () => {
    resetModal()
    closeModal()
  }

  //Template
  const formItemLayout = 
    (formLayout === 'horizontal')
      ? {labelCol:{span: 6}, wrapperCol:{span: 12}}
      : null

  return (
    <Modal
      title={(isEditing) ? modalProps.user.name : t('Add user')}
      open={modalType !== GLOBAL_MODAL_TYPES.USER ? false : showModal}
      closable={!states.isSubmitting}
      zIndex={120}
      width={(!screens.md) ? 'calc(100% - 32px)' : 'calc(100% - 20%)'}
      destroyOnClose={true}
      onCancel={() => {
        closeThisModal()
      }}
      footer={
        <div style={{textAlign:'center'}}>
          <Space>
            <Button onClick={closeThisModal}>
              {t('Cancel')}
            </Button>
            <Button type="primary" loading={data.isSubmitting} disabled={(data.error || data.isSubmitting)} onClick={() => {
              formRef.current.submit()
            }}>
              {t('Save')}
            </Button>
          </Space>
        </div>
      }
    >
      {!data.isLoaded ? (
        <LoadingBlock />
      ) : (
        <>
          {(!data.isSubmitting && data.submitError) && (
            <ApiErrorResult errorData={data.submitErrorData} template="alert" status="error" style={{marginBottom:20}} />
          )}

          {data.error ? (
            <ApiErrorResult errorData={data.submitErrorData} actionTitle={t('Try to reload')} actionHandle={() => {
              setData({ ...states, error:false, errorData:null, isLoaded:false })
            }} />
          ) : (
            <Form
              ref={formRef}
              {...formItemLayout}
              layout={formLayout}
              initialValues={states}
              onValuesChange={(changedValues, allValues) => {
                setStates({ ...states, ...allValues })
              }}
              onFinishFailed={({ values, errorFields, outOfDate }) => formError(errorFields, t)}
              onFinish={() => {
                submitForm()
              }}
            >
              <Form.Item label={t('Avatar')}>
                <ImageCropUploader
                  name="avatar"
                  image={data.uploadedAvatarImage || states.avatar}
                  aspect={1/1}
                  quality={1}
                  acceptMime={THUMB_FILE_ACCEPT_MIME}
                  fileSizeLimit={THUMB_FILE_SIZE_LIMIT}
                  modalTitle={t('Avatar')}
                  onChangeDone={(info) => {
                    getBase64(info.file.originFileObj, (preview) => {
                      setData({...data, uploadedAvatar: info.file, uploadedAvatarImage: preview})
                    })
                  }}
                  onDelete={handleDeleteAvatar}
                />
              </Form.Item>
              <Form.Item
                label={t('Name')}
                name='name'
                rules={[
                  { required: true, message: t('Please enter a name') },
                  { max: 30, message: t('Name must not exceed %d% characters').replace('%d%', 30) },
                ]}
              >
                <Input
                  showCount
                  maxLength={30}
                />
              </Form.Item>
              <Form.Item
                label={t('Email')}
                name="email"
                required={true}
                rules={[
                  { required: true, message: t('Please enter a email') },
                  { type: 'email', message: t('Please enter a valid email') },
                  { max: 255, message: t('Email must not exceed %d% characters').replace('%d%', 255) }
                ]}
              >
                <Input type="email" disabled={isProtected} />
              </Form.Item>
              <Form.Item
                label={t('Password')}
                name='password'
                rules={[
                  { min: 8, message: t('Password must be at least %d% characters').replace('%d%', 8) },
                  { max: 16, message: t('Password must not exceed %d% characters').replace('%d%', 16) },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined style={{ color:'#CCC' }} />}
                  showCount
                  maxLength={16}
                />
              </Form.Item>
              <Form.Item
                label={t('User type')}
                name='id_system_right'
                required={true}
                rules={[
                  { required: true, message: t('Please select a user type') },
                ]}
              >
                <Select getPopupContainer={trigger => trigger.parentNode} disabled={isProtected}>
                  {data.systemRights.map(item => 
                    <Option value={item.id} key={"systemRightsOption" + item}>{item.name}</Option>
                  )}
                </Select>
              </Form.Item>
              <Form.Item
                label={t('Broadcast restriction')}
                name='id_broadcast_restriction'
                required={true}
                rules={[
                  { required: true, message: t('Please select a broadcast restriction') },
                ]}
              >
                <Select getPopupContainer={trigger => trigger.parentNode}>
                  {data.broadcastRestrictions.map(item => 
                    <Option value={item.id} key={"broadcastRestrictionsOption" + item}>{item.name}</Option>
                  )}
                </Select>
              </Form.Item>
              <Form.Item
                label={t('Access rights')}
                name='access_rights'
              >
                <Checkbox.Group style={{ marginTop:6 }}>
                  {data.accessRights.map(item => 
                    <span key={"accessRightsOption" + item.id}>
                      <Checkbox disabled={item.code === 'public'} value={item.id}>{item.name}</Checkbox>
                      <br />
                    </span>
                  )}
                </Checkbox.Group>
              </Form.Item>
              <Form.Item
                label={t('Active')}
                name='active'
                required={true}
              >
                <Switch
                  checked={states.active}
                  checkedChildren={<><CheckOutlined /> {t('Yes')}</>}
                  unCheckedChildren={<><CloseOutlined /> {t('No')}</>}
                  disabled={isProtected}
                />
              </Form.Item>
            </Form>
          )}
        </>
      )}
    </Modal>
  )
}
