import { memo, useContext, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Modal, Alert, Space, List, Divider, Typography } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import { Context as AuthContext } from '../../context/AuthContext';
import { APIRequest, isBadResponse, restoreFiles } from '../../models/APIRequest';
import FileTreeSelect from '../form/FileTreeSelect';
import { axiosError } from '../Notifications';
import { isContributor } from '../../utils/AccessRightUtils';

const FileRestoreModal = (props) => {
  const TAG = 'FileRestoreModal'

  //Template
  const { t } = useTranslation()
  const { Text } = Typography
  const files = props?.files
  const widgetType = props?.widgetType
  const widgetLabel = (props?.widgetLabel) ? props?.widgetLabel : t('Restore')
  const widgetProps = props?.widgetProps
  const onRestoreFiles = props?.onRestoreFiles
  
  //States
  const DEFAULT_STATES = {
    isModalVisible: false,
    isSubmitting: false,
    folderId: props?.idFolder,
    formValidationError: false
  }
  const { authState } = useContext(AuthContext)
  const [ states, setStates ] = useState(DEFAULT_STATES)

  //Check right & availability
  if (!isContributor(authState)) {
    console.log(TAG, 'Bad rights')
    return null
  } else if (!files || !files.length) {
    console.log(TAG, 'Bad files prop (array require)')
    return null
  }

  //Methods template
  const showModal = () => {
    setStates({...states, isModalVisible: true})
  }

  const hideModal = () => {
    setStates({
      ...states,
      ...DEFAULT_STATES
    })
  }

  const handleRestoreFiles = () => {
    console.log(TAG, 'handleRestoreFiles')

    if (!states.folderId) {
      setStates({ ...states, formValidationError: true })
      return
    }

    setStates({ ...states, isSubmitting: true, formValidationError: false })

    const fileIds = []
    files.forEach((file) => fileIds.push(file.id))

    restoreFiles(APIRequest(authState.language, authState.token), fileIds, states.folderId).then(function (results) {
      if (isBadResponse(results.data)) {
        axiosError(t)

        setStates({ ...states, isSubmitting: false })

        return
      }

      if (typeof onRestoreFiles === 'function') {
        onRestoreFiles(results.data.data.files_restored)
      }
      
      hideModal()
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({ ...states, isSubmitting: false })
    })
  }

  const getWidget = () => {
    if (widgetType === 'menuItem') {
      return (
        <button
          type="button"
          className="menu-item-button"
          onClick={showModal}
        >
          <RollbackOutlined /> {widgetLabel}
        </button>
      )
    } else if (widgetType === 'button') {
      return (
        <Button
          icon={<RollbackOutlined />}
          onClick={showModal}
          {...widgetProps}
        >
          {widgetLabel}
        </Button>
      )
    } else {
      return 'Unknow widget type'
    }
  }

  //Modal
  return (
    <>
      {getWidget()}

      <Modal
        title={t('Restore media')}
        open={states.isModalVisible}
        zIndex={110}
        onCancel={hideModal}
        footer={[
          <Button type="link" key="restoreSelectionNo" onClick={hideModal}>
            {t('Cancel')}
          </Button>,
          <Button type="primary" key="restoreSelectionYes" disabled={states.isSubmitting} loading={states.isSubmitting} onClick={handleRestoreFiles}>
            {t('Restore')}
          </Button>,
        ]}
      >
        <Space direction="vertical" style={{ width:'100%' }}>
          <List
            size="small"
            dataSource={files}
            renderItem={item => (
              <List.Item style={{ display:'flex' }}>
                <div style={{ width:50, textAlign:'center', marginRight:10 }}>
                  <img src={item.thumb} style={{ maxWidth:50, maxHeight:30 }} alt={item.title} />
                </div>
                <div style={{ flex:1 }}>
                  {item.title}
                </div>
              </List.Item>
            )}
          />

          <Divider style={{ marginTop:10, marginBottom:10 }} />
          <Text strong>{t('Select destination folder')}</Text>
          {states.formValidationError && (
            <Alert
              key="restoreSelectionError"
              description={t('Please select a folder.')}
              type="error"
              onClose={() => setStates({...states, formValidationError:false })}
              showIcon
              closable
            />
          )}
          <FileTreeSelect
            value={states.idFolder}
            onChange={(value) => {
              setStates({
                ...states,
                folderId: value
              })
            }}
            style={{ width:'100%' }}
          />
        </Space>
      </Modal>
    </>
  )
}

export default memo(FileRestoreModal)
