import { useEffect, useState, useContext, createRef } from 'react';
import { useTranslation } from "react-i18next";
import {
  Grid,
  Modal,
  Form,
  Input,
  Button,
  Space,
} from 'antd';
import { ModalContext } from "../../context/ModalContext";
import { Context as AuthContext } from '../../context/AuthContext';
import {
  APIRequest,
  isBadResponse,
  updateWatermark,
  addWatermark
} from '../../models/APIRequest';
import { axiosError, formError } from '../Notifications';
import ApiErrorResult from '../ApiErrorResult';
import ImageUploader from '../form/ImageUploader';
import { getBase64 } from '../../utils/FileUtils';
import { GLOBAL_MODAL_TYPES, WATERMARK_FILE_ACCEPT_MIME, WATERMARK_FILE_SIZE_LIMIT } from "../../Constant";

export default function WatermarkModal() {
  const TAG = 'WatermarkModal'

  //Template
  const { t } = useTranslation()
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()
  const formRef = createRef()

  //States
  const DEFAULT_STATES = {
    name: null,
    image: null
  }
  const { modalType, showModal, modalProps, closeModal, modalOnOk } = useContext(ModalContext)
  const isEditing = (modalProps?.id && modalProps?.watermark)
  const { authState } = useContext(AuthContext)
  const [ formLayout, setFormLayout ] = useState('horizontal')
  const [ states, setStates ] = useState(DEFAULT_STATES)
  const [ data, setData ] = useState({
    isSubmitting: false,
    submitError: false,
    submitErrorData: null,
    uploadedWatermark: null,
    uploadedWatermarkImage: null
  })

  //Hooks
  useEffect(() => {
    if (modalType !== GLOBAL_MODAL_TYPES.WATERMARK || !showModal) {
      return
    }

    if (isEditing) {
      setStates({
        name: modalProps.watermark.name,
        image: modalProps.watermark.image
      })
    } else {
      setStates(DEFAULT_STATES)
    }
  }, [modalProps])

  useEffect(() => {
    setFormLayout((!screens.xl) ? 'vertical' : 'horizontal')
  }, [screens])

  //Template methods
  const submitForm = () => {
    console.log(TAG, 'submitForm')

    //Loading
    setData({...data,
      isSubmitting: true
    })

    //Make formData
    const formDatas = {
      name: states.name,
      watermark: data.uploadedWatermark?.originFileObj
    }

    if (!isEditing || states.password) {
      formDatas['password'] = states.password
    }

    //Method
    const request = (isEditing)
      ? updateWatermark(APIRequest(authState.language, authState.token, null, 0), modalProps.id, formDatas)
      : addWatermark(APIRequest(authState.language, authState.token, null, 0), formDatas)

    request.then(response => {
      if (isBadResponse(response.data)) {
        setData({
          ...data,
          isSubmitting: false,
          submitError: true,
          submitErrorData: {
            code: response.data?.data?.code,
            message: response.data?.data?.message,
            extraData: response.data?.data?.extra_data
          }
        })

        return
      }

      resetModal()
      modalOnOk(response.data.data.watermark, true)
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setData({
        ...data,
        isSubmitting: false,
        submitError: true,
        submitErrorData: {
          code: null,
          message: thrown
        }
      })
    })
  }

  const resetModal = () => {
    setStates(DEFAULT_STATES)
    setData({
      ...data,
      isSubmitting: false,
      submitError: false,
      submitErrorData: null,
      uploadedWatermark: null,
      uploadedWatermarkImage: null,
      uploadedThumb: null,
      uploadedThumbImage: null,
    })
  }

  const closeThisModal = () => {
    resetModal()
    closeModal()
  }

  //Template
  const formItemLayout = 
    (formLayout === 'horizontal')
      ? {labelCol:{span: 6}, wrapperCol:{span: 12}}
      : null

  return (
    <Modal
      title={(isEditing) ? modalProps.watermark.title : t('Add watermark')}
      open={modalType !== GLOBAL_MODAL_TYPES.WATERMARK ? false : showModal}
      closable={!states.isSubmitting}
      zIndex={120}
      width={(!screens.md) ? 'calc(100% - 32px)' : 'calc(100% - 20%)'}
      destroyOnClose={true}
      onCancel={() => {
        closeThisModal()
      }}
      footer={
        <div style={{textAlign:'center'}}>
          <Space>
            <Button onClick={closeThisModal}>
              {t('Cancel')}
            </Button>
            <Button type="primary" loading={data.isSubmitting} disabled={data.isSubmitting} onClick={() => {
              formRef.current.submit()
            }}>
              {t('Save')}
            </Button>
          </Space>
        </div>
      }
    >

      {(!data.isSubmitting && data.submitError) && (
        <ApiErrorResult errorData={data.submitErrorData} template="alert" status="error" style={{marginBottom:20}} />
      )}

      <Form
        ref={formRef}
        {...formItemLayout}
        layout={formLayout}
        initialValues={states}
        onValuesChange={(changedValues, allValues) => {
          setStates({ ...states, ...allValues })
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => formError(errorFields, t)}
        onFinish={() => {
          submitForm()
        }}
      >
        <Form.Item label={t('Watermark')}>
          <ImageUploader
            name="image"
            acceptMime={WATERMARK_FILE_ACCEPT_MIME}
            fileSizeLimit={WATERMARK_FILE_SIZE_LIMIT}
            image={data.uploadedWatermarkImage || states.image}
            onChangeDone={(info) => {
              getBase64(info.file.originFileObj, (preview) => {
                setData({...data, uploadedWatermark: info.file, uploadedWatermarkImage: preview})
              })
            }}
            onDelete={null}
          />
        </Form.Item>

        <Form.Item
          label={t('Name')}
          name='name'
          rules={[
            { required: true, message: t('Please enter a name') },
            { max: 30, message: t('Name must not exceed %d% characters').replace('%d%', 30) },
          ]}
        >
          <Input
            showCount
            maxLength={30}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
