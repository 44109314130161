import { memo, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Card, Button, Space, Typography, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Context as AuthContext } from '../../context/AuthContext';
import { ModalContext } from "../../context/ModalContext";
import { APIRequest, isBadResponse, delFolder } from '../../models/APIRequest';
import { axiosError } from '../Notifications';
import { canEditFolder } from '../../utils/AccessRightUtils';
import { GLOBAL_MODAL_TYPES, FOLDER_THUMB_DEFAULT } from '../../Constant';

const FolderBlockList = (props) => {
  const TAG = 'FolderBlockList'

  //Props
  const folder = props?.folder
  const onClick = props?.onClick
  const onDeleteFolder = props?.onDeleteFolder
  const onUpdateFolder = props?.onUpdateFolder

  //Template
  const { t } = useTranslation()
  const { Paragraph } = Typography
  const { authState } = useContext(AuthContext)
  const { openModal, closeModal } = useContext(ModalContext)

  if (!folder) {
    return (<>Bad folder props</>)
  }

  //Template methods
  const handleDel = () => {
    if (!folder?.id) {
      return
    }

    delFolder(APIRequest(authState.language, authState.token), folder.id).then((results) => {
      if (isBadResponse(results.data)) {
        axiosError(t)
        return
      }

      onDeleteFolder(folder.id, results.data.data.files_moved_to_parent)
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)
    })
  }

  const getActions = () => {
    const actions = []

    if (!authState.isLogged || !canEditFolder(authState)) {
      return actions
    }

    return (
      <Space>
        <Button
          shape="circle"
          icon={<EditOutlined />}
          onClick={() => {
            openModal(
              GLOBAL_MODAL_TYPES.FOLDER,
              { id: folder.id, folder: folder },
              (folderUpdated, close) => {
                if (folderUpdated) {
                  onUpdateFolder(folderUpdated.id, folderUpdated)
                }

                if (close) {
                  closeModal()
                }
              },
              null
            )
            //setIsHovered(false)
          }}
        />
        <Popconfirm
          title={
            <>
              {t('Are you sure to delete this folder?')}
              <br />
              {t('All subfolders and media inside this folder will be moved to its parent folder.')}
            </>
          }
          onConfirm={() => handleDel()}
          cancelButtonProps={{ type:'primary' }}
          okButtonProps={{ danger: true, type:'dashed' }}
          okText={t('Yes')}
          cancelText={t('No')}
        >
          <Button
            shape="circle"
            danger
            icon={<DeleteOutlined />}
          />
        </Popconfirm>
      </Space>
    )
  }

  return (
    <Card
      hoverable
      extra={getActions()}
      title={folder.title}
      bodyStyle={{
        height:220,
        backgroundColor:'#FFF',
        backgroundImage:'url(' + ((folder.thumb) ? folder.thumb : FOLDER_THUMB_DEFAULT) + ')',
        backgroundSize:'cover',
        backgroundPosition:'center',
        backgroundRepeat:'no-repeat',
        padding:0
      }}
      style={{border:0}}
    >
      <div
        onClick={onClick}
        style={{
          position:'absolute',
          top:65,
          right:0,
          left:0,
          bottom:0,
          cursor:'pointer'
        }}
        >
          {folder.description && (
            <div style={{
                position:'absolute',
                bottom:0,
                left:0,
                right:0,
                backgroundColor:'rgba(225, 225, 225, .85)',
                padding:10,
                transition:'opacity .25s',
                opacity: 100
              }}
            >
              <Paragraph ellipsis={{ rows:2 }} style={{ textAlign:'center', margin:0 }}>
                {folder.description}
              </Paragraph>
            </div>
          )}
      </div>
    </Card>
  );
}

export default memo(FolderBlockList)