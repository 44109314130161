import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Context as AuthContext } from "../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { APIRequest, isBadResponse, loginFromApi } from '../../models/APIRequest';
import { FOLDER_ROOT_ID } from "../../Constant";
import { axiosError } from "../Notifications";
import ApiErrorResult from "../ApiErrorResult";
import { Layout, Typography, Spin, Image } from "antd";

export default function ApiLogin() {
  const TAG = "ApiLogin"
  const { authState, signin } = useContext(AuthContext)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const { Content } = Layout
  const { Text } = Typography
  const encryptedToken = params?.encryptedToken ? params.encryptedToken : null
  const [ states, setStates ] = useState({
    isLoaded: false,
    error: false,
    errorData: null
  })

  useEffect(() => {
    if (encryptedToken === null) {
      console.log(TAG, "encryptedToken IS NULL")

      setStates({
        ...states,
        isLoaded: true,
        error: true
      })

      return
    }

    loginFromApi(APIRequest(authState.language), encryptedToken).then(response => {
      console.log(TAG, "loginFromApi")

      if (isBadResponse(response.data)) {
        console.log(TAG, "loginFromApi:isBadResponse")

        setStates({
          ...states,
          isLoaded: true,
          error: true,
          errorData: response.data.data
        })

        return
      }

      signin({
        token: response.data.data.token,
        data: response.data.data.session_data
      })

      setTimeout(() => {
        navigate('/media/explore/' + FOLDER_ROOT_ID)
      }, 500)
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({...states, error: true, errorData: null})
    })
  }, []);

  if (!states.isLoaded) {
    return (

      <Layout style={{minHeight:'100vh'}}>
        <Content style={{display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center'}}>
          <Image
            src="/asset/system/sodi-logo-black.png"
            preview={false}
            style={{ marginBottom:50 }}
          />

          <Spin size="large" />
          <Text type="secondary" style={{marginLeft: '1rem'}}>{t('We try to connect you')}...</Text>
        </Content>
      </Layout>
    )
  }

  if (states.error) {
    return (
      <ApiErrorResult errorData={states.errorData} />
    )
  }
}