import { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Outlet, useParams, useNavigate, useLocation, useOutletContext } from "react-router-dom";
import { Empty, Divider, Typography, List, Alert, Input, Button, Space, Result } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined, LockOutlined, DeleteOutlined } from '@ant-design/icons';
import { APIRequest, isBadResponse, getShare } from '../../models/APIRequest';
import { Context as AuthContext } from '../../context/AuthContext';
import LoadingBlock from '../../components/LoadingBlock';
import FileBlockList from '../../components/list/FileBlockList';
import DownloadModal from '../../components/DownloadModal';
import ApiErrorResult from '../../components/ApiErrorResult';
import { axiosError } from '../../components/Notifications';
import { getStorageData, setStorageData } from '../../utils/StorageUtils';

export default function MediaShareScreen(props) {
  const TAG = 'MediaShareScreen'

  //Template
  const { t } = useTranslation()
  const { Title, Paragraph } = Typography
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [ updateBreacrumbs ] = useOutletContext()
  const idShare = params?.idShare ? params.idShare : null
  const secureKey = params?.secureKey ? params.secureKey : null

  //State
  const DEFAULT_STATES = {
    isLoaded: false,
    idShare: idShare,
    secureKey: secureKey,
    expired: false,
    deleted: false,
    password: null,
    passwordModalVisible: false,
    badPassword: false,
    error: false,
    errorData: null,
    share: null,
    fileModalOpened: false
  }
  const { authState } = useContext(AuthContext)
  const [ states, setStates ] = useState(DEFAULT_STATES)

  //Hooks
  useEffect(() => {
    if (!states.isLoaded) {
      return
    }

    console.log(TAG, '[authState.language]')

    setStates({
      ...states,
      ...DEFAULT_STATES,
      preloadStates: false
    })
  }, [authState.language])

  useEffect(() => {
    console.log(TAG, '[location]')

    let oldStates = getStorageData(TAG)

    if (oldStates) {
      oldStates = JSON.parse(oldStates)

      if (oldStates && (!oldStates.isLoaded || oldStates?.idShare !== idShare || oldStates?.secureKey !== secureKey)) {
        oldStates = null
      }
    } else {
      oldStates = null
    }
    
    setStates({
      ...states,
      ...DEFAULT_STATES,
      ...oldStates,
      preloadStates: false,
      fileModalOpened: checkOpenedFile()
    })
  }, [location])

  useEffect(() => {
    loadData()

    if (updateBreacrumbs) {
      console.log(TAG, 'updateBreacrumbs')
      updateBreacrumbs([
        {title: t('Share'), 'route': null}
      ])
    }
  }, [states.preloadStates, states.isLoaded])

  useEffect(() => {
    console.log(TAG, '[states]')

    try {
      setStorageData(TAG, JSON.stringify(states))
    } catch(e) {
      console.log(TAG, e)
    }
  }, [states])

  /************************************************* STATE METHODS ********************************************************/
  const loadData = () => {
    console.log(TAG, 'LoadData')

    if (states.preloadStates || states.isLoaded) {
      return
    }

    console.log(TAG, 'Getting data...')

    getShare(APIRequest(authState.language, authState.token), idShare, secureKey, states.password).then(response => {
      const apiResponseCode = response.data?.data?.code

      if (apiResponseCode === "SHARE_REQUIRE_PASSWORD" || apiResponseCode === "SHARE_BAD_PASSWORD") {
        setStates({
          ...states,
          preloadStates: false,
          isLoaded: true,
          passwordVisible: true,
          badPassword: (apiResponseCode === "SHARE_BAD_PASSWORD"),
          error: false,
          errorData: null,
        })

        return

      } else if (apiResponseCode === "SHARE_DELETE") {
        setStates({
          ...states,
          preloadStates: false,
          isLoaded: true,
          expired: false,
          deleted: true,
          error: false,
          errorData: null,
        })

        return

      } else if (apiResponseCode === "SHARE_EXPIRED") {
        setStates({
          ...states,
          preloadStates: false,
          isLoaded: true,
          expired: true,
          error: false,
          errorData: null,
        })

        return

      } else if (isBadResponse(response.data)) {
        setStates({
          ...states,
          preloadStates: false,
          isLoaded: true,
          passwordModalVisible: false,
          error: true,
          errorData: response.data.data
        })

        return
      }

      setStates({
        ...states,
        preloadStates: false,
        isLoaded: true,
        passwordVisible: false,
        share: response.data.data.share,
        error: false,
        errorData: null,
      })
    })
    .catch((thrown) => {
      console.log(TAG, thrown)
      axiosError(t)

      setStates({
        ...states,
        preloadStates: false,
        isLoaded: true,
        passwordVisible: false,
        error: true,
        errorData: null,
      })
    })
  }

  /*********************************************** TEMPLATE METHODS ********************************************************/
  const checkOpenedFile = () => {
    return location.pathname.match(/(file)\/([0-9]{1,})/)
  }

  const handleFile = (id, fileCode) => {
    if (states.fileModalOpened || !location.pathname.match(/\/media\/(share)/)) {
      return
    }

    setStates({ ...states, fileModalOpened: true })

    navigate('/media/share/' + idShare + '/' + secureKey + '/file/' + id + '/' + fileCode)
  }

  const onDeleteFile = (fileId) => {
    console.log(TAG, 'onDeleteFile')

    const files = states.share.files.filter(file => file.id !== fileId)

    setStates({ ...states, share: { ...states.share, files: files } })
  }

  const onUpdateFile = (fileId, updatedFile) => {
    console.log(TAG, 'onUpdateFile (' + fileId + ')')

    let fileKey = -1

    states.share.files.forEach((item, key) => {
      console.log(TAG, item.id + ' === ' + fileId)

      if (item.id === fileId) {
          fileKey = key
      }
    })

    if (fileKey === -1) {
      console.log(TAG, 'onUpdateFile - fileKey not found!')
      return
    }

    const files = Object.assign([], states.share.files)
    files[fileKey] = updatedFile

    setStates({ ...states, share: { ...states.share, files: files } })
  }

  const getStates = () => {
    return states
  }

  const getParentName = () => {
    return TAG
  }

  const getFileList = () => {
    if (!states.share?.files || !states.share.files.length) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <span>
              {t('No media found')}...
            </span>
          }
        />
      )
    }

    return (
      <>
        <DownloadModal
          files={ states.share.files }
          share={{
            id: states.idShare,
            secureKey: states.secureKey,
            password: states.password
          }}
          widgetType="button"
          widgetProps={{
            block: true,
            type:'primary',
            size:'large',
            style: { maxWidth: 450, display:'block', margin:'0 auto' }
          }}
        />

        <List
          grid={{
            gutter: [16, 16],
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 4,
            xxl: 4,
          }}
          dataSource={states.share.files}
          rowKey={item => item.id + item.date_upd}
          renderItem={item => (
            <List.Item style={{marginBottom:0}} key={item.id}>
              <FileBlockList
                file={item}
                share={{
                  id: states.idShare,
                  secureKey: states.secureKey,
                  password: states.password
                }}
                disabled={item.opened}
                onClick={() => handleFile(item.id, item.code)}
                onDeleteFile={onDeleteFile}
                onUpdateFile={onUpdateFile}
                canAddBasket={false}
                canShare={false}
              />
            </List.Item>
          )}
        />
      </>
    )
  }

  if (states.preloadStates || !states.isLoaded) {
    return (
      <LoadingBlock />
    )
  }

  if (states.error) {
    return (
      <ApiErrorResult errorData={states.errorData} actionTitle={t('Try to reload')} actionHandle={() => {
        setStates({
          ...states,
          error: false,
          errorData: null,
          isLoaded: false,
          passwordVisible: false,
          badPassword: false,
          expired: false
        })
      }} />
    )
  }

  if (states.expired) {
    return (
      <Result
        icon={<DeleteOutlined style={{ color:'inherit' }} />}
        title={t('Sorry this share has expired.')}
      />
    )
  }

  if (states.deleted) {
    return (
      <Result
        icon={<DeleteOutlined style={{ color:'inherit' }} />}
        title={t('Sorry this share has deleted.')}
      />
    )
  }

  if (states.passwordVisible) {
    return (
      <div style={{ maxWidth:400, margin:'0 auto' }}>
        <Space direction="vertical">
          {states.badPassword && (
            <Alert
              key="shareBadPassword"
              message={t('Incorrect password.')}
              type="error"
              showIcon
              closable
              style={{ textAlign:'center' }}
            />
          )}

          <Alert
            key="shareRequirePassword"
            message={<><LockOutlined /> {t('Please enter the password of this share.')}</>}
            description={(
              <>
                <Input.Password
                  size="large"
                  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  style={{ margin:'20px 0' }}
                  onChange={(e) => {
                    setStates({ ...states, password: e?.target?.value })
                  }}
                />
                <Button type="primary" block size="large" onClick={() => setStates({ ...states, isLoaded: false })}>
                  {t('Access to sharing')}
                </Button>
              </>
            )}
            type="info"
            style={{ textAlign:'center' }}
          />
        </Space>
      </div>
    )
  }

  return (
    <main>
      <Title style={{ textAlign:'center' }}>{states.share.title}</Title>
      {states.share.description !== "" && (
        <Paragraph style={{ textAlign:'center' }}>{states.share.description}</Paragraph>
      )}
      <Divider />

      {getFileList()}

      <Outlet context={[ onDeleteFile, onUpdateFile, getParentName, getStates ]} />
    </main>
  );
}